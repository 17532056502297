import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "set-password"
}

import { ref } from 'vue'
import { ElMessageBox } from 'element-plus'
import * as Api from '@/utils/api'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useUsersStore } from '@/pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'password',
  props: {
    password: {
        type: String,
        default: ''
    },
    isShowSet: {
        type: Boolean,
        default: false
    },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()
const userouter = useRouter()
const store = useUsersStore()

const props = __props
const emit = __emit

const passwordLength = ref(0)

const inputRef = ref(null)
const isFocus = ref(false)

const handleInput = (value) => {
    const password = value.replace(/[^0-9]/g, '')
    passwordLength.value = password.length
    emit('update:modelValue', password);
    if (password.length == 6) {
        inputRef.value?.blur()
    }
}

const checkVal = () => {
    isFocus.value = false;
    if (passwordLength.value != 6) {
        passwordLength.value = 0
        emit('update:modelValue', '')
    }
}

const focus = () => {
    if (props.isShowSet) {
        checkNeedUpdate();
    }
    isFocus.value = true;
    inputRef.value?.focus();
    passwordLength.value = 0
    emit('update:modelValue', '')
}

const toSetting = () => {
    userouter.push({
        path: '/userCenter/settings',
        query: { type: 'payPassWord' }
    })
}

const checkNeedUpdate = () => {
    const NeedUpdatePayPassword = store.NeedUpdatePayPassword
    console.log(NeedUpdatePayPassword);
    if (NeedUpdatePayPassword == null) {
        Api.Get('user/api/CustomerInfo/', {}).then(
            (res: any) => {
                store.NeedUpdatePayPassword = res.Customer.NeedUpdatePayPassword
                if (res.Customer.NeedUpdatePayPassword) {
                    ShowUpdate()
                }
            })
    } else if (NeedUpdatePayPassword == true) {
        ShowUpdate()
    }
}

const ShowUpdate = () => {
    ElMessageBox.alert(t('您的支付密码已有较长时间未修改，为保障安全，请尽快进行更新'), t('提示'), {
        confirmButtonText: t('设置新支付密码'),
        type: 'success',
    }).then(() => {
        toSetting()
    })
}


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_link = _resolveComponent("el-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['password', { isFocus: isFocus.value }])
  }, [
    _createElementVNode("ul", {
      onClick: focus,
      class: "password-box"
    }, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (index) => {
        return _createElementVNode("li", {
          class: _normalizeClass({ highlight: index - 1 < passwordLength.value, focusBox: index - 1 == passwordLength.value && isFocus.value })
        }, null, 2)
      }), 64))
    ]),
    _createVNode(_component_el_input, _mergeProps(_ctx.$attrs, {
      ref_key: "inputRef",
      ref: inputRef,
      value: __props.password,
      onInput: handleInput,
      onBlur: checkVal,
      placeholder: "请输入数字"
    }), null, 16, ["value"]),
    (__props.isShowSet)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_link, {
            type: "primary",
            onClick: toSetting
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('修改密码')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})