import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "HomeInfo"
}
const _hoisted_3 = { class: "HL" }
const _hoisted_4 = { class: "ShopName" }
const _hoisted_5 = { class: "HR" }
const _hoisted_6 = { class: "CategorieTitle" }
const _hoisted_7 = { class: "CategorieList" }
const _hoisted_8 = { class: "ProductSearch" }
const _hoisted_9 = { class: "Classification" }
const _hoisted_10 = { class: "Categorie" }
const _hoisted_11 = { style: {"flex":"1"} }
const _hoisted_12 = { style: {"display":"flex"} }
const _hoisted_13 = { class: "SellerProdustList" }
const _hoisted_14 = { class: "ProductPagination" }

import { ref, reactive, onMounted } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import PaginationHook from '@/hooks/pagination'
import Loading from '@/components/loading.vue'
import * as Api from '@/utils/api'
import CommodityCard from '@/components/CommodityCard.vue'
import ProductSkeleton from '@/components/ProductSkeleton.vue'
import EmptyArray from '@/components/EmptyArray.vue'
import FilterType from '@/components/FilterType.vue'

import { useRoute, useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'mall-home',
  setup(__props) {

const route = useRoute()
const Router = useRouter()
const HomeCategories = ref(null)
const Categories = ref(null)
const HomeInfo = ref(null)
const SelectCategory = ref(0)
const total = ref(0)

const Filters = reactive({
  typeId: 1,
  MaxPrice: undefined,
  MinPrice: undefined,
})

const ShopCategoryId = ref(null)

const ChangeCategory = (id) => {
  SelectCategory.value = id
}

const SellerProdustList = ref(null)
const SellerProdusts = () => {
  SellerProdustList.value = null
  Api.Post('sellers/api/SellerProdusts/', {
    CategoryId: [],
    MaxPrice: Filters.MaxPrice,
    MinPrice: Filters.MinPrice,
    Page: pageNum.value,
    PageSize: pageSize.value,
    ProductsQuerySortType: Filters.typeId,
    Score: null,
    ShopCategoryId: ShopCategoryId.value,
    ShopId: route.query.ShopId,
    QueryShopType: 1,
  }).then((res: any) => {
    SellerProdustList.value = res.Goods
    total.value = res.Total
  })
}

const { pageNum, pageSize, handleCurrentChange, handleSizeChange } =
  PaginationHook(SellerProdusts)

onMounted(() => {
  pageSize.value = 15
  SellerProdusts()
  Api.Get('sellers/api/SellerHome/', {
    shopId: route.query.ShopId,
  }).then((res: any) => {
    HomeCategories.value = res.HomeCategories
    HomeCategories.value.forEach((ele) => {
      Api.Post('sellers/api/SellerProdusts/', {
        CategoryId: [],
        Page: 1,
        PageSize: 7,
        ShopCategoryId: ele.CategoryId,
        ShopId: route.query.ShopId,
        QueryShopType: 1,
      }).then((res: any) => {
        ele.Products = res.Goods
      })
    })
    HomeInfo.value = res.HomeInfo
    if (res.HomeInfo.ShopTypeId != 1) {
      Router.push({
        path: '/LodaFood',
      })
    }
  })

  Api.Get('sellers/api/SellerCategory/', {
    shopId: route.query.ShopId,
  }).then((res: any) => {
    Categories.value = res.Categories
    // while (condition) {}
  })
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_ChatDotRound = _resolveComponent("ChatDotRound")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Timer = _resolveComponent("Timer")!
  const _component_Goods = _resolveComponent("Goods")!
  const _component_Star = _resolveComponent("Star")!
  const _component_Shop = _resolveComponent("Shop")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (HomeInfo.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_suffix_img, {
              url: HomeInfo.value.ShopLogo,
              type: 3
            }, null, 8, ["url"]),
            _createVNode(_component_suffix_img, {
              url: HomeInfo.value.ShopLogo,
              type: 3
            }, null, 8, ["url"]),
            _createElementVNode("span", _hoisted_4, _toDisplayString(HomeInfo.value.ShopName), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ChatDotRound)
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('聊天回应率')) + ":", 1),
              _createElementVNode("span", null, _toDisplayString(HomeInfo.value.ChatResponseRate) + "%", 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Timer)
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('回应速度')) + ":", 1),
              _createElementVNode("span", null, _toDisplayString(HomeInfo.value.ChatResponseSpeed) + _toDisplayString(_ctx.$t('小时内')), 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Goods)
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('商品')) + ":", 1),
              _createElementVNode("span", null, _toDisplayString(HomeInfo.value.ProductNum), 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Star)
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('评价')) + ":", 1),
              _createElementVNode("span", null, _toDisplayString(HomeInfo.value.Evaluation), 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Shop)
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('加入时间')) + ":", 1),
              _createElementVNode("span", null, _toDisplayString(HomeInfo.value.CreatedMonth) + _toDisplayString(_ctx.$t('个月前')), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(HomeCategories.value, (Category) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "HomeCategories",
        key: Category.CategoryId
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", null, _toDisplayString(Category.CategoryName), 1),
          _cache[3] || (_cache[3] = _createElementVNode("span", null, null, -1))
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Category.Products, (Product, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: Product }, [
              (index < 7)
                ? (_openBlock(), _createBlock(CommodityCard, {
                    key: 0,
                    data: Product,
                    index: index,
                    class: "CCard"
                  }, null, 8, ["data", "index"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_el_radio, {
            modelValue: ShopCategoryId.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ShopCategoryId).value = $event)),
            label: null,
            onChange: _cache[1] || (_cache[1] = ($event: any) => {;(ShopCategoryId.value = null), SellerProdusts()})
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('全部')), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Categories.value, (Categorie) => {
            return (_openBlock(), _createBlock(_component_el_radio, {
              key: Categorie,
              modelValue: ShopCategoryId.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ShopCategoryId).value = $event)),
              label: Categorie.CategoryId,
              size: "large",
              onChange: ($event: any) => {;(ShopCategoryId.value = Categorie.CategoryId), SellerProdusts()}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(Categorie.CategoryName), 1)
              ]),
              _: 2
            }, 1032, ["modelValue", "label", "onChange"]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(FilterType, {
            Filters: Filters,
            onFilterChange: SellerProdusts
          }, null, 8, ["Filters"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          (!SellerProdustList.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(10, (i) => {
                return _createVNode(ProductSkeleton, {
                  class: "CCard",
                  key: i
                })
              }), 64))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(SellerProdustList.value, (Product, index) => {
            return (_openBlock(), _createBlock(CommodityCard, {
              key: Product,
              data: Product,
              index: index,
              class: "CCard"
            }, null, 8, ["data", "index"]))
          }), 128)),
          (SellerProdustList.value)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(4 - (SellerProdustList.value.length % 4), (placeholder) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "placeholderCard",
                  key: placeholder
                }))
              }), 128))
            : _createCommentVNode("", true),
          _createVNode(EmptyArray, {
            show: SellerProdustList.value && SellerProdustList.value.length == 0
          }, null, 8, ["show"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_el_pagination, {
            currentPage: _unref(pageNum),
            "page-size": _unref(pageSize),
            onSizeChange: _unref(handleSizeChange),
            onCurrentChange: _unref(handleCurrentChange),
            total: total.value,
            background: "",
            layout: "prev, pager, next"
          }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"])
        ])
      ])
    ])
  ]))
}
}

})