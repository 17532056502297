import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "OrderCard" }
const _hoisted_2 = { class: "Card_Head" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "Card_Body" }
const _hoisted_5 = { class: "Card_Body_L" }
const _hoisted_6 = { class: "Product" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "Product" }
const _hoisted_9 = { class: "Card_Body_R" }
const _hoisted_10 = { class: "ModifiedByName" }
const _hoisted_11 = { class: "Configuration" }
const _hoisted_12 = { class: "Configuration" }
const _hoisted_13 = { class: "Configuration_C" }
const _hoisted_14 = { class: "Configuration" }
const _hoisted_15 = { class: "Configuration_C" }

import { ref, reactive, onMounted, toRefs } from 'vue'
import * as Api from '@/utils/api'
import { formatDate } from '@/utils/format'
import { useRouter } from 'vue-router'
import Pay from '@/components/user-center/pay.vue'
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import OSS from '@/components/oss.vue'
import MallLabel from '@/components/mall_label.vue'
import { useI18n } from 'vue-i18n'
import GoogleTranslate from '@/components/GoogleTranslate.vue'
import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'order-card',
  props: {
  data: Object,
  index: Number,
},
  setup(__props) {

const store = useUsersStore()
const { PaymentStatus } = toRefs(store)

const { t } = useI18n()

const ruleRef = ref<FormInstance>()

const userouter = useRouter()
const isMore = ref(false)
const show = ref(false)
const FirstArticle = ref(null)
const ShowPay = ref(false)
const PayDate = ref(null)
const SourceId = ref(null)
const ShowDialog = ref(false)
const DialogType = ref(null)

const ruleForm = reactive({
  CancelID: null,
  Remark: null,
  ImgUrlList: [],
})

const rules = reactive<FormRules>({
  CancelID: [{ required: true, message: t('请选择'), trigger: 'change' }],
})

const productDetails = (item) => {
  const href = userouter.resolve({
    path: '/productDetails',
    query: { outId: item.OutId, mallId: item.MallId },
  })
  window.open(href.href, '_blank')
}
const BuyLoading = ref(false)
const BuyAgain = () => {
  BuyLoading.value = true
  Api.Post('cart/api/NewShoppingCart/', {
    OrderId: props.data.CustomerOrderId,
  }).then(
    (res) => {
      BuyLoading.value = false
      ElMessageBox.alert(t('已加入购物车'), t('信息'), {
        confirmButtonText: t('确定'),
        type: 'success',
      }).then(() => {
        userouter.push({ path: '/shoppingCart' })
      })
    },
    (err) => {
      BuyLoading.value = false
    }
  )
}

const PayChange = () => {
  ShowPay.value = false
}

const props = __props

const cancels = ref()
const setDialogType = (i) => {
  DialogType.value = i
  ruleForm.CancelID = null
  ShowDialog.value = true
  switch (i) {
    case 1:
      Api.Get('orders/api/Dictionary/', {
        TypeId: 54,
      }).then((res: any) => {
        cancels.value = res.Dictonarys
      })
      break
    case 2:
      Api.Get('basic/api/BasicDictionary/', {
        type_id: 232,
      }).then((res: any) => {
        cancels.value = res.BasicDictionaryList
      })
      break
  }
}

const CheckType = (formEl: FormInstance | undefined) => {
  formEl.validate((valid) => {
    if (valid) {
      switch (DialogType.value) {
        case 1:
          Api.Get('orders/api/cancelorder/', {
            OrderID: props.data.CustomerOrderId,
            CancelReasonTypeID: ruleForm.CancelID,
          }).then((res: any) => {
            isSuccess()
          })
          break
        case 2:
          let body = {
            BusinessTypeId: 1,
            ReasonId: ruleForm.CancelID,
            OrderNo: props.data.OrderNo,
            Remark: ruleForm.Remark,
            ImgUrlList: ruleForm.ImgUrlList,
          }
          body.ImgUrlList = body.ImgUrlList.map((item) => {
            return item.url
          })

          Api.Post('orders/api/SaleCancelOrder/', body).then((res: any) => {
            isSuccess()
          })
        default:
          break
      }
    }
  })
}

const isSuccess = () => {
  ShowDialog.value = false
  store.PaymentStatus = !store.PaymentStatus
}

const received = () => {
  ElMessageBox.confirm(t('是否确认订单？'), t('信息'), {
    confirmButtonText: t('已收到货'),
    cancelButtonText: t('取消'),
    type: 'warning',
  }).then(() => {
    Api.Get('orders/api/confirmorder/', {
      OrderID: props.data.CustomerOrderId,
    }).then((res: any) => {
      store.PaymentStatus = !store.PaymentStatus
    })
  })
}

const orderDetails = () => {
  const href = userouter.resolve({
    path: '/orderDetails',
    query: { OrderNo: props.data.OrderNo },
  })
  window.open(href.href, '_blank')
}

const toSalesOrder = () => {
  const href = userouter.resolve({
    path: '/afterSalesOrder',
    query: { OrderNo: props.data.OrderNo },
  })
  window.open(href.href, '_blank')
}

onMounted(() => {
  FirstArticle.value = props.data.ResponseList[0]
  PayDate.value = props.data
  SourceId.value = props.data.CustomerOrderSourceId
  setTimeout(() => {
    show.value = true
  }, 100 * props.index)
})

return (_ctx: any,_cache: any) => {
  const _component_ChatDotRound = _resolveComponent("ChatDotRound")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (FirstArticle.value)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 0,
          name: "el-fade-in-linear"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(props.data.CreatedOn, 'yyyy-MM-dd hh:mm:ss')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('订单号')) + ": ", 1),
                _createElementVNode("span", null, _toDisplayString(props.data.OrderNo), 1),
                (FirstArticle.value.MallId == 111036)
                  ? (_openBlock(), _createBlock(MallLabel, { key: 0 }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(props.data.CustomerOrderSourceName), 1)),
                _createElementVNode("span", null, _toDisplayString(props.data.TransnationalFreightType), 1),
                (FirstArticle.value.MallId == 111036)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 2,
                      to: {
            path: '/IM',
            query: {
              type: 'Order',
              shopId: props.data.ShopId,
            },
          },
                      target: "_blank"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChatDotRound)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", {
                    class: "summary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (productDetails(FirstArticle.value)))
                  }, [
                    _createVNode(_component_suffix_img, {
                      url: FirstArticle.value.ImagePath,
                      type: 3
                    }, null, 8, ["url"]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", null, [
                        _createVNode(GoogleTranslate, {
                          text: FirstArticle.value.Product,
                          type: null,
                          MallId: FirstArticle.value.MallId
                        }, null, 8, ["text", "MallId"])
                      ]),
                      _createElementVNode("p", null, [
                        _createVNode(GoogleTranslate, {
                          text: FirstArticle.value.SkuTitle,
                          type: null,
                          MallId: FirstArticle.value.MallId
                        }, null, 8, ["text", "MallId"])
                      ])
                    ]),
                    _createElementVNode("div", null, "x " + _toDisplayString(FirstArticle.value.Quantity), 1)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.data.ResponseList, (item, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: item.ItemId
                    }, [
                      (index > 0)
                        ? (_openBlock(), _createBlock(_component_el_collapse_transition, { key: 0 }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("div", {
                                class: "summary",
                                onClick: ($event: any) => (productDetails(item))
                              }, [
                                _createVNode(_component_suffix_img, {
                                  url: item.ImagePath,
                                  type: 3
                                }, null, 8, ["url"]),
                                _createElementVNode("div", _hoisted_8, [
                                  _createElementVNode("p", null, [
                                    _createVNode(GoogleTranslate, {
                                      text: FirstArticle.value.Product,
                                      type: null,
                                      MallId: FirstArticle.value.MallId
                                    }, null, 8, ["text", "MallId"])
                                  ]),
                                  _createElementVNode("p", null, [
                                    _createVNode(GoogleTranslate, {
                                      text: item.SkuTitle,
                                      type: null,
                                      MallId: FirstArticle.value.MallId
                                    }, null, 8, ["text", "MallId"])
                                  ])
                                ]),
                                _createElementVNode("div", null, "x " + _toDisplayString(item.Quantity), 1)
                              ], 8, _hoisted_7), [
                                [_vShow, isMore.value]
                              ])
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128)),
                  (props.data.ResponseList.length > 1)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "isMore",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (isMore.value = !isMore.value))
                      }, _toDisplayString(_ctx.$t(!isMore.value ? '更多' : '收起')), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", null, _toDisplayString(props.data.Customer.Name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$ccy(props.data.TotalAmount)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("p", null, _toDisplayString(props.data.Status), 1),
                      _withDirectives(_createElementVNode("p", null, " ( " + _toDisplayString(_ctx.$t('售后中')) + " ) ", 513), [
                        [_vShow, props.data.HaveAfterSale == 1]
                      ]),
                      _createVNode(_component_el_link, { onClick: orderDetails }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('订单详情')), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      (
                  (props.data.StatusId < 4 || props.data.StatusId == 8) &&
                  props.data.PaymentTypeId != 2 &&
                  props.data.HaveAfterSale == 0
                )
                        ? (_openBlock(), _createBlock(_component_el_button, {
                            key: 0,
                            size: "small",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (
                  props.data.CustomerOrderSourceId == 10 &&
                  props.data.StatusId == 8
                    ? setDialogType(2)
                    : setDialogType(1)
                ))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('取消订单')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (props.data.StatusId == 3)
                        ? (_openBlock(), _createBlock(_component_el_button, {
                            key: 1,
                            size: "small",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (ShowPay.value = true))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('支付订单')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (
                  props.data.CustomerOrderSourceId != 40 &&
                  props.data.CustomerOrderSourceId != 10
                )
                        ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                            key: 2,
                            size: "small",
                            disabled: BuyLoading.value,
                            onClick: BuyAgain
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('重新下单')), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled"])), [
                            [_directive_loading, BuyLoading.value]
                          ])
                        : _createCommentVNode("", true),
                      (
                  props.data.StatusId < 7 &&
                  props.data.LabelMessage == '' &&
                  props.data.CustomerOrderSourceId != 40
                )
                        ? (_openBlock(), _createBlock(_component_el_button, {
                            key: 3,
                            size: "small"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('打印标签')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (props.data.StatusId < 4 && props.data.LabelMessage != '')
                        ? (_openBlock(), _createBlock(_component_el_button, {
                            key: 4,
                            size: "small"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('取消标签')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (props.data.StatusId == 180)
                        ? (_openBlock(), _createBlock(_component_el_button, {
                            key: 5,
                            size: "small",
                            onClick: received
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('确认签收')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (props.data.StatusId == 4)
                        ? (_openBlock(), _createBlock(_component_el_button, {
                            key: 6,
                            size: "small",
                            onClick: orderDetails
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('待补款')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (
                  props.data.StatusId == 180 &&
                  props.data.HaveAfterSale == 0 &&
                  props.data.PaymentTypeId != 2
                )
                        ? (_openBlock(), _createBlock(_component_el_button, {
                            key: 7,
                            size: "small",
                            onClick: orderDetails
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('发起售后')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ], 512), [
              [_vShow, show.value]
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_el_dialog, {
      modelValue: ShowDialog.value,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((ShowDialog).value = $event)),
      title: _ctx.$t('取消订单'),
      width: "400px"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", null, [
          _createVNode(_component_el_button, {
            onClick: _cache[7] || (_cache[7] = ($event: any) => (ShowDialog.value = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('取消')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (CheckType(ruleRef.value)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('确认')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref_key: "ruleRef",
          ref: ruleRef,
          model: ruleForm,
          rules: rules,
          "label-position": "top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _unref(t)('取消原因'),
              prop: "CancelID"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: ruleForm.CancelID,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((ruleForm.CancelID) = $event)),
                  placeholder: _ctx.$t('请选择'),
                  size: "large",
                  class: "cancelOrder"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cancels.value, (cancel) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: cancel.Value || cancel.DicValue,
                        label: cancel.Name || cancel.DicName,
                        value: cancel.Value || cancel.DicValue
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            (DialogType.value == 2)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: _unref(t)('退款金额')
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$ccy(props.data.TotalAmount)) + "  " + _toDisplayString(props.data.TotalAmountCurrencyCode), 1)
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            (DialogType.value == 2)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  label: _unref(t)('备注')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: ruleForm.Remark,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((ruleForm.Remark) = $event)),
                      type: "textarea",
                      placeholder: _unref(t)('请输入')
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            (DialogType.value == 2)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 2,
                  label: _unref(t)('上传凭证')
                }, {
                  default: _withCtx(() => [
                    _createVNode(OSS, {
                      type: 4,
                      imgList: ruleForm.ImgUrlList,
                      max: 2,
                      onImgsChange: _cache[6] || (_cache[6] = 
            (val) => {
              ruleForm.ImgUrlList = val
            }
          )
                    }, null, 8, ["imgList"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(Pay, {
      Date: PayDate.value,
      ShowPay: ShowPay.value,
      SourceId: SourceId.value,
      onPayChange: PayChange
    }, null, 8, ["Date", "ShowPay", "SourceId"])
  ], 64))
}
}

})