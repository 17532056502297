import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "package_inquiry" }
const _hoisted_2 = { class: "ExpressNoInput" }
const _hoisted_3 = { class: "ExpressHistory" }

import { watch, ref, onMounted } from 'vue'
import Loading from '@/components/loading.vue'
import EmptyArray from '@/components/EmptyArray.vue'
import * as Api from '@/utils/api'


export default /*@__PURE__*/_defineComponent({
  __name: 'package_inquiry',
  setup(__props) {

const ExpressNo = ref(null)
const isLoding = ref(false)
const isExpressNo = ref(null)
const Traces = ref([])

const SearchPackage = () => {
  Traces.value = null
  Api.Get('orders/api/PackageTraces/', {
    ExpressNo: ExpressNo.value,
  }).then((res: any) => {
    isExpressNo.value = ExpressNo.value
    Traces.value = res.Traces
  })
}

onMounted(() => { })

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_input, {
        modelValue: ExpressNo.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ExpressNo).value = $event)),
        "suffix-icon": _ctx.Search,
        clearable: "",
        size: "large",
        placeholder: _ctx.$t('请输入快递单号')
      }, null, 8, ["modelValue", "suffix-icon", "placeholder"]),
      _createVNode(_component_el_button, {
        onClick: SearchPackage,
        size: "large",
        type: "danger",
        disabled: !ExpressNo.value
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('查询')), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _withDirectives(_createElementVNode("h1", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('快递单号')) + ":  ", 1),
      _createElementVNode("label", null, _toDisplayString(isExpressNo.value), 1)
    ], 512), [
      [_vShow, isExpressNo.value]
    ]),
    _createElementVNode("div", _hoisted_3, [
      (Traces.value)
        ? (_openBlock(), _createBlock(_component_el_steps, {
            key: 0,
            direction: "vertical",
            active: 1
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Traces.value, (Trace, index) => {
                return (_openBlock(), _createBlock(_component_el_step, {
                  key: index,
                  title: Trace.TraceMessage + '【' + Trace.WarehouseName + '】',
                  description: Trace.OperationDate + ' ' + Trace.OperationTime,
                  icon: "CircleCheckFilled"
                }, null, 8, ["title", "description"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(EmptyArray, {
        show: Traces.value && !Traces.value.length
      }, null, 8, ["show"]),
      _withDirectives(_createVNode(Loading, { type: "11" }, null, 512), [
        [_vShow, !Traces.value]
      ])
    ])
  ]))
}
}

})