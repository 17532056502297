import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/imgs/maintain.png'


const _hoisted_1 = { class: "container" }

import { ref, reactive, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: '404',
  setup(__props) {

const Router = useRouter()
const state: any = reactive({
  count: 0,
  timer: null,
})

onMounted(() => {
  state.count = 5
  state.timer = setInterval(() => {
    state.count--
    if (state.count <= 0) {
      clearInterval(state.timer)
      Router.push('/')
    }
  }, 1000)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
    _createElementVNode("div", null, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('页面维护中')) + "...", 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('前往首页')) + _toDisplayString(state.count) + "S", 1)
    ])
  ]))
}
}

})