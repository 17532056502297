import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, Transition as _Transition, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/imgs/seach.png'
import _imports_1 from '../assets/imgs/translate.png'
import _imports_2 from '../assets/imgs/ShoppingCart.png'


const _hoisted_1 = { class: "external" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "rt" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { style: {"cursor":"pointer"} }
const _hoisted_11 = {
  key: 0,
  class: "search"
}
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "ct" }
const _hoisted_14 = { class: "SearchType" }
const _hoisted_15 = { class: "search-input" }
const _hoisted_16 = { class: "ShoppingCart" }
const _hoisted_17 = { class: "HotKeys OneKes" }
const _hoisted_18 = { class: "HotKeys" }

import {
  getCurrentInstance,
  reactive,
  watch,
  ref,
  onMounted,
  toRefs,
  markRaw,
} from 'vue'
import router from '@/router/index'
import * as Api from '@/utils/api'
import { OssUpload } from '@/utils/OssClient'
import { ElLoading, ElMessageBox } from 'element-plus'
import MainBusinessClassification from '@/components/MainBusinessClassification.vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { Warning } from '@element-plus/icons-vue'
import { useUsersStore } from '@/pinia'
import { SiteSet } from '@/utils/siteConfig'
import { processImage } from '@/utils/base64'



export default /*@__PURE__*/_defineComponent({
  __name: 'Head',
  setup(__props) {

const store = useUsersStore()
const { CartCount, Token, getJwtToken, HotKeys } = toRefs(store)

const { t } = useI18n()
const userouter = useRouter()
const visible = ref(false)

const SearchType = ref(0)
const input = ref('')
const ShowSearch = ref(true)
const ShowNavigation = ref(true)
const translate = ref(true)
const KeysVisible = ref(false)

const { proxy } = getCurrentInstance()

const showLans = ref(false)
const lans = ref(SiteSet().lans)

const changeLan = (type) => {
  proxy.$i18n.locale = type
  localStorage.setItem('lang', type)
  store.$reset()
  location.reload()
}

const HotKeyPush = (item) => {
  KeysVisible.value = false
  proxy?.$Bus.emit('UpSearch', 'mitt')
}

const isHttpUrl = (val) => {
  visible.value = val ? false : true

  let reg = /(http|https):\/\/([\w.]+\/?)\S*/gi

  if (val.match(reg)) {
    const loading = ElLoading.service({
      lock: true,
      text: 'Verify Link',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    Api.Post('grabgoods/api/CheckValue/', {
      Value: val,
    })
      .then((res: any) => {
        loading.close()
        if (res.IsProduct) {
          const href = userouter.resolve({
            path: '/productDetails',
            query: { outId: res.OutId, mallId: res.MallId, Url: val },
          })
          window.open(href.href, '_blank')
        }
        input.value = null
      })
      .catch((err) => {
        input.value = null
        loading.close()
      })
  }
}

const Search = (translate) => {
  if (!input.value && SearchType.value == 0) {
    ElMessageBox.alert(
      t('粘贴需要代购的中国购物网站的商品网址或者商品名称,例子:') +
      'https://detail.1688.com/offer/586266294486.htmL',
      t('信息'),
      {
        confirmButtonText: 'OK',
        icon: markRaw(Warning),
      }
    )
  } else {
    userouter.push({
      path: '/searchList',
      query: { type: SearchType.value, input: input.value, translate },
    })
    proxy?.$Bus.emit('UpSearch', 'mitt')
  }
}

const Upload = async (file) => {
  console.log(file)
  const base64img = await processImage(file.file).then()
  // console.log(base64img)
  store.base64img = base64img
  userouter.push({
    name: 'searchList',
    query: { img: 'true' },
  })
  // const loading = ElLoading.service({
  //   lock: true,
  //   text: 'Verify Link',
  //   background: 'rgba(0, 0, 0, 0.7)',
  // })
  // OssUpload(file.file, 3)
  //   .then((res: any) => {
  //     loading.close()
  //     userouter.push({
  //       name: 'searchList',
  //       query: { img: res },
  //     })
  //   })
  //   .catch((err) => {
  //     loading.close()
  //   })
}

const routers = reactive(router)

watch(
  routers,
  (newValue: any, oldValue) => {
    if (
      newValue.currentRoute.name != undefined ||
      newValue.currentRoute.name != 'login'
    ) {
      store.setState('grabgoods/api/SearchKeys', {}, 'HotKeys')
    }

    if (newValue.currentRoute.query.type) {
      SearchType.value = newValue.currentRoute.query.type
      if (newValue.currentRoute.query.inputKey)
        input.value = JSON.parse(newValue.currentRoute.query.inputKey).Key
    }
    const hideList = JSON.stringify(['login', 'register', 'IM'])
    const hideNavigation = JSON.stringify(['login', 'register', 'IM'])
    ShowSearch.value = !hideList.includes(newValue.currentRoute.name)
    ShowNavigation.value = !hideNavigation.includes(newValue.currentRoute.name)
  },
  {
    immediate: true, // 立即执行
    deep: true, // 深度监听
  }
)

onMounted(() => { })

return (_ctx: any,_cache: any) => {
  const _component_ArrowDownBold = _resolveComponent("ArrowDownBold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ArrowUpBold = _resolveComponent("ArrowUpBold")!
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_Camera = _resolveComponent("Camera")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_MoreFilled = _resolveComponent("MoreFilled")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("ul", {
            class: "lt",
            onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (showLans.value = true)),
            onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (showLans.value = false))
          }, [
            _createElementVNode("li", null, [
              _createElementVNode("img", {
                src: require('../assets/imgs/' + _unref(proxy).$i18n.locale + '.png')
              }, null, 8, _hoisted_3),
              _createElementVNode("p", null, _toDisplayString(lans.value[_unref(proxy).$i18n.locale].name), 1),
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ArrowDownBold)
                ]),
                _: 1
              })
            ]),
            _withDirectives(_createVNode(_component_el_collapse_transition, null, {
              default: _withCtx(() => [
                _createElementVNode("a", null, [
                  _createElementVNode("li", null, [
                    _createElementVNode("img", {
                      src: require('../assets/imgs/' + _unref(proxy).$i18n.locale + '.png')
                  
                    }, null, 8, _hoisted_4),
                    _createElementVNode("p", null, _toDisplayString(lans.value[_unref(proxy).$i18n.locale].name), 1),
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ArrowUpBold)
                      ]),
                      _: 1
                    })
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lans.value, (lan, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (lan.val != _unref(proxy).$i18n.locale)
                        ? (_openBlock(), _createElementBlock("li", {
                            key: 0,
                            onClick: ($event: any) => (changeLan(lan.val))
                          }, [
                            _createElementVNode("img", {
                              src: require('../assets/imgs/' + lan.val + '.png')
                            }, null, 8, _hoisted_6),
                            _createElementVNode("p", null, _toDisplayString(lan.name), 1)
                          ], 8, _hoisted_5))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ])
              ]),
              _: 1
            }, 512), [
              [_vShow, showLans.value]
            ])
          ], 32),
          _createElementVNode("ul", _hoisted_7, [
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('首页')), 1)
                ]),
                _: 1
              })
            ]),
            _withDirectives(_createElementVNode("li", null, [
              _createVNode(_component_router_link, { to: "/login" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('登录')), 1)
                ]),
                _: 1
              })
            ], 512), [
              [_vShow, !_unref(Token)]
            ]),
            _withDirectives(_createElementVNode("li", null, [
              _createVNode(_component_router_link, { to: "/register" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('注册')), 1)
                ]),
                _: 1
              })
            ], 512), [
              [_vShow, !_unref(Token)]
            ]),
            (_unref(Token))
              ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                  _createVNode(_component_router_link, { to: "/userCenter" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(getJwtToken).CustomerName) + "(" + _toDisplayString(_ctx.$t('用户中心')) + ") ", 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, { to: "/helpCenter" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('帮助中心')), 1)
                ]),
                _: 1
              })
            ]),
            (_ctx.$SiteSet['seller'])
              ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                  _createVNode(_component_el_link, {
                    href: "https://seller.loda.com.kh",
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _cache[14] || (_cache[14] = _createElementVNode("span", { class: "Mall" }, "Mall", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('卖家中心')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, { to: "/Guide" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('新手引导')), 1)
                ]),
                _: 1
              })
            ]),
            (_unref(Token))
              ? (_openBlock(), _createElementBlock("li", {
                  key: 2,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(store).logout()))
                }, [
                  _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.$t('退出')), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      (ShowSearch.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_router_link, {
              class: "LodaHome",
              to: "/",
              alt: "LODA logo"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require('../assets/imgs/' + _ctx.$SiteSet['logoPng'])
                }, null, 8, _hoisted_12)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("span", {
                  class: _normalizeClass({ Type: SearchType.value == 0 }),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (SearchType.value = 0))
                }, _toDisplayString(_ctx.$t('海外购')), 3),
                (_ctx.$SiteSet['SearchType1'])
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass({ Type: SearchType.value == 1 }),
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (SearchType.value = 1))
                    }, _toDisplayString(_ctx.$t('本地商城')), 3))
                  : _createCommentVNode("", true),
                (_ctx.$SiteSet['SearchType2'])
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass({ Type: SearchType.value == 2 }),
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (SearchType.value = 2))
                    }, _toDisplayString(_ctx.$t('店铺')), 3))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_el_tooltip, {
                  visible: visible.value,
                  placement: "top-start"
                }, {
                  content: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('粘贴需要代购的中国购物网站的商品网址或者商品名称、')) + " ", 1),
                    _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('例子')) + ": https://detail.1688.com/offer/666294135694.html ", 1),
                    _createElementVNode("p", null, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('支持商家')) + ": ", 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('阿里巴巴')), 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('淘宝')), 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('天猫')), 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('京东')), 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('拼多多')), 1)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: input.value,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((input).value = $event)),
                      name: "searchInput",
                      placeholder: SearchType.value != 2 ? _ctx.$t('搜索商品') : _ctx.$t('搜索店铺'),
                      onKeyup: _cache[7] || (_cache[7] = _withKeys(($event: any) => (Search(false)), ["enter"])),
                      onInput: isHttpUrl,
                      onFocus: _cache[8] || (_cache[8] = ($event: any) => (visible.value = SearchType.value == 0 ? true : false)),
                      onBlur: _cache[9] || (_cache[9] = ($event: any) => (visible.value = false))
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["visible"])
              ]),
              _createVNode(_Transition, { name: "el-fade-in-linear" }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_el_upload, {
                    accept: ".jpg,.jpeg,.png",
                    action: "",
                    "http-request": Upload,
                    "show-file-list": false
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Camera)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, SearchType.value == 0]
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("div", {
                class: "pt",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (Search(false)))
              }, _cache[16] || (_cache[16] = [
                _createElementVNode("img", { src: _imports_0 }, null, -1)
              ])),
              _createVNode(_Transition, { name: "el-fade-in-linear" }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", {
                    class: "pt",
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (Search(true)))
                  }, _cache[17] || (_cache[17] = [
                    _createElementVNode("img", { src: _imports_1 }, null, -1)
                  ]), 512), [
                    [_vShow, SearchType.value == 0]
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_router_link, { to: "/shoppingCart" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("span", { class: "CartCount" }, _toDisplayString(_unref(CartCount)), 513), [
                      [_vShow, _unref(CartCount)]
                    ]),
                    _cache[18] || (_cache[18] = _createElementVNode("img", { src: _imports_2 }, null, -1))
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(HotKeys)?.Keys, (key) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    key: key,
                    onClick: ($event: any) => (HotKeyPush(key)),
                    to: {
            path: '/searchList',
            query: {
              type: 0,
              inputKey: JSON.stringify(key),
              input: key.Value,
            },
          }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(key.Key), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "to"]))
                }), 128))
              ]),
              _withDirectives(_createVNode(_component_el_icon, {
                onClick: _cache[12] || (_cache[12] = ($event: any) => (KeysVisible.value = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MoreFilled)
                ]),
                _: 1
              }, 512), [
                [_vShow, _unref(HotKeys)?.Keys.length]
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createVNode(MainBusinessClassification, null, null, 512), [
      [_vShow, ShowNavigation.value]
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: KeysVisible.value,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((KeysVisible).value = $event)),
      title: _ctx.$t('热搜词'),
      width: "50%"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(HotKeys)?.Keys, (key) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                key: key,
                onClick: ($event: any) => (HotKeyPush(key)),
                to: {
          path: '/searchList',
          query: { type: 0, inputKey: JSON.stringify(key), input: key.Value },
        }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(key.Key), 1)
                ]),
                _: 2
              }, 1032, ["onClick", "to"]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}
}

})