import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "transport_package" }
const _hoisted_2 = { class: "Status" }
const _hoisted_3 = { class: "Status" }
const _hoisted_4 = { class: "Status" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "BoxCenter"
}
const _hoisted_7 = { class: "PackageBody" }
const _hoisted_8 = { class: "TypeName" }
const _hoisted_9 = { class: "PackageDetial" }
const _hoisted_10 = { class: "ExpressNo" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 0,
  class: "isSet"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "ProductPagination" }
const _hoisted_15 = { class: "affix" }

import { ref, reactive, onMounted, watch, toRefs } from 'vue'
import PaginationHook from '@/hooks/pagination'
import * as Api from '@/utils/api'
import Loading from '@/components/loading.vue'
import EmptyArray from '@/components/EmptyArray.vue'
import { formatDate } from '@/utils/format'
import type { FormInstance } from 'element-plus'
import { ElLoading, ElMessageBox } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { shortcuts } from '@/utils/constant'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'transport_package',
  setup(__props) {

const store = useUsersStore()
const { BD13, TT10, TP } = toRefs(store)

const userouter = useRouter()
const { toClipboard } = useClipboard()
const { t } = useI18n()

const ruleFormRef = ref<FormInstance>()
const PackageList = ref(null)
const StatuId = ref(0)
const TypeTd = ref(0)
const CombineStatuId = ref(0)
const total = ref(0)
const isIndeterminate = ref(false)
const checkAll = ref(false)

const handleCheckAllChange = (val: boolean) => {
  isIndeterminate.value = false
  checkList.value = []
  if (val) {
    checkList.value = PackageList.value.map((item) => {
      return item.PackageUniqueId
    })
  }
}

const resetForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.resetFields()
}

const ruleForm = reactive({
  Date: null,
  key_words: null,
})

const Copy = (item) => {
  toClipboard(item.ExpressNo)
  ElMessageBox.alert(t('已复制到粘贴板'), t('提示'), {
    confirmButtonText: 'OK',
  })
}

const checkList = ref([])

const CheckPackage = (item) => {
  const index = checkList.value.indexOf(item.PackageUniqueId)
  if (index != -1) {
    checkList.value.splice(index, 1)
  } else {
    checkList.value.push(item.PackageUniqueId)
  }
  isIndeterminate.value = checkList.value.length ? true : false
}
const TransportLoading = ref(false)
const TransportPackageSetMerge = () => {
  TransportLoading.value = true

  Api.Post('orders/api/TransportPackageSetMerge/', {
    PackageIdList: checkList.value,
  }).then(
    (res) => {
      TransportLoading.value = false
      if (!res.IsError) {
        GetPackage()
      }
    },
    (err) => {
      TransportLoading.value = false
    }
  )
}

const SetPackage = (item) => {
  const href = userouter.resolve({
    path: '/express/international',
    query: {
      id: item.PackageUniqueId,
      type: (item.TransportPackageTypeId =
        item.TransportPackageTypeId == 30 ? 10 : item.TransportPackageTypeId),
    },
  })
  window.open(href.href, '_blank')
}

const DeletePackage = (item) => {
  ElMessageBox.confirm(t('确定要删除吗'), t('提示'), {
    confirmButtonText: t('确定'),
    cancelButtonText: t('取消'),
    type: 'warning',
  }).then(() => {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    Api.Post('orders/api/TransportPackageDelete/', {
      PackageUniqueId: item.PackageUniqueId,
    }).then((res: any) => {
      loading.close()
      GetPackage()
    })
  })
}

const GetPackage = () => {
  PackageList.value = null
  checkList.value = []
  isIndeterminate.value = false
  Api.Get('orders/api/TransportPackageNew/', {
    start_date: ruleForm.Date ? ruleForm.Date[0] : null,
    end_date: ruleForm.Date ? ruleForm.Date[1] : null,
    express_no: ruleForm.key_words?.replace(/\s*/g, '').length
      ? ruleForm.key_words
      : null,
    page_size: pageSize.value,
    page_index: pageNum.value,
    statu_id: StatuId.value.toString(),
    freight_type_id: TypeTd.value.toString(),
    combine_statu_id: CombineStatuId.value.toString(),
  }).then((res: any) => {
    PackageList.value = res.TransportPackageList
    total.value = res.TotalRecordsCount
  })
}

const { pageNum, pageSize, handleCurrentChange, handleSizeChange } =
  PaginationHook(GetPackage)

onMounted(() => {
  ruleForm.Date = shortcuts[0].value
  GetPackage()
  store.setState('orders/api/TransportPackage', {}, 'TP')
  store.setState('basic/api/BasicDictionary', { type_id: 13 }, 'BD13')
  store.setState('basic/api/TransnationalType/', { type_id: 10 }, 'TT10')

  pageSize.value = 10
})

return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_CopyDocument = _resolveComponent("CopyDocument")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_EditPen = _resolveComponent("EditPen")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_affix = _resolveComponent("el-affix")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_radio_group, {
        modelValue: StatuId.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((StatuId).value = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (GetPackage()))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(TP)?.TransportPackageStatuList, (Statu, index) => {
            return (_openBlock(), _createBlock(_component_el_radio_button, {
              key: index,
              label: Statu.StatuId
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(Statu.StatuName), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_radio_group, {
        modelValue: TypeTd.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((TypeTd).value = $event)),
        onChange: _cache[3] || (_cache[3] = ($event: any) => (GetPackage()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_button, { label: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('全部')), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(TT10)?.Types, (Statu, index) => {
            return (_openBlock(), _createBlock(_component_el_radio_button, {
              key: index,
              label: Statu.ID
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(Statu.TypeName), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_radio_group, {
        modelValue: CombineStatuId.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((CombineStatuId).value = $event)),
        onChange: _cache[5] || (_cache[5] = ($event: any) => (GetPackage()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_button, { label: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('全部')), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(BD13)?.BasicDictionaryList, (Types, index) => {
            return (_openBlock(), _createBlock(_component_el_radio_button, {
              key: index,
              label: Types.DicValue
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(Types.DicName), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createVNode(_component_el_form, {
      ref_key: "ruleFormRef",
      ref: ruleFormRef,
      model: ruleForm,
      inline: true,
      size: _ctx.formSize,
      "label-position": "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('查询时间'),
          prop: "Date"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              modelValue: ruleForm.Date,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((ruleForm.Date) = $event)),
              type: "daterange",
              clearable: false,
              "value-format": "YYYY-MM-DD",
              "unlink-panels": "",
              "range-separator": "——",
              "start-placeholder": "Start date",
              "end-placeholder": "End date",
              shortcuts: _unref(shortcuts),
              onChange: _ctx.GetBox
            }, null, 8, ["modelValue", "shortcuts", "onChange"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('快递单号'),
          prop: "key_words"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: ruleForm.key_words,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((ruleForm.key_words) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, { style: {"display":"flex","align-items":"flex-end"} }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "danger",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (GetPackage()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('查询')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              onClick: _cache[9] || (_cache[9] = ($event: any) => (resetForm(ruleFormRef.value)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('清空')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "size"]),
    _createVNode(_component_el_checkbox_group, {
      class: "PackageList",
      modelValue: checkList.value,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((checkList).value = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(PackageList.value, (Package, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "Package",
            onClick: ($event: any) => (CheckPackage(Package))
          }, [
            (CombineStatuId.value == 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_el_checkbox, {
                    onClick: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["stop"])),
                    label: Package.PackageUniqueId
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(Package.TransportPackageTypeName), 1),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("strong", {
                    onClick: _withModifiers(($event: any) => (_ctx.cheecked(Package)), ["stop"])
                  }, [
                    _createTextVNode(" NO:" + _toDisplayString(Package.ExpressNo) + " ", 1),
                    _createVNode(_component_el_icon, {
                      onClick: _withModifiers(($event: any) => (Copy(Package)), ["stop"])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CopyDocument)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ], 8, _hoisted_11),
                  (Package.TransportPackageStatuId == 1 ||
                Package.TransportPackageStatuId == 5 ||
                Package.TransportPackageStatuId == 10
                )
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                        _createVNode(_component_el_icon, {
                          onClick: _withModifiers(($event: any) => (SetPackage(Package)), ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_EditPen)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_el_icon, {
                          onClick: _withModifiers(($event: any) => (DeletePackage(Package)), ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Delete)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]))
                    : _createCommentVNode("", true),
                  (Package.TransportPackageStatuId > 10)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: "MoreDetial",
                        onClick: _withModifiers(($event: any) => (_ctx.showDetial(Package)), ["stop"])
                      }, _toDisplayString(_ctx.$t('详情')), 9, _hoisted_13))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("p", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('状态')) + ":", 1),
                  _createElementVNode("span", null, _toDisplayString(Package.TransportPackageStatus), 1)
                ]),
                _createElementVNode("p", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('录单时间')) + ":", 1),
                  _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(Package.CreatedOn, 'yyyy-MM-dd hh:mm:ss')), 1)
                ]),
                _createElementVNode("p", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('收货人')) + ":", 1),
                  _createElementVNode("span", null, _toDisplayString(Package.ReceiverName), 1)
                ]),
                _createElementVNode("p", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('商品数量')) + ":", 1),
                  _createElementVNode("span", null, _toDisplayString(Package.Quantity), 1)
                ]),
                _createElementVNode("p", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('发货类型')) + ":", 1),
                  _createElementVNode("span", null, _toDisplayString(Package.DeliveryTypeName), 1)
                ]),
                _createElementVNode("p", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('提货方式')) + ":", 1),
                  _createElementVNode("span", null, _toDisplayString(Package.CompanyName), 1)
                ]),
                _createElementVNode("p", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('运输方式')) + ":", 1),
                  _createElementVNode("span", null, _toDisplayString(Package.TransnationalFreightTypes), 1)
                ]),
                _withDirectives(_createElementVNode("p", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('备注')) + ":", 1),
                  _createElementVNode("span", null, _toDisplayString(Package.CustomerRemark), 1)
                ], 512), [
                  [_vShow, Package.CustomerRemark]
                ])
              ])
            ])
          ], 8, _hoisted_5))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (PackageList.value)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(PackageList.value.length % 2, (index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "placeholderPackage"
          }))
        }), 128))
      : _createCommentVNode("", true),
    _createVNode(EmptyArray, {
      show: PackageList.value && !PackageList.value.length
    }, null, 8, ["show"]),
    _withDirectives(_createVNode(Loading, { type: "13" }, null, 512), [
      [_vShow, !PackageList.value]
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_el_pagination, {
        currentPage: _unref(pageNum),
        "onUpdate:currentPage": _cache[12] || (_cache[12] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : null)),
        "page-size": _unref(pageSize),
        onSizeChange: _unref(handleSizeChange),
        onCurrentChange: _unref(handleCurrentChange),
        total: total.value,
        background: "",
        layout: "prev, pager, next"
      }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"])
    ]),
    _withDirectives(_createVNode(_component_el_affix, {
      ref: "affix",
      position: "bottom",
      offset: 0
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_el_checkbox, {
            modelValue: checkAll.value,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((checkAll).value = $event)),
            indeterminate: isIndeterminate.value,
            onChange: handleCheckAllChange
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('全选')), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "indeterminate"]),
          _withDirectives(_createVNode(_component_el_button, {
            loading: TransportLoading.value,
            type: "danger",
            onClick: TransportPackageSetMerge
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('合并')), 1)
            ]),
            _: 1
          }, 8, ["loading"]), [
            [_vShow, checkList.value?.length > 1]
          ])
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, CombineStatuId.value == 1]
    ])
  ]))
}
}

})