import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "international" }
const _hoisted_2 = { class: "typeCard" }
const _hoisted_3 = { class: "typeTitle" }
const _hoisted_4 = { class: "typeCard" }
const _hoisted_5 = { class: "typeTitle" }
const _hoisted_6 = { class: "PackageList" }
const _hoisted_7 = {
  key: 0,
  class: "HandlingFee"
}
const _hoisted_8 = { class: "typeCard" }
const _hoisted_9 = { class: "typeTitle" }
const _hoisted_10 = { class: "TransportRadio" }
const _hoisted_11 = { class: "typeTitle" }
const _hoisted_12 = { class: "TransportRadio" }
const _hoisted_13 = { class: "typeTitle" }
const _hoisted_14 = {
  key: 0,
  class: "TransportRadio"
}
const _hoisted_15 = { class: "typeCard" }
const _hoisted_16 = { class: "typeTitle" }
const _hoisted_17 = { class: "Notices" }
const _hoisted_18 = { class: "typeCard" }
const _hoisted_19 = { class: "isSubmit" }
const _hoisted_20 = { class: "dialog-footer" }

import {
  watch,
  ref,
  reactive,
  onMounted,
  getCurrentInstance,
  toRefs,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import { useUsersStore } from '@/pinia'
import useClipboard from 'vue-clipboard3'
import * as Api from '@/utils/api'
import Loading from '@/components/loading.vue'
import Article from '@/components/article.vue'
import Repository from '@/components/user-center/repository.vue'
import Password from '@/components/password.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'international',
  setup(__props) {

const store = useUsersStore()
const { BD56, BD180, WareList } = toRefs(store)

const route = useRoute()
const Router = useRouter()
const { toClipboard } = useClipboard()
const { t } = useI18n()
const { proxy } = getCurrentInstance()

const expressRef = ref<FormInstance>()

const isAgree = ref(false)

const PackageTypeId = ref(null)
const dialogTableVisible = ref(false)
const tabNumber = ref('0')
const isVerified = ref(false)
const PackageList = ref({
  Packages: [
    {
      ExpressNo: null,
      GoodAmount: 0,
      GoodName: null,
      HandlingFee: 0,
      IsNeedBox: false,
      PackageCategoryId: null,
      Quantity: 1,
      Remark: null,
    },
  ],
})

const GoodAmountPass = (rule: any, value: any, callback: any) => {
  PackageList.value.Packages[rule.field.split('.')[1]].HandlingFee = 0
  if (value) {
    let Rules = RuleList.value.find((e) => {
      return value >= e.StartAmount && value <= e.EndAmount
    })
    if (Rules) {
      PackageList.value.Packages[rule.field.split('.')[1]].HandlingFee =
        Rules.FixedPrice ? Rules.FixedPrice : value * Rules.FixedRate
    }
    callback()
  } else {
    callback()
  }
}

const CategoryPass = (rule: any, value: any, callback: any) => {
  if (TypeId.value == 4) {
    if (!value) {
      callback(new Error('Please select'))
    } else {
      callback()
    }
  } else {
    PackageList.value.Packages[rule.field.split('.')[1]].PackageCategoryId =
      null
    callback()
  }
}

const rules = reactive<FormRules>({
  ExpressNo: [{ required: true, message: t('请输入'), trigger: 'blur' }],
  GoodName: [{ required: true, message: t('请输入'), trigger: 'blur' }],
  Quantity: [{ required: true, message: t('请输入'), trigger: 'blur' }],
  GoodAmount: [{ validator: GoodAmountPass, trigger: 'change' }],
  PackageCategoryId: [{ validator: CategoryPass, trigger: 'change' }],
})

const copy = () => {
  let text = WareList.value.WareList[tabNumber.value]
  toClipboard(
    t('收货人') +
      ':' +
      text.Consignee +
      '\r\n' +
      t('联系电话') +
      ':' +
      text.Mobile +
      '\r\n' +
      t('邮政编码') +
      ':' +
      text.Postcode +
      '\r\n' +
      t('收货地址') +
      ':' +
      text.Address
  )

  ElMessageBox.alert(t('已复制到粘贴板'), 'Tips', {
    confirmButtonText: 'OK',
  })
}

const AddPackage = () => {
  PackageList.value.Packages.push({
    ExpressNo: null,
    GoodAmount: 0,
    GoodName: null,
    HandlingFee: 0,
    IsNeedBox: false,
    PackageCategoryId: null,
    Quantity: 1,
    Remark: null,
  })
}

const CheckGoodAmount = (i) => {
  PackageList.value.Packages[i].GoodAmount = null
  PackageList.value.Packages[i].HandlingFee = 0
}

const DeletePackage = (index) => {
  PackageList.value.Packages.splice(index, 1)
}

const isPassword = ref(null)
const NeedPassword = ref(false)

const submitExpress = () => {
  proxy.$refs['expressRef']['validate']((valid, fields) => {
    if (valid) {
      if (!isVerified.value) {
        NeedPassword.value = PackageList.value.Packages.some((val) => {
          return val.HandlingFee > 0
        })
      }
      if (!NeedPassword.value) {
        if (route.query.id) {
          EditPackage(null)
        } else {
          CreateTransportPackageBatch(null)
        }
      }
    }
  })
}

const isLoding = ref(false)

const EditPackage = (Password) => {
  let isPackage = PackageList.value.Packages[0]
  let body = {
    TargetWarehouseId: Warehouse.value.Id,
    PackageUniqueId: route.query.id,
    ExpressNo: isPackage.ExpressNo,
    GoodName: isPackage.GoodName,
    Quantity: isPackage.Quantity,
    Remark: isPackage.Remark,
    FreightTypeId: TypeId.value,
    DeliveryTypeId: DictionaryId.value,
    GoodAmount: !isVerified.value
      ? Number(Number(isPackage.GoodAmount).toFixed(2)) || null
      : null,
    Password,
    PackageCategoryId:
      TypeId.value == 4 ? isPackage.PackageCategoryId : Types.value[0].DicValue,
    IsNeedBox: Api.FUC(isPackage.IsNeedBox),
  }
  isLoding.value = true
  NeedPassword.value = false
  Api.Post('orders/api/TransportPackageUpdateByNew', body)
    .then((res: any) => {
      isLoding.value = false
      ElMessageBox.confirm(t('是否跳转到包裹列表'), t('提示'), {
        confirmButtonText: t('确定'),
        cancelButtonText: t('取消'),
        type: 'warning',
      }).then(() => {
        Router.push({
          path: '/userCenter/myPackage',
        })
      })
    })
    .catch(() => {
      isLoding.value = false
    })
}

const CreateTransportPackageBatch = (Password) => {
  isLoding.value = true
  NeedPassword.value = false
  Api.Post('orders/api/CreateTransportPackageBatch', {
    TargetWarehouseId: Warehouse.value.Id,
    PackageList: PackageList.value.Packages,
    FreightTypeId: TypeId.value,
    DeliveryTypeId: DictionaryId.value,
    Password,
    TransportPackageTypeId: PackageTypeId.value,
  })
    .then((res: any) => {
      isLoding.value = false
      ElMessageBox.confirm(t('是否跳转到包裹列表'), t('提示'), {
        confirmButtonText: t('确定'),
        cancelButtonText: t('取消'),
        type: 'warning',
      }).then(() => {
        Router.push({
          path: '/userCenter/myPackage',
        })
      })
    })
    .catch(() => {
      isLoding.value = false
    })
}

const Types = ref(null)
const TypeId = ref(null)
const Notices = ref(null)

const GetMore = (typeId) => {
  PackageTypeId.value = typeId
  Api.Get('basic/api/BasicDictionary', {
    type_id: 166,
    value_id: typeId,
  }).then((res: any) => {
    Notices.value = res.BasicDictionaryList
  })

  Api.Get('basic/api/TransnationalType/', {
    type_id: typeId,
  }).then((res: any) => {
    Types.value = res.Types
    if (route.query.id) {
      GetPackage()
    } else {
      TypeId.value = res.Types[0].ID
    }
  })
}

const ChangeTypeId = (id) => {
  if (id == 4) {
    DictionaryId.value = 1
    store
      .getState(
        'basic/api/BasicDictionary',
        { type_id: 216, value_id: 5 },
        'BD216'
      )
      .then((res: any) => {
        let tipText = ''
        res.BasicDictionaryList.forEach((ele) => {
          tipText += ele.Desc + '<br/>'
        })
        ElMessageBox.confirm(tipText, res.BasicDictionaryList[0].DicName, {
          dangerouslyUseHTMLString: true,
          confirmButtonText: t('确定'),
          cancelButtonText: t('取消'),
        }).catch(() => {
          TypeId.value = Types.value[0].ID
        })
      })
  }
}

const SetWarehouse = (val) => {
  Warehouse.value.Id = val.params.WarehouseId
  Warehouse.value.Name = val.params.WarehouseName
  dialogTableVisible.value = false
}

const Warehouse = ref(null)
const DictionaryTip = ref(null)
const ReinforceTip = ref(null)
const MaxGoodAmount = ref(0)
const MinGoodAmount = ref(0)
const RuleList = ref(null)

const GetPackage = () => {
  PackageList.value.Packages = []
  Api.Get('orders/api/TransportPackageUpdate/', {
    PackageUniqueId: route.query.id,
  }).then((res: any) => {
    let isPackage = res.TransportPackage
    isVerified.value = isPackage.GoodAmount ? true : false
    isPackage.TransportPackageItems.forEach((ele) => {
      PackageList.value.Packages.push({
        ExpressNo: isPackage.ExpressNo,
        GoodName: ele.GoodName,
        IsNeedBox: isPackage.IsNeedBox,
        Quantity: ele.Quantity,
        Remark: isPackage.CustomerRemark,
        GoodAmount: isPackage.GoodAmount,
        HandlingFee: isPackage.FeeAmount,
        PackageCategoryId: isPackage.PackageCategoryId,
      })
    })
    TypeId.value = isPackage.TransnationalFreightTypeId
    DictionaryId.value = isPackage.DeliveryTypeId
    Warehouse.value = {
      Id: isPackage.TargetWarehouseId,
      Name: isPackage.TargetWarehouseName,
    }
  })
}

const DictionaryId = ref(null)

watch(
  () => BD56.value,
  (val) => {
    if (val) {
      DictionaryId.value = route.query.id
        ? null
        : val.BasicDictionaryList[0].DicValue
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  store.setState('basic/api/BasicDictionary', { type_id: 56 }, 'BD56')
  store.setState('basic/api/BasicDictionary', { type_id: 180 }, 'BD180')
  store.setState('basic/api/WarehouseContactList', {}, 'WareList')

  if (!route.query.type) {
    store
      .getState(
        'basic/api/BasicDictionary',
        {
          type_id: 166,
        },
        'BD166'
      )
      .then((res: any) => {
        GetMore(res.BasicDictionaryList[0].DicValue)
      })
  } else {
    GetMore(route.query.type)
  }

  if (!route.query.id) {
    Api.Get('user/api/GetCustomerDefaultWarehouse/', {}).then((res: any) => {
      Warehouse.value = {
        Id: res.DefaultWareId,
        Name: res.DefaultWareName,
      }
    })
  }
  store
    .getState(
      'basic/api/BasicDictionary',
      {
        type_id: 216,
        value_id: 7,
      },
      'BD216v7'
    )
    .then((res: any) => {
      DictionaryTip.value = res.BasicDictionaryList
    })

  store
    .getState(
      'basic/api/BasicDictionary',
      {
        type_id: 216,
        value_id: 1,
      },
      'BD216v1'
    )
    .then((res: any) => {
      ReinforceTip.value = res.BasicDictionaryList
    })

  store
    .getState(
      'basic/api/InsurancePriceRule/',
      {
        TypeId: 1,
      },
      'InsurancePriceRule'
    )
    .then((res: any) => {
      MaxGoodAmount.value = res.MaxGoodAmount
      MinGoodAmount.value = res.MinGoodAmount
      RuleList.value = res.RuleList
    })
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_InfoFilled = _resolveComponent("InfoFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_affix = _resolveComponent("el-affix")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('仓库地址')), 1),
          _withDirectives(_createVNode(_component_el_button, {
            type: "danger",
            icon: "CopyDocument",
            onClick: copy
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode(" Copy ")
            ])),
            _: 1
          }, 512), [
            [_vShow, _unref(WareList)]
          ])
        ]),
        _withDirectives(_createVNode(_component_el_tabs, {
          type: "border-card",
          modelValue: tabNumber.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tabNumber).value = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(WareList)?.WareList, (Ware, index) => {
              return (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: index,
                label: Ware.TypeName
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_descriptions, {
                    column: 3,
                    border: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_descriptions_item, {
                        label: _ctx.$t('收货人')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(Ware.Consignee), 1)
                        ]),
                        _: 2
                      }, 1032, ["label"]),
                      _createVNode(_component_el_descriptions_item, {
                        label: _ctx.$t('联系电话')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(Ware.Mobile), 1)
                        ]),
                        _: 2
                      }, 1032, ["label"]),
                      _createVNode(_component_el_descriptions_item, {
                        label: _ctx.$t('邮政编码')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(Ware.Postcode), 1)
                        ]),
                        _: 2
                      }, 1032, ["label"]),
                      _createVNode(_component_el_descriptions_item, {
                        label: _ctx.$t('收货地址')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(Ware.Address), 1)
                        ]),
                        _: 2
                      }, 1032, ["label"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]), [
          [_vShow, _unref(WareList)]
        ]),
        _withDirectives(_createVNode(Loading, { type: "12" }, null, 512), [
          [_vShow, !_unref(WareList)]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('包裹信息')), 1),
          _withDirectives(_createVNode(_component_el_button, {
            type: "danger",
            icon: "CirclePlusFilled",
            onClick: AddPackage
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('新增包裹')), 1)
            ]),
            _: 1
          }, 512), [
            [_vShow, !_unref(route).query.id]
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_form, {
            inline: true,
            ref_key: "expressRef",
            ref: expressRef,
            model: PackageList.value,
            "label-position": "top",
            class: "PackageForm"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(PackageList.value.Packages, (Package, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "PackageFor",
                  key: index
                }, [
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('快递单号'),
                    prop: `Packages.${index}.ExpressNo`,
                    rules: rules.ExpressNo,
                    style: {"width":"150px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: Package.ExpressNo,
                        "onUpdate:modelValue": ($event: any) => ((Package.ExpressNo) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["label", "prop", "rules"]),
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('货物名称'),
                    prop: `Packages.${index}.GoodName`,
                    rules: rules.GoodName,
                    style: {"width":"100px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: Package.GoodName,
                        "onUpdate:modelValue": ($event: any) => ((Package.GoodName) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["label", "prop", "rules"]),
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('货物数量'),
                    prop: `Packages.${index}.Quantity`,
                    rules: rules.Quantity
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input_number, {
                        modelValue: Package.Quantity,
                        "onUpdate:modelValue": ($event: any) => ((Package.Quantity) = $event),
                        precision: 0,
                        min: 1,
                        "controls-position": "right"
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["label", "prop", "rules"]),
                  _createVNode(_component_el_form_item, {
                    prop: `Packages.${index}.GoodAmount`,
                    rules: rules.GoodAmount
                  }, {
                    label: _withCtx(() => [
                      _withDirectives(_createVNode(_component_el_checkbox, {
                        modelValue: Package['isGoodAmount'],
                        "onUpdate:modelValue": ($event: any) => ((Package['isGoodAmount']) = $event),
                        class: "isGoodAmount",
                        onChange: ($event: any) => (CheckGoodAmount(index))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]), [
                        [_vShow, !isVerified.value]
                      ]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('保险服务(申报价值)')) + " ", 1),
                      _createVNode(_component_el_tooltip, { placement: "top" }, {
                        content: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('最大货值金额')) + ": " + _toDisplayString(MaxGoodAmount.value), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_InfoFilled)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_input_number, {
                        modelValue: Package.GoodAmount,
                        "onUpdate:modelValue": ($event: any) => ((Package.GoodAmount) = $event),
                        min: 0,
                        max: MaxGoodAmount.value,
                        precision: 0,
                        disabled: !Package['isGoodAmount'],
                        "controls-position": "right"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "max", "disabled"]),
                      (Package.HandlingFee)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('保险费')) + ":" + _toDisplayString(_ctx.$ccy(Package.HandlingFee)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["prop", "rules"]),
                  _createVNode(_component_el_form_item, {
                    prop: `Packages.${index}.IsNeedBox`
                  }, {
                    label: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('木架加固服务')) + " ", 1),
                      _createVNode(_component_el_tooltip, { placement: "top" }, {
                        content: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ReinforceTip.value, (Tips) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: Tips }, [
                              _createTextVNode(_toDisplayString(Tips.Desc), 1),
                              _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1))
                            ], 64))
                          }), 128))
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_InfoFilled)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_checkbox, {
                        modelValue: Package.IsNeedBox,
                        "onUpdate:modelValue": ($event: any) => ((Package.IsNeedBox) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["prop"]),
                  _withDirectives(_createVNode(_component_el_form_item, {
                    prop: `Packages.${index}.PackageCategoryId`,
                    rules: rules.PackageCategoryId,
                    style: {"width":"150px"}
                  }, {
                    label: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('包裹类目')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: Package.PackageCategoryId,
                        "onUpdate:modelValue": ($event: any) => ((Package.PackageCategoryId) = $event),
                        placeholder: _ctx.$t('请选择')
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(BD180)?.BasicDictionaryList, (Category) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: Category,
                              label: Category.DicName,
                              value: Category.DicValue
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder"])
                    ]),
                    _: 2
                  }, 1032, ["prop", "rules"]), [
                    [_vShow, TypeId.value == 4]
                  ]),
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('备注'),
                    prop: `Packages.${index}.Remark`
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: Package.Remark,
                        "onUpdate:modelValue": ($event: any) => ((Package.Remark) = $event),
                        type: "textarea",
                        autosize: "",
                        "show-word-limit": "",
                        maxlength: "200"
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["label", "prop"]),
                  _withDirectives(_createVNode(_component_el_form_item, {
                    label: _ctx.$t('操作')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        type: "danger",
                        icon: "Delete",
                        circle: "",
                        onClick: ($event: any) => (DeletePackage(index))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 2
                  }, 1032, ["label"]), [
                    [_vShow, PackageList.value.Packages && PackageList.value.Packages.length > 1]
                  ])
                ]))
              }), 128))
            ]),
            _: 1
          }, 8, ["model"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('运输方式')) + " ", 1),
            _createVNode(_component_el_tooltip, { placement: "top-start" }, {
              content: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(
                  '备注:以发往海外的时间开始计算，如有节假日，就顺延几天节假日时间。'
                )), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_InfoFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode(Article, { id: 5 }, {
            name: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('查看禁运物品')), 1)
            ]),
            _: 1
          })
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_10, [
          _createVNode(_component_el_radio_group, {
            modelValue: TypeId.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((TypeId).value = $event)),
            size: "large",
            onChange: ChangeTypeId
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Types.value, (type) => {
                return (_openBlock(), _createBlock(_component_el_radio, {
                  key: type.ID,
                  label: type.ID
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(type.TypeName), 1)
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ], 512), [
          [_vShow, Types.value]
        ]),
        _withDirectives(_createVNode(Loading, { type: "12" }, null, 512), [
          [_vShow, !Types.value]
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("label", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('发货类型')) + " ", 1),
            _createVNode(_component_el_tooltip, { placement: "top-start" }, {
              content: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(DictionaryTip.value, (Tips) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: Tips }, [
                    _createTextVNode(_toDisplayString(Tips.Desc), 1),
                    _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1))
                  ], 64))
                }), 128))
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_InfoFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_12, [
          _createVNode(_component_el_radio_group, {
            modelValue: DictionaryId.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((DictionaryId).value = $event)),
            size: "large"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(BD56)?.BasicDictionaryList, (Dictionary) => {
                return _withDirectives((_openBlock(), _createBlock(_component_el_radio, {
                  key: Dictionary.DicValue,
                  label: Dictionary.DicValue
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(Dictionary.DicName), 1)
                  ]),
                  _: 2
                }, 1032, ["label"])), [
                  [_vShow, TypeId.value < 3 || Dictionary.DicValue != 2]
                ])
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ], 512), [
          [_vShow, _unref(BD56)?.BasicDictionaryList]
        ]),
        _withDirectives(_createVNode(Loading, { type: "12" }, null, 512), [
          [_vShow, !_unref(BD56)?.BasicDictionaryList]
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('提货仓库')) + " ", 1),
            _createVNode(_component_el_tooltip, { placement: "top-start" }, {
              content: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('请选择距您最近的提货仓直发包裹，您会更快的收到包裹。')), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_InfoFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _withDirectives(_createVNode(_component_el_button, {
            type: "danger",
            icon: "HomeFilled",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (dialogTableVisible.value = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('选择仓库')), 1)
            ]),
            _: 1
          }, 512), [
            [_vShow, Warehouse.value]
          ])
        ]),
        (Warehouse.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_component_el_radio_group, {
                modelValue: Warehouse.value.Id,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((Warehouse.value.Id) = $event)),
                size: "large"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio, {
                    label: Warehouse.value.Id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(Warehouse.value.Name), 1)
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(Loading, { type: "12" }, null, 512), [
          [_vShow, !Warehouse.value]
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('注意事项')), 1)
        ]),
        _withDirectives(_createElementVNode("ul", _hoisted_17, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Notices.value, (Notice, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, " •   " + _toDisplayString(Notice.Desc), 1))
          }), 128))
        ], 512), [
          [_vShow, Notices.value]
        ]),
        _withDirectives(_createVNode(Loading, { type: "12" }, null, 512), [
          [_vShow, !Notices.value]
        ])
      ]),
      _createVNode(_Transition, { name: "el-fade-in-linear" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_affix, {
            ref: "affix",
            position: "bottom",
            offset: 0
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_el_checkbox, {
                    modelValue: isAgree.value,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((isAgree).value = $event)),
                    size: "large",
                    label: _ctx.$t('我已阅读并同意')
                  }, null, 8, ["modelValue", "label"]),
                  _createVNode(Article, { id: 8 }, {
                    name: _withCtx(() => [
                      _createTextVNode(" 《" + _toDisplayString(_ctx.$t('包裹转运验货规则和服务协议')) + "》 ", 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(Article, { id: 6 }, {
                    name: _withCtx(() => [
                      _createTextVNode(" 《" + _toDisplayString(_ctx.$t('保险服务及条款')) + "》 ", 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    class: "LODABTN",
                    type: "danger",
                    size: "large",
                    disabled: !isAgree.value,
                    loading: isLoding.value,
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (submitExpress()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(route).query.id ? _ctx.$t('修改') : _ctx.$t('提交')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading"])
                ])
              ])
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: NeedPassword.value,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((NeedPassword).value = $event)),
      title: _ctx.$t('支付密码'),
      width: "300px",
      "before-close": (isPassword.value = null)
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_20, [
          _createVNode(_component_el_button, {
            onClick: _cache[8] || (_cache[8] = ($event: any) => (NeedPassword.value = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('取消')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "danger",
            disabled: !isPassword.value,
            loading: isLoding.value,
            onClick: _cache[9] || (_cache[9] = ($event: any) => (
            _unref(route).query.id
              ? EditPackage(isPassword.value)
              : CreateTransportPackageBatch(isPassword.value)
          ))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('确定')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(Password, {
          modelValue: isPassword.value,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((isPassword).value = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "before-close"]),
    _createVNode(_component_el_dialog, {
      width: "600px",
      modelValue: dialogTableVisible.value,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((dialogTableVisible).value = $event)),
      title: _ctx.$t('提货仓库')
    }, {
      default: _withCtx(() => [
        _createVNode(Repository, {
          noSet: true,
          Warehouse: Warehouse.value,
          onWarehouseChange: SetWarehouse
        }, null, 8, ["Warehouse"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}
}

})