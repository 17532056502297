import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "BoxList" }
const _hoisted_2 = { class: "condition" }
const _hoisted_3 = { class: "TransportBoxes" }
const _hoisted_4 = { class: "BoxDetail" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "Generalize isDetail" }
const _hoisted_7 = { style: {"display":"flex","align-items":"center","margin":"0 0 0 5px"} }
const _hoisted_8 = { class: "BoxTitle" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "isDetail" }
const _hoisted_11 = { class: "isDetail" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "isDetail" }
const _hoisted_14 = {
  key: 0,
  class: "isDetail"
}
const _hoisted_15 = {
  key: 1,
  class: "isDetail"
}
const _hoisted_16 = { class: "Cost isDetail" }
const _hoisted_17 = { class: "OrderItemDetial" }
const _hoisted_18 = { class: "Detial" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "ProductPagination" }
const _hoisted_21 = { class: "Box_Affix" }

import { ref, onMounted, watch, toRefs } from 'vue'
import moment from 'moment'
import PaginationHook from '@/hooks/pagination'
import * as Api from '@/utils/api'
import { formatDate, textContent } from '@/utils/format'
import Pay from '@/components/user-center/pay.vue'
import Delivery from '@/components/user-center/delivery.vue'
import EmptyArray from '@/components/EmptyArray.vue'
import Loading from '@/components/loading.vue'
import { SiteSet } from '@/utils/siteConfig'
import { useUsersStore } from '@/pinia'
import { shortcuts } from '@/utils/constant'

export default /*@__PURE__*/_defineComponent({
  __name: 'user_box_list',
  setup(__props) {

const country = SiteSet().getPlacePredictions
const store = useUsersStore()
const { PaymentStatus, Entries } = toRefs(store)

const Types = ref([
  {
    name: '全部',
    val: null,
  },
  {
    name: '等待付款',
    val: false,
  },
  {
    name: '已经付款',
    val: true,
  },
])
const isType = ref(null)
const isEntrie = ref(null)
const isDate = ref(null)
const TransportBoxes = ref(null)
const total = ref(0)
const SelectAll = ref(false)
const TotalAmount = ref(0)
const ShowPay = ref(false)
const PayDate = ref({
  TotalAmount: 0,
})
const Ids = ref(null)

const PayDelivery = () => {
  Ids.value = []
  PayDate.value.TotalAmount = TotalAmount.value
  TransportBoxes.value.map((ele) => {
    if (ele.checked) {
      Ids.value.push(ele.BoxId)
    }
  })
  ShowPay.value = true
}

const DeliveryDate = ref(null)
const ShowDelivery = ref(false)
const InitiateDelivery = () => {
  let Deliverys = TransportBoxes.value.filter((e) => {
    return e.checked
  })
  if (Deliverys.length) {
    DeliveryDate.value = Deliverys
    ShowDelivery.value = true
  }
}

const DeliveryChange = (val) => {
  ShowDelivery.value = false
  DeliveryDate.value = []
  if (val) {
    GetBox()
  }
}

watch(
  () => PaymentStatus.value,
  (res) => {
    SelectCalculation()
    GetBox()
  },
  {
    deep: true,
  }
)

const CheckAll = () => {
  SelectAll.value = !SelectAll.value
  TransportBoxes.value.map((e) => {
    if (e.IsCheckPay || !e.IsDelivery) {
      e.checked = SelectAll.value
    }
  })
  SelectCalculation()
}

const Choice = (item) => {
  if (item.IsCheckPay || !item.IsDelivery) {
    item.checked = !item.checked
  }
  SelectCalculation()
}
const isCheckNum = ref(false)
const SelectCalculation = () => {
  let num = 0
  TotalAmount.value = 0
  isCheckNum.value = false
  TransportBoxes.value.map((e) => {
    if (e.checked) {
      num++
      TotalAmount.value += e.TotalAmount
      isCheckNum.value = true
    }
  })
  let AllChecks = TransportBoxes.value.filter((e) => {
    return !isType.value ? e.IsCheckPay : !e.IsDelivery
  })
  SelectAll.value = AllChecks.length == num ? true : false
}

const Boxdetial = (detial) => {
  detial.isShow = !detial.isShow
  if (!detial.BoxDetailList && detial.isShow) {
    Api.Get('orders/api/TransportBox/', {
      BoxSerialNo: detial.Serail,
    }).then((res: any) => {
      detial.BoxDetailList = res.BoxDetailList
    })
  }
}

const GetBox = () => {
  SelectAll.value = false
  TotalAmount.value = 0
  TransportBoxes.value = null
  const body = {
    page_size: pageSize.value,
    page_index: pageNum.value,
    from_date: isDate.value[0],
    to_date: isDate.value[1],
    status_id:
      typeof isType.value == 'boolean'
        ? textContent(isType.value)
        : isType.value,
    pick_up_id: '0',
    good_statu_id: isEntrie.value,
  }
  Api.Get('orders/api/TransportBoxByNew/', body).then((res: any) => {
    TransportBoxes.value = res.TransportBoxes
    total.value = res.TotalRecordsCount
  })
}

const { pageNum, pageSize, handleCurrentChange, handleSizeChange } =
  PaginationHook(GetBox)

onMounted(() => {
  isDate.value = [
    moment().subtract(3, 'months').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ]

  pageSize.value = 10

  store.setState(
    'basic/api/Dictionary/',
    { dictionary_unique_id: 'A38CDD9A-4C80-45DF-AFAB-8574937F3FF8' },
    'Entries'
  )

  GetBox()
})

return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_Select = _resolveComponent("Select")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition")!
  const _component_CaretTop = _resolveComponent("CaretTop")!
  const _component_CaretBottom = _resolveComponent("CaretBottom")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_affix = _resolveComponent("el-affix")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_radio_group, {
          modelValue: isEntrie.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isEntrie).value = $event)),
          onChange: GetBox
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio_button, { label: null }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('全部')), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(Entries)?.Entries, (entrie) => {
              return (_openBlock(), _createBlock(_component_el_radio_button, {
                key: entrie.value,
                label: entrie.Id
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(entrie.Name), 1)
                ]),
                _: 2
              }, 1032, ["label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_el_radio_group, {
          modelValue: isType.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isType).value = $event)),
          onChange: GetBox
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Types.value, (type) => {
              return (_openBlock(), _createBlock(_component_el_radio_button, {
                key: type['value'],
                label: type.val
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(type.name)), 1)
                ]),
                _: 2
              }, 1032, ["label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createElementVNode("div", null, [
          _createVNode(_component_el_date_picker, {
            modelValue: isDate.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((isDate).value = $event)),
            type: "daterange",
            clearable: false,
            "value-format": "YYYY-MM-DD",
            "unlink-panels": "",
            "range-separator": "——",
            "start-placeholder": "Start date",
            "end-placeholder": "End date",
            shortcuts: _unref(shortcuts),
            onChange: GetBox
          }, null, 8, ["modelValue", "shortcuts"])
        ])
      ]),
      _createVNode(_Transition, { name: "el-fade-in-linear" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(TransportBoxes.value, (detial, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass([
          'TransportBoxe',
          { Boxtype1: detial.InStockStatuId == 15 },
          { Boxtype2: detial.IsReceTimeOut },
        ])
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: "BoxDetailNext",
                    onClick: ($event: any) => (Choice(detial))
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        ((isType.value != null && detial.IsCheckPay) ||
                    (isType.value != null && !detial.IsDelivery)
                    )
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass(['DetialChecked', { isChecked: detial.checked }])
                            }, [
                              _withDirectives(_createVNode(_component_el_icon, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Select)
                                ]),
                                _: 2
                              }, 1536), [
                                [_vShow, detial.checked]
                              ])
                            ], 2))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("span", null, _toDisplayString(detial.FreightType), 1),
                        _createElementVNode("span", null, _toDisplayString(detial.Serail), 1),
                        _createElementVNode("span", null, " (" + _toDisplayString(_ctx.$t(detial.PackageCount == 1 ? '单件' : '集包')) + ") ", 1),
                        (detial.IsDelivery)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, "(" + _toDisplayString(_ctx.$t('已验证')) + ")", 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(detial.CalculateBoxType), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('物流状态')) + ":", 1),
                      _createElementVNode("span", null, _toDisplayString(detial.InStockStatus), 1)
                    ]),
                    _createVNode(_component_el_collapse_transition, null, {
                      default: _withCtx(() => [
                        (detial.isShow)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('箱子内含件数')) + ":", 1),
                                _createElementVNode("span", null, _toDisplayString(detial.PackageCount), 1)
                              ]),
                              (detial.StockOutOn)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('大陆出库时间')) + ":", 1),
                                    _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(detial.StockOutOn, 'yyyy-MM-dd')), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (_unref(country) == 'id' && detial.ShippingMark)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('货物类型')) + ":", 1),
                                    _createElementVNode("span", null, _toDisplayString(detial.ShippingMark), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('体积')), 1),
                                  _createElementVNode("span", null, _toDisplayString(detial.AbroadVolume.toFixed(3)), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('实重')), 1),
                                  _createElementVNode("span", null, _toDisplayString(detial.Weight.toFixed(3)), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('计费重量')), 1),
                                  _createElementVNode("span", null, _toDisplayString(detial.AbroadWeight.toFixed(3)), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('国际运费')), 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(detial.ShouldIncomeCN2THFreightAmount)), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('其他费用')), 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(detial.OtherAmount)), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('总金额')), 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(detial.TotalAmount)), 1)
                                ])
                              ]),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detial.BoxDetailList, (item) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "OrderItem isDetail",
                                  key: item.OrderNo
                                }, [
                                  _createElementVNode("div", null, _toDisplayString(item.OrderNo), 1),
                                  _createElementVNode("div", _hoisted_17, [
                                    _createVNode(_component_suffix_img, {
                                      url: item.GoodImgUrl
                                    }, null, 8, ["url"]),
                                    _createElementVNode("div", _hoisted_18, [
                                      _createElementVNode("div", null, _toDisplayString(item.GoodName), 1),
                                      _createElementVNode("div", null, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(item.Price)), 1),
                                        _createElementVNode("span", null, "x" + _toDisplayString(item.Quantity), 1)
                                      ])
                                    ])
                                  ])
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ], 8, _hoisted_5),
                  _createElementVNode("div", {
                    class: "DetialButton",
                    onClick: ($event: any) => (Boxdetial(detial))
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t(!detial.isShow ? '包裹详情' : '收起')) + " ", 1),
                    _withDirectives(_createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_CaretTop)
                      ]),
                      _: 2
                    }, 1536), [
                      [_vShow, detial.isShow]
                    ]),
                    _withDirectives(_createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_CaretBottom)
                      ]),
                      _: 2
                    }, 1536), [
                      [_vShow, !detial.isShow]
                    ])
                  ], 8, _hoisted_19)
                ])
              ], 2))
            }), 128))
          ], 512), [
            [_vShow, TransportBoxes.value]
          ])
        ]),
        _: 1
      }),
      _createVNode(EmptyArray, {
        show: TransportBoxes.value && !TransportBoxes.value.length
      }, null, 8, ["show"]),
      _withDirectives(_createVNode(Loading, { type: "13" }, null, 512), [
        [_vShow, !TransportBoxes.value]
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_el_pagination, {
          currentPage: _unref(pageNum),
          "onUpdate:currentPage": _cache[3] || (_cache[3] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : null)),
          "page-size": _unref(pageSize),
          onSizeChange: _unref(handleSizeChange),
          onCurrentChange: _unref(handleCurrentChange),
          total: total.value,
          background: "",
          layout: "prev, pager, next"
        }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"])
      ])
    ]),
    _withDirectives(_createVNode(_component_el_affix, {
      ref: "affix",
      position: "bottom",
      offset: 0
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", null, [
            _createElementVNode("span", {
              class: _normalizeClass(['DetialChecked', 'AllChecked', { isChecked: SelectAll.value }])
            }, [
              _withDirectives(_createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Select)
                ]),
                _: 1
              }, 512), [
                [_vShow, SelectAll.value]
              ])
            ], 2),
            _createVNode(_component_el_button, {
              onClick: CheckAll,
              size: "large",
              type: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('全选')), 1)
              ]),
              _: 1
            })
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _withDirectives(_createElementVNode("span", { class: "totalAmount" }, _toDisplayString(_ctx.$ccy(TotalAmount.value)), 513), [
              [_vShow, !isType.value]
            ]),
            _createVNode(_component_el_button, {
              size: "large",
              type: "danger",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (!isType.value ? PayDelivery() : InitiateDelivery()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(!isType.value ? '支付' : '发起配送')), 1)
              ]),
              _: 1
            })
          ], 512), [
            [_vShow, isCheckNum.value]
          ])
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, isType.value != null && TransportBoxes.value && TransportBoxes.value.length != 0]
    ]),
    _createVNode(Delivery, {
      Date: DeliveryDate.value,
      ShowDelivery: ShowDelivery.value,
      onDeliveryChange: DeliveryChange
    }, null, 8, ["Date", "ShowDelivery"]),
    _createVNode(Pay, {
      Date: PayDate.value,
      ShowPay: ShowPay.value,
      Ids: Ids.value,
      PayType: "Box",
      onPayChange: _cache[5] || (_cache[5] = ($event: any) => (ShowPay.value = false))
    }, null, 8, ["Date", "ShowPay", "Ids"])
  ], 64))
}
}

})