import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "types" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "Product" }
const _hoisted_5 = {
  key: 0,
  class: "MoreBuyings"
}
const _hoisted_6 = {
  key: 1,
  class: "noMore"
}

import { ref, reactive, onMounted, watch } from 'vue'
import * as Api from '@/utils/api'
import { useRoute } from 'vue-router'
import CommodityCard from '@/components/CommodityCard.vue'
import ProductSkeleton from '@/components/ProductSkeleton.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'hot_products',
  setup(__props) {

const route = useRoute()
const ProductList = ref(null)
const types: any = ref([])

const changeType = (id) => {
  typeId.value = id
  pageNum.value = 1
  noMore.value = false
  window.scrollTo({ top: 0 })
  GetTop100()
}

const typeId: any = ref(1)
const pageNum = ref(1)
const pageSize = ref(60)
const noMore = ref(false)
const HotsLoding = ref(false)

const GetTop100 = async () => {
  HotsLoding.value = false
  if (types.value.length == 0) {
    const res: any = await Api.Get('grabgoods/api/GetTop100/', {}).then()
    types.value = res.Categories
    typeId.value = res.Categories[0].CategoryId
  }


  Api.Get('grabgoods/api/GetTop100/', {
    page: pageNum.value,
    pagesize: pageSize.value,
    cid: typeId.value,
  }).then((res: any) => {
    HotsLoding.value = res.Goods?.length < 60 ? false : true
    if (res.Goods?.length < 60) {
      noMore.value = true
    }
    if (pageNum.value == 1) {
      ProductList.value = res.Goods
    } else {
      ProductList.value = ProductList.value.concat(res.Goods)
    }
  })
}

onMounted(() => {
  typeId.value = route.query?.id || null
  GetTop100()
})

return (_ctx: any,_cache: any) => {
  const _component_el_affix = _resolveComponent("el-affix")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_affix, { offset: 40 }, {
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(types.value, (type, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              onClick: ($event: any) => (changeType(type.CategoryId)),
              class: _normalizeClass({ selectType: typeId.value == type.CategoryId })
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t(type.CategoryName)), 1)
            ], 10, _hoisted_3))
          }), 128))
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      (!ProductList.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(24, (i) => {
            return _createVNode(ProductSkeleton, {
              class: "CCard",
              key: i
            })
          }), 64))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ProductList.value, (Product, index) => {
        return (_openBlock(), _createBlock(CommodityCard, {
          key: Product,
          data: Product,
          index: index,
          height: 290,
          class: "CCard"
        }, null, 8, ["data", "index"]))
      }), 128)),
      (ProductList.value)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(6 - (ProductList.value.length % 6), (placeholder) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "placeholderCard",
              key: placeholder
            }))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    (HotsLoding.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (pageNum.value++, GetTop100()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('更多')) + " ... ", 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (noMore.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('到底啦~看看其他精选内容')), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})