import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Transition as _Transition, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { style: {"position":"relative"} }
const _hoisted_2 = {
  key: 0,
  class: "Name"
}
const _hoisted_3 = {
  key: 1,
  class: "Name"
}
const _hoisted_4 = { class: "Price" }
const _hoisted_5 = {
  key: 0,
  class: "Origin"
}
const _hoisted_6 = { class: "Rate" }
const _hoisted_7 = {
  key: 2,
  class: "CustomerName"
}

import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import MallLabel from '@/components/mall_label.vue'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import GoogleTranslate from './GoogleTranslate.vue'
import { SiteSet } from '@/utils/siteConfig'


export default /*@__PURE__*/_defineComponent({
  __name: 'CommodityCard',
  props: {
  data: Object,
  index: Number,
  height: { type: Number, default: 330 },
},
  setup(__props) {

const { t } = useI18n()
const userouter = useRouter()
const show = ref(false)
const localLan = localStorage.getItem('lang')
const country = SiteSet().getPlacePredictions

const PayTime = () => {
  const hours = CreatedOn('hours')
  if (hours < 24) {
    return hours == 0 ? CreatedOn('minutes') + t('分钟前') : hours + t('小时前')
  } else {
    return CreatedOn('days') + t('天前')
  }
}

const CreatedOn = (type) => {
  return moment().diff(moment(props.data.CreatedOn), type)
}

const props = __props

onMounted(() => {
  // const href = userouter.resolve({
  //   path: '/productDetails',
  //   query: {
  //     outId: props.data.OutId || props.data.SellerPId,
  //     mallId: props.data.MallId,
  //     Url: props.data.Url,
  //   },
  // })
  // window.open(href.href, '_blank')
  setTimeout(() => {
    show.value = true
  }, 100 * props.index)
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(MallLabel, null, null, 512), [
      [_vShow, props.data.MallId == 111036]
    ]),
    _createElementVNode("div", {
      class: "CommodityCard",
      style: _normalizeStyle({ height: props.height + 'px' })
    }, [
      _createVNode(_Transition, { name: "el-fade-in-linear" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: {
          path: '/productDetails',
          query: {
            outId: props.data.OutId || props.data.SellerPId,
            mallId: props.data.MallId,
            Url: props.data.Url,
          },
        },
            target: "_blank",
            title: props.data.GoodsName || props.data.ProductName
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_suffix_img, {
                  alt: props.data.GoodsName || props.data.ProductName,
                  url: props.data.ImgUrl || props.data.ProductImgUrl,
                  type: 5
                }, null, 8, ["alt", "url"]),
                (_unref(localLan) == 'zh-cn' && _unref(country) != 'kh')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(GoogleTranslate, {
                        text: props.data.GoodsName || props.data.ProductName,
                        type: "card",
                        MallId: props.data.MallId
                      }, null, 8, ["text", "MallId"])
                    ]))
                  : _createCommentVNode("", true),
                (_unref(country) == 'kh')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(GoogleTranslate, {
                        text: props.data.GoodsName || props.data.ProductName,
                        type: "card",
                        MallId: props.data.MallId
                      }, null, 8, ["text", "MallId"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(props.data.DiscountPrice || props.data.Price)), 1),
                  (props.data.OriginalPrice || props.data.DiscountPrice)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$ccy(props.data.OriginalPrice || props.data.Price)), 1))
                    : _createCommentVNode("", true)
                ]),
                _withDirectives(_createElementVNode("span", _hoisted_6, [
                  _createTextVNode(_toDisplayString(props.data.DiscountRate) + " ", 1),
                  _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                  _cache[1] || (_cache[1] = _createTextVNode(" OFF "))
                ], 512), [
                  [_vShow, props.data.DiscountRate]
                ]),
                (props.data.CustomerName)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("span", null, _toDisplayString(PayTime()), 1),
                      _createElementVNode("span", null, _toDisplayString(props.data.CustomerName) + " " + _toDisplayString(_ctx.$t('购买过')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ], 512), [
                [_vShow, show.value]
              ])
            ]),
            _: 1
          }, 8, ["to", "title"])
        ]),
        _: 1
      })
    ], 4)
  ]))
}
}

})