import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "WarehouseMap" }

import { ref, onMounted, watch } from 'vue'
import * as Api from '@/utils/api'
import Loading from '@/components/loading.vue'
import EmptyArray from '@/components/EmptyArray.vue'
import { GoogleMap, InfoWindow, Marker } from 'vue3-google-map'
import { ElMessageBox } from 'element-plus'
import { useI18n } from 'vue-i18n'
import { mapKey } from '@/main'


export default /*@__PURE__*/_defineComponent({
  __name: 'repository',
  props: {
  Warehouse: Object,
  noSet: Boolean,
},
  emits: ['WarehouseChange'],
  setup(__props, { emit: __emit }) {

const props = __props

const { t } = useI18n()

const _warehouse = props.Warehouse
const _noSet = props.noSet
const emits = __emit

const isDefault = ref({ lat: 11.5466075, lng: 104.9005807 })
const center = ref(isDefault.value)
const mapRef = ref(null)
const zoom = ref(15)

const Cities = ref()
const CitieId = ref(null)
const SignWarehouse = ref([])
const WarehouseIndex = ref(null)
const SelectWarehouse = ref(null)
const isSetDefault = ref(false)

const SetWarehouse = () => {
  emits('WarehouseChange', { params: SelectWarehouse.value })
}

const SetDefaultWarehouse = () => {
  isSetDefault.value = true
  Api.Post('basic/api/SetCustomerWarehouse/', {
    WarehouseId: SelectWarehouse.value.WarehouseId,
  }).then(() => {
    isSetDefault.value = false
    ElMessageBox.alert(t('提交成功'), '', {
      confirmButtonText: 'OK',
      showClose: false,
      closeOnPressEscape: false,
    })
    SetWarehouse()
  })
}

const CitieChange = () => {
  SignWarehouse.value = null
  WarehouseIndex.value = null
  SelectWarehouse.value = null
  Api.Get('basic/api/GetSignWarehouse', {
    city_id: CitieId.value,
  }).then((res: any) => {
    SignWarehouse.value = res.Warehouses
  })
}

const WarehouseChange = (value) => {
  SelectWarehouse.value = SignWarehouse.value[value]
  setTimeout(() => {
    zoom.value = 18
    center.value = {
      lat: Number(SelectWarehouse.value.LatSize),
      lng: Number(SelectWarehouse.value.LngSize),
    }
  }, 1000)
}

onMounted(() => {
  Api.Get('basic/api/GetCitiesByCountryCode/', {}).then((res: any) => {
    Cities.value = res.Cities
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_component_el_select, {
      modelValue: CitieId.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((CitieId).value = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (CitieChange())),
      filterable: "",
      placeholder: _ctx.$t('请选择省份'),
      size: "large"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Cities.value, (citie) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: citie.CityId,
            label: citie.CityName,
            value: citie.CityId
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "placeholder"])), [
      [_directive_loading, !Cities.value]
    ]),
    _withDirectives((_openBlock(), _createBlock(_component_el_select, {
      modelValue: WarehouseIndex.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((WarehouseIndex).value = $event)),
      onChange: WarehouseChange,
      filterable: "",
      placeholder: _ctx.$t(SignWarehouse.value && !SignWarehouse.value.length ? '未开通提货仓' : '请选择提货仓库'),
      size: "large",
      disabled: !SignWarehouse.value
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(SignWarehouse.value, (warehouse, index) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: warehouse.WarehouseId,
            label: warehouse.IsSelf ? warehouse.WarehouseName + '__' + _ctx.$t('自营') : warehouse.WarehouseName,
            value: index
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "placeholder", "disabled"])), [
      [_directive_loading, !SignWarehouse.value]
    ]),
    _withDirectives(_createVNode(_unref(GoogleMap), {
      ref_key: "mapRef",
      ref: mapRef,
      "api-key": _unref(mapKey),
      style: {"width":"100%","height":"400px"},
      center: center.value,
      zoom: zoom.value,
      minZoom: 11,
      clickableIcons: false,
      "disable-default-ui": true,
      language: "en"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(SignWarehouse.value, (Warehouse) => {
          return (_openBlock(), _createBlock(_unref(Marker), {
            key: Warehouse.WarehouseId,
            options: {
        position: {
          lat: Number(Warehouse.LatSize),
          lng: Number(Warehouse.LngSize),
        },
      }
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(InfoWindow), null, {
                default: _withCtx(() => [
                  _createElementVNode("b", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('联系人')) + ": " + _toDisplayString(Warehouse.Consignee), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('联系电话')) + ": " + _toDisplayString(Warehouse.Mobile), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('地址')) + ": " + _toDisplayString(Warehouse.Address), 1)
                  ])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["options"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["api-key", "center", "zoom"]), [
      [_vShow, SelectWarehouse.value]
    ]),
    _withDirectives((_openBlock(), _createBlock(_component_el_button, {
      disabled: WarehouseIndex.value == null,
      type: "danger",
      size: "large",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(_noSet) ? SetWarehouse() : SetDefaultWarehouse()))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('确认')), 1)
      ]),
      _: 1
    }, 8, ["disabled"])), [
      [_directive_loading, isSetDefault.value]
    ])
  ]))
}
}

})