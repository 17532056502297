import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "EmptyArray" }

import { ref, onMounted, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'EmptyArray',
  props: {
  show: Boolean,
},
  setup(__props) {

const props = __props

watch([() => props.show], () => { }, {
  deep: true, // 深度监听
})

onMounted(() => { })

return (_ctx: any,_cache: any) => {
  const _component_Box = _resolveComponent("Box")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "el-fade-in" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_Box)
          ]),
          _: 1
        }),
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('无查询结果')), 1)
      ], 512), [
        [_vShow, __props.show]
      ])
    ]),
    _: 1
  }))
}
}

})