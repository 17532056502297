import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["innerHTML"]

import { ref, reactive, onMounted, watch, getCurrentInstance } from 'vue'
import * as Api from '@/utils/api'
import { useRoute } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'article',
  setup(__props) {

const { proxy } = getCurrentInstance()
const route = useRoute()

const lan = proxy?.$SiteSet['article']

const content = ref(null)

onMounted(() => {
  Api.Get('article/api/ArticleTitle/', {
    ArticleId: lan[localStorage.getItem('lang') || 'km'][route.query.Id - 1],
  }).then((res: any) => {
    if (res.ArticlesList.length) {
      content.value = res.ArticlesList[0].Content
    }
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_skeleton, {
      rows: 20,
      animated: ""
    }, null, 512), [
      [_vShow, !content.value]
    ]),
    _createElementVNode("div", { innerHTML: content.value }, null, 8, _hoisted_2)
  ]))
}
}

})