import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "set_adrress" }
const _hoisted_2 = { class: "register" }

import { ref, reactive, watch, onMounted, toRefs } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import * as Api from '@/utils/api'
import { textContent, changeKey } from '@/utils/format'
import OSS from '@/components/oss.vue'
import GoogleMap from '@/components/user-center/google_map.vue'
import { useI18n } from 'vue-i18n'

import { useUsersStore } from '@/pinia'
import type { FormInstance } from 'element-plus'

export default /*@__PURE__*/_defineComponent({
  __name: 'set_adress',
  props: {
  dialogInfo: Object,
},
  setup(__props) {

const store = useUsersStore()
const { AreaCodeList } = toRefs(store)

const { t } = useI18n()

const isMap = reactive({
  isShow: false,
  address: null,
})

const ruleFormRef = ref<FormInstance>()

const props = __props
// 直接解构，不需要使用 toRefs
const _dialogInfo = props.dialogInfo

const imgList = ref([])
const isUpLoding = ref(false)

//PositioningInformation
const PI = reactive({
  CityName: null,
  DistrictName: null,
  LatSize: null,
  LngSize: null,
})

const openMap = () => {
  isMap.isShow = true
  isMap.address = {
    lat: PI.LatSize,
    lng: PI.LngSize,
  }
}

watch(
  () => isMap.address,
  (val) => {
    if (val && val.address) {
      PI.LatSize = val.lat
      PI.LngSize = val.lng
      PI.CityName = val.CityName
      PI.DistrictName = val.DistrictName
      ruleForm.Address = val.address
    }
  },
  {
    immediate: true,
    deep: true,
  }
)

watch(
  () => _dialogInfo.isShow,
  (val) => {
    if (val && _dialogInfo.id) {
      GetAddresses(_dialogInfo.id)
    }
  },
  {
    immediate: true,
    deep: true,
  }
)

const GetAddresses = (addressid) => {
  AddressId.value = addressid
  Api.Get('user/api/ReceiveAddresses_New/', {
    addressid,
  }).then((res: any) => {
    let old = res.Address
    ruleForm.ReceiverName = old.ReceiverName
    ruleForm.Mobile = old.Mobile
    ruleForm.Address = old.Address.split('|')[0]
    ruleForm.DetailedAddress = old.Address.split('|')[1]
    ruleForm.IsDefaultAddress = old.IsDefaultAddress
    imgList.value = old.ImgUrlList
      ? changeKey(old.ImgUrlList, 'ImgUrl', 'url')
      : []
    PI.CityName = old.City
    PI.DistrictName = old.DistrictName
    PI.LatSize = old.LatSize
    PI.LngSize = old.LngSize
  })
}

const handleClose = (done: () => void) => {
  imgList.value = []
  ruleFormRef.value.resetFields()
  done()
}

const ruleForm = reactive({
  ReceiverName: null,
  Mobile: null,
  Address: null,
  DetailedAddress: null,
  IsDefaultAddress: false,
})

const AddressId = ref(null)

const DevicePass = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error(t('不能为空')))
  } else {
    !eval(AreaCodeList.value.Items[0].Regular).test(value)
      ? callback(new Error(t('请输入正确手机号')))
      : callback()
  }
}

const rules = reactive({
  ReceiverName: [{ required: true, message: t('不能为空'), trigger: 'blur' }],
  Mobile: [{ required: true, validator: DevicePass }],
  Address: [{ required: true, message: t('不能为空'), trigger: 'blur' }],
  DetailedAddress: [
    {
      required: true,
      min: 5,
      max: 150,
      message: t('不能小于5或大于150个字符'),
      trigger: 'blur',
    },
  ],
})

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      const body = {
        Address: ruleForm.Address + '|' + ruleForm.DetailedAddress,
        AddressId: AddressId.value,
        AddressTypeId: 1,
        CityName: PI.CityName,
        DistrictName: PI.DistrictName,
        ReceiverName: ruleForm.ReceiverName,
        ImgUrlList: changeKey(imgList.value, 'url', 'ImgUrl'),
        IsDefaultAddress: textContent(ruleForm.IsDefaultAddress),
        LngSize: PI.LngSize.toString(),
        LatSize: PI.LatSize.toString(),
        Mobile: ruleForm.Mobile,
        VerificationCode: null,
      }
      isUpLoding.value = true
      Api.Post('user/api/ReceiveAddresses_New/', body).then((res: any) => {
        isUpLoding.value = false
        if (!res.IsError) {
          ElMessage({
            message: t('已保存'),
            type: 'success',
          })
          _dialogInfo.isShow = false
          store.AddressModified = true
        }
      })
    }
  })
}

onMounted(() => {
  store.setState('user/api/SendMessage', {}, 'AreaCodeList')
})

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_MapLocation = _resolveComponent("MapLocation")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _unref(_dialogInfo).isShow,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(_dialogInfo).isShow) = $event)),
    modal: true,
    title: _ctx.$t('收货地址'),
    width: "60%",
    "before-close": handleClose,
    "custom-class": "adrress_dialog"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_form, {
            ref_key: "ruleFormRef",
            ref: ruleFormRef,
            model: ruleForm,
            rules: rules,
            size: "formSize",
            "label-position": "top"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('收货人'),
                prop: "ReceiverName"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    placeholder: _ctx.$t('请输入姓名'),
                    modelValue: ruleForm.ReceiverName,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ruleForm.ReceiverName) = $event))
                  }, null, 8, ["placeholder", "modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('手机号'),
                prop: "Mobile"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: ruleForm.Mobile,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ruleForm.Mobile) = $event)),
                    placeholder: _ctx.$t('请输入手机号'),
                    "prefix-icon": "Iphone"
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('所在地区'),
                prop: "Address",
                class: "isAddress"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, { onClick: openMap }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('选择地址')) + "    ", 1),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_MapLocation)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _withDirectives(_createVNode(_component_el_input, {
                    modelValue: ruleForm.Address,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ruleForm.Address) = $event)),
                    disabled: ""
                  }, null, 8, ["modelValue"]), [
                    [_vShow, ruleForm.Address]
                  ])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('详细住址'),
                prop: "DetailedAddress"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: ruleForm.DetailedAddress,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((ruleForm.DetailedAddress) = $event)),
                    type: "textarea",
                    "show-word-limit": true,
                    placeholder: _ctx.$t('请输入详细住址')
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('地址照片')
              }, {
                default: _withCtx(() => [
                  _createVNode(OSS, {
                    type: 5,
                    imgList: imgList.value,
                    max: 2,
                    obj: "ImgUrl",
                    onImgsChange: _cache[4] || (_cache[4] = 
                (val) => {
                  imgList.value = val
                }
              )
                  }, null, 8, ["imgList"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, { prop: "IsDefaultAddress" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_checkbox, {
                    modelValue: ruleForm.IsDefaultAddress,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((ruleForm.IsDefaultAddress) = $event)),
                    label: _ctx.$t('设为默认地址'),
                    size: "large"
                  }, null, 8, ["modelValue", "label"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    size: "large ",
                    type: "danger",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (submitForm(ruleFormRef.value))),
                    loading: isUpLoding.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('确认')), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"]),
          _createVNode(GoogleMap, { isMap: isMap }, null, 8, ["isMap"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})