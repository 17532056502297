import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "PageTypes" }
const _hoisted_3 = { class: "Page" }

import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import * as Api from '@/utils/api'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'user-center',
  setup(__props) {

const { proxy } = getCurrentInstance()
const { t } = useI18n()
const route = useRoute()
const userouter = useRouter()
const CurrentType = ref(null)

const PageTypes = ref([
  {
    name: '订单中心',
    list: [
      {
        name: '用户中心',
        type: 'basic',
        show: true,
      },
      {
        name: '我的订单',
        type: 'orders',
        show: true,
      },

      {
        name: '收藏夹',
        type: 'favorites',
        show: true,
      },
    ],
  },
  {
    name: '货物中心',
    list: [
      {
        name: '我的包裹',
        type: 'myPackage',
        show: true,
      },

      {
        name: '我的箱子',
        type: 'myBox',
        show: true,
      },

      {
        name: '乐达配送',
        type: 'lodaDelivery',
        show: proxy?.$SiteSet['lodaDelivery'],
      },

      {
        name: '收货地址',
        type: 'addressList',
        show: true,
      },
      {
        name: '提货仓库',
        type: 'repository',
        show: true,
      },
    ],
  },
  {
    name: '资产中心',
    list: [
      {
        name: '我的钱包',
        type: 'wallet',
        show: true,
      },
      {
        name: '我的优惠劵',
        type: 'coupons',
        show: proxy?.$SiteSet['coupons'],
      },
    ],
  },
  {
    name: '设置',
    list: [
      {
        name: '个人资料',
        type: 'userInformation',
        show: true,
      },
      {
        name: '安全设置',
        type: 'settings',
        show: true,
      },
      {
        name: '邀请好友',
        type: 'invitation',
        show: proxy?.$SiteSet['invitation'],
      },
      {
        name: '消息通知',
        type: 'stationLetter',
        show: proxy?.$SiteSet['stationLetter'],
      },
    ],
  },
])

onBeforeRouteUpdate((to, from) => {
  CurrentType.value = to.name
})

onMounted(() => {
  CurrentType.value = userouter.currentRoute.value.name
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(PageTypes.value, (Types, index) => {
        return (_openBlock(), _createElementBlock("ul", { key: index }, [
          _createElementVNode("li", null, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t(Types.name)), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Types.list, (Type, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (Type?.show)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: { name: Type.type }
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", {
                            class: _normalizeClass({ CurrentType: Type.type == CurrentType.value })
                          }, _toDisplayString(_ctx.$t(Type.name)), 3)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ])
  ]))
}
}

})