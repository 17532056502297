import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onMouseenter", "onClick"]
const _hoisted_2 = { class: "Childs" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "NextChilds" }
const _hoisted_5 = ["onClick"]

import {
  watch,
  ref,
  reactive,
  onMounted,
  toRefs,
  getCurrentInstance,
} from 'vue'

import { useUsersStore } from '@/pinia'
import { useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'Categories',
  setup(__props) {

const store = useUsersStore()
const { AllFrontCategories } = toRefs(store)

const Router = useRouter()

const { proxy } = getCurrentInstance()
const ShowChilds = ref(false)
const Childs = ref(null)
const CategoryId = ref(null)

const mouseenterChilds = (Childs) => {
  // if (Childs.Childs.length) {
  ShowChilds.value = true
  Childs.value = Childs.Childs
  CategoryId.value = Childs.CategoryId
  // }
}

const searchListPush = (item) => {
  Router.push({
    path: '/searchList',
    query: { type: 0, CategoryId: item.CategoryId },
  })
  proxy?.$Bus.emit('UpSearch', 'mitt')
}

onMounted(() => {
  store.setState(
    'grabgoods/api/GetAllFrontCategories',
    {},
    'AllFrontCategories'
  )
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!

  return (_openBlock(), _createElementBlock("div", {
    class: "Categories",
    onMouseleave: _cache[0] || (_cache[0] = ($event: any) => {; (ShowChilds.value = false), (CategoryId.value = null)})
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AllFrontCategories)?.Categories, (Categories) => {
      return (_openBlock(), _createElementBlock("div", {
        key: Categories.CategoryId,
        class: _normalizeClass([
      'Categorie',
      { SelectCategorie: CategoryId.value == Categories.CategoryId },
    ]),
        onMouseenter: ($event: any) => (Categories.Childs.length ? (ShowChilds.value = true, Childs.value = Categories.Childs, CategoryId.value = Categories.CategoryId) : null),
        onClick: ($event: any) => (searchListPush(Categories))
      }, [
        _createVNode(_component_suffix_img, {
          url: Categories.PicUrl,
          type: 0
        }, null, 8, ["url"]),
        _createElementVNode("span", null, _toDisplayString(Categories.CategoryName), 1)
      ], 42, _hoisted_1))
    }), 128)),
    _createVNode(_Transition, { name: "el-fade-in" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Childs.value, (Child) => {
            return (_openBlock(), _createElementBlock("div", { key: Child }, [
              _createElementVNode("label", null, [
                _createElementVNode("span", {
                  class: "CategoryName",
                  onClick: ($event: any) => (searchListPush(Child))
                }, _toDisplayString(Child.CategoryName), 9, _hoisted_3)
              ]),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Child.Childs, (NextChild) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "NextChild",
                    onClick: ($event: any) => (searchListPush(NextChild)),
                    key: NextChild.CategoryId
                  }, [
                    _createVNode(_component_suffix_img, {
                      class: "PicUrl",
                      url: NextChild.PicUrl,
                      type: 0
                    }, null, 8, ["url"]),
                    _createElementVNode("span", null, _toDisplayString(NextChild.CategoryName), 1)
                  ], 8, _hoisted_5))
                }), 128))
              ])
            ]))
          }), 128))
        ], 512), [
          [_vShow, ShowChilds.value]
        ])
      ]),
      _: 1
    })
  ], 32))
}
}

})