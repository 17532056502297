import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { class: "setUp" }

import { ref, reactive, onMounted, watch, toRefs } from 'vue'
import SetAdress from '@/components/user-center/set_adress.vue'
import * as Api from '@/utils/api'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'address_card',
  props: {
  item: Object,
  isDefault: Boolean,
  index: Number,
  setDefault: Boolean,
},
  setup(__props) {

const store = useUsersStore()
const { AddressModified } = toRefs(store)

const dialogInfo = reactive({
  isShow: false,
  id: null,
})
const isLoading = ref(false)
const show = ref(false)
const props = __props

const SetDefalutAddress = (AddressId) => {
  isLoading.value = true
  Api.Put('user/api/ReceiveAddresses_New/', {
    AddressId,
    IsDefaultAddress: 'True',
  }).then((res: any) => {
    isLoading.value = false
    store.AddressModified = true
  })
}

const SetAddress = (id) => {
  dialogInfo.id = id
  dialogInfo.isShow = true
}

const DeleteAdress = (id) => {
  isLoading.value = true
  Api.Delete('user/api/ReceiveAddresses_New/', {
    id,
  }).then((res: any) => {
    isLoading.value = false
    if (!res.IsError) {
      show.value = false
    }
  })
}

watch([props.isDefault, props.setDefault], () => { }, {
  deep: true, // 深度监听
})

onMounted(() => {
  setTimeout(() => {
    show.value = true
  }, 50 * props.index)
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_Transition, { name: "el-fade-in-linear" }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['UserAddress', { Default: __props.isDefault }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(__props.item.ReceiverName), 1),
          _createElementVNode("span", null, _toDisplayString(__props.item.Address), 1),
          _createElementVNode("span", null, _toDisplayString(__props.item.Mobile), 1)
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            type: "info",
            icon: "Edit",
            circle: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (SetAddress(__props.item.AddressId)))
          }),
          (props.setDefault)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "danger",
                icon: "Delete",
                circle: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (DeleteAdress(__props.item.AddressId)))
              }))
            : _createCommentVNode("", true)
        ]),
        (props.setDefault)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['IsDefault', { Default: __props.item.IsDefaultAddress }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (
        __props.item.IsDefaultAddress ? null : SetDefalutAddress(__props.item.AddressId)
        ))
            }, _toDisplayString(_ctx.$t(__props.item.IsDefaultAddress ? '默认地址' : '设为默认')), 3))
          : _createCommentVNode("", true),
        _createVNode(SetAdress, { dialogInfo: dialogInfo }, null, 8, ["dialogInfo"])
      ], 2)), [
        [_vShow, show.value],
        [_directive_loading, isLoading.value]
      ])
    ]),
    _: 1
  }))
}
}

})