import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "CouponCard"
}
const _hoisted_2 = { class: "C_L" }
const _hoisted_3 = { class: "C_R" }

import { ref, onMounted } from 'vue'
import { formatDate } from '@/utils/format'

export default /*@__PURE__*/_defineComponent({
  __name: 'coupon_card',
  props: {
  data: Object,
  index: Number,
},
  setup(__props) {

const show = ref(false)
let item = ref(null)

const props = __props

onMounted(() => {
  console.log(props.data)
  item.value = props.data
  setTimeout(() => {
    show.value = true
  }, 100 * props.index)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "el-fade-in-linear" }, {
    default: _withCtx(() => [
      (show.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("span", null, "$", -1)),
              _createTextVNode(" " + _toDisplayString(_unref(item).DiscountAmount), 1)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", null, _toDisplayString(_unref(item).CouponConsumeRange), 1),
              _createElementVNode("span", null, _toDisplayString(_unref(item).DiscountAmount + ' ' + _unref(item).DiscountCurrencyCode), 1),
              _createElementVNode("span", null, _toDisplayString(_unref(item).SerialNumber), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('有效期至')) + ": " + _toDisplayString(_unref(formatDate)(_unref(item).ExpiredOn, 'yyyy-MM-dd hh:mm')), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})