import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "ImgSearching" }
const _hoisted_3 = ["onClick"]

import { ref, reactive, onMounted, onBeforeUnmount, watch } from 'vue'
import * as Api from '@/utils/api'
import moment from 'moment'
import { useRouter } from 'vue-router'
import ProductSkeleton from '@/components/ProductSkeleton.vue'
import { useUsersStore } from '@/pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'img_search',
  setup(__props) {

const store = useUsersStore()
const userouter = useRouter()
const pageIndex = ref(1)
const ImgSearch = (img) => {
  store.base64img = img
  userouter.push({
    name: 'searchList',
    query: { img: 'true' },
  })
}

const ImgCardLoding = ref(false)
const ImgSearchingList = ref([])
const NoMore = ref(false)
const EveryoneImgSearching = () => {
  ImgCardLoding.value = true
  store.getEveryoneImg(pageIndex.value, true).then(res => {
    ImgCardLoding.value = false
    ImgSearchingList.value = res
    store.ImgSearchingList = ImgSearchingList.value
    NoMore.value = true
    setTimeout(() => {
      if (!observer.value) {
        observer.value = new IntersectionObserver((entry) => {
          if (
            entry[0].isIntersecting &&
            !ImgCardLoding.value &&
            NoMore.value
          ) {
            pageIndex.value++
            EveryoneImgSearching()
          }
        })
        observer.value.observe(document.querySelector('.Reserved'))
      }
    }, 1000)
  })

}

const observer = ref(null)
onBeforeUnmount(() => {
  if (observer.value) {
    observer.value.disconnect()
  }
})
onMounted(() => {
  EveryoneImgSearching()
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ImgSearchingList.value, (Imgs, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "ImgCard",
          key: index,
          onClick: ($event: any) => (ImgSearch(Imgs.ImageUrl))
        }, [
          _createVNode(_component_suffix_img, {
            url: Imgs.ImageUrl
          }, null, 8, ["url"]),
          _createElementVNode("p", null, [
            _createElementVNode("span", null, _toDisplayString(Imgs.CustomerName), 1),
            _createElementVNode("span", null, _toDisplayString(_unref(moment)(Imgs.CreatedOn).format('YYYY-MM-DD HH:mm:ss')), 1)
          ])
        ], 8, _hoisted_3))
      }), 128)),
      (ImgSearchingList.value.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(6 - (ImgSearchingList.value.length % 6), (placeholder) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "placeholderCard",
              key: placeholder
            }))
          }), 128))
        : _createCommentVNode("", true),
      (ImgCardLoding.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(18, (i) => {
            return _createVNode(ProductSkeleton, {
              class: "ImgCardLoding",
              key: i
            })
          }), 64))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})