import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "favorites" }
const _hoisted_2 = { class: "FavoriteList" }
const _hoisted_3 = { class: "ProductName" }
const _hoisted_4 = { class: "DeleteProduct" }
const _hoisted_5 = { class: "ProductPagination" }

import { ref, reactive, onMounted, watch } from 'vue'
import PaginationHook from '@/hooks/pagination'
import Loading from '@/components/loading.vue'
import EmptyArray from '@/components/EmptyArray.vue'
import * as Api from '@/utils/api'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'favorites',
  setup(__props) {

const userouter = useRouter()
const FavoriteList = ref(null)
const total = ref(0)

const CustomerFavorite = (item) => {
  Api.Post('user/api/CustomerFavorites/', {
    Id: item.Id,
  }).then((res: any) => {
    GetFavorites()
  })
}

const GetFavorites = () => {
  Api.Get('user/api/CustomerFavorites/', {
    PageSize: pageSize.value,
    PageIndex: pageNum.value,
  }).then((res: any) => {
    FavoriteList.value = res.FavirutesList
    total.value = res.Total
  })
}

const productDetails = (i) => {
  const href = userouter.resolve({
    path: '/productDetails',
    query: { outId: i.ProductUrl, mallId: i.MallId || 111036 },
  })
  window.open(href.href, '_blank')
}

const { pageNum, pageSize, handleCurrentChange, handleSizeChange } =
  PaginationHook(GetFavorites)

onMounted(() => {
  pageSize.value = 30
  GetFavorites()
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_DeleteFilled = _resolveComponent("DeleteFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(FavoriteList.value, (Favorite, index) => {
        return (_openBlock(), _createBlock(_component_el_card, {
          class: "Favorite",
          key: index,
          onClick: ($event: any) => (productDetails(Favorite))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_suffix_img, {
              url: Favorite.ProductImgUrl,
              type: 5
            }, null, 8, ["url"]),
            _createElementVNode("div", _hoisted_3, _toDisplayString(Favorite.ProductName), 1),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_DeleteFilled, {
                    onClick: _withModifiers(($event: any) => (CustomerFavorite(Favorite)), ["stop"])
                  }, null, 8, ["onClick"])
                ]),
                _: 2
              }, 1024)
            ])
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128)),
      (FavoriteList.value)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(5 - (FavoriteList.value.length % 5), (placeholder) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "placeholderCard",
              key: placeholder
            }))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    _createVNode(EmptyArray, {
      show: FavoriteList.value && !FavoriteList.value.length
    }, null, 8, ["show"]),
    _withDirectives(_createVNode(Loading, { type: "13" }, null, 512), [
      [_vShow, !FavoriteList.value]
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_el_pagination, {
        currentPage: _unref(pageNum),
        "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : null)),
        "page-size": _unref(pageSize),
        onSizeChange: _unref(handleSizeChange),
        onCurrentChange: _unref(handleCurrentChange),
        total: total.value,
        background: "",
        layout: "prev, pager, next"
      }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"])
    ])
  ]))
}
}

})