import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "AreaCode"
}
const _hoisted_2 = { class: "AreaCodeList" }
const _hoisted_3 = ["onClick"]

import { onMounted, watch, toRefs } from '@vue/runtime-core'
import * as Api from '@/utils/api'
import { ref } from 'vue'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'RegionSelection',
  props: {
  wid: Number || String,
},
  emits: ['AreaCode'],
  setup(__props, { emit: __emit }) {

const store = useUsersStore()
const { AreaCodeList } = toRefs(store)
const NewAreaCodeList = ref(null)

const emit = __emit
const isShow = ref(false)
const AreaCode = ref(null)

const changeAreaCode = (i) => {
  AreaCode.value = i
  isShow.value = false
  emit('AreaCode', i)
}

const props = __props

watch(
  () => AreaCodeList.value,
  (res) => {
    if (res && !props?.wid) {
      emit('AreaCode', res.Items[0])
      AreaCode.value = res.Items[0]
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  if (props?.wid) {
    Api.Get2('user/api/SendMessage/', {}).then((res: any) => {
      emit('AreaCode', res.Items[0])
      AreaCode.value = res.Items[0]
      NewAreaCodeList.value = res
    })
  } else {
    store.setState('user/api/SendMessage', {}, 'AreaCodeList')
  }
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!

  return (_openBlock(), _createElementBlock("div", {
    class: "isAreaCode",
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (isShow.value = _ctx.$SiteSet['areaCode'] ? true : false)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (isShow.value = false))
  }, [
    _createElementVNode("div", null, [
      (AreaCode.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_suffix_img, {
              url: 'https://www.loda.com.kh' + AreaCode.value.AreaImg
            }, null, 8, ["url"]),
            _createTextVNode(" " + _toDisplayString(AreaCode.value.AreaNumber), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props?.wid
            ? NewAreaCodeList.value?.Items
            : _unref(AreaCodeList)?.Items, (AreaCode) => {
            return (_openBlock(), _createElementBlock("div", {
              key: AreaCode.AreaCode,
              class: "AreaCode",
              onClick: ($event: any) => (changeAreaCode(AreaCode))
            }, [
              _createVNode(_component_suffix_img, {
                url: 'https://www.loda.com.kh' + AreaCode.AreaImg
              }, null, 8, ["url"]),
              _createTextVNode(" " + _toDisplayString(AreaCode.AreaNumber), 1)
            ], 8, _hoisted_3))
          }), 128))
        ], 512), [
          [_vShow, isShow.value]
        ])
      ]),
      _: 1
    })
  ], 32))
}
}

})