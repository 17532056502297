import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "FilterType" }
const _hoisted_2 = { class: "FilterTypeTitle" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "FilterTypeTitle" }

import { ref, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterType',
  props: {
  Filters: Object,
},
  emits: ['FilterChange'],
  setup(__props, { emit: __emit }) {

const props = __props

const emit = __emit
const _Filters = props.Filters

const types = ref([
  { name: '综合排序', id: 1 },
  { name: '新品', id: 2 },
  { name: '销量', id: 3 },
  { name: '价格', id: 0 },
])

const ChangeType = (id, index) => {
  if (index == 3) {
    switch (types.value[3].id) {
      case 0:
      case 5:
        types.value[3].id = 4
        break
      case 4:
        types.value[3].id = 5
        break
    }
    _Filters.typeId = types.value[3].id
  } else {
    _Filters.typeId = id
    types.value[3].id = 0
  }
  emit('FilterChange')
}

return (_ctx: any,_cache: any) => {
  const _component_Sort = _resolveComponent("Sort")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_SortDown = _resolveComponent("SortDown")!
  const _component_SortUp = _resolveComponent("SortUp")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_Minus = _resolveComponent("Minus")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('筛选')) + ":", 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(types.value, (type, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: type.id,
        class: _normalizeClass(['FilterTypes', { SelectType: _unref(_Filters).typeId == type.id }]),
        onClick: ($event: any) => (ChangeType(type.id, index))
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t(type.name)) + " ", 1),
        _withDirectives(_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_Sort)
          ]),
          _: 2
        }, 1536), [
          [_vShow, type.id == 0]
        ]),
        _withDirectives(_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_SortDown)
          ]),
          _: 2
        }, 1536), [
          [_vShow, type.id == 4]
        ]),
        _withDirectives(_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_SortUp)
          ]),
          _: 2
        }, 1536), [
          [_vShow, type.id == 5]
        ])
      ], 10, _hoisted_3))
    }), 128)),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('价格')) + ":", 1),
    _createVNode(_component_el_input_number, {
      modelValue: _unref(_Filters).MinPrice,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(_Filters).MinPrice) = $event)),
      min: 0,
      max: _unref(_Filters).MaxPrice,
      controls: false,
      placeholder: "Min"
    }, null, 8, ["modelValue", "max"]),
    _createVNode(_component_el_icon, null, {
      default: _withCtx(() => [
        _createVNode(_component_Minus)
      ]),
      _: 1
    }),
    _createVNode(_component_el_input_number, {
      modelValue: _unref(_Filters).MaxPrice,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(_Filters).MaxPrice) = $event)),
      min: _unref(_Filters).MinPrice,
      controls: false,
      placeholder: "Max"
    }, null, 8, ["modelValue", "min"]),
    _createVNode(_component_el_button, {
      type: "danger",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('FilterChange')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('套用')), 1)
      ]),
      _: 1
    })
  ]))
}
}

})