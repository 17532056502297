import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "order-list" }
const _hoisted_2 = { class: "order-list-head" }
const _hoisted_3 = { class: "OrderStatu" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "sources" }
const _hoisted_7 = { class: "head-R" }
const _hoisted_8 = { class: "ordersTitle" }
const _hoisted_9 = { class: "shortcuts" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "orders" }
const _hoisted_12 = { class: "ProductPagination" }

import { ref, onMounted, watch, toRefs } from 'vue'
import * as Api from '@/utils/api'
import PaginationHook from '@/hooks/pagination'
import OrderCard from './order-card.vue'
import SaleOrderCard from './sale-order-card.vue'
import EmptyArray from '@/components/EmptyArray.vue'
import Loading from '@/components/loading.vue'
import { shortcuts } from '@/utils/constant'
import { SiteSet } from '@/utils/siteConfig'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'order-list',
  setup(__props) {

const store = useUsersStore()
const { PaymentStatus } = toRefs(store)

watch(
  () => PaymentStatus.value,
  (res) => {
    GetOrders()
  },
  {
    deep: true,
  }
)

const CurrentStatu = ref(null)
const shortcutIndex = ref(1)

const OrderStatu = ref(SiteSet().OrderStatu)

const sources = ref(SiteSet().sources)
const total = ref(0)
const keys = ref(null)
const Orders = ref(null)
const order_sources = ref(null)

const changeType = () => {
  total.value = 0
  GetOrders()
}

const WaitingOrders = ref([])

const GetFailOrder = () => {
  // let data = {}
  // Api.Post('orders/api/FailOrderWaiting/', data).then((res:any) => {
  //   console.log(res)
  // })
}

const StatusList = ref([])

const GetOrders = () => {
  Orders.value = null
  if (CurrentStatu.value != 20) {
    const data = {
      sort_order: 1,
      page_size: pageSize.value,
      page_index: pageNum.value,
      order_by: 'CreatedOn',
      status_id: CurrentStatu.value,
      keys: keys.value || null,
      order_sources: order_sources.value,
      date_from: shortcuts[shortcutIndex.value].value[0],
      date_to: shortcuts[shortcutIndex.value].value[1],
    }
    Api.Get('orders/api/orders/', data).then((res: any) => {
      total.value = res.TotalRecordsCount
      Orders.value = res.Orders
      StatusList.value = res.StatusList
      // OrderStatu.value.map((Order) => {
      //   res.StatusList.map((Status) => {
      //     if (Order.StatuId == Status.StatuId) {
      //       Order.OrderCount = Status.OrderCount
      //     }
      //     if (Order.OrderCount) {

      //     }
      //   })
      // })
    })
  } else {
    Api.Post('orders/api/SaleList/', {
      PageSize: pageSize.value,
      PageIndex: pageNum.value,
    }).then((res: any) => {
      total.value = res.TotalCount
      Orders.value = res.SaleOrderList
    })
  }
}

const { pageNum, pageSize, handleCurrentChange, handleSizeChange } =
  PaginationHook(GetOrders)

onMounted(() => {
  pageSize.value = 5
  changeType()
})

return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ArrowDown = _resolveComponent("ArrowDown")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(OrderStatu.value, (Statu) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass({ Select: CurrentStatu.value == Statu.StatuId }),
            key: Statu,
            onClick: ($event: any) => {; (CurrentStatu.value = Statu.StatuId), changeType()}
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t(Statu.name)) + " ", 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(StatusList.value, (Status) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: Status.StatuId
              }, [
                (Status.StatuId == Statu.StatuId)
                  ? (_openBlock(), _createElementBlock("a", _hoisted_5, _toDisplayString(Status.OrderCount), 1))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ], 10, _hoisted_4))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_el_radio_group, {
        modelValue: order_sources.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((order_sources).value = $event)),
        onChange: changeType
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sources.value, (source) => {
            return (_openBlock(), _createBlock(_component_el_radio_button, {
              key: source.val,
              label: source.val
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(source.name)), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_input, {
          modelValue: keys.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((keys).value = $event)),
          placeholder: _ctx.$t('订单号/商品名称'),
          "suffix-icon": "Search"
        }, {
          append: _withCtx(() => [
            _createElementVNode("span", {
              onClick: changeType,
              style: {"cursor":"pointer"}
            }, _toDisplayString(_ctx.$t('查询')), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "placeholder"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_el_popover, {
        placement: "bottom",
        width: 100,
        trigger: "hover"
      }, {
        reference: _withCtx(() => [
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(_ctx.$t(_unref(shortcuts)[shortcutIndex.value].text)) + " ", 1),
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_ArrowDown)
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(shortcuts), (shortcut, index) => {
              return (_openBlock(), _createElementBlock("p", {
                key: index,
                onClick: ($event: any) => {; (shortcutIndex.value = index), changeType()}
              }, _toDisplayString(_ctx.$t(shortcut.text)), 9, _hoisted_10))
            }), 128))
          ])
        ]),
        _: 1
      }),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('订单详情')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('收货人')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('金额')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('状态')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('操作')), 1)
    ]),
    _createElementVNode("div", _hoisted_11, [
      (CurrentStatu.value != 20)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(Orders.value, (Order, index) => {
            return (_openBlock(), _createBlock(OrderCard, {
              key: index,
              data: Order,
              index: index
            }, null, 8, ["data", "index"]))
          }), 128))
        : _createCommentVNode("", true),
      (CurrentStatu.value == 20)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(Orders.value, (Order, index) => {
            return (_openBlock(), _createBlock(SaleOrderCard, {
              key: index,
              data: Order,
              index: index
            }, null, 8, ["data", "index"]))
          }), 128))
        : _createCommentVNode("", true),
      _createVNode(EmptyArray, {
        show: Orders.value && !Orders.value.length
      }, null, 8, ["show"]),
      _withDirectives(_createVNode(Loading, { type: "13" }, null, 512), [
        [_vShow, !Orders.value]
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_el_pagination, {
          currentPage: _unref(pageNum),
          "onUpdate:currentPage": _cache[2] || (_cache[2] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : null)),
          "page-size": _unref(pageSize),
          onSizeChange: _unref(handleSizeChange),
          onCurrentChange: _unref(handleCurrentChange),
          total: total.value,
          background: "",
          layout: "prev, pager, next"
        }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"])
      ])
    ])
  ]))
}
}

})