import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "account_settings" }
const _hoisted_2 = { class: "close" }
const _hoisted_3 = { class: "close" }
const _hoisted_4 = { class: "close" }
const _hoisted_5 = { class: "close" }

import { ref, onMounted, watch, toRefs } from 'vue'
import LoginPassword from '@/components/user-center/account-settings/login-password.vue'
import EmailMobile from '@/components/user-center/account-settings/email_mobile.vue'
import TransactionPassword from '@/components/user-center/account-settings/transaction-password.vue'
import { useUsersStore } from '@/pinia'
import { useRoute } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'account_settings',
  setup(__props) {

const store = useUsersStore()
const { CustomerSecureInfo } = toRefs(store)
const route = useRoute()

const settings = ref([
  {
    name: '登录密码',
    tips: '登录密码能够保证您的账户安全，让您购物更放心',
    btn: '密码',
    type: 'Upwd',
    isBind: true,
  },
  {
    name: '交易密码',
    tips: '保障资金安全，提款需要设置提款密码',
    btn: '密码',
    type: 'Ppwd',
    isBind: true,
  },
  {
    name: '验证手机',
    tips: '验证后，可用于快速找回登录密码及交易密码，接收账户余额变动提醒',
    btn: '手机',
    type: 'phone',
    isBind: true,
  },
  {
    name: '验证邮件',
    tips: '您验证的邮箱',
    bindTips: '绑定邮箱后，可用于找回密码，接收相关邮件',
    btn: '邮箱',
    type: 'mail',
    isBind: true,
  },
])

watch(
  () => CustomerSecureInfo.value,
  (val) => {
    if (val) {
      settings.value[1].isBind = val.IsTransactionPwd
      settings.value[2].isBind = val.IsMobile
      settings.value[3].isBind = val.IsEmail
    }
  },
  {
    immediate: true,
  }
)

const ShowSet = ref(null)

const set = (type) => {
  ShowSet.value = type
}

onMounted(() => {
  if (route.query.type == 'payPassWord') {
    ShowSet.value = 'Ppwd'
  }
  store.setState('user/api/CustomerSecureInfo/', {}, 'CustomerSecureInfo')
})

return (_ctx: any,_cache: any) => {
  const _component_CircleCheck = _resolveComponent("CircleCheck")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Warning = _resolveComponent("Warning")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_SuccessFilled = _resolveComponent("SuccessFilled")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(settings.value, (setting, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "settings",
          key: index
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["", ['tip', { bindTip: !setting.isBind }]])
          }, [
            (setting.isBind)
              ? (_openBlock(), _createBlock(_component_el_icon, {
                  key: 0,
                  size: 30,
                  color: "#FA713E"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheck)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_el_icon, {
                  key: 1,
                  size: 30,
                  color: "#999"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Warning)
                  ]),
                  _: 1
                })),
            _createElementVNode("span", null, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$t(setting.name)), 1),
              _createElementVNode("sub", null, _toDisplayString(_ctx.$t(
                !setting.isBind
                  ? setting.bindTips || setting.tips
                  : setting.tips
              )), 1)
            ])
          ], 2),
          _createVNode(_component_el_link, {
            underline: false,
            onClick: ($event: any) => (set(setting.type))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(setting.isBind ? '修改' : '绑定')) + _toDisplayString(_ctx.$t(setting.btn)), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]))
      }), 128))
    ], 512), [
      [_vShow, !ShowSet.value]
    ]),
    _withDirectives(_createVNode(_component_el_button, {
      type: "info",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (ShowSet.value = null))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('返回设置')), 1)
      ]),
      _: 1
    }, 512), [
      [_vShow, ShowSet.value]
    ]),
    (ShowSet.value == 'Upwd')
      ? (_openBlock(), _createBlock(LoginPassword, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_icon, {
                size: 100,
                color: "#FA713E"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SuccessFilled)
                ]),
                _: 1
              }),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('登录密码修改成功')) + "!", 1),
                _createVNode(_component_el_button, {
                  type: "info",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (ShowSet.value = null))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('返回设置')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (ShowSet.value == 'Ppwd')
      ? (_openBlock(), _createBlock(TransactionPassword, { key: 1 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_icon, {
                size: 100,
                color: "#FA713E"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SuccessFilled)
                ]),
                _: 1
              }),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('支付密码修改成功')) + "!", 1),
                _createVNode(_component_el_button, {
                  type: "info",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (ShowSet.value = null))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('返回设置')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (ShowSet.value == 'phone')
      ? (_openBlock(), _createBlock(EmailMobile, {
          key: 2,
          type: true
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_icon, {
                size: 100,
                color: "#FA713E"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SuccessFilled)
                ]),
                _: 1
              }),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('手机号修改成功')) + "!", 1),
                _createVNode(_component_el_button, {
                  type: "info",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (ShowSet.value = null))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('返回设置')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (ShowSet.value == 'mail')
      ? (_openBlock(), _createBlock(EmailMobile, {
          key: 3,
          type: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_icon, {
                size: 100,
                color: "#FA713E"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SuccessFilled)
                ]),
                _: 1
              }),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('邮箱修改成功')) + "!", 1),
                _createVNode(_component_el_button, {
                  type: "info",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (ShowSet.value = null))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('返回设置')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})