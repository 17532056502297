import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/imgs/App.jpg'
import _imports_1 from '../assets/imgs/qrCode.png'
import _imports_2 from '../assets/imgs/ios_store.png'
import _imports_3 from '../assets/imgs/google_play.png'
import _imports_4 from '../assets/imgs/huawei.png'
import _imports_5 from '../assets/imgs/ButtonDirect.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "introduce" }
const _hoisted_4 = { class: "QrCode" }

import { ref, reactive, onMounted, watch } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'app_download',
  setup(__props) {

onMounted(() => { })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t("欢迎使用LODA")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("这是柬埔寨最大电子商务平台，提供在线购物、外卖、物流快递、本地生活等生活服务，让您随时随地享受便捷生活。")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[0] || (_cache[0] = _createElementVNode("p", null, " SCAN ME NOW ", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("立即下载LODA APP")), 1),
        _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_1 }, null, -1))
      ]),
      _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"ApplicationMarket\" data-v-7224980c><a href=\"https://apps.apple.com/cn/app/id1014885268\" target=\"_balank\" data-v-7224980c><img alt=\"Download LODA iOS APP\" title=\"Download LODA iOS APP\" src=\"" + _imports_2 + "\" data-v-7224980c></a><a href=\"https://play.google.com/store/apps/details?id=com.anhe.loda\" target=\"_balank\" data-v-7224980c><img alt=\"Download LODA Android APP\" title=\"Download LODA Android APP From Google Play\" src=\"" + _imports_3 + "\" data-v-7224980c></a><a href=\"https://appgallery.huawei.com/#/app/C102032523\" target=\"_balank\" data-v-7224980c><img alt=\"Download LODA APP\" title=\"Download LODA APP From Huawei\" src=\"" + _imports_4 + "\" data-v-7224980c></a><a href=\"https://www.loda.com.kh/app/loda.apk\" target=\"_balank\" data-v-7224980c><img alt=\"Download LODA Android APP\" title=\"Download LODA Android APP\" src=\"" + _imports_5 + "\" data-v-7224980c></a></div>", 1))
    ])
  ]))
}
}

})