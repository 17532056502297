import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "delivery" }
const _hoisted_2 = {
  key: 0,
  class: "trips"
}
const _hoisted_3 = { class: "content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "dialog-footer" }
const _hoisted_6 = { class: "dialog-footer" }

import { reactive, ref, onMounted, watch, toRefs } from 'vue'
import * as Api from '@/utils/api'
import { ElMessageBox, FormInstance, FormRules } from 'element-plus'
import { useI18n } from 'vue-i18n'
import AddressCard from '@/components/user-center/address_card.vue'
import moment from 'moment'


export default /*@__PURE__*/_defineComponent({
  __name: 'delivery',
  props: {
  Date: Object,
  ShowDelivery: Boolean,
},
  emits: ["DeliveryChange"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()

const emit = __emit

const DoneDelivery = () => {
  emit('DeliveryChange', false)
}

const DeliveryLoad = ref(false)
const SubmitPay = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      PayLoad.value = true
      Api.Get('user/api/CustomerPwdIsLock/', { TypeId: 1 }).then(
        (res: any) => {
          PayLoad.value = false
          if (!res.IsError) {
            if (res.IsLock) {
              ElMessageBox.alert(
                t(`交易密码已锁定`, {
                  name: moment(res.LockTime).format('YYYY-MM-DD HH:mm:ss'),
                }),
                '',
                {
                  confirmButtonText: 'OK',
                  showClose: false,
                }
              )
            } else {
              SubmitDelivery()
            }
          }
        },
        () => { }
      )
    }
  })
}
const SubmitBox = () => {
  if (totalFee.value > 0 && AddressId.value == 1) {
    ShowPay.value = true
    Api.Get('bank/api/CustomerBalance/', {
      CustomerId: 0,
    }).then((res: any) => {
      BalanceItem.value =
        res.BalanceItems.length > 0 ? res.BalanceItems[0] : null
    })
  } else {
    ShowPay.value = false
    SubmitDelivery()
  }
}
const SubmitDelivery = () => {
  Api.Post('orders/api/DeliveryServer/', {
    BoxList: props.Date,
    AddressId: AddressId.value == 1 ? CustomerAddress.value.AddressId : null,
    TypeId: AddressId.value,
    ExpectStartTime: AddressId.value == 1 ? null : Timelist.value.StartHour,
    ExpectEndTime: AddressId.value == 1 ? null : Timelist.value.EndHour,
    PayPwd:
      totalFee.value > 0 && AddressId.value == 1 ? PayForm.pay_password : null,
    AllDeliveryFee:
      totalFee.value > 0 && AddressId.value == 1 ? totalFee.value.toFixed(2) : null,
  }).then((res: any) => {
    ShowPay.value = false

    ElMessageBox.alert(t('提交成功'), '', {
      confirmButtonText: 'OK',
      showClose: false,
      closeOnPressEscape: false,
      callback: () => {
        emit('DeliveryChange', true)
      },
    })
  })
}

const CustomerAddress = ref(null)
const GetDefaultAddress = () => {
  Api.Get('orders/api/GetPickUpExpectTime', {}).then((res: any) => {
    DateList.value = res.DateList
    Timelist.value = DateList.value[0].Timelist[0]
  })
  Api.Get('basic/api/BasicDictionary/', {
    type_id: 195,
  }).then((res: any) => {
    AddressId.value = res.BasicDictionaryList[0].DicValue
    DictionaryList.value = res.BasicDictionaryList
  })
  Api.Get('user/api/GetCustomerDefaultAddress', {}).then((res: any) => {
    CustomerAddress.value = res
  })
}

const UserWarehousesList = ref(null)
const GetReceiveAddresses = () => {
  Api.Get('user/api/ReceiveAddresses_New/', {
    addressTypeId: 1,
  }).then((res: any) => {
    UserWarehousesList.value = res.ReceiveAddresses
  })
}

const props = __props
const { Date, ShowDelivery } = toRefs(props)
watch(
  () => props.ShowDelivery,
  (val) => {
    if (val) {
      GetDefaultAddress()
    }
  },
  {
    immediate: true,
    deep: true,
  }
)
watch(Date, (newVal, oldVal) => {
  totalFee.value = 0
  props.Date.forEach((ele) => {
    totalFee.value += ele.DeliveryFee
  })
  if (totalFee.value > 0) {
    Api.Get('basic/api/BasicDictionary', {
      type_id: 216,
      value_id: 10,
    }).then((res: any) => {
      DescTitle.value = res.BasicDictionaryList[0].DicName
      res.BasicDictionaryList.forEach((ele) => {
        DescContent.value += ele.Desc + '<br/>'
      })
    })
  }
})

const DateTimeChange = (val) => {
  Timelist.value = DateList.value[val].Timelist[0]
}
const DonePay = () => {
  ShowPay.value = false
}
const AddressId = ref(null)
const DictionaryList = ref(null)
const DateList = ref(null)
const Timelist = ref(null)
const totalFee = ref(0)
const DescTitle = ref(null)
const DescContent = ref('')
const ShowPay = ref(false)
const PayLoad = ref(false)
const PayForm = reactive({
  pay_password: null,
})
const payRules = reactive<FormRules>({
  pay_password: [
    { required: true, message: t('请输入'), trigger: 'blur' },
    { min: 6, message: t('最少6位'), trigger: 'change' },
  ],
})
const BalanceItem = ref(null)
const PayRef = ref<FormInstance>()

onMounted(() => { })

return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_WarningFilled = _resolveComponent("WarningFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, {
      modelValue: props.ShowDelivery,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((props.ShowDelivery) = $event)),
      title: _ctx.$t('发起配送'),
      width: "500px",
      "before-close": DoneDelivery,
      center: true
    }, {
      footer: _withCtx(() => [
        (totalFee.value > 0 && AddressId.value == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createTextVNode(_toDisplayString(_ctx.$t('配送费')) + "： ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(totalFee.value)), 1),
                _createVNode(_component_el_popover, {
                  placement: "top-start",
                  title: DescTitle.value,
                  width: 250,
                  trigger: "hover"
                }, {
                  reference: _withCtx(() => [
                    _createVNode(_component_el_icon, { style: {"font-size":"24px","margin-left":"5px"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_WarningFilled, { color: "#f1541e" })
                      ]),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", { innerHTML: DescContent.value }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                }, 8, ["title"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_el_button, {
            onClick: DoneDelivery,
            size: "large"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('关闭')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "danger",
            size: "large",
            loading: DeliveryLoad.value,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (SubmitBox()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_radio_group, {
            modelValue: AddressId.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((AddressId).value = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(DictionaryList.value, (Dictionary) => {
                return (_openBlock(), _createBlock(_component_el_radio, {
                  key: Dictionary.DicValue,
                  label: Dictionary.DicValue,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(Dictionary.DicName), 1)
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          (AddressId.value == 1)
            ? (_openBlock(), _createBlock(_component_el_scrollbar, {
                key: 0,
                "max-height": "200px",
                class: "UserWarehousesList"
              }, {
                default: _withCtx(() => [
                  (CustomerAddress.value && !UserWarehousesList.value)
                    ? (_openBlock(), _createBlock(AddressCard, {
                        key: 0,
                        item: CustomerAddress.value,
                        isDefault: true,
                        index: 0
                      }, null, 8, ["item"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(UserWarehousesList.value, (Warehouses, index) => {
                    return (_openBlock(), _createBlock(AddressCard, {
                      item: Warehouses,
                      index: index,
                      isDefault: Warehouses.AddressId == CustomerAddress.value.AddressId,
                      key: index,
                      onClick: ($event: any) => (CustomerAddress.value = Warehouses)
                    }, null, 8, ["item", "index", "isDefault", "onClick"]))
                  }), 128)),
                  (!UserWarehousesList.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "AddressesPlaceholder",
                        onClick: GetReceiveAddresses
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('显示全部')), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (AddressId.value != 1)
            ? (_openBlock(), _createBlock(_component_el_tabs, {
                key: 1,
                type: "border-card",
                onTabChange: DateTimeChange
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(DateList.value, (DateTime, index) => {
                    return (_openBlock(), _createBlock(_component_el_tab_pane, {
                      key: index,
                      label: DateTime.Date
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio_group, {
                          modelValue: Timelist.value,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((Timelist).value = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(DateTime.Timelist, (Time) => {
                              return (_openBlock(), _createBlock(_component_el_radio, {
                                key: Time,
                                label: Time,
                                size: "large"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(Time.HoutTag), 1)
                                ]),
                                _: 2
                              }, 1032, ["label"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["label"]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_table, {
            data: props.Date,
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                property: "Serail",
                label: _ctx.$t('箱子号')
              }, null, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                property: "InStockStatus",
                label: _ctx.$t('状态')
              }, null, 8, ["label"])
            ]),
            _: 1
          }, 8, ["data"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_el_dialog, {
      modelValue: ShowPay.value,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((ShowPay).value = $event)),
      title: _ctx.$t('支付订单'),
      width: "400px",
      "before-close": DonePay,
      center: true
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_el_button, {
            onClick: DonePay,
            size: "large"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('关闭')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "danger",
            size: "large",
            loading: PayLoad.value,
            onClick: _cache[5] || (_cache[5] = ($event: any) => (SubmitPay(PayRef.value)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('支付')), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref_key: "PayRef",
          ref: PayRef,
          model: PayForm,
          rules: payRules,
          "label-position": "top",
          class: "PackageForm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('账户余额')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(BalanceItem.value
          ? BalanceItem.value.AvailableAmount + ' ' + BalanceItem.value.CurrencyCode
          : 0 + ' ' + _ctx.$SiteSet['CurrencyCode']) + " ", 1),
                _createVNode(_component_router_link, { to: { path: '/userCenter/wallet' } }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      class: "RechargeBtn",
                      type: "danger",
                      onClick: DonePay
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('充值')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('应付金额')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$ccy(totalFee.value)), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('交易密码'),
              prop: "pay_password"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  size: "large",
                  modelValue: PayForm.pay_password,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((PayForm.pay_password) = $event)),
                  type: "password"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}
}

})