import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "LodingProduct" }
const _hoisted_2 = { class: "ProductName" }

import { ref } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'ProductSkeleton',
  setup(__props) {

const loading = ref(true)

return (_ctx: any,_cache: any) => {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_skeleton, {
      loading: loading.value,
      animated: ""
    }, {
      template: _withCtx(() => [
        _createVNode(_component_el_skeleton_item, {
          class: "ProductImg",
          variant: "image"
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_skeleton_item, { variant: "text" }),
          _createVNode(_component_el_skeleton_item, { variant: "text" })
        ]),
        _createVNode(_component_el_skeleton_item, {
          class: "ProductPrice",
          variant: "text"
        })
      ]),
      _: 1
    }, 8, ["loading"])
  ]))
}
}

})