import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "isMall" }

import { ref, reactive, onMounted, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'mall_label',
  setup(__props) {

onMounted(() => { })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, " Mall "))
}
}

})