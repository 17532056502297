import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "goods-image" }
const _hoisted_2 = { class: "image-slot" }
const _hoisted_3 = { class: "small" }
const _hoisted_4 = { class: "image-slot" }

import { ref, watch, reactive } from 'vue'
import { useMouseInElement } from '@vueuse/core'


export default /*@__PURE__*/_defineComponent({
  __name: 'enlarge-images',
  props: {
  images: {
    type: Array,
    default: () => [],
  },
  MainGraph: {
    type: String,
    default: () => null,
  },
},
  setup(__props) {

const props = __props

const currIndex = ref(0)
const target = ref(null)
const isShow = ref(false)
// 遮罩层的坐标
const position = reactive({
  left: 0,
  top: 0,
})

// 控制背景图的位置
const bgPosition = reactive({
  backgroundPositionX: 0,
  backgroundPositionY: 0,
})

const { elementX, elementY, isOutside } = useMouseInElement(target)
// 侦听鼠标移动后信息
watch([elementX, elementY, isOutside], () => {
  // 每次有值发生变化，就读取新的数据即可
  isShow.value = !isOutside.value
  // 鼠标在图片的区域之外，不需要计算坐标
  if (isOutside.value) return
  // 水平方向
  if (elementX.value < 100) {
    // 左边界
    position.left = 0
  } else if (elementX.value > 300) {
    // 右边界
    position.left = 200
  } else {
    // 中间的状态
    position.left = elementX.value - 100
  }
  // 垂直方向
  if (elementY.value < 100) {
    // 上边界
    position.top = 0
  } else if (elementY.value > 300) {
    // 下边界
    position.top = 200
  } else {
    // 中间的状态
    position.top = elementY.value - 100
  }
  // console.log(elementX.value, elementY.value, isOutside.value)
  // 计算预览大图背景的位置
  bgPosition.backgroundPositionX = -position.left * 2 + 'px'
  bgPosition.backgroundPositionY = -position.top * 2 + 'px'
  // 计算左侧遮罩层位置
  position.left += 'px'
  position.top += 'px'
})

return (_ctx: any,_cache: any) => {
  const _component_Picture = _resolveComponent("Picture")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: "large",
      style: _normalizeStyle([
      {
        backgroundImage: `url(${__props.MainGraph ? __props.MainGraph : __props.images[currIndex.value]})`,
      },
      bgPosition,
    ])
    }, null, 4), [
      [_vShow, isShow.value]
    ]),
    _createElementVNode("div", {
      class: "middle",
      ref_key: "target",
      ref: target
    }, [
      _createVNode(_component_el_image, {
        fit: "scale-down",
        class: "GoodsImage",
        src: __props.MainGraph ? __props.MainGraph : __props.images[currIndex.value]
      }, {
        error: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_Picture)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["src"]),
      _withDirectives(_createElementVNode("div", {
        class: "layer",
        style: _normalizeStyle([position])
      }, null, 4), [
        [_vShow, isShow.value]
      ])
    ], 512),
    _createVNode(_component_el_scrollbar, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.images, (img, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "small_box",
              key: img
            }, [
              _createVNode(_component_el_image, {
                class: _normalizeClass([{ active: i === currIndex.value && !__props.MainGraph }, "GoodsImage"]),
                onMouseenter: ($event: any) => {; (currIndex.value = i), (__props.MainGraph = null)},
                fit: "scale-down",
                src: img
              }, {
                error: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Picture)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 2
              }, 1032, ["class", "onMouseenter", "src"])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}
}

})