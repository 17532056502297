import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "types" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { style: {"color":"#999"} }
const _hoisted_5 = { class: "AmountList" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "contents" }
const _hoisted_8 = ["innerHTML"]

import { ref, reactive, onMounted, toRefs, watch } from 'vue'
import { useRouter } from 'vue-router'
import type { FormInstance } from 'element-plus'
import * as Api from '@/utils/api'
import { useI18n } from 'vue-i18n'
import Pay from '@/components/user-center/pay.vue'
import Loading from '@/components/loading.vue'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'call-charges',
  setup(__props) {

const store = useUsersStore()
const { AreaCodeList } = toRefs(store)

const userouter = useRouter()

const { t } = useI18n()
const ShowPay = ref(false)
const PayDate = ref(null)
const PayLoad = ref(false)
const content = ref(null)

const AmountList = ref(['1', '2', '5', '8', '10', '15'])

const PhoneFormRef = ref<FormInstance>()

const PhoneForm = reactive({
  Phone: null,
  Amount: null,
})

const userAreaCode = ref(null)

const DevicePass = (rule: any, value: any, callback: any) => {
  !eval(userAreaCode.value.Regular).test(value)
    ? callback(new Error(t('请输入正确手机号')))
    : callback()
}

const rules = reactive({
  Phone: [
    { required: true, message: t('请输入'), trigger: 'blur' },
    { required: true, validator: DevicePass },
  ],
  Amount: [{ required: true, message: t('请选择充值金额'), trigger: 'blur' }],
})

const PayChange = () => {
  ShowPay.value = false
  userouter.push({
    path: '/userCenter/orders',
  })
}

const PhoneRecharge = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      PayLoad.value = true
      Api.Post('orders/api/PhoneRecharge/', PhoneForm)
        .then((res: any) => {
          PayLoad.value = false
          if (res.CanPayOrderNos[0]) {
            PayLoad.value = true
            Api.Get('orders/api/orders/', {
              order_no: res.CanPayOrderNos[0],
            }).then((OrdersRes: any) => {
              console.log(OrdersRes)
              formEl.resetFields()
              PayLoad.value = false
              ShowPay.value = true
              PayDate.value = OrdersRes.CustomerOrder
            })
          }
        })
        .catch(() => {
          PayLoad.value = false
        })
    }
  })
}

watch(
  () => AreaCodeList.value,
  (res) => {
    if (res) {
      userAreaCode.value = res.Items[0]
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  store.setState('user/api/SendMessage', {}, 'AreaCodeList')
  const lan = ref({
    'zh-cn': 1160420,
    en: 1160421,
    km: 1160422,
  })
  Api.Get('article/api/ArticleTitle/', {
    ArticleId: lan.value[localStorage.getItem('lang') || 'km'],
  }).then((res: any) => {
    if (res.ArticlesList.length) {
      content.value = res.ArticlesList[0].Content
    }
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('话费充值')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_form, {
          ref_key: "PhoneFormRef",
          ref: PhoneFormRef,
          model: PhoneForm,
          rules: rules,
          "label-position": "top",
          class: "PhoneForm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('手机号'),
              prop: "Phone"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  placeholder: _ctx.$t('请输入以0开头的手机号码'),
                  modelValue: PhoneForm.Phone,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((PhoneForm.Phone) = $event))
                }, null, 8, ["placeholder", "modelValue"]),
                _createElementVNode("sub", _hoisted_4, _toDisplayString(_ctx.$t('例如')) + ":0189404212 ", 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('充值金额'),
              prop: "Amount"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(AmountList.value, (Amount) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: Amount,
                      class: _normalizeClass({ isSelect: PhoneForm.Amount == Amount }),
                      onClick: ($event: any) => (PhoneForm.Amount = Amount)
                    }, _toDisplayString(_ctx.$ccy(Number(Amount))), 11, _hoisted_6))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  size: "large",
                  type: "danger",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (PhoneRecharge(PhoneFormRef.value))),
                  loading: PayLoad.value
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('充值')), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", { innerHTML: content.value }, null, 8, _hoisted_8),
          _withDirectives(_createVNode(Loading, { type: "3" }, null, 512), [
            [_vShow, !content.value]
          ])
        ])
      ])
    ]),
    _createVNode(Pay, {
      Date: PayDate.value,
      ShowPay: ShowPay.value,
      SourceId: 40,
      onPayChange: PayChange
    }, null, 8, ["Date", "ShowPay"])
  ], 64))
}
}

})