import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "express_title" }

import { ref, onMounted } from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'express',
  setup(__props) {

const ExpressTypes = ref([
  {
    name: '国际快递',
    val: 'international',
  },
  {
    name: '包裹查询',
    val: 'packageInquiry',
  },
  {
    name: '运费估算',
    val: 'freightEstimate',
  },
  {
    name: '网点查询',
    val: 'siteQuery',
  },
])

const userouter = useRouter()
const route = useRoute()
const isSelect = ref('0')

onBeforeRouteUpdate((to, from) => {
  isSelect.value = to.name.toString()
})

onMounted(() => {
  isSelect.value = userouter.currentRoute.value.name.toString()
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ExpressTypes.value, (types) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          to: { name: types.val }
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass({ isSelect: isSelect.value == types.val })
            }, _toDisplayString(_ctx.$t(types.name)), 3)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 256))
    ]),
    _createVNode(_component_router_view)
  ]))
}
}

})