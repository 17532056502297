import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-password" }

import { ref, reactive, onMounted, watch } from 'vue'
import type { FormInstance } from 'element-plus'
import * as Api from '@/utils/api'
import { useI18n } from 'vue-i18n'

export default /*@__PURE__*/_defineComponent({
  __name: 'login-password',
  setup(__props) {

const { t } = useI18n()

const active = ref(1)
const ruleFormRef = ref<FormInstance>()

const isLoading = ref(false)

const ruleForm = reactive({
  OldPassword: null,
  NewPassword: null,
  ConfirmPayPwd: null,
})

const checkPass = (rule: any, value: any, callback: any) => {
  if (value != ruleForm.NewPassword) {
    callback(new Error(t('密码不一致')))
  } else {
    callback()
  }
}

const rules = reactive({
  OldPassword: [{ required: true, message: t('请输入'), trigger: 'blur' }],
  NewPassword: [
    { required: true, message: t('请输入'), trigger: 'blur' },
    { min: 6, message: t('最少6位'), trigger: 'blur' },
  ],
  ConfirmPayPwd: [{ required: true, validator: checkPass }],
})

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      isLoading.value = true
      Api.Post('user/api/MemberLoginAccount/', {
        OldPassword: ruleForm.OldPassword,
        NewPassword: ruleForm.NewPassword,
      }).then(
        (res) => {
          isLoading.value = false
          active.value = 2
        },
        () => {
          isLoading.value = false
        }
      )
    }
  })
}

onMounted(() => { })

return (_ctx: any,_cache: any) => {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_steps, {
      space: 200,
      active: active.value,
      simple: "",
      class: "steps"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_step, {
          title: _ctx.$t('修改登录密码'),
          icon: "Edit"
        }, null, 8, ["title"]),
        _createVNode(_component_el_step, {
          title: _ctx.$t('完成'),
          icon: "Select"
        }, null, 8, ["title"])
      ]),
      _: 1
    }, 8, ["active"]),
    _withDirectives(_createVNode(_component_el_form, {
      class: "ruleForm",
      ref_key: "ruleFormRef",
      ref: ruleFormRef,
      model: ruleForm,
      rules: rules,
      "label-position": "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('请输入旧密码'),
          prop: "OldPassword"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: ruleForm.OldPassword,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ruleForm.OldPassword) = $event)),
              type: "password",
              "show-password": "",
              placeholder: _ctx.$t('旧密码')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('请输入新密码'),
          prop: "NewPassword"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: ruleForm.NewPassword,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ruleForm.NewPassword) = $event)),
              type: "password",
              "show-password": "",
              placeholder: _ctx.$t('新密码')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('再次输入新密码'),
          prop: "ConfirmPayPwd"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: ruleForm.ConfirmPayPwd,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ruleForm.ConfirmPayPwd) = $event)),
              type: "password",
              "show-password": "",
              placeholder: _ctx.$t('再次输入新密码')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              class: "submitBtn",
              size: "large ",
              type: "danger",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (submitForm(ruleFormRef.value))),
              loading: isLoading.value
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]), [
      [_vShow, active.value == 1]
    ]),
    (active.value == 2)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true)
  ]))
}
}

})