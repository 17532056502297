import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/imgs/LodaWeChat.jpg'


const _hoisted_1 = { class: "goTop" }

import {
  watch,
  ref,
  onMounted,
  onUnmounted,
  toRefs,
  getCurrentInstance,
} from 'vue'
import { useRouter } from 'vue-router'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'back_top',
  setup(__props) {

const store = useUsersStore()
const { ShowRefresh } = toRefs(store)
const { proxy } = getCurrentInstance()

const userouter = useRouter()
const scrollTop = ref('')
const goTopShow = ref(false)

const RouterPush = (path) => {
  userouter.push({
    path,
  })
}

const setShowRefresh = () => {
  store.ShowRefresh = false
  proxy?.$Bus.emit('reload')
}

const handleScroll = () => {
  scrollTop.value =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop
  if (scrollTop.value > 800) {
    //设置页面滑动多少显示按钮
    goTopShow.value = true
  } else {
    goTopShow.value = false
  }
}

const goTop = () => {
  let timer = null
  cancelAnimationFrame(timer)
  timer = requestAnimationFrame(function fn() {
    if (scrollTop.value > 0) {
      scrollTop.value -= 100 //回到顶部的速度
      document.body.scrollTop = document.documentElement.scrollTop =
        scrollTop.value
      timer = requestAnimationFrame(fn)
    } else {
      cancelAnimationFrame(timer)
      goTopShow.value = false
    }
  })
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

return (_ctx: any,_cache: any) => {
  const _component_Refresh = _resolveComponent("Refresh")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_Transition, { name: "el-fade-in-linear" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", {
          class: "RefreshPage",
          onClick: setShowRefresh
        }, [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_Refresh)
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, _unref(ShowRefresh)]
        ]),
        _createElementVNode("ul", null, [
          _createVNode(_component_el_popover, {
            placement: "left",
            width: "auto",
            trigger: "hover"
          }, {
            reference: _withCtx(() => [
              _withDirectives(_createElementVNode("li", null, null, 512), [
                [_vShow, _ctx.$SiteSet['weixin']]
              ])
            ]),
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("img", {
                class: "weixinPopover",
                src: _imports_0
              }, null, -1))
            ]),
            _: 1
          }),
          _createElementVNode("li", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (RouterPush('/shoppingCart')))
          }),
          _createElementVNode("li", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (RouterPush('/helpCenter')))
          }),
          _createElementVNode("li", { onClick: goTop })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})