import Compressor from 'compressorjs';
let originalFileSize: number=0 // 原始文件大小
let compressedFileSize: number = 0 // 压缩后文件大小
let compressedBase64: string | null = null // 压缩后的文件的 Base64 字符串

// 主处理函数：接收 { webPath, format } 并返回 Base64 字符串
export const processImage = (image: File) => {
  return new Promise(async (resolve, reject) => {
    try {
      originalFileSize = image.size
      // 如果文件小于 2048KB，直接转换为 Base64，不进行压缩
      if (image.size < 2048 * 1024) {
        const base64 = await convertFileToBase64(image)
        compressedBase64 = base64
        // console.log('文件小于2048KB，直接返回Base64:', base64)
        resolve(base64) // 返回 Base64 字符串
      } else {
        // 使用 Compressor.js 压缩图片，目标是小于 500KB
        const compressedFile: any = await compressFile(image)
        if (compressedFile) {
          compressedFileSize = compressedFile.size
          // 压缩后文件转换为 Base64
          const base64 = await convertFileToBase64(compressedFile)
          compressedBase64 = base64
          // console.log('压缩后的Base64字符串:', base64)
          resolve(base64) // 返回 Base64 字符串
        }
      }
    } catch (error) {
      console.error('图片处理失败:', error)
      reject(error) // 如果发生错误，返回拒绝的 Promise
    }
  })
}

// 从 webPath 获取 Blob 的方法
const fetchBlobFromWebPath = async (webPath: string) => {
  const response = await fetch(webPath)
  if (!response.ok) {
    throw new Error('Failed to fetch the Blob from webPath')
  }
  return await response.blob()
}

// 将 Blob 转换为 File 的方法
const blobToFile = (blob: Blob, fileName: string, format: string) => {
  return new File([blob], fileName, { type: `image/${format}` })
}

// 使用 Compressor.js 压缩图片的辅助方法
const compressFile = (file: File) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.8, // 设置较低的质量以减少文件大小
      maxWidth: 1000, // 可选，限制最大宽度
      maxHeight: 1000, // 可选，限制最大高度
      success: (compressedFile: File) => {
        // 如果压缩后的文件小于 500KB，返回
        if (compressedFile.size < 2048 * 1024) {
          resolve(compressedFile)
        } else {
          reject(new Error('压缩后文件仍然大于 2048KB'))
        }
      },
      error: (err: Error) => reject(err),
    })
  })
}

const convertFileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const img = new Image()
      img.onload = () => {
        // 创建 Canvas 并设置尺寸为图片的原始尺寸
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        if (!ctx) {
          reject(new Error('无法获取 Canvas 上下文'))
          return
        }
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)
        const jpgBase64 = canvas.toDataURL('image/jpeg', 1)
        resolve(jpgBase64)
      }
      img.src = reader.result as string
    }
    reader.readAsDataURL(file) // 读取文件并转换为 Base64
  })
}
