import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, Transition as _Transition, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '../assets/imgs/weixin.png'
import _imports_1 from '../assets/imgs/facebook.png'
import _imports_2 from '../assets/imgs/zh-cn.png'
import _imports_3 from '../assets/imgs/km.png'


const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "subject" }
const _hoisted_3 = { class: "Exhibition" }
const _hoisted_4 = { class: "Favorite" }
const _hoisted_5 = { class: "Share" }
const _hoisted_6 = { class: "weixinQr" }
const _hoisted_7 = { class: "details" }
const _hoisted_8 = { class: "GoodsName" }
const _hoisted_9 = {
  key: 1,
  class: "Ladder"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "MallPrice" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 1,
  class: "itemPrice"
}
const _hoisted_15 = { key: 2 }
const _hoisted_16 = {
  key: 2,
  class: "Citie SkuMargin"
}
const _hoisted_17 = { class: "SkuTitle" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 3,
  class: "Freight SkuMargin"
}
const _hoisted_20 = { class: "SkuTitle" }
const _hoisted_21 = {
  key: 0,
  style: {"width":"30px","height":"30px"},
  src: _imports_2
}
const _hoisted_22 = {
  key: 1,
  style: {"width":"30px","height":"30px"},
  src: _imports_3
}
const _hoisted_23 = {
  key: 4,
  class: "Freight SkuMargin"
}
const _hoisted_24 = { class: "SkuTitle" }
const _hoisted_25 = {
  key: 5,
  class: "Freight SkuMargin"
}
const _hoisted_26 = { class: "SkuTitle" }
const _hoisted_27 = {
  key: 6,
  class: "Freight SkuMargin"
}
const _hoisted_28 = { class: "SkuTitle" }
const _hoisted_29 = {
  key: 7,
  class: "MallName SkuMargin"
}
const _hoisted_30 = { class: "SkuTitle" }
const _hoisted_31 = { class: "SkuTitle" }
const _hoisted_32 = { class: "Values" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { key: 0 }
const _hoisted_35 = {
  key: 8,
  class: "Sku SkuValueQty"
}
const _hoisted_36 = { class: "SkuTitle" }
const _hoisted_37 = {
  key: 0,
  style: {"color":"red","margin-left":"5px"}
}
const _hoisted_38 = {
  key: 0,
  class: "StockQty"
}
const _hoisted_39 = {
  key: 1,
  class: "StockQty"
}
const _hoisted_40 = { class: "Sku" }
const _hoisted_41 = { class: "SkuTitle" }
const _hoisted_42 = { class: "Total" }
const _hoisted_43 = { class: "TotalPrice" }
const _hoisted_44 = {
  key: 0,
  class: "TotalNumber"
}
const _hoisted_45 = { class: "skus" }
const _hoisted_46 = { class: "skusTitle" }
const _hoisted_47 = { class: "skuTitle" }
const _hoisted_48 = {
  key: 9,
  class: "Sku"
}
const _hoisted_49 = { class: "SkuTitle" }
const _hoisted_50 = { class: "ProductButton SkuMargin" }
const _hoisted_51 = {
  key: 0,
  class: "Shop"
}
const _hoisted_52 = { class: "ShopC" }
const _hoisted_53 = { class: "ShopName" }
const _hoisted_54 = { class: "ShopButton" }
const _hoisted_55 = { class: "SellerInfo" }
const _hoisted_56 = { class: "bt" }
const _hoisted_57 = { class: "GoodsRecommendList" }
const _hoisted_58 = { class: "GoodsRecommendTitle" }
const _hoisted_59 = ["innerHTML"]
const _hoisted_60 = {
  key: 0,
  class: "container isLoading"
}
const _hoisted_61 = { class: "ProducHead" }
const _hoisted_62 = { class: "ProductImg" }
const _hoisted_63 = { class: "ProductName" }
const _hoisted_64 = { class: "ProducBody" }
const _hoisted_65 = { class: "ProductName" }
const _hoisted_66 = {
  key: 0,
  class: "container isShelves"
}
const _hoisted_67 = {
  key: 0,
  class: "container isShelves"
}
const _hoisted_68 = { key: 0 }
const _hoisted_69 = {
  key: 0,
  class: "IsBlacklist"
}
const _hoisted_70 = {
  key: 0,
  class: "container isShelves"
}

import {
  ref,
  onMounted,
  unref,
  toRefs,
  watch,
  getCurrentInstance,
  onUnmounted,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus'
import { ClickOutside as vClickOutside } from 'element-plus'
import * as Api from '@/utils/api'
import { toWechat } from '@/utils/share'
import CommodityCard from '@/components/CommodityCard.vue'
import EnlargeImages from '@/components/enlarge-images.vue'
import MallLabel from '@/components/mall_label.vue'
import QrcodeVue from 'qrcode.vue'
import { ShareFacebook } from '@/utils/fb'
import { useUsersStore } from '@/pinia'
import * as SignalR from '@microsoft/signalr'
import { myPromise } from '@/utils/chathub'
import GoogleTranslate from '@/components/GoogleTranslate.vue'
import { SiteSet } from '@/utils/siteConfig'
import { translateApi } from '@/utils/GoogleTranslate'


export default /*@__PURE__*/_defineComponent({
  __name: 'product-details',
  setup(__props) {

const { proxy } = getCurrentInstance()
const store = useUsersStore()
const { Cities, Token }: any = toRefs(store)
const country = SiteSet().getPlacePredictions

const { t } = useI18n()
const Router = useRouter()

const hrefUrl = ref(location.href)

const route = useRoute()
const item: any = ref(null)
const MainGraph = ref(null)
const GoodsRecommendList = ref([])
const isLadderPrices = ref(null)
const ShowCities = ref(false)
const Citie = ref(null)
const SkuValueQty: any = ref(0)
const CombinationSku: any = ref([])
const CurrentSku: any = ref(null)
const GroceryList: any = ref([])
const GroceryQty = ref(0)

const NoGoods = ref(false)
const Remark = ref(null)
const IsBlacklist = ref(false)

const PriceRange = ref(null)

const buttonRef = ref()
const popoverRef = ref()
const onClickOutside = () => {
  unref(popoverRef).popperRef?.delayHide?.()
}

const connection = ref()
const chatStart = (body) => {
  if (!connection.value) {
    connection.value = new SignalR.HubConnectionBuilder()
      .withUrl('https://testmsg.loda.com.kh/chathub', {
        accessTokenFactory: () => Token.value,
      })
      .build()
    connection.value
      .start()
      .then(() => {
        connection.value.invoke('ConnectionForTokenAccess', body.SearchChannel)
        Api.Post('grabgoods/api/GoodsSearch/', body).then((res: any) => {})
      })
      .catch()
  } else {
    Api.Post('grabgoods/api/GoodsSearch/', body).then((res: any) => {})
  }
  connection.value.on('ReceiveContent', (res) => {
    try {
      const parse = JSON.parse(res)
      GoodsItem(parse)
    } catch (error) {}
  })
}

onUnmounted(() => {
  if (connection.value) connection.value.stop()
})

const GoodsItem = (parse) => {
  item.value = parse
  IsBlacklist.value = parse?.IsBlacklist
  NoGoods.value = parse?.GoodsName ? false : true
  if (!item.value.Skus.length) {
    CurrentSku.value = {
      ImgUrl: item.value.MasterImg,
      Price: item.value.Price,
      Qty: 0,
      OutSkuId: '0',
    }
  } else {
    item.value.Skus.forEach((ele, index) => {
      SelectSku(index, ele.Values[0])
    })
  }
  GetGoodsRecommend(item.value)
}

const SelectSku = (index, sku) => {
  SkuValueQty.value = 0
  CombinationSku.value[index] = sku.Value
  if (CombinationSku.value.length == item.value.Skus.length) {
    let SkuValue = ''
    CombinationSku.value.forEach((value, index) => {
      SkuValue += index == 0 ? value : ';' + value
    })
    CurrentSku.value = item.value.SkuMap.find((sku) => {
      return sku.Value == SkuValue
    })
    if (item.value.MallId == 15 || item.value.MallId == 121440) {
      AddGroceryList(true)
    }
    MainGraph.value =
      CurrentSku.value.ImgUrl ||
      CombinationSku.value[0].ImgUrl ||
      item.value.MasterImg
    if (CurrentSku.value?.RedirectUrl) {
      route.query.Url = CurrentSku.value?.RedirectUrl
      GetProduct()
    }
    if (CurrentSku.value?.RedirectUrl) {
      route.query.Url = CurrentSku.value?.RedirectUrl
      GetProduct()
    }
  }
}

const AddGroceryList = (isCheck) => {
  const isExists = GroceryList.value.findIndex((item) => {
    return item.SkuTitle == CurrentSku.value['Value']
  })
  if (isCheck) {
    SkuValueQty.value = isExists != -1 ? GroceryList.value[isExists].Qty : 0
  } else {
    if (isExists != -1) {
      if (SkuValueQty.value > 0) {
        GroceryList.value[isExists].Qty = SkuValueQty.value
      } else {
        GroceryList.value.splice(isExists, 1)
      }
    } else {
      GroceryList.value.push({
        Qty: SkuValueQty.value,
        SkuValue: CurrentSku.value.OutSkuId,
        //下次删除
        Price: CurrentSku.value.Price,
        SkuTitle: CurrentSku.value.Value,
        SkuImgUrl: MainGraph.value,
      })
    }
  }
}

const QuantityChange = () => {
  // 如果输入值不是item.value.BatchNumber的倍数，则调整为item.value.BatchNumber的倍数
  if (
    item.value.BatchNumber &&
    item.value.BatchNumber > 1 &&
    SkuValueQty.value % item.value.BatchNumber !== 0
  ) {
    if (SkuValueQty.value > item.value.BatchNumber) {
      SkuValueQty.value =
        Math.round(SkuValueQty.value / item.value.BatchNumber) *
        item.value.BatchNumber
    } else {
      if (SkuValueQty.value !== 0) SkuValueQty.value = item.value.BatchNumber
    }
  }
  if (item.value.MallId == 15 || item.value.MallId == 121440) {
    AddGroceryList(false)
    GroceryQty.value = 0
    GroceryQty.value = GroceryList.value.reduce((prev, cur) => {
      return prev + cur.Qty
    }, 0)
    if (item.value.LadderPrices.length) {
      CheckLadderPrices()
    }
  }
}
//删除和修改同方法，没想好怎么合并
const DeleteGrocery = (index) => {
  if (CurrentSku.value.OutSkuId == GroceryList.value[index].SkuValue) {
    SkuValueQty.value = 0
  }
  GroceryList.value.splice(index, 1)
  GroceryQty.value = GroceryList.value.reduce((prev, cur) => {
    return prev + cur.Qty
  }, 0)
  if (item.value.LadderPrices.length) {
    CheckLadderPrices()
  }
}

const GroceryChange = (index) => {
  // 如果输入值不是item.value.BatchNumber的倍数，则调整为item.value.BatchNumber的倍数
  if (
    item.value.BatchNumber &&
    item.value.BatchNumber > 1 &&
    GroceryList.value[index].Qty % item.value.BatchNumber !== 0
  ) {
    if (GroceryList.value[index].Qty > item.value.BatchNumber) {
      GroceryList.value[index].Qty =
        Math.round(GroceryList.value[index].Qty / item.value.BatchNumber) *
        item.value.BatchNumber
    } else {
      if (GroceryList.value[index].Qty !== 0)
        GroceryList.value[index].Qty = item.value.BatchNumber
    }
  }

  if (CurrentSku.value.OutSkuId == GroceryList.value[index].SkuValue) {
    SkuValueQty.value = GroceryList.value[index].Qty
  }
  GroceryQty.value = GroceryList.value.reduce((prev, cur) => {
    return prev + cur.Qty
  }, 0)
  if (item.value.LadderPrices.length) {
    CheckLadderPrices()
  }
}

const CheckLadderPrices = () => {
  const Qty = item.value.Skus.length ? GroceryQty.value : SkuValueQty.value
  isLadderPrices.value = item.value.LadderPrices.find((ladder) => {
    return ladder.EndQuantity
      ? Qty >= ladder.StartQuantity && Qty <= ladder.EndQuantity
      : Qty >= ladder.StartQuantity
  })
}

const SameParagraph = () => {
  store.base64img = item.value.MasterImg
  Router.push({
    name: 'searchList',
    query: { img: 'true' },
  })
}

const isAdd = () => {
  if (item.value.LadderPrices.length) {
    return isLadderPrices.value ? false : true
  } else if (item.value.MinQuantity) {
    if (GroceryList.value.length) {
      return item.value.MinQuantity > GroceryQty.value
    } else {
      return item.value.MinQuantity > SkuValueQty.value
    }
  } else {
    return SkuValueQty.value > 0
  }
}

const WechatShare = () => {
  toWechat(hrefUrl.value, document.title)
}

const FavoriteLoding = ref(false)
const FavoriteId = ref(null)
const CustomerFavorite = () => {
  let body = {}
  if (FavoriteId.value) {
    body = {
      Id: FavoriteId.value,
    }
  } else {
    body = {
      OutProductId: item.value.OutID,
      ProductUrl: item.value.OutID,
      ProductImgUrl: item.value.MasterImg,
      ProductName: item.value.GoodsName,
      MallId: item.value.MallId,
    }
  }
  FavoriteLoding.value = true
  Api.Post('user/api/CustomerFavorites/', body).then(
    (res: any) => {
      FavoriteLoding.value = false
      FavoriteId.value = res.Id
    },
    () => {
      FavoriteLoding.value = false
    }
  )
}

const CheckFavoriteFavorite = (pId, mallId) => {
  FavoriteLoding.value = true
  Api.Get('sellers/api/CheckFavorite/', {
    pId,
    mallId,
  }).then((res: any) => {
    FavoriteLoding.value = false
    FavoriteId.value = res.FavoriteId
  })
}

const GetCities = () => {
  store.setState(
    'basic/api/Cities/',
    { province_id: 1311160291 },
    'Cities',
    null
  )
}

const Beyond = ref(false)
const TemplatesDetails: any = ref([])
const CityFreight = ref(null)
const ChangeCityFreight = (City) => {
  let CityID = City.CityId
  Citie.value = City
  ShowCities.value = false
  CityFreight.value = null
  Beyond.value = false
  TemplatesDetails.value = []
  let Templates = item.value.FreightTemplatesDetails
  if (Templates.NoDeliveryCityId) {
    let Cid = Templates.NoDeliveryCityId.findIndex((res) => res == CityID)
    Beyond.value = Cid == -1 ? false : true
  }

  Templates.FreightRules?.forEach((val: any) => {
    if (!val.CityId) {
      TemplatesDetails.value.push(val)
    }
    if (val.CityId == CityID) {
      TemplatesDetails.value.push(val)
    }
  })

  let isTemplates = Templates.FreightTemplates.findIndex((res: any) => {
    return res.CityId == CityID
  })
  if (isTemplates != -1) {
    CityFreight.value = Templates.FreightTemplates[isTemplates].Freight
  }
  if (CityFreight.value == null) {
    CityFreight.value = Templates.FreightTemplates[0].Freight
  }
}

watch(
  [() => Cities.value, () => item.value],
  () => {
    if (item.value?.FreightTemplatesDetails && Cities.value) {
      ChangeCityFreight(Cities.value.Cities[0])
    }
  },
  {
    deep: true, // 深度监听
    immediate: true,
  }
)

const buyNow = () => {
  useUsersStore().BuyNowList = {
    OutId: item.value.OutID,
    MallId: item.value.MallId,
    SkuValues: getSkuValues(),
  }
  Router.push({
    name: 'placeOrder',
  })
}

const getSkuValues = () => {
  return !GroceryList.value.length
    ? [
        {
          SkuValue: item.value.SkuMap.length ? CurrentSku.value.OutSkuId : '0',
          Qty: item.value.SkuMap.length ? SkuValueQty.value : SkuValueQty.value,
        },
      ]
    : GroceryList.value
}

const AddCart = () => {
  // return false
  const data = {
    MallId: item.value.MallId,
    OutId: item.value.OutID,
    PurchasingSiteId: 1,
    ShopName: item.value.ShopName,
    SkuValues: getSkuValues(),
    CustomerMemo: null,
    ProductId: null,
    Remark: Remark.value,
    RemarkImg: null,
    SellerPId: item.value.MallId == 111036 ? item.value.OutID : null,
  }
  const loading = ElLoading.service({
    lock: true,
    text: t('加入购物车中'),
    background: 'rgba(0, 0, 0, 0.7)',
  })
  Api.Post('cart/api/NewShoppingCart/', data)
    .then((res: any) => {
      loading.close()
      if (!res.IsError) {
        ElMessageBox.confirm(t('成功添加'), t('信息'), {
          confirmButtonText: t('继续'),
          cancelButtonText: t('购物车详情'),
          type: 'success',
        })
          .then(() => {})
          .catch(() => {
            Router.push({
              path: '/shoppingCart',
            })
          })
      }
    })
    .catch(() => {
      loading.close()
    })
}

const GetGoodsRecommend = async (item) => {
  // document.title =
  //   (item.GoodsName || t('很抱歉，没有淘到该代购商品!')) +
  //   ' - ' +
  //   proxy.$SiteSet?.LODA
  document.title =
    ((await translateApi(item.GoodsName, null, null, null, 2).then()) ||
      t('很抱歉，没有淘到该代购商品!')) +
    ' - ' +
    proxy.$SiteSet['LODA']
  const body: any = {
    OutId: item.OutID,
    MallId: item.MallId,
    page: 1,
    pageSize: 12,
  }
  Api.Get('grabgoods/api/GoodsRecommend/', body).then((res: any) => {
    GoodsRecommendList.value = res.Goods
  })
}

const GetProduct = (Update: any = false) => {
  NoGoods.value = false
  item.value = null
  CheckFavoriteFavorite(route.query.outId, route.query.mallId)
  if (Number(route.query.mallId) == 111036) {
    Api.Get('sellers/api/SellerProductSku/', {
      pid: route.query.outId,
    }).then((res: any) => {
      item.value = res.GoodsItem
      if (
        res.GoodsItem.SellerInfo.ShopTypeId != 1 &&
        !sessionStorage.getItem('needTest')
      ) {
        Router.push({
          path: '/LodaFood',
        })
      }
      GetCities()

      NoGoods.value = item.value?.GoodsName ? false : true

      if (item.value.Skus.length) {
        item.value.Skus.forEach((ele, index) => {
          SelectSku(index, ele.Values[0])
        })
      }

      GetGoodsRecommend(item.value)
    })
  } else {
    const body = {
      IsUpdate: Update,
      Url: route.query.Url,
      OutId: route.query.outId,
      MallId: route.query.mallId,
    }
    if (route.query.SearchChannel)
      body['SearchChannel'] = route.query.SearchChannel
    // chatStart(body)
    Api.Post('grabgoods/api/GoodsSearch/', body).then((res: any) => {
      GoodsItem(res.GoodsItem)
    })
    setTimeout(() => {
      if (item.value == null) {
        NoGoods.value = true
      }
    }, 15000)
  }
}

onMounted(() => {
  myPromise().then((res: any) => {
    console.log(res)
  })
  GetProduct()
})

return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_Star = _resolveComponent("Star")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_StarFilled = _resolveComponent("StarFilled")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_arrow_down_bold = _resolveComponent("arrow-down-bold")!
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_CircleCloseFilled = _resolveComponent("CircleCloseFilled")!
  const _component_SemiSelect = _resolveComponent("SemiSelect")!
  const _component_WarningFilled = _resolveComponent("WarningFilled")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
      default: _withCtx(() => [
        (
        item.value &&
        !NoGoods.value &&
        !item.value?.IsShelves &&
        !item.value?.NotSupportLink &&
        !IsBlacklist.value
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(EnlargeImages, {
                    images: item.value.Imgs.length > 0 ? item.value.Imgs : [item.value.MasterImg],
                    MainGraph: MainGraph.value
                  }, null, 8, ["images", "MainGraph"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('分享')), 1),
                      _createVNode(_component_el_popover, {
                        placement: "top",
                        width: 25,
                        trigger: "click"
                      }, {
                        reference: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createElementVNode("img", { src: _imports_0 }, null, -1)
                        ])),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(QrcodeVue, {
                              value: hrefUrl.value,
                              "size:300": "",
                              level: "H"
                            }, null, 8, ["value"])
                          ])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("img", {
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(ShareFacebook) && _unref(ShareFacebook)(...args))),
                        src: _imports_1
                      })
                    ]),
                    _withDirectives((_openBlock(), _createElementBlock("div", { onClick: CustomerFavorite }, [
                      _withDirectives(_createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Star)
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, !FavoriteId.value]
                      ]),
                      _withDirectives(_createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_StarFilled)
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, FavoriteId.value]
                      ]),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('收藏商品')), 1)
                    ])), [
                      [_directive_loading, FavoriteLoding.value]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createVNode(MallLabel, null, null, 512), [
                      [_vShow, item.value.MallId == 111036]
                    ]),
                    _createVNode(_component_el_link, {
                      href: item.value.Url,
                      target: "_blank"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(GoogleTranslate, {
                          text: item.value.GoodsName,
                          type: null,
                          MallId: item.value.MallId
                        }, null, 8, ["text", "MallId"])
                      ]),
                      _: 1
                    }, 8, ["href"])
                  ]),
                  (item.value.MallId != 111036)
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 0,
                        class: "SameParagraph",
                        onClick: SameParagraph,
                        type: "danger",
                        text: "",
                        bg: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('找同款')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (item.value.LadderPrices && item.value.LadderPrices.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('价格')), 1),
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('起批量')), 1)
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value.LadderPrices, (Ladder) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: Ladder.StartQuantity,
                            class: _normalizeClass({ isLadder: isLadderPrices.value == Ladder })
                          }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(Ladder?.Price)), 1),
                            (Ladder.EndQuantity)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(Ladder.StartQuantity + '~' + Ladder.EndQuantity), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_11, " ≥ " + _toDisplayString(Ladder.StartQuantity), 1))
                          ], 2))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_12, [
                    (isLadderPrices.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$ccy(isLadderPrices.value.Price)), 1))
                      : (item.value.DiscountPrice)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(CurrentSku.value?.Price || item.value.Price)), 1),
                            _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(CurrentSku.value?.DiscountPrice || item.value.DiscountPrice)), 1),
                            _createElementVNode("span", null, _toDisplayString(CurrentSku.value?.DiscountRate || item.value.DiscountRate) + "  OFF ", 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$ccy(PriceRange.value || CurrentSku.value?.Price || item.value.Price)), 1)),
                    _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.$t('我们承诺您的交易安全:我们只会在买家收到货后拨款给卖家!')), 513), [
                      [_vShow, item.value.MallId == 111036]
                    ])
                  ]),
                  (item.value.MallId == 111036)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('配送至')), 1),
                        (_unref(Cities))
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: "CityName",
                              onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (ShowCities.value = true))
                            }, [
                              _createTextVNode(_toDisplayString(Citie.value ? Citie.value.CityName : _unref(Cities).Cities[0].CityName) + " ", 1),
                              _createVNode(_component_el_icon, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_arrow_down_bold)
                                ]),
                                _: 1
                              })
                            ], 32))
                          : _createCommentVNode("", true),
                        (CityFreight.value)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(TemplatesDetails.value, (TemplatesDetail, index) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: index,
                                class: "TemplatesDetail"
                              }, _toDisplayString(TemplatesDetail.ConditionValue), 1))
                            }), 128))
                          : _createCommentVNode("", true),
                        _createVNode(_Transition, { name: "el-fade-in-linear" }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("div", {
                              class: "Cities",
                              onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (ShowCities.value = false))
                            }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(Cities)?.Cities, (C) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: C.CityId,
                                  onClick: ($event: any) => (ChangeCityFreight(C))
                                }, _toDisplayString(C.CityName), 9, _hoisted_18))
                              }), 128))
                            ], 544), [
                              [_vShow, ShowCities.value]
                            ])
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (item.value.MallId == 111036)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                        _createElementVNode("span", _hoisted_20, [
                          (item.value.Country == 1)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_21))
                            : (_openBlock(), _createElementBlock("img", _hoisted_22))
                        ]),
                        _createElementVNode("span", null, _toDisplayString(item.value.DeliveryCountry), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (item.value.MallId == 111036)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('付款方式')), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value.Payments, (Payment) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: Payment.PaymentTypeId
                          }, _toDisplayString(Payment.PaymentValue) + "    ", 1))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true),
                  (item.value.MinQuantity && item.value.MallId != 111036)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t('起批量')), 1),
                        _createElementVNode("span", null, "≥" + _toDisplayString(item.value.MinQuantity), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (item.value.MallId == 111036)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                        _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t('运费')), 1),
                        _createElementVNode("span", null, _toDisplayString(Beyond.value
                  ? _ctx.$t('该地址不支持配送')
                  : CityFreight.value
                  ? _ctx.$ccy(CityFreight.value)
                  : _ctx.$t('包邮')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (item.value.MallId != 111036)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                        _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t('商品来自')), 1),
                        _createElementVNode("span", null, _toDisplayString(_unref(country) != 'kh' ? 'CHINA' : item.value.MallName), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value.Skus, (Sku, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "Skus",
                      key: Sku.Index
                    }, [
                      _createElementVNode("div", _hoisted_31, [
                        _createVNode(GoogleTranslate, {
                          text: Sku.Name,
                          type: null
                        }, null, 8, ["text"])
                      ]),
                      _createElementVNode("div", _hoisted_32, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Sku.Values, (Value) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: Value,
                            class: _normalizeClass([
                  'Value',
                  { checkVal: CombinationSku.value[index] === Value.Value },
                ]),
                            onClick: ($event: any) => (SelectSku(index, Value))
                          }, [
                            _createVNode(_component_el_tooltip, {
                              effect: "light",
                              placement: "top-start"
                            }, {
                              content: _withCtx(() => [
                                _createElementVNode("span", null, [
                                  _createVNode(GoogleTranslate, {
                                    text: Value.Name,
                                    type: null
                                  }, null, 8, ["text"])
                                ])
                              ]),
                              default: _withCtx(() => [
                                (Value.ImgUrl)
                                  ? (_openBlock(), _createBlock(_component_suffix_img, {
                                      key: 0,
                                      class: "ValueImage",
                                      url: Value.ImgUrl
                                    }, null, 8, ["url"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            (!Value.ImgUrl)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_34, [
                                  _createVNode(GoogleTranslate, {
                                    text: Value.Name,
                                    type: null
                                  }, null, 8, ["text"])
                                ]))
                              : _createCommentVNode("", true)
                          ], 10, _hoisted_33))
                        }), 128))
                      ])
                    ]))
                  }), 128)),
                  (CurrentSku.value || !item.value.SkuMap.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                        _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$t('数量')), 1),
                        _createVNode(_component_el_input_number, {
                          modelValue: SkuValueQty.value,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((SkuValueQty).value = $event)),
                          size: "large",
                          min: 0,
                          step: item.value.BatchNumber || 1,
                          max: item.value.MallId == 111036 ? item.value.StockQty : Infinity,
                          onChange: QuantityChange,
                          precision: 0
                        }, null, 8, ["modelValue", "step", "max"]),
                        (item.value.BatchNumber && item.value.BatchNumber > 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_ctx.$t(`数量必须是`, { name: item.value.BatchNumber })), 1))
                          : _createCommentVNode("", true),
                        (item.value.MallId == 111036)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              (CurrentSku.value)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_ctx.$t('库存')) + ": " + _toDisplayString(CurrentSku.value.StockQty), 1))
                                : (!item.value.SkuMap.length && item.value.StockQty)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_39, _toDisplayString(_ctx.$t('库存')) + ": " + _toDisplayString(item.value.StockQty), 1))
                                  : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.$t('总额')) + ":", 1),
                    _createElementVNode("div", _hoisted_42, [
                      _createElementVNode("span", _hoisted_43, [
                        (item.value.MallId == 111036)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(_toDisplayString(_ctx.$ccy(
                      (CurrentSku.value?.DiscountPrice ||
                        item.value.DiscountPrice ||
                        CurrentSku.value?.Price ||
                        item.value.Price) * SkuValueQty.value
                    )), 1)
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(_ctx.$ccy(
                      (GroceryList.value.length ? GroceryQty.value : SkuValueQty.value) *
                        (isLadderPrices.value
                          ? isLadderPrices.value.Price
                          : item.value?.Price.indexOf('-') != -1
                          ? 0
                          : item.value.Price)
                    )), 1)
                            ], 64))
                      ]),
                      (GroceryList.value.length)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_44, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('数量')) + ":", 1),
                            _createElementVNode("span", null, _toDisplayString(GroceryQty.value), 1),
                            _withDirectives((_openBlock(), _createElementBlock("span", {
                              ref_key: "buttonRef",
                              ref: buttonRef
                            }, [
                              _createTextVNode(_toDisplayString(_ctx.$t('采购清单')), 1)
                            ])), [
                              [_vShow, GroceryList.value.length],
                              [_unref(vClickOutside), onClickOutside]
                            ]),
                            _createVNode(_component_el_popover, {
                              placement: "top",
                              ref_key: "popoverRef",
                              ref: popoverRef,
                              "virtual-ref": buttonRef.value,
                              trigger: "click",
                              width: "500px",
                              title: _ctx.$t('采购清单'),
                              "virtual-triggering": "",
                              "popper-class": "PurchaseList"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_45, [
                                  _createElementVNode("div", _hoisted_46, [
                                    _cache[9] || (_cache[9] = _createElementVNode("span", null, "SKU", -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('数量')), 1)
                                  ]),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(GroceryList.value, (sku, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "skusTr",
                                      key: index
                                    }, [
                                      _createVNode(_component_el_button, {
                                        type: "danger",
                                        icon: "Delete",
                                        circle: "",
                                        onClick: ($event: any) => (DeleteGrocery(index))
                                      }, null, 8, ["onClick"]),
                                      _createElementVNode("span", _hoisted_47, [
                                        _createVNode(GoogleTranslate, {
                                          text: sku.SkuTitle,
                                          type: null
                                        }, null, 8, ["text"])
                                      ]),
                                      _createVNode(_component_el_input_number, {
                                        modelValue: sku.Qty,
                                        "onUpdate:modelValue": ($event: any) => ((sku.Qty) = $event),
                                        size: "large",
                                        min: 0,
                                        step: item.value.BatchNumber || 1,
                                        onChange: ($event: any) => (GroceryChange(index)),
                                        precision: 0
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "step", "onChange"])
                                    ]))
                                  }), 128))
                                ])
                              ]),
                              _: 1
                            }, 8, ["virtual-ref", "title"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  (item.value.MallId != 111036)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                        _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.$t('给客服留言')) + ":", 1),
                        _createVNode(_component_el_input, {
                          modelValue: Remark.value,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((Remark).value = $event)),
                          rows: 5,
                          type: "textarea",
                          maxlength: "100",
                          "show-word-limit": "",
                          placeholder: _ctx.$t('请输入')
                        }, null, 8, ["modelValue", "placeholder"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_50, [
                    _createVNode(_component_el_button, {
                      class: "AddCart",
                      color: "#fa713e",
                      icon: "ShoppingCart",
                      disabled: isAdd(),
                      size: "large",
                      onClick: buyNow
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('立即购买')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"]),
                    _createVNode(_component_el_button, {
                      class: "AddCart",
                      color: "#fa713e",
                      icon: "ShoppingCart",
                      disabled: isAdd(),
                      size: "large",
                      onClick: AddCart
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('加入购物车')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"]),
                    _withDirectives(_createVNode(_component_el_button, {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (GetProduct(true))),
                      size: "large",
                      color: "#fa713e",
                      plain: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('更新商品数据')), 1)
                      ]),
                      _: 1
                    }, 512), [
                      [_vShow, item.value.MallId != 111036]
                    ])
                  ])
                ])
              ]),
              (item.value.MallId == 111036)
                ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                    _createVNode(_component_suffix_img, {
                      class: "ShopLogo",
                      url: item.value.SellerInfo.ShopLogo
                    }, null, 8, ["url"]),
                    _createElementVNode("div", _hoisted_52, [
                      _createElementVNode("div", _hoisted_53, _toDisplayString(item.value.SellerInfo.ShopName), 1),
                      _createElementVNode("div", _hoisted_54, [
                        _createVNode(_component_router_link, {
                          to: {
                path: '/IM',
                query: {
                  type: 'Grabgood',
                  GoodsName: item.value.GoodsName,
                  ImgUrl: item.value.MasterImg,
                  Price: item.value.Price,
                  Url: hrefUrl.value,
                  shopId: item.value.ShopId,
                },
              },
                          target: "_blank"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("button", null, _toDisplayString(_ctx.$t('即时聊天')), 1)
                          ]),
                          _: 1
                        }, 8, ["to"]),
                        _createElementVNode("button", {
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (
                _unref(Router).push({
                  path: '/mallHome',
                  query: { ShopId: item.value.ShopId },
                })
              ))
                        }, _toDisplayString(_ctx.$t('进入商店')), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_55, [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('聊天回应率')) + ":", 1),
                        _createElementVNode("span", null, _toDisplayString(item.value.SellerInfo.ChatResponseRate) + "%", 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('回应速度')) + ":", 1),
                        _createElementVNode("span", null, _toDisplayString(item.value.SellerInfo.ChatResponseSpeed) + " " + _toDisplayString(_ctx.$t('小时内')), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('商品')) + ":", 1),
                        _createElementVNode("span", null, _toDisplayString(item.value.SellerInfo.ProductNum), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('评价')) + ":", 1),
                        _createElementVNode("span", null, _toDisplayString(item.value.SellerInfo.Evaluation.toFixed(1)), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('加入时间')) + ":", 1),
                        _createElementVNode("span", null, _toDisplayString(item.value.SellerInfo.CreatedMonth) + " " + _toDisplayString(_ctx.$t('个月前')), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_56, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_57, [
                    _createElementVNode("div", _hoisted_58, _toDisplayString(_ctx.$t('推荐商品')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(GoodsRecommendList.value, (GoodsRecommend, index) => {
                      return (_openBlock(), _createBlock(CommodityCard, {
                        key: GoodsRecommend,
                        data: GoodsRecommend,
                        index: index,
                        class: "CCard"
                      }, null, 8, ["data", "index"]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", {
                  class: "Description",
                  innerHTML: item.value.Description
                }, null, 8, _hoisted_59)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "el-zoom-in-center" }, {
      default: _withCtx(() => [
        (!item.value && !NoGoods.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
              _createVNode(_component_el_skeleton, { animated: "" }, {
                template: _withCtx(() => [
                  _createElementVNode("div", _hoisted_61, [
                    _createElementVNode("div", _hoisted_62, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (isImage) => {
                        return _createVNode(_component_el_skeleton_item, {
                          key: isImage,
                          variant: "image"
                        })
                      }), 64))
                    ]),
                    _createElementVNode("div", _hoisted_63, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (isText) => {
                        return _createVNode(_component_el_skeleton_item, {
                          key: isText,
                          style: _normalizeStyle('width:' + 200 / isText + '%'),
                          variant: "text"
                        }, null, 8, ["style"])
                      }), 64))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_64, [
                    _createElementVNode("div", _hoisted_65, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (isText) => {
                        return _createVNode(_component_el_skeleton_item, {
                          key: isText,
                          variant: "text"
                        })
                      }), 64))
                    ])
                  ])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
      default: _withCtx(() => [
        (item.value?.IsShelves)
          ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
              _createElementVNode("div", null, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCloseFilled)
                  ]),
                  _: 1
                }),
                _cache[10] || (_cache[10] = _createTextVNode("     ")),
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCloseFilled)
                  ]),
                  _: 1
                }),
                _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SemiSelect)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('商品已下架')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
      default: _withCtx(() => [
        (NoGoods.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
              _createElementVNode("div", null, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCloseFilled)
                  ]),
                  _: 1
                }),
                _cache[12] || (_cache[12] = _createTextVNode("     ")),
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCloseFilled)
                  ]),
                  _: 1
                }),
                _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SemiSelect)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('很抱歉，没有淘到该代购商品!')) + " ", 1),
                (_unref(route).query?.Url)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_68, _toDisplayString(_ctx.$t('您可以仔细检查输入的代购链接是否正确')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, [
                (_unref(route).query?.Url || item.value?.Url)
                  ? (_openBlock(), _createBlock(_component_el_link, {
                      key: 0,
                      type: "danger",
                      href: _unref(route).query.Url || item.value?.Url,
                      target: "_blank"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('打开原链接')), 1)
                      ]),
                      _: 1
                    }, 8, ["href"]))
                  : _createCommentVNode("", true),
                _cache[14] || (_cache[14] = _createTextVNode("     ")),
                _createVNode(_component_el_link, {
                  type: "warning",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (GetProduct()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('更新商品数据')), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
      default: _withCtx(() => [
        (IsBlacklist.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
              _createElementVNode("div", null, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_WarningFilled)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('很抱歉，该商品为黑名单商品!')), 1),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('商品名字')) + ": " + _toDisplayString(item.value.GoodsName), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('您可以选择其他喜爱商品再次尝试，谢谢。')), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
      default: _withCtx(() => [
        (item.value?.NotSupportLink)
          ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
              _createElementVNode("div", null, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCloseFilled)
                  ]),
                  _: 1
                }),
                _cache[15] || (_cache[15] = _createTextVNode("     ")),
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCloseFilled)
                  ]),
                  _: 1
                }),
                _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SemiSelect)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('暂时不支持该链接')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}
}

})