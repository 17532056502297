import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "Coupons" }
const _hoisted_2 = { class: "CouponList" }
const _hoisted_3 = { class: "ProductPagination" }

import { ref, onMounted, watch } from 'vue'
import PaginationHook from '@/hooks/pagination'
import CouponCard from './coupon_card.vue'
import * as Api from '@/utils/api'
import Loading from '@/components/loading.vue'
import EmptyArray from '@/components/EmptyArray.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'coupon_list',
  setup(__props) {

const States = ref([
  { name: '正常使用', id: 5 },
  { name: '已经使用', id: 20 },
  { name: '已经过期', id: 250 },
])

const total = ref(0)
const StatusId = ref(5)
const CouponList = ref(null)

const GetCoupons = () => {
  CouponList.value = null
  Api.Get('bank/api/Coupons/', {
    coupon_status_id: StatusId.value,
    page_index: pageNum.value,
    page_size: pageSize.value,
  }).then((res: any) => {
    CouponList.value = res.Coupons
    total.value = res.TotalRecordsCount
  })
}

const { pageNum, pageSize, handleCurrentChange, handleSizeChange } =
  PaginationHook(GetCoupons)

onMounted(() => {
  pageSize.value = 12
  GetCoupons()
})

return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_radio_group, {
      modelValue: StatusId.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((StatusId).value = $event)),
      size: "large",
      onChange: GetCoupons
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(States.value, (State) => {
          return (_openBlock(), _createBlock(_component_el_radio_button, {
            key: State.id,
            label: State.id
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(State.name)), 1)
            ]),
            _: 2
          }, 1032, ["label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(CouponList.value, (Coupon, index) => {
        return (_openBlock(), _createBlock(CouponCard, {
          key: index,
          data: Coupon,
          index: index,
          class: _normalizeClass({ NotAvailable: StatusId.value == 20 || StatusId.value == 250 })
        }, null, 8, ["data", "index", "class"]))
      }), 128)),
      (CouponList.value)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(3 - (CouponList.value.length % 3), (placeholder) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "placeholderCouponCard",
              key: placeholder
            }))
          }), 128))
        : _createCommentVNode("", true),
      _createVNode(EmptyArray, {
        show: CouponList.value && !CouponList.value.length
      }, null, 8, ["show"]),
      _withDirectives(_createVNode(Loading, { type: "13" }, null, 512), [
        [_vShow, !CouponList.value]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_pagination, {
        currentPage: _unref(pageNum),
        "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : null)),
        "page-size": _unref(pageSize),
        onSizeChange: _unref(handleSizeChange),
        onCurrentChange: _unref(handleCurrentChange),
        total: total.value,
        background: "",
        layout: "prev, pager, next"
      }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"])
    ])
  ]))
}
}

})