import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, resolveDirective as _resolveDirective, createBlock as _createBlock, isRef as _isRef, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wallet"
}
const _hoisted_2 = {
  key: 0,
  class: "AvailableAmount"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "ProductPagination" }

import { ref, onMounted, nextTick } from 'vue'
import PaginationHook from '@/hooks/pagination'
import Loading from '@/components/loading.vue'
import * as Api from '@/utils/api'
import { formatDate } from '@/utils/format'
import Recharge from '@/components/user-center/recharge.vue'
import Withdrawal from '@/components/user-center/withdrawal.vue'
import { shortcuts } from '@/utils/constant'


export default /*@__PURE__*/_defineComponent({
  __name: 'wallet',
  setup(__props) {

const ShowType = ref(null)
const BalanceItem = ref(null)
const activeName = ref(1)
const recordList = ref(null)
const total = ref(null)
const Date = ref(null)

const getRowClass = (row: any) => {
  if (!row.row.HasDetail) return 'row-expand-cover'
}
const table: any = ref(null)
const handleExpandChange = (row: any, expandedRows: any) => {
  if (!row.HasDetail) {
    // 取消展开
    table.value.toggleRowExpansion(row, false)
  } else {
    if (!row.FinanceList) {
      Api.Get('bank/api/BalanceDetailFinance/', {
        ChargeId: row.ChargeId,
      }).then((res: any) => {
        row.FinanceList = res.Details
        nextTick(() => {
          table.value.toggleRowExpansion(row, true)
        })
      })
    }
  }
}
const handleClick = () => {
  recordList.value = null
  switch (activeName.value) {
    case 1:
      Api.Get('bank/api/BalanceAudits/', {
        page_index: pageNum.value,
        page_size: pageSize.value,
        start_date: Date.value[0],
        end_date: Date.value[1],
      }).then((res: any) => {
        recordList.value = res.Records
        total.value = res.TotalRecordsCount
      })
      break
    case 2:
      let page_index = pageNum.value
      page_index--
      Api.Get('bank/api/WithdrawCash/', {
        page_index: page_index.toString(),
        page_size: pageSize.value,
        start_date: Date.value[0],
        end_date: Date.value[1],
      }).then((res: any) => {
        recordList.value = res.Requests
        total.value = res.TotalRecordsCount
      })
      break
    case 3:
      Api.Get('bank/api/BalanceDetails/', {
        page_index: pageNum.value,
        page_size: pageSize.value,
        start_date: Date.value[0],
        end_date: Date.value[1],
      }).then((res: any) => {
        recordList.value = res.Records
        total.value = res.TotalRecordsCount
      })
      break
    default:
      break
  }
}
const handleUpdate = (value) => {
  if (value == 'close') {
    ShowType.value = null
    handleClick()
  }
}
const { pageNum, pageSize, handleCurrentChange, handleSizeChange } =
  PaginationHook(handleClick)

onMounted(() => {
  Date.value = shortcuts[0].value
  pageSize.value = 10
  handleClick()
  Api.Get('bank/api/CustomerBalance/', {
    CustomerId: 0,
  }).then((res: any) => {
    BalanceItem.value = res.BalanceItems.length ? res.BalanceItems[0] : {}
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_Back = _resolveComponent("Back")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!ShowType.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (BalanceItem.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("b", null, _toDisplayString(_ctx.$t('我的余额')), 1),
                _createElementVNode("b", null, _toDisplayString(_ctx.$ccy(BalanceItem.value.AvailableAmount) || '0.00'), 1)
              ]))
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(Loading, { type: "3" }, null, 512), [
            [_vShow, !BalanceItem.value]
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_el_button, {
              type: "danger",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (ShowType.value = 1))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('充值')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (ShowType.value = 2))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('提现')), 1)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_el_date_picker, {
            class: "startDate",
            modelValue: Date.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((Date).value = $event)),
            type: "daterange",
            clearable: false,
            "value-format": "YYYY-MM-DD",
            "unlink-panels": "",
            "range-separator": "——",
            "start-placeholder": "Start date",
            "end-placeholder": "End date",
            shortcuts: _unref(shortcuts),
            onChange: _cache[3] || (_cache[3] = ($event: any) => (handleClick()))
          }, null, 8, ["modelValue", "shortcuts"]),
          _createVNode(_component_el_tabs, {
            modelValue: activeName.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((activeName).value = $event)),
            class: "demo-tabs",
            onTabChange: handleClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, {
                label: _ctx.$t('充值记录'),
                name: 1
              }, null, 8, ["label"]),
              _createVNode(_component_el_tab_pane, {
                label: _ctx.$t('提现记录'),
                name: 2
              }, null, 8, ["label"]),
              _createVNode(_component_el_tab_pane, {
                label: _ctx.$t('收支明细'),
                name: 3
              }, null, 8, ["label"])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", null, [
            (activeName.value == 1)
              ? _withDirectives((_openBlock(), _createBlock(_component_el_table, {
                  key: 0,
                  data: recordList.value,
                  "header-row-class-name": 'RecordHeader'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('时间')
                    }, {
                      default: _withCtx(({ row }) => [
                        _createTextVNode(_toDisplayString(_unref(formatDate)(row.CreatedOn, 'yyyy-MM-dd hh:mm:ss')), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "DealNumber",
                      label: _ctx.$t('流水号')
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "Income",
                      label: _ctx.$t('充值金额')
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "StatusName",
                      label: _ctx.$t('状态')
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "TypeName",
                      label: _ctx.$t('充值渠道')
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["data"])), [
                  [_directive_loading, recordList.value == null]
                ])
              : _createCommentVNode("", true),
            (activeName.value == 2)
              ? _withDirectives((_openBlock(), _createBlock(_component_el_table, {
                  key: 1,
                  data: recordList.value,
                  "header-row-class-name": 'RecordHeader'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('时间')
                    }, {
                      default: _withCtx(({ row }) => [
                        _createTextVNode(_toDisplayString(_unref(formatDate)(row.CreatedOn, 'yyyy-MM-dd hh:mm:ss')), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "AuditSerialNo",
                      label: _ctx.$t('流水号')
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "SwiftCode",
                      label: _ctx.$t('提现卡号')
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "Amount",
                      label: _ctx.$t('提现金额')
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "WithdrawCashStatu",
                      label: _ctx.$t('状态')
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["data"])), [
                  [_directive_loading, recordList.value == null]
                ])
              : _createCommentVNode("", true),
            (activeName.value == 3)
              ? _withDirectives((_openBlock(), _createBlock(_component_el_table, {
                  key: 2,
                  ref_key: "table",
                  ref: table,
                  data: recordList.value,
                  onExpandChange: handleExpandChange,
                  "row-class-name": getRowClass,
                  "header-row-class-name": 'RecordHeader'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, { type: "expand" }, {
                      default: _withCtx((props) => [
                        (props.row.HasDetail)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                              _withDirectives((_openBlock(), _createBlock(_component_el_table, {
                                data: props.row.FinanceList
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_table_column, {
                                    prop: "OrderNo",
                                    label: _ctx.$t('订单号')
                                  }, null, 8, ["label"]),
                                  _createVNode(_component_el_table_column, {
                                    prop: "Amount",
                                    label: _ctx.$t('金额')
                                  }, null, 8, ["label"]),
                                  _createVNode(_component_el_table_column, {
                                    prop: "TypeName",
                                    label: _ctx.$t('类型')
                                  }, null, 8, ["label"])
                                ]),
                                _: 2
                              }, 1032, ["data"])), [
                                [_directive_loading, !props.row.FinanceList]
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('时间')
                    }, {
                      default: _withCtx(({ row }) => [
                        _createTextVNode(_toDisplayString(_unref(formatDate)(row.CreatedOn, 'yyyy-MM-dd hh:mm:ss')), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "SerialNumber",
                      label: _ctx.$t('交易号')
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "Income",
                      label: _ctx.$t('收入'),
                      width: "80"
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "Payment",
                      label: _ctx.$t('支出'),
                      width: "80"
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "Balance",
                      label: _ctx.$t('余额')
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "PaymentMode",
                      label: _ctx.$t('详情')
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["data"])), [
                  [_directive_loading, recordList.value == null]
                ])
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_pagination, {
              currentPage: _unref(pageNum),
              "onUpdate:currentPage": _cache[5] || (_cache[5] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : null)),
              "page-size": _unref(pageSize),
              onSizeChange: _unref(handleSizeChange),
              onCurrentChange: _unref(handleCurrentChange),
              total: total.value,
              background: "",
              layout: "prev, pager, next"
            }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"])
          ])
        ]))
      : _createCommentVNode("", true),
    (ShowType.value)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          class: "isBack",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (ShowType.value = null))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_Back)
              ]),
              _: 1
            }),
            _createTextVNode("   " + _toDisplayString(_ctx.$t('返回我的钱包')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (ShowType.value == 1)
      ? (_openBlock(), _createBlock(Recharge, {
          key: 2,
          onUpdateValue: handleUpdate
        }))
      : _createCommentVNode("", true),
    (ShowType.value == 2)
      ? (_openBlock(), _createBlock(Withdrawal, {
          key: 3,
          onSelectType: _cache[7] || (_cache[7] = ($event: any) => {;(ShowType.value = null), (activeName.value = 2), handleClick()})
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})