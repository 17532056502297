import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "LodaDelivery" }
const _hoisted_2 = { class: "transport_package" }
const _hoisted_3 = { class: "Status" }
const _hoisted_4 = { class: "Status" }
const _hoisted_5 = { class: "DeliveryList" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { style: {"width":"100%"} }
const _hoisted_8 = { class: "detial Statu" }
const _hoisted_9 = { class: "detial" }
const _hoisted_10 = {
  key: 0,
  class: "detial"
}
const _hoisted_11 = { class: "detial" }
const _hoisted_12 = { class: "detial" }
const _hoisted_13 = { class: "detial" }
const _hoisted_14 = {
  key: 1,
  class: "detial"
}
const _hoisted_15 = { class: "detial" }
const _hoisted_16 = { class: "detial" }
const _hoisted_17 = { class: "detial" }
const _hoisted_18 = {
  key: 0,
  class: "isBarcode"
}
const _hoisted_19 = { class: "Checked" }
const _hoisted_20 = { class: "ProductPagination" }
const _hoisted_21 = { class: "LodaDelivery_Affix" }
const _hoisted_22 = { class: "totalAmount" }
const _hoisted_23 = { class: "ProductPagination" }
const _hoisted_24 = { class: "dialog-footer" }

import { ref, watch, onMounted, toRefs } from 'vue'
import * as Api from '@/utils/api'
import { formatDate } from '@/utils/format'
import Barcode from '@/components/Barcode.vue'
import EmptyArray from '@/components/EmptyArray.vue'
import Loading from '@/components/loading.vue'
import PaginationHook from '@/hooks/pagination'
import Pay from '@/components/user-center/pay.vue'
import Delivery from '@/components/user-center/delivery.vue'
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import { shortcuts } from '@/utils/constant'
import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'loda_delivery',
  setup(__props) {

const store = useUsersStore()
const { PaymentStatus, BD4 } = toRefs(store)

const { t } = useI18n()

const DeliveryList = ref(null)
const total = ref(0)
const totalAmount = ref(0)
const isSelectAll = ref(false)
const ShowAffix = ref(false)
const isDate = ref(null)
const StatuId = ref(0)

const ShowPay = ref(false)
const PayDate = ref({
  TotalAmount: 0,
})
const DeliveryIds = ref([])
const isShowIndex = ref(null)

const PayDelivery = () => {
  DeliveryIds.value = []
  PayDate.value.TotalAmount = totalAmount.value
  DeliveryList.value.map((ele) => {
    if (ele.checked) {
      DeliveryIds.value.push(ele.ID)
    }
  })
  ShowPay.value = true
}

const Checked = (item) => {
  if (item.StatuId == 5) {
    item.checked = !item.checked
    isTotal()
  }
}

const ClickChooseAll = () => {
  DeliveryList.value.map((item) => {
    if (item.StatuId == 5) {
      item.checked = true
    }
  })
  isTotal()
}

const Reminders = (OrderNo, index) => {
  Api.Post('orders/api/DeliveryOrderRemind', {
    OrderNo,
  }).then((res: any) => {
    ElMessage({
      message: t('催单成功'),
      type: 'success',
    })
    if (!res.IsError) {
      DeliveryList.value[index].IsRemind = false
    }
  })
}

const MoreDetails = (item, index) => {
  isShowIndex.value = index
  Api.Post('orders/api/DeliveryDetail/', {
    OrderNo: item.SerialNo,
  }).then((res: any) => {
    DeliveryList.value[index].BoxDetailList = res.BoxDetailList
  })
}

const isTotal = () => {
  totalAmount.value = 0
  let isPays = 0
  let isChecks = 0
  DeliveryList.value.map((ele) => {
    ele.StatuId == 5 ? isPays++ : null
    if (ele.checked) {
      isChecks++
      totalAmount.value += ele.ShouldPaymentAmount
    }
  })
  isSelectAll.value = isChecks == isPays ? true : false
}

const GetDelivery = () => {
  totalAmount.value = 0
  DeliveryList.value = null
  isShowIndex.value = null
  Api.Get('orders/api/DeliveryServer/', {
    statu_id: StatuId.value.toString(),
    start_date: isDate.value ? isDate.value[0] : null,
    end_date: isDate.value ? isDate.value[1] : null,
    page_index: pageNum.value,
    page_size: pageSize.value,
  }).then((res: any) => {
    DeliveryList.value = res.DeliverExpresses
    if (res.DeliverExpresses.length > 0) {
      ShowAffix.value = true
    }
    total.value = res.TotalRecordsCount
  })
}

const ShowDeliveryList = ref(false)
const InitiateList = ref(null)
const DlTotal = ref(null)

const GetDeliveryList = () => {
  Api.Get('orders/api/GetWaitDeliveryBoxList/', {
    page_size: DlSize.value,
    page_index: DlIndex.value,
  }).then((res: any) => {
    InitiateList.value = res.TransportBoxes
    DlTotal.value = res.TotalRecordsCount
  })
}

const handleSelectionChange = (val) => {
  DeliveryDate.value = val
}

const DeliveryDate = ref([])
const ShowDelivery = ref(false)
const DeliveryChange = (val) => {
  ShowDelivery.value = false
  if (val) {
    GetDeliveryList()
  }
}

watch(
  () => PaymentStatus.value,
  (res) => {
    isTotal()
    GetDelivery()
  },
  {
    deep: true,
  }
)

const DlIndex = ref(1)
const DlSize = ref(20)
const DlCurrentChange = () => {

}
const DlSizeChange = () => {

}

const { pageNum, pageSize, handleCurrentChange, handleSizeChange } =
  PaginationHook(GetDelivery)

onMounted(() => {
  pageSize.value = 10
  isDate.value = [
    moment().subtract(3, 'months').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ]
  GetDelivery()
  store.setState('basic/api/BasicDictionary', { type_id: 4 }, 'BD4')
})

return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_Select = _resolveComponent("Select")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_affix = _resolveComponent("el-affix")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_radio_group, {
              modelValue: StatuId.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((StatuId).value = $event)),
              onChange: GetDelivery
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio_button, { label: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('全部')), 1)
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(BD4)?.BasicDictionaryList, (Statu, index) => {
                  return (_openBlock(), _createBlock(_component_el_radio_button, {
                    key: index,
                    label: Statu.DicValue
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(Statu.DicName), 1)
                    ]),
                    _: 2
                  }, 1032, ["label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_date_picker, {
              modelValue: isDate.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isDate).value = $event)),
              type: "daterange",
              clearable: false,
              "value-format": "YYYY-MM-DD",
              "unlink-panels": "",
              "range-separator": "——",
              "start-placeholder": "Start date",
              "end-placeholder": "End date",
              shortcuts: _unref(shortcuts),
              onChange: _cache[2] || (_cache[2] = ($event: any) => (GetDelivery()))
            }, null, 8, ["modelValue", "shortcuts"])
          ])
        ]),
        _createVNode(_component_el_button, {
          size: "large",
          type: "danger",
          onClick: _cache[3] || (_cache[3] = ($event: any) => {ShowDeliveryList.value = true;GetDeliveryList()})
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('发起配送')), 1)
          ]),
          _: 1
        })
      ]),
      _withDirectives(_createVNode(Loading, { type: "13" }, null, 512), [
        [_vShow, !DeliveryList.value]
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(DeliveryList.value, (Delivery, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass([
        'Cards',
        { Statu1: Delivery.StatuId == 10 },
        { Statu2: Delivery.StatuId == 14 },
        { Statu3: Delivery.StatuId == 15 },
        { Statu4: Delivery.IsTimeOut },
        { isChecked: Delivery.checked },
      ]),
            onClick: ($event: any) => (Checked(Delivery))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _withDirectives(_createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Select)
                ]),
                _: 2
              }, 1536), [
                [_vShow, Delivery.checked]
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('状态')), 1),
                _createElementVNode("span", null, _toDisplayString(Delivery.StatuName), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('流水号')), 1),
                _createElementVNode("span", null, _toDisplayString(Delivery.SerialNo), 1)
              ]),
              (Delivery.ShouldPaymentAmount)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('服务费')), 1),
                    _createElementVNode("span", null, _toDisplayString(Delivery.ShouldPaymentAmount) + " USD", 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('客户代号')), 1),
                _createElementVNode("span", null, _toDisplayString(Delivery.CustomerCode), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t(Delivery.PickUpTypeId == 5 ? '提货仓库' : '仓库')), 1),
                _createElementVNode("span", null, _toDisplayString(Delivery.WarehouseName) + " " + _toDisplayString(Delivery.PickUpType), 1)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('提交时间')), 1),
                _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(Delivery.CreatedOn, 'yyyy-MM-dd hh:mm:ss')), 1)
              ]),
              (Delivery.PickUpTypeId == 5)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('预约取货时间')), 1),
                    _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(Delivery.ExpectEndTime, 'yyyy-MM-dd hh:mm:ss')), 1)
                  ]))
                : _createCommentVNode("", true),
              (Delivery.PickUpTypeId != 5 && isShowIndex.value == index)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('收货人')), 1),
                      _createElementVNode("span", null, _toDisplayString(Delivery.Name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('电话')), 1),
                      _createElementVNode("span", null, _toDisplayString(Delivery.Mobile), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('地址')), 1),
                      _createElementVNode("span", null, _toDisplayString(Delivery.Address), 1)
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              (isShowIndex.value == index)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    (Delivery.PickUpTypeId == 5)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _createVNode(Barcode, {
                            value: Delivery.RandomCode
                          }, null, 8, ["value"])
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_table, {
                      data: Delivery.BoxDetailList
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_table_column, {
                          prop: "BoxSerialNo",
                          label: _ctx.$t('箱子号')
                        }, null, 8, ["label"]),
                        _createVNode(_component_el_table_column, {
                          prop: "AbroadBoxVolume",
                          label: _ctx.$t('体积')
                        }, null, 8, ["label"]),
                        _createVNode(_component_el_table_column, {
                          prop: "AbroadBoxWeight",
                          label: _ctx.$t('重量')
                        }, null, 8, ["label"])
                      ]),
                      _: 2
                    }, 1032, ["data"])
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_el_button, {
                type: "danger",
                onClick: _withModifiers(($event: any) => (
            isShowIndex.value != index
              ? MoreDetails(Delivery, index)
              : (isShowIndex.value = null)
            ), ["stop"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(isShowIndex.value != index ? '更多' : '收起')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _withDirectives(_createVNode(_component_el_button, {
                type: "danger",
                onClick: ($event: any) => (Reminders(Delivery.SerialNo, index))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('催单')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]), [
                [_vShow, Delivery.IsRemind]
              ])
            ])
          ], 10, _hoisted_6))
        }), 128))
      ]),
      _withDirectives(_createVNode(EmptyArray, null, null, 512), [
        [_vShow, DeliveryList.value && DeliveryList.value.length == 0]
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_el_pagination, {
          currentPage: _unref(pageNum),
          "onUpdate:currentPage": _cache[4] || (_cache[4] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : null)),
          "page-size": _unref(pageSize),
          onSizeChange: _unref(handleSizeChange),
          onCurrentChange: _unref(handleCurrentChange),
          total: total.value,
          background: "",
          layout: "prev, pager, next"
        }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"])
      ])
    ]),
    _withDirectives(_createVNode(_component_el_affix, {
      ref: "affix",
      position: "bottom",
      offset: 0
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", null, [
            _createVNode(_component_el_button, {
              onClick: ClickChooseAll,
              size: "large",
              type: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('全选')), 1)
              ]),
              _: 1
            }),
            _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$ccy(totalAmount.value)), 1),
            _withDirectives(_createVNode(_component_el_button, {
              size: "large",
              type: "danger",
              onClick: PayDelivery
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('支付')), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, totalAmount.value]
            ])
          ])
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, ShowAffix.value]
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: ShowDeliveryList.value,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((ShowDeliveryList).value = $event)),
      title: _ctx.$t('发起配送'),
      width: "600px"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_24, [
          _createVNode(_component_el_button, {
            onClick: _cache[6] || (_cache[6] = ($event: any) => (ShowDeliveryList.value = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('关闭')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (ShowDelivery.value = true)),
            disabled: !DeliveryDate.value.length
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('发起')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_table, {
            data: InitiateList.value,
            style: {"width":"100%"},
            onSelectionChange: handleSelectionChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                type: "selection",
                width: "55"
              }),
              _createVNode(_component_el_table_column, {
                property: "Serail",
                label: _ctx.$t('箱子号')
              }, null, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                property: "AbroadWeight",
                label: _ctx.$t('重量')
              }, null, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                property: "AbroadVolume",
                label: _ctx.$t('体积')
              }, null, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                property: "InStockStatus",
                label: _ctx.$t('状态')
              }, null, 8, ["label"])
            ]),
            _: 1
          }, 8, ["data"]),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_el_pagination, {
              currentPage: DlIndex.value,
              "onUpdate:currentPage": _cache[5] || (_cache[5] = ($event: any) => ((DlIndex).value = $event)),
              "page-size": DlSize.value,
              onSizeChange: DlSizeChange,
              onCurrentChange: DlCurrentChange,
              total: DlTotal.value,
              background: "",
              layout: "prev, pager, next"
            }, null, 8, ["currentPage", "page-size", "total"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(Pay, {
      Date: PayDate.value,
      ShowPay: ShowPay.value,
      Ids: DeliveryIds.value,
      PayType: "Delivery",
      onPayChange: _cache[9] || (_cache[9] = ($event: any) => (ShowPay.value = false))
    }, null, 8, ["Date", "ShowPay", "Ids"]),
    _createVNode(Delivery, {
      Date: DeliveryDate.value,
      ShowDelivery: ShowDelivery.value,
      onDeliveryChange: DeliveryChange
    }, null, 8, ["Date", "ShowDelivery"])
  ], 64))
}
}

})