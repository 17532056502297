import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "phone-number" }
const _hoisted_2 = { key: 2 }

import { ref, reactive, onMounted, watch, toRefs } from 'vue'
import type { FormInstance } from '@element-plus'
import { ElMessageBox } from 'element-plus'
import * as Api from '@/utils/api'
import countDown from '@/utils/countDown'
import { textContent } from '@/utils/format'
import { useI18n } from 'vue-i18n'
import RegionSelection from '@/components/RegionSelection.vue'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'email_mobile',
  props: {
  type: Boolean,
},
  setup(__props) {

const store = useUsersStore()
const { AreaCodeList } = toRefs(store)

const { t } = useI18n()
const { state: CodeTime, start: startTimeout, check_count: CCD } = countDown()
const { state: newCodeTime, start: newstartTimeout } = countDown()

const props = __props

const AccountType = ref(true)
const active = ref(1)
const ruleFormRef = ref<FormInstance>()

const CodeLoading = ref(false)
const newCodeLoading = ref(false)
const isLoading = ref(false)

const ShowSubmit = ref(true)

const ruleForm = reactive({
  VerificationAccount: null,
  VerificationCode: null,
  newVerificationCode: null,
  VerificationMode: null,
})

const Equipment = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error(t('不能为空')))
  } else {
    if (!AccountType.value) {
      const reg = new RegExp(
        '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$'
      )
      !reg.test(value) ? callback(new Error(t('请输入正确的邮箱'))) : callback()
    } else {
      !eval(userAreaCode.value.Regular).test(value)
        ? callback(new Error(t('请输入正确手机号')))
        : callback()
    }
  }
}

const rules = reactive({
  VerificationMode: [{ required: true, validator: Equipment }],
  VerificationCode: [{ required: true, message: t('请输入'), trigger: 'blur' }],
  newVerificationCode: [
    { required: true, message: t('请输入'), trigger: 'blur' },
  ],
})

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      isLoading.value = true
      let Unbound = AccountType.value ? Mobile.value : Email.value
      if (!Unbound) {
        Bind()
      } else {
        Api.Get('user/api/MobileVerificationCode', {
          code: ruleForm.VerificationCode,
          account: AccountType.value? Mobile.value:Email.value,
          SendChannel: 7
        }).then(
          (res) => {
            Bind()
          },
          (err) => {
            isLoading.value = false
          }
        )
      }
    }
  })
}

const Bind = () => {
  let IsCn = AccountType.value ? userAreaCode.value.IsCn : false
  Api.Post('user/api/BindingInformation', {
    VerificationCode: ruleForm.newVerificationCode,
    IsBinding: textContent(false),
    BindingType: !AccountType.value ? 'Email' : 'Mobile',
    BindAccount: ruleForm.VerificationMode,
    IsCn: textContent(IsCn),
  }).then(
    (res) => {
      store.setState(
        'user/api/CustomerSecureInfo/',
        {},
        'CustomerSecureInfo',
        true
      )
      active.value = 3
    },
    (err) => {
      isLoading.value = false
    }
  )
}

const VCode = () => {
  CodeLoading.value = true
  Api.Get('user/api/SendMessage/', {
    SendTo: ruleForm.VerificationAccount,
    SendChannel: 7,
  }).then(
    (res) => {
      active.value = 2
      startTimeout()
      ElMessageBox.alert(t('已发送验证码,请注意查收'), t('提示'), {
        confirmButtonText: t('确定'),
      })
    },
    (err) => {
      CodeLoading.value = false
    }
  )
}

const userAreaCode = ref(null)
const AreaCode = (val) => {
  userAreaCode.value = val
}

const newVCode = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validateField('VerificationMode', (valid) => {
    if (valid) {
      newCodeLoading.value = true
      Api.Get('user/api/BindingInformation/', {
        BindingType: AccountType.value ? 'Mobile' : 'Email',
        BindAccount: ruleForm.VerificationMode,
        IsBinding: textContent(false),
        IsCn: textContent(false),
      }).then(
        () => {
          Api.Get('user/api/SendMessage/', {
            SendTo: ruleForm.VerificationMode,
            SendChannel: 8,
          }).then(
            (res) => {
              ShowSubmit.value = false
              newstartTimeout()
              ElMessageBox.alert(t('已发送验证码,请注意查收'), t('提示'), {
                confirmButtonText: t('确定'),
              })
            },
            (err) => {
              newCodeLoading.value = false
            }
          )
        },
        (err) => {
          newCodeLoading.value = false
        }
      )
    }
  })
}

watch(
  () => CodeTime.count,
  (newV, oldV) => {
    CodeLoading.value = newV == 0 ? false : true
  },
  {
    immediate: true, // 立即执行
    deep: true, // 深度监听
  }
)

watch(
  () => newCodeTime.count,
  (newV, oldV) => {
    if (newV == 0) {
      newCodeLoading.value = false
    }
  },
  {
    immediate: true, // 立即执行
    deep: true, // 深度监听
  }
)

const Email = ref(null)
const Mobile = ref(null)
onMounted(() => {
  store.setState('user/api/SendMessage', {}, 'AreaCodeList')
  CCD()
  AccountType.value = props.type
  Api.Get('user/api/CustomerSecureInfo/', {}).then((res: any) => {
    Email.value = res.Email
    Mobile.value = res.Mobile
    ruleForm.VerificationAccount = AccountType.value
      ? Mobile.value
      : Email.value
    let Unbound = AccountType.value ? Mobile.value : Email.value
    if (!Unbound) {
      active.value = 2
    }
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (AccountType.value ? Mobile.value : Email.value)
      ? (_openBlock(), _createBlock(_component_el_steps, {
          key: 0,
          space: 200,
          active: active.value,
          simple: "",
          class: "steps"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_step, {
              title: _ctx.$t('验证身份'),
              icon: "User"
            }, null, 8, ["title"]),
            _createVNode(_component_el_step, {
              title: _ctx.$t(AccountType.value ? '修改手机号' : '修改邮箱'),
              icon: "Edit"
            }, null, 8, ["title"]),
            _createVNode(_component_el_step, {
              title: _ctx.$t('完成'),
              icon: "Select"
            }, null, 8, ["title"])
          ]),
          _: 1
        }, 8, ["active"]))
      : (_openBlock(), _createBlock(_component_el_steps, {
          key: 1,
          space: 200,
          active: active.value,
          simple: "",
          class: "steps"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_el_step, {
              title: _ctx.$t('验证身份'),
              icon: "User"
            }, null, 8, ["title"]), [
              [_vShow, false]
            ]),
            _createVNode(_component_el_step, {
              title: _ctx.$t(AccountType.value ? '绑定手机号' : '绑定邮箱'),
              icon: "Edit"
            }, null, 8, ["title"]),
            _createVNode(_component_el_step, {
              title: _ctx.$t('完成'),
              icon: "Select"
            }, null, 8, ["title"])
          ]),
          _: 1
        }, 8, ["active"])),
    _withDirectives(_createVNode(_component_el_form, {
      class: "ruleForm",
      ref_key: "ruleFormRef",
      ref: ruleFormRef,
      model: ruleForm,
      rules: rules,
      "label-position": "top"
    }, {
      default: _withCtx(() => [
        (AccountType.value ? Mobile.value : Email.value)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: _ctx.$t(AccountType.value ? '已认证手机号' : '已认证邮箱')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio_group, {
                  modelValue: ruleForm.VerificationAccount,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ruleForm.VerificationAccount) = $event))
                }, {
                  default: _withCtx(() => [
                    (!AccountType.value)
                      ? (_openBlock(), _createBlock(_component_el_radio, {
                          key: 0,
                          label: Email.value
                        }, null, 8, ["label"]))
                      : _createCommentVNode("", true),
                    (AccountType.value)
                      ? (_openBlock(), _createBlock(_component_el_radio, {
                          key: 1,
                          label: Mobile.value
                        }, null, 8, ["label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        (AccountType.value ? Mobile.value : Email.value)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: _ctx.$t('验证码'),
              class: "VerificationCode",
              prop: "VerificationCode"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: ruleForm.VerificationCode,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ruleForm.VerificationCode) = $event)),
                  placeholder: _ctx.$t('请输入验证码')
                }, null, 8, ["modelValue", "placeholder"]),
                _createVNode(_component_el_button, {
                  loading: CodeLoading.value,
                  onClick: VCode
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(!CodeLoading.value ? _ctx.$t('获取验证码') : _unref(CodeTime).count), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        (active.value == 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_el_form_item, {
                label: _ctx.$t(!AccountType.value ? '邮箱' : '手机号'),
                prop: "VerificationMode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    placeholder: _ctx.$t(AccountType.value ? '手机号' : '请输入邮箱'),
                    modelValue: ruleForm.VerificationMode,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ruleForm.VerificationMode) = $event))
                  }, _createSlots({ _: 2 }, [
                    (AccountType.value)
                      ? {
                          name: "prepend",
                          fn: _withCtx(() => [
                            _createVNode(RegionSelection, { onAreaCode: AreaCode })
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["placeholder", "modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('验证码'),
                class: "VerificationCode",
                prop: "newVerificationCode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: ruleForm.newVerificationCode,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((ruleForm.newVerificationCode) = $event)),
                    placeholder: _ctx.$t('请输入验证码')
                  }, null, 8, ["modelValue", "placeholder"]),
                  _createVNode(_component_el_button, {
                    loading: newCodeLoading.value,
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (newVCode(ruleFormRef.value)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(!newCodeLoading.value ? _ctx.$t('获取验证码') : _unref(newCodeTime).count), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              }, 8, ["label"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              class: "submitBtn",
              size: "large ",
              type: "danger",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (submitForm(ruleFormRef.value))),
              loading: isLoading.value,
              disabled: ShowSubmit.value
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
              ]),
              _: 1
            }, 8, ["loading", "disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]), [
      [_vShow, active.value != 3]
    ]),
    (active.value == 3)
      ? _renderSlot(_ctx.$slots, "default", { key: 2 })
      : _createCommentVNode("", true)
  ]))
}
}

})