import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

import { ref, reactive, onMounted, watch, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'IM',
  setup(__props) {

const store = useUsersStore()
const { Token, getJwtToken } = toRefs(store)

const route = useRoute()
const Router = useRouter()

const handleMessageListener = (event) => {
  switch (event.data.type) {
    case 'login':
      postMessage({
        type: 'login',
        UserID: getJwtToken.value.CustomerCode,
        nick: getJwtToken.value.CustomerName,
        UserSig: getJwtToken.value.Usersig,
        Language: 'CN',
        Sessionkey: Token.value,
      })
      break
    case 'isLogin':
      setTimeout(() => {
        postMessage({
          type: 'to',
          Id: route.query.shopId,
        })
        if (route.query.type == 'Grabgood')
          setTimeout(() => {
            postMessage(route.query)
          }, 1000)
      }, 1000)
      break
  }
}

watch(
  () => Token.value,
  (val) => {
    if (!val) {
      Router.push('/login')
    }
  },
  {
    deep: true,
    immediate: true,
  }
)

const postMessage = (message) => {
  document
    .getElementById('IM')
    .contentWindow.postMessage(message, 'https://im.loda.com.kh/im')
  // document
  //   .getElementById('IM')
  //   .contentWindow.postMessage(message, 'http://localhost:8081/')
}

onMounted(() => {
  window.addEventListener('message', handleMessageListener, false)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("iframe", {
      frameborder: "no",
      id: "IM",
      border: "0",
      marginwidth: "0",
      class: "IM",
      src: "https://im.loda.com.kh/im"
    }, null, -1)
  ])))
}
}

})