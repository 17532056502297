import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/imgs/LODA.png'
import _imports_1 from '../assets/imgs/ios_store.png'
import _imports_2 from '../assets/imgs/google_play.png'
import _imports_3 from '../assets/imgs/huawei.png'


const _hoisted_1 = { class: "loda_container" }
const _hoisted_2 = { class: "body" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { href: "mailto:Service@loda.com.kh" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "Reserved" }
const _hoisted_10 = { class: "ContactHeader" }
const _hoisted_11 = { class: "ContactMobile" }

import { ref, onMounted, toRefs } from 'vue'
import { SiteSet } from '@/utils/siteConfig'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'Bottom',
  setup(__props) {

const store = useUsersStore()
const country = SiteSet().getPlacePredictions
const { ServiceContacts, DefaultContacts } = toRefs(store)

const dialogTableVisible = ref(false)
const CustomerService = () => {
  dialogTableVisible.value = true
}

onMounted(() => {
  store.setState('basic/api/ServiceContacts', {}, 'ServiceContacts')
  store.setState('basic/api/DefaultContacts', {}, 'DefaultContacts')
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_User = _resolveComponent("User")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_PhoneFilled = _resolveComponent("PhoneFilled")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("img", { src: _imports_0 }, null, -1)
              ])),
              _: 1
            })
          ]),
          _cache[2] || (_cache[2] = _createStaticVNode("<li data-v-3aa8d88e><a href=\"https://apps.apple.com/cn/app/id1014885268\" target=\"_balank\" data-v-3aa8d88e><img alt=\"Download LODA iOS APP\" title=\"Download LODA iOS APP\" src=\"" + _imports_1 + "\" data-v-3aa8d88e></a></li><li data-v-3aa8d88e><a href=\"https://play.google.com/store/apps/details?id=com.anhe.loda\" target=\"_balank\" data-v-3aa8d88e><img alt=\"Download LODA Android APP\" title=\"Download LODA Android APP From Google Play\" src=\"" + _imports_2 + "\" data-v-3aa8d88e></a></li><li data-v-3aa8d88e><a alt=\"Download LODA APP\" title=\"Download LODA APP From Huawei\" href=\"https://appgallery.huawei.com/#/app/C102032523\" target=\"_balank\" data-v-3aa8d88e><img src=\"" + _imports_3 + "\" data-v-3aa8d88e></a></li>", 3))
        ], 512), [
          [_vShow, _ctx.$SiteSet['Applink']]
        ]),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t('关于') + _ctx.$SiteSet['LODA']), 1)
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: "/JoinLoda" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('加入') + _ctx.$SiteSet['LODA']), 1)
              ]),
              _: 1
            })
          ]),
          (_ctx.$SiteSet['AboutUs'])
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                _createVNode(_component_router_link, { to: "/AboutUs" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('关于我们')), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: { path: '/article', query: { Id: 1 } } }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('服务协议')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: "/helpCenter" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('帮助中心')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t('服务支持')), 1)
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: "/Guide" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('购物指南')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: "/Guide" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('如何下单')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$t('联系方式')), 1)
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, { to: { path: '/express/siteQuery' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('站点查询')), 1)
                ]),
                _: 1
              })
            ]),
            (_unref(DefaultContacts))
              ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                  _createVNode(_component_el_link, {
                    onClick: CustomerService,
                    title: _ctx.$t('点击查看客服联系方式')
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(DefaultContacts)), 1)
                    ]),
                    _: 1
                  }, 8, ["title"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t('工作时间')), 1)
          ]),
          _createElementVNode("li", null, _toDisplayString(_ctx.$t('周一至周五')) + ": " + _toDisplayString(_ctx.$t(_ctx.$SiteSet['WorkingHours1_5'])), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.$t('周六至周日')) + ": " + _toDisplayString(_ctx.$t(_ctx.$SiteSet['WorkingHours6_7'])), 1),
          (_ctx.$SiteSet['Mailbox'])
            ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.$t('邮箱')) + ":  ", 1),
                _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$SiteSet['Mailbox']), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        (_unref(country) != 'pe')
          ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
              _cache[3] || (_cache[3] = _createElementVNode("li", null, [
                _createElementVNode("h1", null, "We accept:")
              ], -1)),
              _createElementVNode("li", null, [
                _createElementVNode("img", {
                  src: _unref(country) == 'kh' ? require('../assets/imgs/accept.jpg') : require('../assets/imgs/Fas.png')
                }, null, 8, _hoisted_8)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", _hoisted_9, "© " + _toDisplayString(_ctx.$SiteSet['LODA']) + ". All Rights Reserved", 1)
    ]),
    _createVNode(_component_el_dialog, {
      width: "600px",
      modelValue: dialogTableVisible.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dialogTableVisible).value = $event)),
      title: _ctx.$t('选择部门')
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ServiceContacts)?.Contacts, (Contact, index) => {
          return (_openBlock(), _createBlock(_component_el_card, {
            key: index,
            class: "ContactCard"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", null, [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_User)
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(Contact.GroupName), 1)
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Contact.Mobiles, (Mobile, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_PhoneFilled)
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" " + _toDisplayString(Mobile), 1)
                  ]))
                }), 128))
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}
}

})