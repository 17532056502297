import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/imgs/lodalogo.png'
import _imports_1 from '../assets/imgs/taobao.png'
import _imports_2 from '../assets/imgs/pddico.png'
import _imports_3 from '../assets/imgs/1688.png'


const _hoisted_1 = { class: "Loda_Home" }
const _hoisted_2 = { class: "head" }
const _hoisted_3 = { class: "Categories" }
const _hoisted_4 = { class: "BannerList" }
const _hoisted_5 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_6 = { class: "rt" }
const _hoisted_7 = { class: "modular" }
const _hoisted_8 = {
  key: 0,
  class: "isLogin"
}
const _hoisted_9 = {
  key: 1,
  class: "isUser"
}
const _hoisted_10 = {
  key: 2,
  class: "plates"
}
const _hoisted_11 = { class: "Notices" }
const _hoisted_12 = { class: "title" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "RecommendModular"
}
const _hoisted_16 = { class: "card-header" }
const _hoisted_17 = ["onMouseenter"]
const _hoisted_18 = { class: "ActivityProducts" }
const _hoisted_19 = { class: "ActivityProduct" }
const _hoisted_20 = { class: "Products" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["title"]
const _hoisted_23 = { class: "card-header" }
const _hoisted_24 = { class: "ActivityShops" }
const _hoisted_25 = ["title"]
const _hoisted_26 = { class: "AllActivityShop" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = ["title"]
const _hoisted_29 = { style: {"display":"flex","align-items":"center","height":"60px","padding":"0 0 0 20px"} }
const _hoisted_30 = { style: {"display":"flex","justify-content":"space-evenly"} }
const _hoisted_31 = {
  key: 2,
  class: "Activities"
}
const _hoisted_32 = { class: "ModuleTitle" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = ["onClick"]
const _hoisted_35 = {
  key: 0,
  class: "ActivitieType1"
}
const _hoisted_36 = { class: "ModuleTitle" }
const _hoisted_37 = ["onClick"]
const _hoisted_38 = ["onClick"]
const _hoisted_39 = { class: "ActivitieType3" }
const _hoisted_40 = {
  key: 0,
  class: "VerticalCard"
}
const _hoisted_41 = { class: "ModuleTitle" }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = ["onClick"]
const _hoisted_44 = { class: "ActivitieType5" }
const _hoisted_45 = {
  key: 0,
  class: "VerticalCard"
}
const _hoisted_46 = { class: "ModuleTitle" }
const _hoisted_47 = ["onClick"]
const _hoisted_48 = { class: "ActivitieType4" }
const _hoisted_49 = { class: "BuyingsTitle" }
const _hoisted_50 = { class: "Buyings" }
const _hoisted_51 = {
  key: 0,
  class: "BuyingsCard"
}
const _hoisted_52 = {
  key: 1,
  class: "BuyingsCard"
}
const _hoisted_53 = {
  key: 3,
  class: "MoreBuyings"
}

import { ref, onMounted, watch, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import * as Api from '@/utils/api'
// import { BannerPush } from '@/utils/bannerClick'
import Categories from '@/components/Categories.vue'
import CommodityCard from '@/components/CommodityCard.vue'
import EventMerchandise from '@/components/EventMerchandise.vue'
import TimeDifference from '@/components/TimeDifference.vue'
import { useI18n } from 'vue-i18n'
import { useUsersStore } from '@/pinia'
import { SiteSet } from '@/utils/siteConfig'


export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  setup(__props) {

const Router = useRouter()
const BannerList = ref([])
const ActivityList = ref([])
const Notices = ref([])
const { t } = useI18n()
const store = useUsersStore()
const { getJwtToken, Token } = toRefs(store)

const isToken = ref(false)
const Rate: any = ref(null)
const localLan = localStorage.getItem('lang')
const country = SiteSet().getPlacePredictions
const hightSet = (localLan == 'zh-cn' && country != 'kh')
watch(
  () => Token.value,
  (val) => {
    isToken.value = Boolean(val)
    if (val) {
      // Api.Get('basic/api/CustomerExchangeRate/', {}).then((res:any) => {
      //   Rate.value = res.Rate
      // })
      if (localStorage.getItem('Rate')) {
        const localRate: any = localStorage.getItem('Rate')
        Rate.value = JSON.parse(localRate)
      }
    }
  },
  {
    immediate: true,
    deep: true,
  }
)

const PlateTypeKey = (plate) => {
  let url
  switch (plate.PlateTypeId) {
    case 2:
      url = '/mallHome?ShopId=' + plate.PlateTypeKey
      break
    case 3:
      url = plate.PlateTypeKey
      break
    case 4:
      url = '/activity?id=' + plate.PlateTypeKey
      break
  }
  return url
}

const platePush = (i) => {
  switch (i.PlateTypeId) {
    case 2:
      Router.push({ path: '/mallHome', query: { ShopId: i.PlateTypeKey } })
      break
    case 3:
      Router.push({ path: i.PlateTypeKey })
      break
    case 4:
      const href = Router.resolve({
        path: '/activity',
        query: { id: i.PlateTypeKey },
      })
      window.open(href.href, '_blank')
      break
  }
}

const HrefType = (item) => {
  let href = null
  switch (item.HrefTypeId) {
    case 1:
      const Href = item.Href.split('|')
      href = Router.resolve({
        path: '/productDetails',
        query: { outId: Href[0], mallId: Href[1] },
      })
      break
    case 2:
      href = Router.resolve({
        path: '/searchList',
        query: { type: 0, CategoryId: item.Href },
      })
      break
    case 3:
      let Type = item.Href.split('|')
      if (Type[1] == 'mall') {
        href = Router.resolve({
          path: '/mallHome',
          query: { ShopId: Type[0] },
        })
      }
      break
    case 4:
      href = Router.resolve({
        path: '/activity',
        query: { id: item.Href },
      })
      break
    case 6:
      href = Router.resolve({
        path: '/notice',
        query: { id: item.Href },
      })
      break
    default:
      break
  }
  if (href) {
    window.open(href.href, '_blank')
  } else if (item.Href && item.Href != '') {
    window.open(item.Href, '_blank')
  }
}

const BannerPush = (item) => {
  let ActivityId
  if (item.Id) {
    ActivityId = item.Id
  } else if (item.HrefTypeId == 4) {
    ActivityId = item.Href
  }
  if (ActivityId) {
    const href = Router.resolve({
      path: '/activity',
      query: { id: ActivityId }
    })
    window.open(href.href, '_blank')
  }
}

const ShopsPush = (item) => {
  const href = Router.resolve({
    path: '/mallHome',
    query: { ShopId: item.ShopId },
  })
  window.open(href.href, '_blank')
}

const ProductsPush = (item) => {
  const href = Router.resolve({
    path: '/productDetails',
    query: { outId: item.OutId || item.SellerPId, mallId: item.MallId },
  })
  window.open(href.href, '_blank')
}

const Buyings = ref([])
const BuyingsIndex = ref(0)
const MoreBuyings = ref(true)

const Buying = () => {
  MoreBuyings.value = false
  Api.Get('grabgoods/api/EveryoneBuying', {
    pageIndex: BuyingsIndex.value,
    pageSize: 48,
  }).then((res: any) => {
    MoreBuyings.value = res.Items?.length < 48 ? false : true
    Buyings.value = Buyings.value.concat(res.Items)
  })
}

const Activities: any = ref(null)
const Plates = ref(null)

const ActivityShops = ref([])
const ActivityProducts = ref(null)
const ActivityIndex = ref(0)
const ModuleId = ref(null)
const getProductList=() => {
  Activities.value.forEach((ele:any) => {
    ele.Children.forEach((item:any) => {
      Api.Post('sellers/api/SellerProdusts/', {
        Page: 1,
        PageSize: item.HomeDefaultNum,
        ModuleId: item.Id,
      }).then((res: any) => {
        item.Products=res.Goods
      })
    });
  });

}

onMounted(() => {
  if (country === 'kh') {
    Activities.value = []
    Api.Get('activity/api/MarketingActivities', {
      activityTypeId: 3,
      websiteType: 1,
      isHomeDisplay: true
    }).then((res: any) => {
      res.MarketingActivities.forEach((item:any) => {
        if(item.AssociationTypeId==1&&item.StyleTypeId==4) {
          // 商品推荐
          ActivityProducts.value=item
        }
        if(item.AssociationTypeId==2&&item.StyleTypeId==4) {
          // 店铺推荐
          ActivityShops.value = item.ActivitySellers
        }
        if(item.AssociationTypeId==1&&item.StyleTypeId !=4) {
        // 活动商品推荐
          Activities.value.push(item)
          // getProductList()
        }
      });
    })
  }



  Api.Get('basic/api/AppPlates/', {
    apptypeid: 10,
  }).then((res: any) => {
    Plates.value = res.Plates
  })

  Api.Get('basic/api/banner', {
    BannerType: 1,
  }).then((res: any) => {
    BannerList.value = res.BannerList
  })

  // 活动专题
  Api.Get('activity/api/MarketingActivities', {
    activityTypeId: 1,
    websiteType: 1,
    isHomeDisplay: true
  }).then((res: any) => {
    ActivityList.value = res.MarketingActivities
  })
  Api.Get('basic/api/Notice/', {
  }).then((res: any) => {
    const newNotices = []
    let NoticesSlice = 4 //二维数组切割数量
    for (let i = 0; i < res.Notices?.length / NoticesSlice; i++) {
      newNotices.push(
        res.Notices.slice(i * NoticesSlice, i * NoticesSlice + NoticesSlice)
      )
    }
    Notices.value = newNotices
  })



  Buying()
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UserFilled = _resolveComponent("UserFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(Categories)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (BannerList.value?.length > 0)
          ? (_openBlock(), _createBlock(_component_el_carousel, {
              key: 0,
              interval: 4000,
              arrow: "never",
              height: "300px"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(BannerList.value, (Banner) => {
                  return (_openBlock(), _createBlock(_component_el_carousel_item, {
                    key: Banner,
                    onClick: ($event: any) => (HrefType(Banner))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_suffix_img, {
                        url: Banner.BannerUrl,
                        type: 0
                      }, null, 8, ["url"])
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_el_skeleton, {
              key: 1,
              animated: ""
            }, {
              template: _withCtx(() => [
                _createVNode(_component_el_skeleton_item, {
                  variant: "image",
                  style: {"width":"100%","height":"300px"}
                })
              ]),
              _: 1
            })),
        (ActivityList.value?.length > 0)
          ? (_openBlock(), _createBlock(_component_el_carousel, {
              key: 2,
              interval: 1500,
              arrow: "never",
              height: "150px",
              type: "card"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ActivityList.value, (Activity) => {
                  return (_openBlock(), _createBlock(_component_el_carousel_item, {
                    key: Activity,
                    onClick: ($event: any) => (BannerPush(Activity))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_suffix_img, {
                        url: Activity.ImgUrl,
                        type: 0
                      }, null, 8, ["url"])
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_el_skeleton, {
              key: 3,
              animated: ""
            }, {
              template: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_el_skeleton_item, {
                    variant: "image",
                    style: {"width":"33%","height":"150px"}
                  }),
                  _createVNode(_component_el_skeleton_item, {
                    variant: "image",
                    style: {"width":"33%","height":"150px"}
                  }),
                  _createVNode(_component_el_skeleton_item, {
                    variant: "image",
                    style: {"width":"33%","height":"150px"}
                  })
                ])
              ]),
              _: 1
            }))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (!isToken.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _cache[4] || (_cache[4] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
                _createElementVNode("div", null, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$SiteSet?.Welcome || 'Welcome to Loda'), 1),
                  _createVNode(_component_router_link, { to: "/login" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('登录')), 1)
                    ]),
                    _: 1
                  }),
                  _cache[3] || (_cache[3] = _createTextVNode("| ")),
                  _createVNode(_component_router_link, { to: "/register" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('注册')), 1)
                    ]),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (isToken.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_router_link, { to: "/userCenter" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_UserFilled)
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" Hi~: " + _toDisplayString(_unref(getJwtToken).CustomerName), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("p", null, _toDisplayString(_ctx.$SiteSet?.Welcome || 'Welcome to Loda'), 1)
              ]))
            : _createCommentVNode("", true),
          (Plates.value?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Plates.value, (plate) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    class: "plate",
                    key: plate.Index,
                    to: PlateTypeKey(plate),
                    title: plate.Name
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_suffix_img, {
                        url: plate.Icon
                      }, null, 8, ["url"]),
                      _createElementVNode("span", null, _toDisplayString(plate.Name), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "title"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(6 % Plates.value?.length, (placeholder) => {
                  return (_openBlock(), _createElementBlock("span", {
                    class: "placeholderPlates",
                    key: placeholder
                  }))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"platform\"><a href=\"https://www.taobao.com/\" target=\"_blank\"><img src=\"" + _imports_1 + "\"></a><a href=\"http://mobile.yangkeduo.com/\" target=\"_blank\"><img src=\"" + _imports_2 + "\"></a><a href=\"https://www.1688.com/\" target=\"_blank\"><img src=\"" + _imports_3 + "\"></a></div>", 1))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('公告')), 1),
          (!Notices.value?.length)
            ? (_openBlock(), _createBlock(_component_el_skeleton, {
                key: 0,
                rows: 5,
                animated: ""
              }))
            : _createCommentVNode("", true),
          (Notices.value?.length > 0)
            ? (_openBlock(), _createBlock(_component_el_carousel, {
                key: 1,
                height: "140px",
                direction: "vertical",
                interval: 3000,
                autoplay: false
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Notices.value, (NoticeArray) => {
                    return (_openBlock(), _createBlock(_component_el_carousel_item, { key: NoticeArray }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(NoticeArray, (Notice) => {
                          return (_openBlock(), _createElementBlock("div", {
                            onClick: ($event: any) => (_ctx.NoticePush(Notice)),
                            key: Notice
                          }, [
                            _createVNode(_component_router_link, {
                              to: {
                  path: '/notice',
                  query: { id: Notice.Id },
                },
                              target: "_blank",
                              title: Notice.Title
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  class: "NoticeTitle",
                                  innerHTML: Notice.Title
                                }, null, 8, _hoisted_14)
                              ]),
                              _: 2
                            }, 1032, ["to", "title"])
                          ], 8, _hoisted_13))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.$SiteSet?.RecommendModular && ActivityProducts.value?.Title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode(_component_el_card, { class: "RecommendCard" }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("span", null, _toDisplayString(ActivityProducts.value?.Title), 1),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ActivityProducts.value?.Children, (Activitys, index) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: index,
                      class: _normalizeClass([{ isActivity: ActivityIndex.value == index }]),
                      onMouseenter: ($event: any) => (ActivityIndex.value = index)
                    }, _toDisplayString(Activitys.Title), 43, _hoisted_17))
                  }), 128))
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_18, [
                _withDirectives(_createElementVNode("div", _hoisted_19, [
                  (ActivityProducts.value?.Children[ActivityIndex.value]?.GoodsList?.length>0)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (ProductsPush(ActivityProducts.value?.Children[ActivityIndex.value]?.GoodsList[0])))
                      }, [
                        _createVNode(_component_suffix_img, {
                          url: ActivityProducts.value?.Children[ActivityIndex.value]?.GoodsList[0]?.ImgUrl,
                          alt: ActivityProducts.value?.Children[ActivityIndex.value]?.GoodsList[0]?.GoodsName,
                          title: ActivityProducts.value?.Children[ActivityIndex.value]?.GoodsList[0]?.GoodsName
                        }, null, 8, ["url", "alt", "title"]),
                        _createElementVNode("p", null, _toDisplayString(ActivityProducts.value?.Children[ActivityIndex.value]?.GoodsList[0]?.GoodsName), 1),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$ccy(ActivityProducts.value?.Children[ActivityIndex.value]?.GoodsList[0]?.Price)), 1)
                      ]))
                    : _createCommentVNode("", true)
                ], 512), [
                  [_vShow, ActivityProducts.value?.Children[ActivityIndex.value]?.GoodsList?.length>0]
                ]),
                _createElementVNode("div", _hoisted_20, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ActivityProducts.value?.Children[
              ActivityIndex.value
            ].GoodsList, (Products, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (index > 0 && index < 5)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "ActivityProduct",
                            onClick: ($event: any) => (ProductsPush(Products))
                          }, [
                            _createVNode(_component_suffix_img, {
                              url: Products.ImgUrl,
                              type: 0,
                              alt: Products.GoodsName,
                              title: Products.GoodsName
                            }, null, 8, ["url", "alt", "title"]),
                            _createElementVNode("p", {
                              title: Products.GoodsName
                            }, _toDisplayString(Products.GoodsName), 9, _hoisted_22),
                            _createElementVNode("p", null, _toDisplayString(_ctx.$ccy(Products.Price)), 1)
                          ], 8, _hoisted_21))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ])
              ])
            ]),
            _: 1
          }),
          (ActivityShops.value?.length)
            ? (_openBlock(), _createBlock(_component_el_card, {
                key: 0,
                class: "RecommendCard"
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('店铺推荐')), 1)
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", {
                      class: "ActivityShop",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (ShopsPush(ActivityShops.value[0])))
                    }, [
                      _createVNode(_component_suffix_img, {
                        url: ActivityShops.value[0].ShopLogo,
                        type: 0,
                        alt: ActivityShops.value[0].ShopName,
                        title: ActivityShops.value[0].ShopName
                      }, null, 8, ["url", "alt", "title"]),
                      _createElementVNode("p", {
                        title: ActivityShops.value[0].ShopName
                      }, _toDisplayString(ActivityShops.value[0].ShopName), 9, _hoisted_25)
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ActivityShops.value, (Shops, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          (index > 0 && index < 7)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "isActivityShop",
                                onClick: ($event: any) => (ShopsPush(Shops))
                              }, [
                                _createVNode(_component_suffix_img, {
                                  url: Shops.ShopLogo,
                                  type: 0,
                                  alt: Shops.ShopName,
                                  title: Shops.ShopName
                                }, null, 8, ["url", "alt", "title"]),
                                _createElementVNode("p", {
                                  title: Shops.ShopName
                                }, _toDisplayString(Shops.ShopName), 9, _hoisted_28)
                              ], 8, _hoisted_27))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!Activities.value && _unref(country) === 'kh')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(4, (i) => {
          return _createVNode(_component_el_skeleton, {
            animated: "",
            key: i
          }, {
            template: _withCtx(() => [
              _createElementVNode("div", _hoisted_29, [
                _createVNode(_component_el_skeleton_item, {
                  variant: "circle",
                  style: {"width":"30px","height":"30px","margin":"0 20px 0 0"}
                }),
                _createVNode(_component_el_skeleton_item, {
                  variant: "text",
                  style: {"width":"80px","height":"30px"}
                })
              ]),
              _createElementVNode("div", _hoisted_30, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
                  return _createVNode(_component_el_skeleton_item, {
                    key: i,
                    variant: "image",
                    style: {"width":"220px","height":"330px"}
                  })
                }), 64))
              ])
            ]),
            _: 2
          }, 1024)
        }), 64))
      : _createCommentVNode("", true),
    (_ctx.$SiteSet['Activities'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Activities.value, (Activitie) => {
            return (_openBlock(), _createElementBlock("div", {
              key: Activitie.Id
            }, [
              (Activitie.StyleTypeId == 1 ||
          Activitie.StyleTypeId == 2
          )
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(Activitie.Children, (Module) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: Module.Sort
                    }, [
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("label", null, [
                          _createVNode(_component_suffix_img, {
                            url: Activitie.Icon || Module.Icon,
                            type: 0
                          }, null, 8, ["url"]),
                          _createElementVNode("span", {
                            onClick: ($event: any) => (BannerPush(Activitie))
                          }, _toDisplayString(Module.Title), 9, _hoisted_33)
                        ]),
                        _createElementVNode("span", {
                          onClick: ($event: any) => (BannerPush(Activitie))
                        }, _toDisplayString(_ctx.$t('更多')), 9, _hoisted_34)
                      ]),
                      (Module.GoodsList)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Module.GoodsList, (Goods, index) => {
                              return (_openBlock(), _createBlock(CommodityCard, {
                                key: Goods,
                                data: Goods,
                                index: index,
                                class: "CCard"
                              }, null, 8, ["data", "index"]))
                            }), 128)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(6 - Module.GoodsList?.length, (placeholder) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "placeholderCard",
                                key: placeholder
                              }))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                : _createCommentVNode("", true),
              (Activitie.StyleTypeId == 3)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("label", null, [
                        _createVNode(_component_suffix_img, {
                          url: Activitie.Icon,
                          type: 0
                        }, null, 8, ["url"]),
                        _createElementVNode("span", {
                          onClick: ($event: any) => (BannerPush(Activitie))
                        }, _toDisplayString(Activitie.Title), 9, _hoisted_37)
                      ]),
                      _createElementVNode("span", {
                        onClick: ($event: any) => (BannerPush(Activitie))
                      }, _toDisplayString(_ctx.$t('更多')), 9, _hoisted_38)
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Activitie.Children, (Module) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: Module.Sort
                        }, [
                          _createVNode(_component_suffix_img, {
                            onClick: ($event: any) => (BannerPush(Activitie)),
                            class: "MainDiagram",
                            url: Module.ImgUrl,
                            type: 0
                          }, null, 8, ["onClick", "url"]),
                          (Module.GoodsList)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Module.GoodsList, (Goods, index) => {
                                  return (_openBlock(), _createBlock(EventMerchandise, {
                                    key: Goods,
                                    data: Goods,
                                    index: index
                                  }, null, 8, ["data", "index"]))
                                }), 128)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(4 - Module?.GoodsList.length, (placeholder) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    style: {"width":"230px","height":"1px"},
                                    key: placeholder
                                  }))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              (Activitie.StyleTypeId == 5)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createElementVNode("div", _hoisted_41, [
                      _createElementVNode("label", null, [
                        _createVNode(_component_suffix_img, {
                          url: Activitie.Icon,
                          type: 0
                        }, null, 8, ["url"]),
                        _createElementVNode("span", {
                          onClick: ($event: any) => (BannerPush(Activitie))
                        }, _toDisplayString(Activitie.Title), 9, _hoisted_42)
                      ]),
                      _createElementVNode("span", {
                        onClick: ($event: any) => (BannerPush(Activitie))
                      }, _toDisplayString(_ctx.$t('更多')), 9, _hoisted_43)
                    ]),
                    _createElementVNode("div", _hoisted_44, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Activitie.Children, (Module) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: Module.Sort
                        }, [
                          _createVNode(_component_suffix_img, {
                            onClick: ($event: any) => (BannerPush(Activitie)),
                            class: "MainDiagram",
                            url: Module.ImgUrl,
                            type: 0
                          }, null, 8, ["onClick", "url"]),
                          (Module.GoodsList)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Module.GoodsList, (Goods, index) => {
                                  return (_openBlock(), _createBlock(EventMerchandise, {
                                    key: Goods,
                                    data: Goods,
                                    index: index
                                  }, null, 8, ["data", "index"]))
                                }), 128)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(10 - Module?.GoodsList.length, (placeholder) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    style: {"width":"230px","height":"1px"},
                                    key: placeholder
                                  }))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              (Activitie.StyleTypeId == 4)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("label", null, [
                        _createElementVNode("span", null, _toDisplayString(Activitie.Title), 1)
                      ]),
                      _createElementVNode("span", {
                        onClick: ($event: any) => (BannerPush(Activitie))
                      }, _toDisplayString(_ctx.$t('更多')), 9, _hoisted_47)
                    ]),
                    _createElementVNode("div", _hoisted_48, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Activitie.Children, (Module) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: Module.Sort
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Module.GoodsList, (Goods, index) => {
                            return (_openBlock(), _createBlock(CommodityCard, {
                              key: Goods,
                              data: Goods,
                              index: index,
                              class: "CCard"
                            }, null, 8, ["data", "index"]))
                          }), 128)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(12 - Module.GoodsList?.length, (placeholder) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "placeholderCard",
                              key: placeholder
                            }))
                          }), 128))
                        ], 64))
                      }), 128))
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h1", _hoisted_49, "- " + _toDisplayString(_ctx.$t('大家都在买')) + " -", 1),
    _createElementVNode("div", _hoisted_50, [
      (_unref(country) != 'kh')
        ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Buyings.value, (Goods, index) => {
              return (_openBlock(), _createBlock(CommodityCard, {
                key: Goods,
                data: Goods,
                height: _unref(hightSet) == false ? 290 : 330,
                index: index,
                class: "CCard"
              }, null, 8, ["data", "height", "index"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_52, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Buyings.value, (Goods, index) => {
              return (_openBlock(), _createBlock(CommodityCard, {
                key: Goods,
                data: Goods,
                index: index,
                class: "CCard"
              }, null, 8, ["data", "index"]))
            }), 128)),
            (Buyings.value?.length % 6)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(6 - (Buyings.value?.length % 6), (placeholder) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "placeholderCard",
                    key: placeholder
                  }))
                }), 128))
              : _createCommentVNode("", true)
          ]))
    ]),
    (MoreBuyings.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (BuyingsIndex.value++, Buying()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('更多')) + " ... ", 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})