import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Transition as _Transition, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"position":"relative"} }
const _hoisted_2 = { class: "EventMerchandise" }
const _hoisted_3 = { class: "Name" }
const _hoisted_4 = { class: "Price" }

import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import MallLabel from '@/components/mall_label.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'EventMerchandise',
  props: {
  data: Object,
  index: Number,
},
  setup(__props) {

const userouter = useRouter()

const show = ref(false)

const props = __props

// const productDetails = () => {
//   const i = props.data
//   const outId = i.OutId || i.SellerPId
//   const href = userouter.resolve({
//     path: '/productDetails',
//     query: { outId, mallId: i.MallId },
//   })
//   window.open(href.href, '_blank')
// }

onMounted(() => {
  setTimeout(() => {
    show.value = true
  }, 100 * props.index)
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(MallLabel, null, null, 512), [
      [_vShow, props.data.MallId == 111036]
    ]),
    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
      default: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: {
        path: '/productDetails',
        query: {
          outId: props.data.OutId || props.data.SellerPId,
          mallId: props.data.MallId,
        },
      },
          target: "_blank",
          title: props.data.GoodsName || props.data.ProductName
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(props.data.GoodsName || props.data.ProductName), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(props.data.Price)), 1)
              ]),
              _createVNode(_component_suffix_img, {
                url: props.data.ImgUrl || props.data.ProductImgUrl,
                type: 5
              }, null, 8, ["url"])
            ], 512), [
              [_vShow, show.value]
            ])
          ]),
          _: 1
        }, 8, ["to", "title"])
      ]),
      _: 1
    })
  ]))
}
}

})