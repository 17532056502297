import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

import { reactive, ref, onMounted, watch, toRefs } from 'vue'
import * as Api from '@/utils/api'
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import { useI18n } from 'vue-i18n'
import { getSupplementaryAmount } from '@/utils/calculation'
import Password from '@/components/password.vue'

import { useUsersStore } from '@/pinia'
import moment from 'moment'

export default /*@__PURE__*/_defineComponent({
  __name: 'pay',
  props: {
  Date: Object,
  ShowPay: Boolean,
  SourceId: Number,
  Ids: Array,
  PayType: String,
},
  emits: ['PayChange'],
  setup(__props, { emit: __emit }) {

const store = useUsersStore()
const { getJwtToken } = toRefs(store)

const { t } = useI18n()
const PayRef = ref<FormInstance>()

const PayForm = reactive({
  customer_order_no: null,
  pay_password: null,
  couponIndex: null,
})

const emit = __emit
const DonePay = () => {
  emit('PayChange')
}
const payRules = reactive<FormRules>({
  pay_password: [{ required: true, message: t('请输入'), trigger: 'blur' }],
})

const PayLoad = ref(false)
const SubmitPay = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      PayLoad.value = true
      Api.Get('user/api/CustomerPwdIsLock/', { TypeId: 1 }).then(
        (res: any) => {
          PayLoad.value = false
          if (!res.IsError) {
            if (res.IsLock) {
              ElMessageBox.alert(
                t(`交易密码已锁定`, {
                  name: moment(res.LockTime).format('YYYY-MM-DD HH:mm:ss'),
                }),
                '',
                {
                  confirmButtonText: 'OK',
                  showClose: false,
                }
              )
            } else {
              if (props.PayType == 'OrderNos') {
                PayLoad.value = true
                Api.Post('bank/api/PaymentList/', {
                  customer_order_no: props.Date.customer_order_no,
                  pay_password: PayForm.pay_password,
                })
                  .then((res: any) => {
                    PayLoad.value = false
                    if (!res.IsError) {
                      success()
                    }
                  })
                  .catch(() => {
                    PayLoad.value = false
                  })
              }
              if (PayForm.customer_order_no) {
                PayLoad.value = true
                Api.Post('bank/api/Payment/', {
                  customer_order_no: PayForm.customer_order_no,
                  pay_password: PayForm.pay_password,
                  coupon_code:
                    PayForm.couponIndex != null
                      ? Coupons.value[PayForm.couponIndex].SerialNumber
                      : null,
                  coupon_security_code:
                    PayForm.couponIndex != null
                      ? Coupons.value[PayForm.couponIndex].SecurityCode
                      : null,
                })
                  .then((res: any) => {
                    PayLoad.value = false
                    if (!res.IsError) {
                      success()
                    }
                  })
                  .catch(() => {
                    PayLoad.value = false
                  })
              }
              if (props.PayType == 'Delivery') {
                PayLoad.value = true
                Api.Post('orders/api/DeliveryServerFreight/', {
                  WarehouseId: '6',
                  PayPwd: PayForm.pay_password,
                  DeliveryIds: props.Ids,
                })
                  .then((res: any) => {
                    PayLoad.value = false
                    if (!res.IsError) {
                      success()
                    }
                  })
                  .catch(() => {
                    PayLoad.value = false
                  })
              }
              if (props.PayType == 'Box') {
                PayLoad.value = true
                Api.Post('orders/api/TransportBox/', {
                  WarehouseId: '6',
                  CustomerCode: getJwtToken.value.CustomerCode,
                  PayPwd: PayForm.pay_password,
                  BoxIds: props.Ids,
                  CouponNo:
                    PayForm.couponIndex != null
                      ? Coupons.value[PayForm.couponIndex].SerialNumber
                      : null,
                })
                  .then((res: any) => {
                    PayLoad.value = false
                    if (!res.IsError) {
                      success()
                    }
                  })
                  .catch(() => {
                    PayLoad.value = false
                  })
              }
            }
          }
        },
        () => {}
      )
    }
  })
}

const success = () => {
  store.PaymentStatus = !store.PaymentStatus
  ElMessageBox.alert(t('支付成功'), '', {
    confirmButtonText: 'OK',
    showClose: false,
    closeOnPressEscape: false,
    callback: () => {
      emit('PayChange')
    },
  })
}

const props = __props

watch(
  () => props.ShowPay,
  (val) => {
    if (val) {
      PayForm.couponIndex = null
      if (props.Date.OrderNo) {
        PayForm.customer_order_no = props.Date.OrderNo
      }
      if (props.SourceId != 40) {
        Api.Get('bank/api/Coupons/', {
          use_coupon_type_id: props.Date.OrderNo ? 1 : 2,
        }).then((res: any) => {
          Coupons.value = res.Coupons
        })
      }
      Api.Get('bank/api/CustomerBalance/', {
        CustomerId: 0,
      }).then((res: any) => {
        BalanceItem.value =
          res.BalanceItems.length > 0 ? res.BalanceItems[0] : null
      })
    }
  },
  {
    immediate: true,
    deep: true,
  }
)

const BalanceItem = ref(null)
const Coupons = ref(null)
onMounted(() => {})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: props.ShowPay,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((props.ShowPay) = $event)),
    title: _ctx.$t('支付订单'),
    width: "400px",
    "before-close": DonePay,
    center: true
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, {
          onClick: DonePay,
          size: "large"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('关闭')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          size: "large",
          loading: PayLoad.value,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (SubmitPay(PayRef.value)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('支付')), 1)
          ]),
          _: 1
        }, 8, ["loading"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref_key: "PayRef",
        ref: PayRef,
        model: PayForm,
        rules: payRules,
        "label-position": "top",
        class: "PackageForm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('账户余额')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(BalanceItem.value
            ? BalanceItem.value.AvailableAmount + ' ' + BalanceItem.value.CurrencyCode
            : 0 + ' ' + _ctx.$SiteSet['CurrencyCode']) + " ", 1),
              _createVNode(_component_router_link, { to: { path: '/userCenter/wallet' } }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    class: "RechargeBtn",
                    type: "danger",
                    onClick: DonePay
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('充值')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('应付金额')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.Date.StatusId == 4
            ? _unref(getSupplementaryAmount)(props.Date)
            : _ctx.$ccy(props.Date.TotalAmount || props.Date.TotalPayment)), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('交易密码'),
            prop: "pay_password"
          }, {
            default: _withCtx(() => [
              _createVNode(Password, {
                modelValue: PayForm.pay_password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((PayForm.pay_password) = $event)),
                isShowSet: true
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _withDirectives(_createVNode(_component_el_form_item, {
            label: _ctx.$t('优惠劵')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: PayForm.couponIndex,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((PayForm.couponIndex) = $event)),
                size: "large",
                clearable: "",
                placeholder: _ctx.$t('请选择')
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Coupons.value, (Coupon, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      label: Coupon.CouponConsumeRange + _ctx.$ccy(Coupon.DiscountAmount),
                      value: index
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]), [
            [_vShow, props.SourceId != 40]
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})