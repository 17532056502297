import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "helpContent" }
const _hoisted_3 = ["innerHTML"]

import { ref, reactive, onMounted } from 'vue'
import * as Api from '@/utils/api'
import Loading from '@/components/loading.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'help-center',
  setup(__props) {

const handleOpen = (html) => {
  Content.value = html.Content
  title.value = html.Title
}

const Content = ref(null)
const title = ref(null)

const ArticleCatalogues = ref(null)
onMounted(() => {
  Api.Get('article/api/GetArticles/', {
    CatalogueId: 'CAFAADCC-763C-4D82-93F7-2BA33EC9054A',
  }).then((res: any) => {
    ArticleCatalogues.value = res.ArticleCatalogues
    handleOpen(res.ArticleCatalogues[0].Articles[0])
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
      default: _withCtx(() => [
        (ArticleCatalogues.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_el_menu, {
                class: "CenterMenu",
                "default-active": "0-0",
                "default-openeds": [0],
                "unique-opened": true
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ArticleCatalogues.value, (Catalogue, index1) => {
                    return (_openBlock(), _createBlock(_component_el_sub_menu, {
                      key: Catalogue.CatalogueId,
                      index: index1
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(Catalogue.CatalogueName), 1)
                      ]),
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Catalogue.Articles, (Article, index2) => {
                          return (_openBlock(), _createBlock(_component_el_menu_item_group, { key: index2 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_menu_item, {
                                onClick: ($event: any) => (handleOpen(Article)),
                                index: index1 + '-' + index2
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(Article.Title), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick", "index"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["index"]))
                  }), 128))
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h5", null, _toDisplayString(title.value), 1),
                _createElementVNode("div", { innerHTML: Content.value }, null, 8, _hoisted_3)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!ArticleCatalogues.value)
      ? (_openBlock(), _createBlock(Loading, {
          key: 0,
          type: 10
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})