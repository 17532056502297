import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"
import _imports_0 from '../assets/imgs/google.png'
import _imports_1 from '../assets/imgs/facebook.png'
import _imports_2 from '../assets/imgs/weixin.png'


const _hoisted_1 = { class: "mainBody" }
const _hoisted_2 = { class: "loginBody" }
const _hoisted_3 = { class: "head" }
const _hoisted_4 = { class: "LoginTips" }
const _hoisted_5 = { class: "remember" }
const _hoisted_6 = { class: "buttons" }
const _hoisted_7 = { class: "register" }
const _hoisted_8 = {
  key: 2,
  class: "ExternalLogin"
}
const _hoisted_9 = { class: "JointLoginBody" }

import { ref, reactive, watch } from 'vue'
import { onMounted } from '@vue/runtime-core'
import type { FormInstance } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import * as Api from '@/utils/api'
import RegionSelection from '@/components/RegionSelection.vue'
import { useI18n } from 'vue-i18n'
import countDown from '@/utils/countDown'
import { useRouter, useRoute } from 'vue-router'
import { googleTokenLogin } from 'vue3-google-login'
import { fbJsdk } from '@/utils/fb'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const store = useUsersStore()

const Router = useRouter()
const route = useRoute()
const { t } = useI18n()

const userAreaCode = ref(null)
const AreaCode = (val) => {
  userAreaCode.value = val
}

const CodeLoading = ref(false)
const loginLoading = ref(false)
const dialogVisible = ref(false)
const enableFacebookLogin = ref(false)
const enableGoogleLogin = ref(true)
const enablePhoneLogin = ref(false)
const enableWechatLogin = ref(true)

const { state: CodeTime, start: startTimeout, check_count: CCD } = countDown()

const ruleFormRef = ref<FormInstance>()
const MobileFormRef = ref<FormInstance>()
const remember = ref(false)
const region = ref(null)
const userForm = reactive({
  LoginName: null,
  Password: null,
  LoginChannel: 1,
})

const userMobileForm = reactive({
  Mobile: null,
  VCode: null,
  LoginChannel: 1,
})

const LoginType = ref(false)

const change = () => {
  LoginType.value = false
  verifyId.value = null
}

const validatePassword = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error(t('请输入密码')))
  } else {
    callback()
  }
}

const validateMobile = (rule: any, value: any, callback: any) => {
  if (!eval(userAreaCode.value.Regular).test(value)) {
    callback(new Error(t('请输入正确手机号')))
  } else {
    callback()
  }
  callback()
}

//自定义校验需要先有才能引用，注意顺序
const rules = reactive({
  LoginName: [{ required: true, message: t('请输入用户名'), trigger: 'blur' }],
  Password: [{ validator: validatePassword, trigger: 'blur' }],
})

const MobileRules = reactive({
  Mobile: [{ validator: validateMobile, trigger: 'change' }],
  VCode: [{ required: true, message: t('请输入验证码'), trigger: 'blur' }],
})

const Obtain = () => {
  CodeLoading.value = true
  Api.Get('auth/api/SmsVerifySend/', {
    verifyId: verifyId.value,
  }).then(
    (res) => {
      startTimeout()
      ElMessageBox.alert(t('已发送验证码,请注意查收'), t('提示'), {
        confirmButtonText: t('确定'),
      })
    },
    (err) => {
      CodeLoading.value = false
    }
  )
}

const Bind = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validateField('Mobile', (valid) => {
    if (valid) {
      const body = {
        ...userMobileForm,
        ...{
          JointLoginId: JointLoginId.value,
          IsCn: userAreaCode.value.IsCn.toString(),
        },
      }
      loginLoading.value = true
      Api.Post('auth/api/MobileLogin/', body).then(
        (res: any) => {
          loginLoading.value = false
          store.setToken(res.AccessToken)
          if (window.history.state.back) {
            Router.go(-1)
          } else {
            Router.push('/')
          }
        },
        (err) => {
          loginLoading.value = false
          console.log(err)
        }
      )
    }
  })
}

const VerificationCode = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validateField('Mobile', (valid) => {
    if (valid) {
      CodeLoading.value = true
      Api.Get('user/api/SendMessage/', {
        SendTo: userMobileForm.Mobile,
        SendChannel: 2,
      }).then(
        (res: any) => {
          if (!res.IsError) {
            startTimeout()
            ElMessageBox.alert(t('已发送验证码,请注意查收'), t('提示'), {
              confirmButtonText: t('确定'),
            })
          } else {
            CodeLoading.value = false
          }
        },
        (err) => {
          CodeLoading.value = false
          console.log(err)
        }
      )
    }
  })
}
const verifyId = ref(null)
const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      if (remember.value) {
        const RUser = {
          UN: userForm.LoginName,
          UP: userForm.Password,
        }
        localStorage.setItem('RUser', JSON.stringify(RUser))
      } else {
        localStorage.removeItem('RUser')
      }
      let JointLoginData = {}
      if (JointLoginId.value) {
        JointLoginData = Object.assign(
          {},
          {
            JointLoginId: JointLoginId.value,
            IsCn: 'False',
          },
          userForm
        )
      }
      loginLoading.value = true
      Api.Post(
        'auth/api/Login/',
        JointLoginId.value ? JointLoginData : userForm
      ).then(
        (res: any) => {
          loginLoading.value = false
          if (res.IsNeedVerify) {
            LoginType.value = true
            userMobileForm.Mobile = res.VerifyInfo.Mobile
            verifyId.value = res.VerifyInfo.Id
          } else {
            store.setToken(res.AccessToken)
            Api.Get('basic/api/CustomerExchangeRate/', {}).then((res: any) => {
              localStorage.setItem('Rate', JSON.stringify(res.Rate))
            })
            Api.Get('user/api/CheckCustomerNeedService/', {})
              .then((res: any) => {
                if (!res.IsError && res.TypeId === 1) {
                  ElMessageBox.confirm(t('请设置提货仓'), t('提示'), {
                    confirmButtonText: t('确定'),
                    showCancelButton: false,
                    type: 'warning',
                  }).then(() => {
                    Router.push('/userCenter/repository')
                  })
                } else {
                  if (window.history.state.back) {
                    Router.go(-1)
                  } else {
                    Router.push('/')
                  }
                }
              })
              .catch(() => {
                if (window.history.state.back) {
                  Router.go(-1)
                } else {
                  Router.push('/')
                }
              })
          }
        },
        (err) => {
          loginLoading.value = false
        }
      )
    } else {
      return false
    }
  })
}

const submitMobileForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      Api.Get('auth/api/SmsVerify/', {
        verifyId: verifyId.value,
        verifyCode: userMobileForm.VCode,
      }).then((res: any) => {
        store.setToken(res.AccessToken)
        if (window.history.state.back) {
          Router.go(-1)
        } else {
          Router.push('/')
        }
      })
    } else {
      return false
    }
  })
}

watch(
  () => CodeTime.count,
  (newV, oldV) => {
    CodeLoading.value = newV == 0 ? false : true
  },
  {
    immediate: true, // 立即执行
    deep: true, // 深度监听
  }
)

const { OAuth2Client } = require('google-auth-library')
const client = new OAuth2Client()
const verifyToken = async (token) => {
  client.setCredentials({ access_token: token })
  const userinfo = await client.request({
    url: 'https://www.googleapis.com/oauth2/v3/userinfo',
  })
  return userinfo.data
}

const googleLogin = (response) => {
  console.log(response)
  verifyToken(response.access_token)
    .then((userInfo) => {
      let body = {
        OpenId: userInfo.sub,
        Username: userInfo.email,
        LoginTypeId: 2,
      }
      JointLogin(body)
    })
    .catch((error) => {
      console.log(error)
    })
}

const facebookLogin = () => {
  FB.getLoginStatus(function (response) {
    if (response.status !== 'connected') {
      // 未登录，fb会让用户登录
      FB.login((res: any) => {
        console.log(res)
        // 不管用户有没有登录都会有res
        facebookPost(res.authResponse)
      })
    } else {
      // 已登录过，fb直接返回response
      facebookPost(response.authResponse)
    }
  })
}

const facebookPost = (authResponse: any) => {
  if (authResponse) {
    FB.api('/me', function (response) {
      let body = {
        OpenId: authResponse.userID,
        Username: response.name,
        LoginTypeId: 1,
      }
      JointLogin(body)
    })
  }
}

const weixinLogin = () => {
  ElMessageBox.alert('<div id="login_container"></div>', '微信登录', {
    dangerouslyUseHTMLString: true,
    center: true,
    confirmButtonText: '取消',
  })
  setTimeout(() => {
    var obj = new WxLogin({
      self_redirect: false,
      id: 'login_container',
      appid: 'wxc739ab130f54f436',
      scope: 'snsapi_login',
      style: 'white',
      redirect_uri: 'https://www.loda.com.kh/login',
      href: '',
    })
  }, 500)
}

const JointLoginId = ref(null)
const JointLogin = (data) => {
  const body = data
  loginLoading.value = true
  Api.Post('auth/api/JointLogin/', body).then(
    (res: any) => {
      loginLoading.value = false
      if (res.JointLoginId) {
        JointLoginId.value = res.JointLoginId
        dialogVisible.value = true
      } else {
        store.setToken(res.AccessToken)
        if (window.history.state.back) {
          Router.go(-1)
        } else {
          Router.push('/')
        }
      }
    },
    (err) => {
      loginLoading.value = false
    }
  )
}

const handleClose = (done: () => void) => {
  JointLoginId.value = null
  dialogVisible.value = false
}

onMounted(() => {
  if (route.query?.code) {
    let body = {
      WeChatCode: route.query?.code,
      LoginTypeId: 5,
    }
    JointLogin(body)
  }
  CCD()
  fbJsdk()
  const RUser = localStorage.getItem('RUser')
  if (RUser) {
    remember.value = true
    userForm.LoginName = JSON.parse(RUser).UN
    userForm.Password = JSON.parse(RUser).UP
  }

  if (route.params.username) {
    userForm.LoginName = route.params.username
    Password.LoginName = route.params.password
  }
  if (JSON.parse(localStorage.getItem('GetAppConfig'))?.loginConfig) {
    const loginConfig = JSON.parse(
      localStorage.getItem('GetAppConfig')
    )?.loginConfig
    enableFacebookLogin.value = loginConfig.enableFacebookLogin
    enableGoogleLogin.value = loginConfig.enableGoogleLogin
    enablePhoneLogin.value = loginConfig.enablePhoneLogin
    enableWechatLogin.value = loginConfig.enableWechatLogin
  }
})

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_GoogleLogin = _resolveComponent("GoogleLogin")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "container",
      style: _normalizeStyle({ background: _ctx.$SiteSet['login_bgColor'] })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass({ Select: !LoginType.value }),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (LoginType.value = false))
            }, _toDisplayString(_ctx.$t('账号登录')), 3)
          ]),
          (!LoginType.value)
            ? (_openBlock(), _createBlock(_component_el_form, {
                key: 0,
                ref_key: "ruleFormRef",
                ref: ruleFormRef,
                model: userForm,
                rules: rules,
                onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (submitForm(ruleFormRef.value)), ["enter"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { prop: "LoginName" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: userForm.LoginName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((userForm.LoginName) = $event)),
                        name: "LoginName",
                        placeholder: _ctx.$t('账号'),
                        "prefix-icon": "Avatar",
                        disabled: loginLoading.value,
                        onBlur: _cache[2] || (_cache[2] = ($event: any) => (userForm.LoginName = $event.target.value.trim()))
                      }, null, 8, ["modelValue", "placeholder", "disabled"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { prop: "Password" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: userForm.Password,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((userForm.Password) = $event)),
                        name: "Password",
                        onBlur: _cache[4] || (_cache[4] = ($event: any) => (userForm.Password = $event.target.value.trim())),
                        placeholder: _ctx.$t('密码'),
                        "prefix-icon": "Unlock",
                        disabled: loginLoading.value,
                        type: "password"
                      }, null, 8, ["modelValue", "placeholder", "disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model", "rules"]))
            : _createCommentVNode("", true),
          (LoginType.value)
            ? (_openBlock(), _createBlock(_component_el_form, {
                key: 1,
                ref_key: "MobileFormRef",
                ref: MobileFormRef,
                model: userMobileForm,
                rules: MobileRules
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t(
                '您的账号可能存在安全风险，为了确保为您本人操作，请先进行安全验证'
              )), 1),
                  _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: userMobileForm.Mobile,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((userMobileForm.Mobile) = $event)),
                        onBlur: _cache[7] || (_cache[7] = ($event: any) => (userMobileForm.Mobile = $event.target.value.trim())),
                        placeholder: _ctx.$t('手机号'),
                        "prefix-icon": "Iphone"
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, {
                    class: "VerificationCode",
                    prop: "VCode"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: userMobileForm.VCode,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((userMobileForm.VCode) = $event)),
                        placeholder: _ctx.$t('验证码'),
                        disabled: loginLoading.value
                      }, null, 8, ["modelValue", "placeholder", "disabled"]),
                      _createVNode(_component_el_button, {
                        loading: CodeLoading.value,
                        disabled: loginLoading.value,
                        onClick: Obtain
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(!CodeLoading.value ? _ctx.$t('获取验证码') : _unref(CodeTime).count), 1)
                        ]),
                        _: 1
                      }, 8, ["loading", "disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model", "rules"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_checkbox, {
              modelValue: remember.value,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((remember).value = $event)),
              label: _ctx.$t('记住用户')
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_component_router_link, { to: "/recoverPassword" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_link, { underline: false }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('忘记密码')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createVNode(_component_el_button, {
              type: "info",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (change()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('取消')), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, verifyId.value]
            ]),
            _createVNode(_component_el_button, {
              class: "button-login",
              loading: loginLoading.value,
              onClick: _cache[11] || (_cache[11] = ($event: any) => (
              !LoginType.value
                ? submitForm(ruleFormRef.value)
                : submitMobileForm(MobileFormRef.value)
            ))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('登 录')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_router_link, { to: "/register" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_link, { type: "danger" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('免费注册')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          (_ctx.$SiteSet['ExternalLogin'])
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (enableGoogleLogin.value)
                  ? (_openBlock(), _createBlock(_component_GoogleLogin, {
                      key: 0,
                      callback: googleLogin,
                      "popup-type": "TOKEN"
                    }, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createElementVNode("img", { src: _imports_0 }, null, -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (enableFacebookLogin.value)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      onClick: facebookLogin,
                      src: _imports_1
                    }))
                  : _createCommentVNode("", true),
                (enableWechatLogin.value)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 2,
                      onClick: weixinLogin,
                      src: _imports_2
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_el_image, {
          class: "Simg",
          src: require('../assets/imgs/' + _ctx.$SiteSet['login_banner']),
          lazy: ""
        }, null, 8, ["src"])
      ]),
      _cache[24] || (_cache[24] = _createElementVNode("div", { class: "other" }, null, -1))
    ], 4),
    _createVNode(_component_el_dialog, {
      modelValue: dialogVisible.value,
      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((dialogVisible).value = $event)),
      title: _ctx.$t('第三方绑定'),
      width: "30%",
      "before-close": handleClose
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_el_tabs, { type: "border-card" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, {
                label: _unref(t)('老用户绑定')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form, {
                    ref_key: "ruleFormRef",
                    ref: ruleFormRef,
                    model: userForm,
                    rules: rules
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, { prop: "LoginName" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: userForm.LoginName,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((userForm.LoginName) = $event)),
                            placeholder: _ctx.$t('账号'),
                            "prefix-icon": "Avatar",
                            disabled: loginLoading.value,
                            onBlur: _cache[13] || (_cache[13] = ($event: any) => (userForm.LoginName = $event.target.value.trim()))
                          }, null, 8, ["modelValue", "placeholder", "disabled"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, { prop: "Password" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: userForm.Password,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((userForm.Password) = $event)),
                            onBlur: _cache[15] || (_cache[15] = ($event: any) => (userForm.Password = $event.target.value.trim())),
                            placeholder: _ctx.$t('密码'),
                            "prefix-icon": "Unlock",
                            disabled: loginLoading.value,
                            type: "password"
                          }, null, 8, ["modelValue", "placeholder", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model", "rules"]),
                  _createVNode(_component_el_button, {
                    class: "button-login",
                    loading: loginLoading.value,
                    onClick: _cache[16] || (_cache[16] = ($event: any) => (submitForm(ruleFormRef.value)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('绑定')), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_tab_pane, {
                label: _unref(t)('新用户绑定')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form, {
                    ref_key: "MobileFormRef",
                    ref: MobileFormRef,
                    model: userMobileForm,
                    rules: MobileRules
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, { prop: "Mobile" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: userMobileForm.Mobile,
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((userMobileForm.Mobile) = $event)),
                            onBlur: _cache[18] || (_cache[18] = ($event: any) => (userMobileForm.Mobile = $event.target.value.trim())),
                            placeholder: _ctx.$t('手机号'),
                            "prefix-icon": "Iphone"
                          }, {
                            prepend: _withCtx(() => [
                              _createVNode(RegionSelection, { onAreaCode: AreaCode })
                            ]),
                            _: 1
                          }, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, {
                        class: "VerificationCode",
                        prop: "VCode"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: userMobileForm.VCode,
                            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((userMobileForm.VCode) = $event)),
                            placeholder: _ctx.$t('验证码'),
                            disabled: loginLoading.value
                          }, null, 8, ["modelValue", "placeholder", "disabled"]),
                          _createVNode(_component_el_button, {
                            loading: CodeLoading.value,
                            disabled: loginLoading.value,
                            onClick: _cache[20] || (_cache[20] = ($event: any) => (VerificationCode(MobileFormRef.value)))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(!CodeLoading.value ? _ctx.$t('获取验证码') : _unref(CodeTime).count), 1)
                            ]),
                            _: 1
                          }, 8, ["loading", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model", "rules"]),
                  _createVNode(_component_el_button, {
                    class: "button-login",
                    loading: loginLoading.value,
                    onClick: _cache[21] || (_cache[21] = ($event: any) => (Bind(MobileFormRef.value)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('绑定')), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}
}

})