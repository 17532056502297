import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "OrderNo" }
const _hoisted_4 = { class: "detatil" }
const _hoisted_5 = {
  key: 2,
  class: "ImgUrlList"
}

import { ref, reactive, onMounted, watch } from 'vue'
import * as Api from '@/utils/api'
import { useRouter, useRoute } from 'vue-router'
import Loading from '@/components/loading.vue'
import EnterPackage from '@/components/sales/enter_package.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'after-sales-order',
  setup(__props) {

const ShowEnter = ref(false)
const SkuItem = ref(null)

const Router = useRouter()
const route = useRoute()

const PageIndex = ref(1)
const Items = ref()

const isEnterPackage = () => {
  console.log(Items.value)
  // SkuItem.value = item
  // ShowEnter.value = true
}

const getSaleDetail = () => {
  Api.Post('orders/api/SaleOrderDetail', {
    SaleOrderNo: route.query.id,
  }).then((res: any) => {
    Items.value = res
  })
}

onMounted(() => {
  getSaleDetail()
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!Items.value)
        ? (_openBlock(), _createBlock(Loading, {
            key: 0,
            type: 13
          }))
        : _createCommentVNode("", true),
      (Items.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('售后详情')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('售后订单号')) + ": " + _toDisplayString(Items.value.SaleOrderNo), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('售后订单')), 1),
                  _createElementVNode("span", null, _toDisplayString(Items.value.SaleType), 1)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('类型')), 1),
                  _createElementVNode("span", null, _toDisplayString(Items.value.SaleType), 1)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('发起时间')), 1),
                  _createElementVNode("span", null, _toDisplayString(Items.value.RecordList[0].CreationTime), 1)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('状态')), 1),
                  _createElementVNode("span", null, _toDisplayString(Items.value.Statu), 1)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('退款金额')), 1),
                  _createElementVNode("span", null, _toDisplayString(Items.value.TotalAmount), 1)
                ])
              ]),
              _createVNode(_component_el_steps, {
                direction: "vertical",
                "finish-status": "wait",
                active: Items.value.RecordList.length - 1
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Items.value.RecordList, (Record, index) => {
                    return (_openBlock(), _createBlock(_component_el_step, {
                      key: index,
                      icon: index == Items.value.RecordList.length - 1
              ? 'MoreFilled'
              : 'CircleCheckFilled'
            ,
                      title: Record.CreationTime
                    }, {
                      description: _withCtx(() => [
                        (Record.Company)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(_toDisplayString(_ctx.$t('快递公司')) + ": " + _toDisplayString(Record.Company) + " ", 1),
                              _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1))
                            ], 64))
                          : _createCommentVNode("", true),
                        (Record.ExpressNo)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(_ctx.$t('快递单号')) + ": " + _toDisplayString(Record.ExpressNo) + " ", 1),
                              _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
                            ], 64))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(Record.OperationType) + " ", 1),
                        _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode(" " + _toDisplayString(Record.Remark) + " ", 1),
                        (Record.ImgUrlList.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Record.ImgUrlList, (Imgs) => {
                                return (_openBlock(), _createBlock(_component_suffix_img, {
                                  key: Imgs,
                                  url: Imgs,
                                  preview: true,
                                  type: 0
                                }, null, 8, ["url"]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["icon", "title"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["active"])
            ]),
            _createVNode(_component_el_table, {
              data: Items.value.Items,
              border: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: _ctx.$t('商品图片'),
                  width: "120px",
                  "header-align": "center"
                }, {
                  default: _withCtx((scope) => [
                    _createVNode(_component_suffix_img, {
                      class: "SkuImg",
                      url: scope.row.ImgUrl,
                      type: 3
                    }, null, 8, ["url"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "SkuTitle",
                  label: _ctx.$t('商品名称')
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "Reason",
                  label: _ctx.$t('原因')
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "Remark",
                  label: _ctx.$t('备注')
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "Quantity",
                  label: _ctx.$t('数量'),
                  "header-align": "center",
                  align: "center",
                  width: "120px"
                }, null, 8, ["label"])
              ]),
              _: 1
            }, 8, ["data"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (ShowEnter.value)
      ? (_openBlock(), _createBlock(EnterPackage, {
          key: 0,
          SkuItem: SkuItem.value,
          ShowEnter: ShowEnter.value,
          onHideEnter: _cache[0] || (_cache[0] = ($event: any) => (ShowEnter.value = false)),
          onEnterChange: _cache[1] || (_cache[1] = ($event: any) => {; (ShowEnter.value = false), getSaleDetail()})
        }, null, 8, ["SkuItem", "ShowEnter"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})