import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ShopName" }
const _hoisted_2 = { class: "ShopSurvey" }

import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'ShopCard',
  props: {
  data: Object,
  index: Number,
},
  setup(__props) {

const route = useRoute()

const userouter = useRouter()

const props = __props
const show = ref(false)

const OpenMallHome = (ShopId) => {
  const href = userouter.resolve({
    path: '/mallHome',
    query: { ShopId },
  })
  window.open(href.href, '_blank')
}

onMounted(() => {
  setTimeout(() => {
    show.value = true
  }, 100 * props.index)
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_ShoppingBag = _resolveComponent("ShoppingBag")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Star = _resolveComponent("Star")!
  const _component_Service = _resolveComponent("Service")!
  const _component_Timer = _resolveComponent("Timer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: "ShopCard",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (OpenMallHome(__props.data.ShopId)))
        }, [
          _createVNode(_component_suffix_img, {
            url: __props.data.ShopLogo,
            type: 5
          }, null, 8, ["url"]),
          _createElementVNode("div", _hoisted_1, _toDisplayString(__props.data.ShopName), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("p", null, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ShoppingBag)
                  ]),
                  _: 1
                }),
                _createTextVNode("  " + _toDisplayString(__props.data.ProductNum), 1)
              ]),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('商品')), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("p", null, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Star)
                  ]),
                  _: 1
                }),
                _createTextVNode("  " + _toDisplayString(__props.data.Evaluation), 1)
              ]),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('评价')), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("p", null, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Service)
                  ]),
                  _: 1
                }),
                _createTextVNode("  " + _toDisplayString(__props.data.ChatResponseRate) + "% ", 1)
              ]),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('聊天回应率')), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("p", null, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Timer)
                  ]),
                  _: 1
                }),
                _createTextVNode("  " + _toDisplayString(__props.data.ChatResponseSpeed) + " H ", 1)
              ]),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('回应速度')), 1)
            ])
          ])
        ], 512), [
          [_vShow, show.value]
        ])
      ]),
      _: 1
    })
  ]))
}
}

})