import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "oss" }
const _hoisted_2 = ["onMouseenter"]
const _hoisted_3 = { class: "custom" }
const _hoisted_4 = { class: "UploadIcon" }

import { ref, onMounted, watch } from 'vue'
import { OssUpload } from '@/utils/OssClient'
import Loading from '@/components/loading.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'oss',
  props: {
  imgList: Array || String,
  max: Number,
},
  emits: ['ImgsChange'],
  setup(__props, { emit: __emit }) {

const showImageViewer = ref(false)
const urlList = ref([])
const imgList: any = ref([])

const props = __props

const previewPic = () => {
  urlList.value = imgList.value.map((item: any) => {
    return item.url
  })
  showImageViewer.value = true
}

const ShowDelete = ref(null)

const max = props.max || 1
const isUploading = ref(false)

const emit = __emit

const DeleteUrl = (index) => {
  imgList.value.splice(index, 1)
  emit('ImgsChange', imgList.value)
}

const Upload = (file) => {
  isUploading.value = true
  OssUpload(file, 5).then(
    (res) => {
      isUploading.value = false
      imgList.value.push({
        url: res,
      })
      emit('ImgsChange', imgList.value)
    },
    (err) => {
      isUploading.value = false
    }
  )
  return false
}

onMounted(() => {
  const type = typeof (props.imgList)
  imgList.value = type == 'string' ? [{ url: props.imgList }] : type == 'object' ? [] : props.imgList
})

return (_ctx: any,_cache: any) => {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_FullScreen = _resolveComponent("FullScreen")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_UploadFilled = _resolveComponent("UploadFilled")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_image_viewer = _resolveComponent("el-image-viewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(imgList.value, (imgs, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "ImgBox",
        key: index,
        onMouseenter: ($event: any) => (ShowDelete.value = index),
        onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (ShowDelete.value = null))
      }, [
        _createVNode(_component_el_image, {
          src: imgs['url']
        }, null, 8, ["src"]),
        _createVNode(_Transition, { name: "el-fade-in" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_FullScreen, { onClick: previewPic })
                ]),
                _: 1
              }),
              _createVNode(_component_el_icon, {
                onClick: ($event: any) => (DeleteUrl(index))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Delete)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ], 512), [
              [_vShow, ShowDelete.value == index]
            ])
          ]),
          _: 2
        }, 1024)
      ], 40, _hoisted_2))
    }), 128)),
    _createVNode(_component_el_upload, {
      action: "#",
      accept: ".jpg,.jpeg,.png",
      "before-upload": Upload,
      class: _normalizeClass(['Adaptation', { isShowUp: imgList.value.length == _unref(max) }]),
      limit: _unref(max),
      "show-file-list": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_UploadFilled)
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(imgList.value.length + '/' + _unref(max)), 1)
          ], 512), [
            [_vShow, !isUploading.value]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(Loading, { type: 12 })
          ], 512), [
            [_vShow, isUploading.value]
          ])
        ])
      ]),
      _: 1
    }, 8, ["class", "limit"]),
    (showImageViewer.value)
      ? (_openBlock(), _createBlock(_component_el_image_viewer, {
          key: 0,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (showImageViewer.value = false)),
          "url-list": urlList.value
        }, null, 8, ["url-list"]))
      : _createCommentVNode("", true)
  ]))
}
}

})