import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "user-information" }
const _hoisted_2 = { class: "card-header" }

import { ref, reactive, onMounted, watch, toRefs } from 'vue'
import * as Api from '@/utils/api'
import Loading from '@/components/loading.vue'
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'user-information',
  setup(__props) {

const store = useUsersStore()
const { CustomerInfo, CustomerSecureInfo, EmployessList } = toRefs(store)

const { t } = useI18n()
const UserLoad = ref(false)

const userForm = reactive({
  CustomerName: null,
  Username: null,
  CustomerCode: null,
  Birthday: null,
  Sex: null,
  Email: null,
  Mobile: null,
  SalesId: null,
})
const userRef = ref<FormInstance>()
const userRules = reactive<FormRules>({})

watch(
  () => CustomerInfo.value,
  (res) => {
    if (res) {
      Object.keys(userForm).forEach((item, index) => {
        userForm[item] = res.Customer[item]
      })
      userForm.SalesId = res.Customer?.EmployeeId || null
    }
  },
  {
    immediate: true,
  }
)

watch(
  () => CustomerSecureInfo.value,
  (res) => {
    if (res) {
      userForm.Email = res.Email
      userForm.Mobile = res.Mobile
    }
  },
  {
    immediate: true,
  }
)

const getCustomerInfo = () => {
  store.setState('user/api/CustomerInfo/', {}, 'CustomerInfo')
  store.setState('user/api/CustomerSecureInfo/', {}, 'CustomerSecureInfo')
  store.setState('user/api/CRM/', { NoLanguage: true }, 'EmployessList')
}

const SubmitUser = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      UserLoad.value = true
      Api.Post('user/api/CustomerInfo/', {
        Birthday: userForm.Birthday,
        CustomerName: userForm.CustomerName,
        Sex: userForm.Sex,
      })
        .then((res: any) => {
          UserLoad.value = false
          ElMessage({
            message: t('已保存'),
            type: 'success',
          })
          if (userForm.SalesId) {
            UserLoad.value = true
            Api.Post('user/api/Customer2Sale/', {
              SalesId: CustomerInfo?.Customer.EmployeeId
                ? null
                : userForm.SalesId,
            })
              .then((res: any) => {
                UserLoad.value = false
              })
              .catch(() => {
                UserLoad.value = false
              })
          }
        })
        .catch(() => {
          UserLoad.value = false
        })
    }
  })
}

onMounted(() => {
  getCustomerInfo()
})

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, { class: "box-card" }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('个人资料')), 1)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref_key: "userRef",
          ref: userRef,
          model: userForm,
          rules: userRules,
          "label-position": "top",
          class: "PackageForm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('用户名')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  size: "large",
                  modelValue: userForm.Username,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((userForm.Username) = $event)),
                  disabled: true
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('昵称')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  size: "large",
                  modelValue: userForm.CustomerName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((userForm.CustomerName) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('用户代码')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  size: "large",
                  modelValue: userForm.CustomerCode,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((userForm.CustomerCode) = $event)),
                  disabled: true
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('生日')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: userForm.Birthday,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((userForm.Birthday) = $event)),
                  size: "large",
                  clearable: false
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('性别')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio_group, {
                  modelValue: userForm.Sex,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((userForm.Sex) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio, {
                      label: "True",
                      size: "large",
                      border: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('男')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_radio, {
                      label: "False",
                      size: "large",
                      border: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('女')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('邮箱')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  size: "large",
                  modelValue: userForm.Email,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((userForm.Email) = $event)),
                  disabled: true
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('手机号')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  size: "large",
                  modelValue: userForm.Mobile,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((userForm.Mobile) = $event)),
                  disabled: true
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('绑定业务员')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: userForm.SalesId,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((userForm.SalesId) = $event)),
                  placeholder: _ctx.$t('请选择'),
                  size: "large",
                  disabled: _unref(CustomerInfo)?.Customer.EmployeeId ? true : false
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(EmployessList)?.EmployessList, (Employess) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: Employess.EmployeeId,
                        label: Employess.EmployeeNo,
                        value: Employess.EmployeeId,
                        disabled: _unref(CustomerInfo)?.Customer.EmployeeId ? true : false
                      }, null, 8, ["label", "value", "disabled"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "disabled"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "danger",
                  size: "large",
                  loading: UserLoad.value,
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (SubmitUser(userRef.value)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('保存')), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    })
  ]))
}
}

})