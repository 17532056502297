import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Transition as _Transition, unref as _unref, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/imgs/noCart.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "CartTitle" }
const _hoisted_3 = { class: "CartList" }
const _hoisted_4 = { class: "ShopTitle" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "ProductList" }
const _hoisted_7 = { class: "Ptop" }
const _hoisted_8 = { class: "ProductImg" }
const _hoisted_9 = { class: "SkuList" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "Sku" }
const _hoisted_12 = {
  key: 0,
  class: "LadderPrices"
}
const _hoisted_13 = {
  key: 0,
  class: "Satisfy"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "isLadderPrices" }
const _hoisted_17 = {
  key: 0,
  style: {"color":"red","max-width":"120px"}
}
const _hoisted_18 = { class: "SkuPrice" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "Remark" }
const _hoisted_23 = { class: "RemarkImg" }
const _hoisted_24 = { class: "isLoding" }
const _hoisted_25 = { class: "NoProduct" }
const _hoisted_26 = { class: "Settlement" }
const _hoisted_27 = { class: "deleteCart" }
const _hoisted_28 = { class: "Total" }

import { ref, onMounted, toRefs } from 'vue'
import * as Api from '@/utils/api'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import OSS from '@/components/oss.vue'
// import GoogleTranslate from '@/components/GoogleTranslate.vue'
import { useUsersStore } from '@/pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'shopping-cart',
  setup(__props) {

const store = useUsersStore()
const { MaxOrderItemCount, SelectCarts } = toRefs(store)

const { t } = useI18n()
const Router = useRouter()

const Ladder = ref()
const PageIndex = ref(1)
const PageSize = ref(10)
const More = ref(false)
const CartList = ref([])
const checkedAll = ref(false)
const isLoding = ref(false)
const TotalPrice = ref(0)
const TotalNumber = ref(0)
const isCheckList = ref([])
const ShowAffix = ref(false)

const PushProduct = (i) => {
  const href = Router.resolve({
    path: '/productDetails',
    query: { outId: i.OutId, mallId: i.MallId },
  })
  window.open(href.href, '_blank')
}

const isSatisfy = ref(0)

const UpImg = (img, sku) => {
  Api.Put('cart/api/NewShoppingCart/', {
    RemarkImg: img.length ? img[0].url : 'del',
    CartIds: sku.Id,
  }).then()
}

const Satisfy = (item) => {
  if (item.LadderPrices && item.LadderPrices.length) {
    let MinQuantity = item.LadderPrices[0].StartQuantity
    let Quantity = 0
    let isCheck = false
    item.Items.forEach((element) => {
      if (element.checked) {
        isCheck = true
        Quantity += element.Quantity
      }
    })
    if (isCheck) {
      isSatisfy.value = isSatisfy.value + MinQuantity > Quantity ? 1 : 0
    }

    return isCheck ? MinQuantity > Quantity : false
  } else {
    return false
  }
}

const Submit = () => {
  if (!isCheckList.value.length || isSatisfy.value > 0) return false
  if (isCheckList.value.length > MaxOrderItemCount.value) {
    return ElMessage({
      type: 'error',
      message: t('超出提交最大数量'),
    })
  }
  useUsersStore().SelectCarts = isCheckList.value
  Router.push({
    name: 'placeOrder',
  })
}

const SkuRemark = (sku) => {
  Api.Put('cart/api/NewShoppingCart/', {
    CartIds: sku.Id,
    Remark: sku.Remark,
  }).then()
}

const isLadderPrice = (Product) => {
  let Quantity = 0
  Product.Items.forEach((element) => {
    Quantity += element.Quantity
  })
  let LadderPrices = Product.LadderPrices.find((Ladder) => {
    return Ladder.EndQuantity != null
      ? Quantity >= Ladder.StartQuantity && Quantity <= Ladder.EndQuantity
      : Quantity >= Ladder.StartQuantity
  })
  if (LadderPrices && LadderPrices.Price) {
    return LadderPrices.Price
  } else {
    return false
  }
}

const DeleteSku = (sku = undefined) => {
  let DeleteList = ''
  if (!sku) {
    isCheckList.value.forEach((res: any) => {
      DeleteList += res.Id + '|'
    })
  }
  ElMessageBox.confirm(t('确认删除吗'), t('提示'), {
    cancelButtonText: t('取消'),
    confirmButtonText: t('确认'),
    type: 'warning',
  })
    .then(() => {
      Api.Delete('cart/api/NewShoppingCart/', {
        CartIds: sku ? sku : DeleteList,
      }).then((res: any) => {
        if (!res.IsError) {
          ElMessage({
            type: 'success',
            message: t('已删除'),
          })
          PageIndex.value = 1
          CartList.value = []
          GetCartList()
        }
      })
    })
    .catch(() => {})
}

const SkuQtyChange = (sku, Product) => {
  // 如果输入值不是item.value.BatchNumber的倍数，则调整为item.value.BatchNumber的倍数
  if (
    Product.BatchNumber &&
    Product.BatchNumber > 1 &&
    sku.Quantity % Product.BatchNumber !== 0
  ) {
    if (sku.Quantity > Product.BatchNumber) {
      sku.Quantity =
        Math.round(sku.Quantity / Product.BatchNumber) * Product.BatchNumber
    } else {
      if (sku.Quantity !== 0) sku.Quantity = Product.BatchNumber
    }
  }
  if (sku.checked) {
    isCheckList.value = isCheckList.value.map((t) => {
      return sku.Id === t.Id ? sku : t
    })
  }
  Api.Put('cart/api/NewShoppingCart/', {
    CartIds: sku.Id,
    Quantity: sku.Quantity,
  }).then()
  TotalCalculate()
}

const TotalCalculate = () => {
  isCheckList.value = []
  isSatisfy.value = 0
  CartList.value.forEach((e1) => {
    e1.Items.forEach((e2) => {
      let LadderNumber = e2.Items.reduce((LadderNumber, size) => {
        return (LadderNumber += size.checked ? size.Quantity : 0)
      }, 0)
      if (e2.LadderPrices) e2.isSatisfy = Satisfy(e2)
      let LadderPrices = null
      e2.Items.forEach((e3) => {
        e3.LadderPrice = null
        if (e2.LadderPrices?.length && e3.checked && LadderNumber) {
          LadderPrices = e2.LadderPrices.find((Ladder) => {
            return Ladder.EndQuantity != null
              ? LadderNumber >= Ladder.StartQuantity &&
                  LadderNumber <= Ladder.EndQuantity
              : LadderNumber >= Ladder.StartQuantity
          })
          if (LadderPrices?.Price) {
            e3.LadderPrice = LadderPrices?.Price
          }
        }
        if (e3.checked) {
          isCheckList.value.push(e3)
        }
      })
    })
  })
  TotalNumber.value = isCheckList.value.length
  TotalPrice.value = 0

  isCheckList.value.forEach((element) => {
    TotalPrice.value += element.LadderPrice
      ? element.Quantity * element.LadderPrice
      : element.Quantity * element.Price
  })
}

//已超级简化
const checkShop = async (item) => {
  // console.log(JSON.stringify(CartList.value))
  if (typeof item == 'object') {
    item.Items && ChangeItem(item.Items, item.checked)
    CartList.value.forEach((item) => {
      CheckChecked(item)
    })
    checkedAll.value = ComputeChecked(CartList.value)
  }
  if (typeof item == 'boolean') {
    ChangeItem(CartList.value, checkedAll.value)
  }
  TotalCalculate()
}

const CheckChecked = (item) => {
  if (item.Items) {
    item.Items.forEach((item) => {
      CheckChecked(item)
    })
    item.checked = ComputeChecked(item.Items)
  }
}

const ComputeChecked = (arr) => {
  const totalChecked = arr.reduce((num, item) => {
    return item.checked ? num + 1 : num
  }, 0)

  return totalChecked == arr.length
}

const ChangeItem = (arr, bol) => {
  arr.forEach((item) => {
    // 为当前对象添加 `checked`
    if (!item.IsShelves) {
      item.checked = bol
      // 如果有嵌套数组，递归处理
      if (Array.isArray(item.Items)) {
        ChangeItem(item.Items, bol)
      }
    }
  })
}

const GetCartList = () => {
  isLoding.value = true
  const data = {
    PageIndex: PageIndex.value,
    PageSize: PageSize.value,
  }
  Api.Get('cart/api/NewShoppingCart/', data).then((res: any) => {
    isLoding.value = false
    if (!res.IsError) {
      CartList.value =
        CartList.value.length > 0 ? CartList.value.concat(res.Items) : res.Items
      if (PageIndex.value == 1) {
        setTimeout(() => {
          ShowAffix.value = true
        }, 1000)
      }

      More.value =
        res.Items.length == 0 || res.Items.length < PageSize.value
          ? false
          : true
    }
  })
}

const scrollTop = () => {
  let ST = document.documentElement.scrollTop || document.body.scrollTop
  //变量windowHeight是可视区的高度
  let WH = document.documentElement.clientHeight || document.body.clientHeight
  //变量scrollHeight是滚动条的总高度
  let SH = document.documentElement.scrollHeight || document.body.scrollHeight
  //滚动条到底部的条件
  if (ST + WH == SH && More.value && !isLoding.value) {
    PageIndex.value++
    GetCartList()
  }
}

onMounted(() => {
  PageSize.value = 50
  GetCartList()
  store.setState('orders/api/MaxOrderItemCount', {}, 'MaxOrderItemCount')
  addEventListener('scroll', scrollTop, true)
})

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_loading = _resolveComponent("loading")!
  const _component_el_affix = _resolveComponent("el-affix")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (CartList.value.length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_el_checkbox, {
              modelValue: checkedAll.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checkedAll).value = $event)),
              label: _ctx.$t('全选'),
              onChange: checkShop
            }, null, 8, ["modelValue", "label"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('商品信息')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('单价')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('数量')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('小计')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('给客服留言')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('操作')), 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(CartList.value, (Shop, i1) => {
        return (_openBlock(), _createElementBlock("div", { key: i1 }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_checkbox, {
              modelValue: Shop.checked,
              "onUpdate:modelValue": ($event: any) => ((Shop.checked) = $event),
              onChange: ($event: any) => (checkShop(Shop))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]),
            _createElementVNode("img", {
              src: require('../assets/imgs/' + Shop.Img)
            }, null, 8, _hoisted_5),
            _createElementVNode("span", null, _toDisplayString(Shop.ShopName), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Shop.Items, (Product, i2) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "Product",
                key: i2
              }, [
                _createElementVNode("span", _hoisted_7, [
                  _createVNode(_component_el_checkbox, {
                    modelValue: Product.checked,
                    "onUpdate:modelValue": ($event: any) => ((Product.checked) = $event),
                    onChange: ($event: any) => (checkShop(Product)),
                    disabled: Product.IsShelves
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "disabled"])
                ]),
                _createElementVNode("span", _hoisted_8, [
                  _createVNode(_component_suffix_img, {
                    url: Product.ProductImgUrl,
                    type: 5,
                    onClick: ($event: any) => (PushProduct(Product))
                  }, null, 8, ["url", "onClick"]),
                  _withDirectives(_createElementVNode("div", { class: "Shelves" }, _toDisplayString(_ctx.$t('已下架')), 513), [
                    [_vShow, Product.IsShelves]
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", {
                    class: "Ptop ProductName",
                    onClick: ($event: any) => (PushProduct(Product))
                  }, _toDisplayString(Product.ProductName), 9, _hoisted_10),
                  _createElementVNode("div", _hoisted_11, [
                    (Product.LadderPrices && Product.LadderPrices.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          (Product.isSatisfy)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('不满足起批量')), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Product.LadderPrices, (LadderPrice, il) => {
                            return (_openBlock(), _createElementBlock("div", { key: il }, [
                              (LadderPrice.EndQuantity)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(LadderPrice.StartQuantity +
                        '~' +
                        LadderPrice.EndQuantity) + " :  ", 1))
                                : _createCommentVNode("", true),
                              (!LadderPrice.EndQuantity)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_15, " ≥ " + _toDisplayString(LadderPrice.StartQuantity) + " :  ", 1))
                                : _createCommentVNode("", true),
                              _withDirectives(_createElementVNode("span", null, " : " + _toDisplayString(_ctx.$ccy(LadderPrice.Price)), 513), [
                                [_vShow, LadderPrice.Price]
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Product.Items, (Sku, i3) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "SkuIndex",
                        key: i3
                      }, [
                        _createVNode(_component_suffix_img, {
                          class: "SkuImg",
                          url: Sku.ImgUrl,
                          type: 5
                        }, null, 8, ["url"]),
                        _createVNode(_component_el_checkbox, {
                          modelValue: Sku.checked,
                          "onUpdate:modelValue": ($event: any) => ((Sku.checked) = $event),
                          onChange: ($event: any) => (checkShop(Sku)),
                          disabled: Product.IsShelves,
                          class: "SkuName"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(Sku.SkuName), 1)
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "disabled"]),
                        _createElementVNode("span", _hoisted_16, [
                          (
                        !Product.LadderPrices ||
                        Product.LadderPrices.length === 0
                      )
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(Sku.Price), 1)
                              ], 64))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", null, [
                          _createVNode(_component_el_input_number, {
                            modelValue: Sku.Quantity,
                            "onUpdate:modelValue": ($event: any) => ((Sku.Quantity) = $event),
                            min: 0,
                            step: Product.BatchNumber || 1,
                            onChange: ($event: any) => (SkuQtyChange(Sku, Product)),
                            size: "small",
                            precision: 0
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "step", "onChange"]),
                          (Product.BatchNumber && Product.BatchNumber > 1)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t(`数量必须是`, { name: Product.BatchNumber })), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("span", _hoisted_18, [
                          (
                        !Product.LadderPrices ||
                        Product.LadderPrices.length == 0
                      )
                            ? (_openBlock(), _createElementBlock("b", _hoisted_19, _toDisplayString(_ctx.$ccy(Sku.Price * Sku.Quantity)), 1))
                            : (_openBlock(), _createElementBlock("b", _hoisted_20, [
                                _createElementVNode("span", {
                                  class: _normalizeClass([{ isLadderPrice: Sku.LadderPrice }])
                                }, _toDisplayString(_ctx.$ccy(Ladder.value || Sku.Price * Sku.Quantity)), 3),
                                (Sku.LadderPrice)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.$ccy(Sku.LadderPrice * Sku.Quantity)), 1))
                                  : _createCommentVNode("", true)
                              ]))
                        ]),
                        _createElementVNode("span", _hoisted_22, [
                          _withDirectives(_createVNode(_component_el_input, {
                            modelValue: Sku.Remark,
                            "onUpdate:modelValue": ($event: any) => ((Sku.Remark) = $event),
                            placeholder: _ctx.$t('留言'),
                            clearable: "",
                            onBlur: ($event: any) => (SkuRemark(Sku))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "onBlur"]), [
                            [_vShow, Shop.MallId != 111036]
                          ])
                        ]),
                        _createElementVNode("span", _hoisted_23, [
                          _withDirectives(_createVNode(OSS, {
                            type: 9,
                            imgList: Sku.RemarkImg,
                            onImgsChange: (message) => UpImg(message, Sku)
                          }, null, 8, ["imgList", "onImgsChange"]), [
                            [_vShow, Shop.MallId != 111036]
                          ])
                        ]),
                        _createVNode(_component_el_icon, {
                          class: "DeleteSku",
                          onClick: ($event: any) => (DeleteSku(Sku.Id))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_delete)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]))
                    }), 128))
                  ])
                ])
              ]))
            }), 128))
          ])
        ]))
      }), 128)),
      _withDirectives(_createVNode(_Transition, { name: "el-fade-in-linear" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_24, [
            _cache[4] || (_cache[4] = _createTextVNode(" Loading~ ")),
            _createVNode(_component_el_icon, { class: "is-loading" }, {
              default: _withCtx(() => [
                _createVNode(_component_loading)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 512), [
        [_vShow, isLoding.value]
      ]),
      _withDirectives(_createElementVNode("div", { class: "NoMore" }, _toDisplayString(_ctx.$t('到底了!')), 513), [
        [_vShow, !More.value && !isLoding.value && CartList.value.length > 0]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_25, [
        _cache[5] || (_cache[5] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('购物车空空如也，赶紧挑选商品塞满你的购物')), 1)
      ], 512), [
        [_vShow, !More.value && !isLoding.value && CartList.value.length == 0]
      ]),
      _withDirectives(_createVNode(_Transition, { name: "el-fade-in-linear" }, {
        default: _withCtx(() => [
          (ShowAffix.value)
            ? (_openBlock(), _createBlock(_component_el_affix, {
                key: 0,
                ref: "affix",
                position: "bottom",
                offset: 0
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_26, [
                    _createVNode(_component_el_checkbox, {
                      modelValue: checkedAll.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((checkedAll).value = $event)),
                      onChange: checkShop,
                      label: _ctx.$t('全选')
                    }, null, 8, ["modelValue", "label"]),
                    _createElementVNode("div", _hoisted_27, [
                      _withDirectives(_createElementVNode("span", {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (DeleteSku()))
                      }, _toDisplayString(_ctx.$t('删除')), 513), [
                        [_vShow, isCheckList.value.length]
                      ]),
                      _createElementVNode("span", {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (DeleteSku('all')))
                      }, _toDisplayString(_ctx.$t('清空购物车')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _withDirectives(_createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('最多提交数量')) + ": ", 1),
                        _createElementVNode("b", null, _toDisplayString(_unref(MaxOrderItemCount)), 1)
                      ], 512), [
                        [_vShow, _unref(MaxOrderItemCount)]
                      ]),
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('已选')) + ": ", 1),
                        _createElementVNode("b", null, _toDisplayString(TotalNumber.value), 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('件')), 1)
                      ]),
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('总额')) + ": ", 1),
                        _createElementVNode("b", null, _toDisplayString(_ctx.$ccy(TotalPrice.value)), 1)
                      ])
                    ]),
                    _createElementVNode("span", {
                      class: _normalizeClass([
                'Submit',
                { dissatisfaction: !isCheckList.value.length || isSatisfy.value > 0 },
              ]),
                      onClick: Submit
                    }, _toDisplayString(_ctx.$t('提交订单')), 3)
                  ])
                ]),
                _: 1
              }, 512))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512), [
        [_vShow, CartList.value.length > 0]
      ])
    ])
  ]))
}
}

})