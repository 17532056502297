import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "ImgSearch"
}
const _hoisted_3 = { class: "ImgSearchUrl" }
const _hoisted_4 = { class: "ImgSearchingList" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "ImgSearchLoding"
}
const _hoisted_7 = {
  key: 2,
  class: "MoreProducts"
}
const _hoisted_8 = {
  key: 3,
  class: "MoreProducts"
}
const _hoisted_9 = {
  key: 0,
  class: "Categories"
}
const _hoisted_10 = {
  key: 0,
  class: "Categorie originType"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "Categorie" }
const _hoisted_13 = { class: "Categorie" }
const _hoisted_14 = { class: "Categorie" }
const _hoisted_15 = {
  key: 1,
  class: "ProductList"
}
const _hoisted_16 = { class: "MallFilter" }
const _hoisted_17 = {
  key: 1,
  class: "SortTypes"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 0,
  class: "Product"
}
const _hoisted_20 = {
  key: 1,
  class: "Product"
}
const _hoisted_21 = {
  key: 2,
  class: "Product"
}
const _hoisted_22 = {
  key: 0,
  class: "ProductPagination"
}
const _hoisted_23 = {
  key: 1,
  class: "MoreProducts"
}
const _hoisted_24 = {
  key: 2,
  class: "ShopList"
}
const _hoisted_25 = { class: "isSearchName" }
const _hoisted_26 = { class: "ProductPagination" }

import {
  ref,
  reactive,
  onMounted,
  watch,
  toRefs,
  getCurrentInstance,
  onUnmounted,
} from 'vue'
import { useRoute } from 'vue-router'
import * as Api from '@/utils/api'
import PaginationHook from '@/hooks/pagination'
import CommodityCard from '@/components/CommodityCard.vue'
import ShopCard from '@/components/ShopCard.vue'
import EmptyArray from '@/components/EmptyArray.vue'
import ProductSkeleton from '@/components/ProductSkeleton.vue'
import FilterType from '@/components/FilterType.vue'
import { useRouter } from 'vue-router'
import { useUsersStore } from '@/pinia'
import { translateApi } from '@/utils/GoogleTranslate'
import { SiteSet } from '@/utils/siteConfig'


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchList',
  setup(__props) {

const store = useUsersStore()
const { AllFrontCategories } = toRefs(store)

const userouter = useRouter()
const { proxy } = getCurrentInstance()

const isOpenAll = () => {
  const Cards: any = document.getElementsByClassName('CommodityCard')
  for (const i in Cards) {
    if (Cards[i].children[0] instanceof HTMLInputElement) {
      Cards[i].children[0].click()
    }
  }
}

const Bus = (str: any) => {
  setTimeout(() => {
    newQuery(route.query.type)
  }, 100)
}

proxy?.$Bus.on('UpSearch', Bus)

onUnmounted(() => {
  // proxy?.$Bus.all.clear()
  proxy?.$Bus.off('UpSearch')
})
const localLan = localStorage.getItem('lang')
const country = SiteSet().getPlacePredictions
const hightSet = localLan == 'zh-cn' && country != 'kh'
const total = ref(0)
const route: any = useRoute()
const ProductList = ref(null)
const CategorieRadio = ref(null)
const MallCategories = ref([])
const MallCategorieChecks = ref([])
const MallTypeId = ref(null)
const ImgUrl = ref(null)
const isImgUrl = ref(false)
const MallTypeList = ref([
  {
    name: '淘宝天猫',
    url: require('../assets/imgs/t_m.png'),
    style: '',
    show: true,
  },
  {
    name: '1688',
    url: require('../assets/imgs/1688_2.png'),
    style: 'object-fit: contain;width: 110px;',
    show: true,
  },
  {
    name: '拼多多',
    url: require('../assets/imgs/pinduoduo.png'),
    style: '',
    show: true,
  },
  {
    name: 'Mall',
    url: require('../assets/imgs/loda_2.png'),
    style: 'object-fit: contain;width: 70px;',
    show: proxy.$SiteSet['LODAMall'],
  },
])
const SortType = ref(0)
const Filters = reactive({
  typeId: 1,
  MaxPrice: undefined,
  MinPrice: undefined,
})

const SortTypes = ref([
  { name: '综合排序', val: 0 },
  { name: '销量升序', val: 1 },
  { name: '销量降序', val: 2 },
])

const Deliverys = ref([
  { name: '全部', val: null },
  { name: '本地(柬埔寨)', val: 'kh' },
  { name: '海外(中国)', val: 'cn' },
])

const Delivery = ref(null)

const newQuery = (is?) => {
  pageNum.value = 1
  total.value = 0
  ProductList.value = null
  if (is) {
    MallTypeId.value =
      is == 1 ? 4 : MallTypeId.value == 4 ? 2 : MallTypeId.value
    selectPageReq()
  } else {
    let type = MallTypeId.value == 4 ? 1 : 0
    if (type != route.query.type) {
      userouter.push({
        path: '/searchList',
        query: { type, input: route.query.input },
      })
      setTimeout(() => {
        selectPageReq()
      }, 100)
    } else {
      selectPageReq()
    }
  }
}

const ProductsLoading = ref(false)
const ImgSearchingList = ref([])
const ErrMsg = ref(null)

const selectPageReq = async () => {
  ErrMsg.value = null
  ProductsLoading.value = false
  switch (Number(route.query.type)) {
    case 0:
      MallCategorieChecks.value = []
      if (route.query.input) {
        CategorieRadio.value = null
      }
      let translateKey = null
      if (route.query.translate === 'true' || route.query.translate === true) {
        translateKey = await translateApi(
          route.query.input,
          proxy.$SiteSet['translateLan'],
          null,
          null,
          1
        ).then()
      }

      ProductsLoading.value = true
      Api.Post('grabgoods/api/GoodsListSearch/', {
        Page: pageNum.value,
        PageSize: pageSize.value,
        // IsSplit: true,
        NeedTranslate: false,
        TypeId: MallTypeId.value,
        Key: translateKey || route.query.input,
        SortType: SortType.value,
        FrontCartId: CategorieRadio.value || '',
      }).then((res: any) => {
        ProductsLoading.value = false
        if (pageNum.value == 1) {
          ProductList.value = res.Goods
        } else {
          ProductList.value = ProductList.value.concat(res.Goods)
        }
      })
      break
    case 1:
      CategorieRadio.value = null
      Api.Post('sellers/api/SellerProdusts/', {
        Page: pageNum.value,
        PageSize: pageSize.value,
        MaxPrice: Filters.MaxPrice,
        MinPrice: Filters.MinPrice,
        ProductKey: route.query.input,
        Delivery: Delivery.value,
        ProductsQuerySortType: Filters.typeId,
        Score: null,
        ShopCategoryId: null,
        ShopId: null,
        CategoryId: MallCategorieChecks.value,
        QueryShopType: 1,
      }).then((res: any) => {
        window.scrollTo(0, 0)
        ProductList.value = JSON.parse(JSON.stringify(res.Goods))
        MallCategories.value = res.QueryCategories
        total.value = res.Total
      })
      break
    case 2:
      Api.Get('sellers/api/GetSellerShops/', {
        Keyword: route.query.input,
        TenantId: route.query.TenantId,
        isTakeaway: 'False',
        Page: pageNum.value,
        PageSize: pageSize.value,
      }).then((res: any) => {
        ProductList.value = res.Sellers
        total.value = res.Total
      })
      break
  }
  if (isImgUrl.value) {
    newImageSearch()
    // total.value = 0
    // if (pageNum.value == 1) ProductList.value = null
    // ProductsLoading.value = true
    // Api.Get('grabgoods/api/ImageSearch', {
    //   ImageUrl: ImgUrl.value,
    //   pageindex: pageNum.value,
    // }).then((res: any) => {
    //   if (res.ErrMsg) {
    //     ProductList.value = ProductList.value || []
    //     ErrMsg.value = res.ErrMsg
    //   } else {
    //     ProductsLoading.value = false
    //     if (pageNum.value == 1) {
    //       ProductList.value = res.Goods
    //     } else {
    //       ProductList.value = ProductList.value.concat(res.Goods)
    //     }
    //   }
    // })
  }
}

const newImageSearch = () => {
  if (ImgSearchingList.value.length == 0) {
    store.getEveryoneImg(1).then((res) => {
      ImgSearchingList.value = res
    })
  }
  let base64img
  if (!ImgUrl.value) {
    base64img = store.base64img
    if (!base64img) userouter.push('/')
    ImgUrl.value = base64img
  }
  total.value = 0
  if (pageNum.value == 1) ProductList.value = null
  ProductsLoading.value = true
  Api.Post('grabgoods/api/MultilingualImageSearch', {
    ImageBaseOrUrl: ImgUrl.value,
    pageindex: pageNum.value,
  }).then((res: any) => {
    if (isBase64(base64img)) {
      ImgUrl.value = res.ImageSearchUrl
      ImgSearchingList.value.unshift({ ImageUrl: res.ImageSearchUrl })
    }
    ProductsLoading.value = false
    ProductList.value =
      pageNum.value == 1 ? res.Goods : ProductList.value.concat(res.Goods)
  })
}

const isBase64 = (str: any) => {
  return (
    typeof str === 'string' &&
    str.startsWith('data:') &&
    str.includes(';base64,')
  )
}

const changeImgUrl = (url) => {
  store.base64img = url
  ImgUrl.value = url
  pageNum.value = 1
  // selectPageReq()
  setTimeout(() => {
    document.getElementsByClassName('ImgSearchingList')[0].scrollLeft =
      document.getElementsByClassName('isMasterImg')[0]['offsetLeft'] - 20
  }, 100)
}

const ChangeCategorie = (Categorie) => {
  CategorieRadio.value = Categorie.CategoryId
  route.query.input = null
  newQuery()
  userouter.push({
    path: '/searchList',
    query: { type: 0, input: null },
  })
}

const { pageNum, pageSize, handleCurrentChange, handleSizeChange } =
  PaginationHook(selectPageReq)

watch(
  [() => route.path, () => route.query.CategoryId, () => store.base64img],
  () => {
    pageNum.value = 1
    if (route.query.CategoryId) {
      CategorieRadio.value = Number(route.query.CategoryId)
    }
    if (route.path == '/searchList') {
      ImgUrl.value = null
      isImgUrl.value = !route.query.img ? false : true
    }
    selectPageReq()
  },
  {
    deep: true, // 深度监听
  }
)

onMounted(() => {
  pageSize.value = 20
  CategorieRadio.value = route.query.CategoryId
    ? Number(route.query.CategoryId)
    : null
  MallTypeId.value = route.query.type == 0 ? 1 : 4
  isImgUrl.value = route.query.img
  selectPageReq()
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_Select = _resolveComponent("Select")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_affix = _resolveComponent("el-affix")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isImgUrl.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_suffix_img, {
              class: "MainImage",
              url: ImgUrl.value
            }, null, 8, ["url"]),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ImgSearchingList.value, (Imgs, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["ImgSearching", { isMasterImg: ImgUrl.value == Imgs.ImageUrl }]),
                  key: index,
                  onClick: ($event: any) => (changeImgUrl(Imgs.ImageUrl))
                }, [
                  (ImgUrl.value == Imgs.ImageUrl)
                    ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Select)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_suffix_img, {
                    url: Imgs.ImageUrl
                  }, null, 8, ["url"])
                ], 10, _hoisted_5))
              }), 128))
            ])
          ]),
          (!ProductList.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(24, (i) => {
                  return _createVNode(ProductSkeleton, {
                    class: "CCard",
                    key: i
                  })
                }), 64))
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ProductList.value, (Product, index) => {
            return (_openBlock(), _createBlock(CommodityCard, {
              key: Product,
              data: Product,
              index: index,
              height: 290,
              class: "CCard"
            }, null, 8, ["data", "index"]))
          }), 128)),
          (ProductList.value)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(6 - (ProductList.value.length % 6), (placeholder) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "placeholderCard",
                  key: placeholder
                }))
              }), 128))
            : _createCommentVNode("", true),
          (!ErrMsg.value && ProductList.value?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_el_button, {
                  loading: ProductsLoading.value,
                  type: "danger",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (pageNum.value++, selectPageReq()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('更多')) + " ... ", 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("p", null, _toDisplayString(ErrMsg.value), 1)
              ]))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_affix, { offset: 40 }, {
      default: _withCtx(() => [
        (_unref(route).query.type && _unref(route).query.type != 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_ctx.$SiteSet['ShowMallType'])
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(MallTypeList.value, (MallType, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (MallType.show)
                          ? (_openBlock(), _createBlock(_component_el_radio, {
                              key: 0,
                              border: "",
                              modelValue: MallTypeId.value,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((MallTypeId).value = $event)),
                              label: index + 1,
                              size: "large",
                              onChange: ($event: any) => {; (MallTypeId.value = index + 1), newQuery()}
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("img", {
                                  src: MallType.url,
                                  style: _normalizeStyle(MallType?.style)
                                }, null, 12, _hoisted_11)
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "label", "onChange"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _withDirectives(_createVNode(_component_el_collapse_transition, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_el_radio, {
                      modelValue: CategorieRadio.value,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((CategorieRadio).value = $event)),
                      label: null,
                      onChange: _cache[3] || (_cache[3] = ($event: any) => {; (CategorieRadio.value = null), newQuery()})
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('全部')), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AllFrontCategories)?.Categories, (Categorie) => {
                      return (_openBlock(), _createBlock(_component_el_radio, {
                        key: Categorie,
                        modelValue: CategorieRadio.value,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((CategorieRadio).value = $event)),
                        label: Categorie.CategoryId,
                        size: "large",
                        onChange: ($event: any) => (ChangeCategorie(Categorie))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(Categorie.CategoryName), 1)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "label", "onChange"]))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 512), [
                [_vShow, _unref(route).query.type == 0]
              ]),
              _withDirectives(_createVNode(_component_el_collapse_transition, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Deliverys.value, (Country, index) => {
                      return (_openBlock(), _createBlock(_component_el_radio, {
                        key: index,
                        modelValue: Delivery.value,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((Delivery).value = $event)),
                        label: Country.val,
                        onChange: ($event: any) => {; (Delivery.value = Country.val), newQuery()}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(Country.name)), 1)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "label", "onChange"]))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 512), [
                [_vShow, _unref(route).query.type == 1]
              ]),
              _withDirectives(_createVNode(_component_el_collapse_transition, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_el_checkbox_group, {
                      modelValue: MallCategorieChecks.value,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((MallCategorieChecks).value = $event)),
                      onChange: _cache[7] || (_cache[7] = ($event: any) => (newQuery()))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(MallCategories.value, (MallC) => {
                          return (_openBlock(), _createBlock(_component_el_checkbox, {
                            key: MallC.CategoryId,
                            label: MallC.CategoryId
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(MallC.CategoryName), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ])
                ]),
                _: 1
              }, 512), [
                [_vShow, _unref(route).query.type == 1]
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_unref(route).query.type && _unref(route).query.type != 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            (_unref(route).query.type == 1)
              ? (_openBlock(), _createBlock(FilterType, {
                  key: 0,
                  Filters: Filters,
                  onFilterChange: newQuery
                }, null, 8, ["Filters"]))
              : _createCommentVNode("", true),
            (_unref(route).query.type == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(SortTypes.value, (type) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: type.val,
                      class: _normalizeClass({ SortType: SortType.value == type.val }),
                      onClick: ($event: any) => {; (SortType.value = type.val), newQuery()}
                    }, _toDisplayString(_ctx.$t(type.name)), 11, _hoisted_18))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_unref(route).query.type == 1)
              ? (_openBlock(), _createBlock(_component_el_pagination, {
                  key: 2,
                  currentPage: _unref(pageNum),
                  "onUpdate:currentPage": _cache[8] || (_cache[8] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : null)),
                  "page-size": _unref(pageSize),
                  onSizeChange: _unref(handleSizeChange),
                  onCurrentChange: _unref(handleCurrentChange),
                  total: total.value,
                  background: "",
                  layout: "prev, next"
                }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            (!ProductList.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(15, (i) => {
                    return _createVNode(ProductSkeleton, {
                      class: "CCard",
                      key: i
                    })
                  }), 64))
                ]))
              : _createCommentVNode("", true),
            (_unref(country) != 'kh')
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ProductList.value, (Product, index) => {
                    return (_openBlock(), _createBlock(CommodityCard, {
                      key: Product,
                      data: Product,
                      height: _unref(hightSet) == false ? 250 : 330,
                      index: index,
                      class: "CCard"
                    }, null, 8, ["data", "height", "index"]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ProductList.value, (Product, index) => {
                    return (_openBlock(), _createBlock(CommodityCard, {
                      key: Product,
                      data: Product,
                      index: index,
                      class: "CCard"
                    }, null, 8, ["data", "index"]))
                  }), 128))
                ])),
            (ProductList.value)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(5 - (ProductList.value.length % 5), (placeholder) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "placeholderCard",
                    key: placeholder
                  }))
                }), 128))
              : _createCommentVNode("", true)
          ]),
          _createVNode(EmptyArray, {
            show: ProductList.value && ProductList.value.length == 0
          }, null, 8, ["show"]),
          (_unref(route).query.type == 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createVNode(_component_el_pagination, {
                  currentPage: _unref(pageNum),
                  "onUpdate:currentPage": _cache[9] || (_cache[9] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : null)),
                  "page-size": _unref(pageSize),
                  onSizeChange: _unref(handleSizeChange),
                  onCurrentChange: _unref(handleCurrentChange),
                  total: total.value,
                  background: "",
                  layout: "prev, pager, next"
                }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"])
              ]))
            : (ProductList.value?.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createVNode(_component_el_button, {
                    loading: ProductsLoading.value,
                    type: "danger",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (pageNum.value++, selectPageReq()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('更多')) + " ... ", 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(route).query.type && _unref(route).query.type == 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _cache[12] || (_cache[12] = _createTextVNode(" \" ")),
            _createElementVNode("span", null, _toDisplayString(_unref(route).query.input), 1),
            _createTextVNode(" \" " + _toDisplayString(_ctx.$t('的相关店铺')), 1)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ProductList.value, (Product, index) => {
            return (_openBlock(), _createBlock(ShopCard, {
              class: "SearchShop",
              key: Product,
              data: Product,
              index: index
            }, null, 8, ["data", "index"]))
          }), 128)),
          _createVNode(EmptyArray, {
            show: ProductList.value && ProductList.value.length == 0
          }, null, 8, ["show"]),
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_el_pagination, {
              currentPage: _unref(pageNum),
              "onUpdate:currentPage": _cache[11] || (_cache[11] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : null)),
              "page-size": _unref(pageSize),
              onSizeChange: _unref(handleSizeChange),
              onCurrentChange: _unref(handleCurrentChange),
              total: total.value,
              background: "",
              layout: "prev, pager, next"
            }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})