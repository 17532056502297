import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "address_list" }
const _hoisted_2 = { class: "list" }

import { ref, reactive, onMounted, watch, toRefs } from 'vue'
import * as Api from '@/utils/api'
import * as Sign from '@/utils/sign'
import AddressCard from '@/components/user-center/address_card.vue'
import SetAdress from '@/components/user-center/set_adress.vue'
import Loading from '@/components/loading.vue'
import EmptyArray from '@/components/EmptyArray.vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'address_list',
  setup(__props) {

const store = useUsersStore()
const { AddressModified } = toRefs(store)

const route = useRoute()
const userouter = useRouter()

const ReceiveAddresses = ref(null)

const dialogInfo = reactive({
  isShow: false,
})

watch(
  () => AddressModified.value,
  (val) => {
    if (val) {
      GetAddresses()
    }
  },
  {
    deep: true,
  }
)

const GetAddresses = () => {
  ReceiveAddresses.value = null
  Api.Get('user/api/ReceiveAddresses_New/', {
    addressTypeId: 1,
  }).then((res: any) => {
    ReceiveAddresses.value = res.ReceiveAddresses
    store.AddressModified = false
  })
}

onMounted(() => {
  GetAddresses()
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_button, {
        class: "NewAddress",
        type: "danger",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (dialogInfo.isShow = true))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('新增收货地址')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ReceiveAddresses.value, (Address, index) => {
          return (_openBlock(), _createBlock(AddressCard, {
            item: Address,
            index: index,
            isDefault: true,
            setDefault: true,
            key: index
          }, null, 8, ["item", "index"]))
        }), 128))
      ]),
      _createVNode(EmptyArray, {
        show: ReceiveAddresses.value && !ReceiveAddresses.value.length
      }, null, 8, ["show"]),
      _withDirectives(_createVNode(Loading, { type: "13" }, null, 512), [
        [_vShow, !ReceiveAddresses.value]
      ])
    ]),
    _createVNode(SetAdress, { dialogInfo: dialogInfo }, null, 8, ["dialogInfo"])
  ], 64))
}
}

})