import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, unref as _unref, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"
import _imports_0 from '../../assets/imgs/FasPay.png'
import _imports_1 from '../../assets/imgs/alipay.png'
import _imports_2 from '../../assets/imgs/WingPay.png'
import _imports_3 from '../../assets/imgs/AbaKHQR.png'
import _imports_4 from '../../assets/imgs/AbaWeChat.png'


const _hoisted_1 = { class: "recharge" }
const _hoisted_2 = { class: "Banks" }
const _hoisted_3 = { class: "ImgTips" }
const _hoisted_4 = { class: "cardHeight" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "content" }
const _hoisted_8 = ["action"]
const _hoisted_9 = ["src"]

import { ref, reactive, onMounted, getCurrentInstance, markRaw } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import PaginationHook from '@/hooks/pagination'
import Loading from '@/components/loading.vue'
import * as Api from '@/utils/api'
import OSS from '@/components/oss.vue'
import { formatDate } from '@/utils/format'
import { WingFrom } from '@/utils/wingFrom'
import { CircleCheck } from '@element-plus/icons-vue'
import { SiteSet } from '@/utils/siteConfig'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'recharge',
  emits: ['updateValue'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()
const country = SiteSet().getPlacePredictions

const userouter = useRouter()

let { proxy } = getCurrentInstance() as any

const FinanceAccountList: any = ref(null)
const isType = ref(1)
const isLoading = ref(false)
const ImgTipUrl = ref(null)

const OfflineRef = ref<FormInstance>()
const OfflineForm = reactive({
  amount: 0,
  account_no: null,
  account_name: null,
  memo: null,
  bank_id: null,
  operation_time: null,
  ImgUrlList: [],
})

const ThirdPartyRef = ref<FormInstance>()
const ThirdPartyForm = reactive({
  Amount: 0,
  Memo: '',
  Channel: 1,
  NotifyUrl: location.href,
})

// 第三方支付步进器输入金额格式化为接口返回的倍数 MinRechargeAmount，向上取整
const changeAmount = () => {
  if (
    RechargeRate?.value.AmountMultiple &&
    ThirdPartyForm.Amount % RechargeRate?.value.AmountMultiple !== 0
  ) {
    if (ThirdPartyForm.Amount > RechargeRate?.value.MinRechargeAmount) {
      ThirdPartyForm.Amount =
        Math.ceil(ThirdPartyForm.Amount / RechargeRate?.value.AmountMultiple) *
        RechargeRate?.value.AmountMultiple
    } else {
      if (ThirdPartyForm.Amount !== 0)
        ThirdPartyForm.Amount = RechargeRate?.value.MinRechargeAmount
    }
  }
}
const validatePass = (rule: any, value: any, callback: any) => {
  if (value == 0) {
    callback(new Error(t('不能为0')))
  } else {
    callback()
  }
}

const ThirdPartyPass = (rule: any, value: any, callback: any) => {
  if (value == 0) {
    callback(new Error(t('不能为0')))
  } else if (
    value <= RechargeRate.value.MaxRechargeAmount &&
    value >= RechargeRate.value.MinRechargeAmount
  ) {
    callback()
  } else {
    callback(new Error(t('金额不满足条件')))
  }
}

const rules = reactive({
  amount: [{ required: true, validator: validatePass, trigger: 'change' }],
  bank_id: [{ required: true, message: t('不能为空'), trigger: 'change' }],
  operation_time: [
    { required: true, message: t('不能为空'), trigger: 'change' },
  ],
  ImgUrlList: [
    {
      type: 'array',
      required: true,
      message: t('不能为空'),
      trigger: 'change',
    },
  ],
})

const ThirdPartyRules = reactive({
  Amount: [{ required: true, validator: ThirdPartyPass, trigger: 'change' }],
})

const FAchange = () => {
  let FA = FinanceAccountList.value.find((res: any) => {
    return OfflineForm.bank_id == res.FinanceBankId
  })
  ImgTipUrl.value = FA.SampleImg || null
  OfflineForm.account_no = FA.AccountNo
  OfflineForm.account_name = FA.AccountName
}

const OfflineSubmit = () => {
  proxy.$refs['OfflineRef'].validate((valid: boolean) => {
    if (valid) {
      OfflineForm.operation_time = formatDate(
        OfflineForm.operation_time,
        'yyyy-MM-dd hh:mm:ss'
      )

      let body: any = Object.assign({ ImgUrlList: [] }, OfflineForm)
      body.ImgUrlList = body.ImgUrlList.map((item) => {
        return item.url
      })
      isLoading.value = true
      Api.Post('bank/api/BalanceAuditsByNew', body)
        .then((res: any) => {
          isLoading.value = false
          proxy.$refs['OfflineRef'].resetFields()
          let tips = t('提交成功!您的充值号为') + res.Request.SerialNumber
          ElMessageBox.confirm(tips, t('提示'), {
            dangerouslyUseHTMLString: true,
            confirmButtonText: t('我要购物'),
            cancelButtonText: t('继续充值'),
          }).then(() => {
            userouter.push('/')
          })
        })
        .catch((err) => {
          isLoading.value = false
        })
    }
  })
}
const fasDialogVisible = ref(false)
const CardType = ref(null)
const clickCard = (type) => {
  CardType.value = type
  fasDialogVisible.value = false
  ThirdPartySubmit()
}
// faspay 充值打开支付方式列表
const FasPaymentDialog = () => {
  proxy.$refs['ThirdPartyRef'].validate((valid: boolean) => {
    if (valid) fasDialogVisible.value = true
  })
  // abaDialogVisible1.value = true
}
const getImgUrl = (item) => {
  try {
    return require(`../../assets/imgs/Payment Channel Icon/${item.Icon}`)
  } catch (error) {
    return require(`../../assets/imgs/FasPay.png`)
  }
}
const abaFormUrl = ref('')
const emit = __emit
// 第三方充值支付
const ThirdPartySubmit = () => {
  proxy.$refs['ThirdPartyRef'].validate((valid: boolean) => {
    if (valid) {
      let params1 = {
        RechargeType: 'MPAY',
        PayType: 'ALIPAY',
      }

      let params2 = {
        RechargeType: 'Wing',
        PayType: '',
        IsApp: false,
      }
      let params4 = {
        PayType: 'abapay_khqr',
        RechargeType: 'ABA',
      }
      let params5 = {
        RechargeType: 'FASPAY',
        PayType: CardType.value,
      }
      let params6 = {
        PayType: 'wechat',
        RechargeType: 'ABA',
      }
      var typeParams: any = null
      switch (isType.value) {
        case 2:
          typeParams = params1
          break
        case 3:
          typeParams = params2
          break
        case 4:
          typeParams = params4
          break
        case 5:
          typeParams = params5
          break
        case 6:
          typeParams = params6
          break
        default:
          break
      }
      let params3 = Object.assign(typeParams, ThirdPartyForm)
      isLoading.value = true
      Api.Post('bank/api/OtherRecharges/', params3)
        .then((res: any) => {
          isLoading.value = false
          proxy.$refs['ThirdPartyRef'].resetFields()
          if (isType.value != 1 && isType.value != 2 && res.PaymentParams) {
            if (isType.value == 6 || isType.value == 4) {
              // <!-- ABA 支付提交表单  -->
              abaFormUrl.value = res.PaymentParams
              setTimeout(() => {
                AbaPayway.checkout()
              }, 100)
            } else {
              window.open(res.PaymentParams, '_blank')
            }
            // window.open(res.PaymentParams, '_self')
          } else {
            sessionStorage.setItem(
              'RechargePayUrl',
              isType.value == 2
                ? res.PaymentParams
                : WingFrom(res, ThirdPartyForm.NotifyUrl)
            )
            // window.open('https://www.loda.com.kh/wing_pay.html', '_blank')
            window.open(`${location.origin}/wing_pay.html`, '_blank')
          }
          if (isType.value != 6 && isType.value != 4) {
            ElMessageBox.confirm(
              t('支付完成后,请根据您支付的情况点击下面按钮'),
              t('提示'),
              {
                cancelButtonText: t('支付遇到问题'),
                confirmButtonText: t('支付完成'),
                type: 'success',
                icon: markRaw(CircleCheck),
              }
            )
              .then(() => {
                emit('updateValue', 'close')
              })
              .catch(() => {})
          }
        })
        .catch((err) => {
          isLoading.value = false
        })
    }
  })
}
const abaDialogVisible1 = ref(false)
const abaDialogUrl = ref(
  'https://debit-sandbox.faspay.co.id/pws/100003/0830000010100000/c8cae489a5862fe3d6b53265906a118ef209b1d9?trx_id=3574270791045815&merchant_id=35742&bill_no=PY202406040041'
)
const RechargeRate: any = ref(null)

const RechargeType = () => {
  switch (isType.value) {
    case 1:
      Api.Get('bank/api/BalanceAudits/', {}).then((res: any) => {
        FinanceAccountList.value = res.FinanceAccountList
      })
      break
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      RechargeRate.value = null
      var type: any = null
      switch (isType.value) {
        case 2:
          type = 'MPay'
          break
        case 3:
          type = 'Wing'
          break
        case 4:
          type = 'ABA'
          break
        case 5:
          type = 'FASPAY'
          break
        case 6:
          type = 'ABA'
          break
        default:
          break
      }
      Api.Get('basic/api/RechargeRate', { rechargetype: type }).then(
        (res: any) => {
          RechargeRate.value = res.PaymentConfigure
          // 印尼faspay 获取支付列表排序显示
          if (isType.value === 5) {
            const typeDict = {}
            const itemTypeMapping = {
              '1': t('电子钱包'),
              '2': t('线下支付'),
              '3': t('银行转账'),
              '4': t('网上银行支付'),
            }
            res.PaymentChannels.forEach((item) => {
              const itemType = item.Type.toString()
              if (!typeDict[itemType]) {
                typeDict[itemType] = {
                  type: itemType,
                  name: itemTypeMapping[itemType] || '',
                  list: [],
                }
              }
              typeDict[itemType].list.push(item)
            })
            RechargeRate.value.typeList = Object.values(typeDict)
            RechargeRate.value.typeList.forEach((item) => {
              item.list.sort((a, b) => a.Sort - b.Sort)
            })
          }
        }
      )
      break
  }
}

onMounted(() => {
  RechargeType()
})

return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      modelValue: isType.value,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((isType).value = $event)),
      type: "border-card",
      onTabChange: RechargeType
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          name: 1,
          label: _ctx.$t('线下充值')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form, {
              ref_key: "OfflineRef",
              ref: OfflineRef,
              model: OfflineForm,
              rules: rules,
              "label-position": "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('汇入银行'),
                  prop: "bank_id"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createVNode(Loading, { type: 4 }, null, 512), [
                      [_vShow, !FinanceAccountList.value]
                    ]),
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_el_radio_group, {
                        modelValue: OfflineForm.bank_id,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((OfflineForm.bank_id) = $event)),
                        onChange: _cache[1] || (_cache[1] = ($event: any) => (FAchange()))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(FinanceAccountList.value, (FA, index) => {
                            return (_openBlock(), _createBlock(_component_el_radio, {
                              key: index,
                              label: FA.FinanceBankId
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(FA.BankName) + " " + _toDisplayString(FA.AccountNo) + " " + _toDisplayString(FA.AccountName), 1)
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('充值金额'),
                  prop: "amount"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input_number, {
                      modelValue: OfflineForm.amount,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((OfflineForm.amount) = $event)),
                      precision: _unref(country) == 'id' ? 0 : 2,
                      step: 1,
                      min: 0,
                      "value-on-clear": 0
                    }, null, 8, ["modelValue", "precision"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('充值时间'),
                  prop: "operation_time"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, {
                      modelValue: OfflineForm.operation_time,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((OfflineForm.operation_time) = $event)),
                      type: "datetime",
                      clearable: false
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('充值证明'),
                  prop: "ImgUrlList"
                }, {
                  default: _withCtx(() => [
                    _createVNode(OSS, {
                      type: 2,
                      imgList: OfflineForm.ImgUrlList,
                      max: 1,
                      onImgsChange: _cache[4] || (_cache[4] = 
                (val) => {
                  OfflineForm.ImgUrlList = val
                }
              )
                    }, null, 8, ["imgList"]),
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t(
                  '请按照示例上传图片，图片不正确或者不清楚都会导致审核过慢或者审核不通过'
                )), 1),
                    _withDirectives(_createVNode(_component_el_image, {
                      class: "ImgTipUrl",
                      src: ImgTipUrl.value,
                      "preview-src-list": [ImgTipUrl.value],
                      lazy: ""
                    }, null, 8, ["src", "preview-src-list"]), [
                      [_vShow, ImgTipUrl.value]
                    ])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('备注'),
                  prop: "memo"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      rows: 4,
                      type: "textarea",
                      modelValue: OfflineForm.memo,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((OfflineForm.memo) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "large",
                      onClick: OfflineSubmit,
                      loading: isLoading.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["label"]),
        (_unref(country) == 'id')
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              name: 5,
              label: _ctx.$t('FasPay')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  ref_key: "ThirdPartyRef",
                  ref: ThirdPartyRef,
                  model: ThirdPartyForm,
                  rules: ThirdPartyRules,
                  "label-position": "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createElementVNode("img", {
                          class: "alipayLogo",
                          src: _imports_0
                        }, null, -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('充值金额'),
                      prop: "Amount"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input_number, {
                          modelValue: ThirdPartyForm.Amount,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((ThirdPartyForm.Amount) = $event)),
                          precision: 0,
                          step: RechargeRate.value?.AmountMultiple || 1,
                          min: RechargeRate.value?.MinRechargeAmount || 0,
                          disabled: !RechargeRate.value,
                          "value-on-clear": 0,
                          onChange: changeAmount
                        }, null, 8, ["modelValue", "step", "min", "disabled"]),
                        _createElementVNode("label", null, "  " + _toDisplayString(_ctx.$t('币种')) + ": " + _toDisplayString(_ctx.$SiteSet['CurrencyCode']), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_Transition, { name: "el-fade-in" }, {
                      default: _withCtx(() => [
                        (RechargeRate.value)
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 0,
                              class: "RechargeRate"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('最小充值金额')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(RechargeRate.value.MinRechargeAmount)), 1)
                                ]),
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('最大充值金额')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(RechargeRate.value.MaxRechargeAmount)), 1)
                                ]),
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('手续费')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(RechargeRate.value.Rate), 1)
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "danger",
                          size: "large",
                          onClick: FasPaymentDialog,
                          disabled: !RechargeRate.value,
                          loading: isLoading.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled", "loading"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        (_unref(country) == 'kh')
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 1,
              name: 2,
              label: _ctx.$t('支付宝充值')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  ref_key: "ThirdPartyRef",
                  ref: ThirdPartyRef,
                  model: ThirdPartyForm,
                  rules: ThirdPartyRules,
                  "label-position": "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createElementVNode("img", {
                          class: "alipayLogo",
                          src: _imports_1
                        }, null, -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('充值金额'),
                      prop: "Amount"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input_number, {
                          modelValue: ThirdPartyForm.Amount,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((ThirdPartyForm.Amount) = $event)),
                          precision: 2,
                          step: RechargeRate.value?.AmountMultiple || 1,
                          min: RechargeRate.value?.MinRechargeAmount || 0,
                          disabled: !RechargeRate.value,
                          "value-on-clear": 0,
                          onChange: changeAmount
                        }, null, 8, ["modelValue", "step", "min", "disabled"]),
                        _createElementVNode("label", null, "  " + _toDisplayString(_ctx.$t('币种')) + ": " + _toDisplayString(_ctx.$SiteSet['CurrencyCode']), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_Transition, { name: "el-fade-in" }, {
                      default: _withCtx(() => [
                        (RechargeRate.value)
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 0,
                              class: "RechargeRate"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('最小充值金额')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(RechargeRate.value.MinRechargeAmount)), 1)
                                ]),
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('最大充值金额')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(RechargeRate.value.MaxRechargeAmount)), 1)
                                ]),
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('手续费')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(RechargeRate.value.Rate), 1)
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "danger",
                          size: "large",
                          onClick: ThirdPartySubmit,
                          disabled: !RechargeRate.value,
                          loading: isLoading.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled", "loading"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        (_unref(country) == 'kh')
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 2,
              name: 3,
              label: _ctx.$t('Wing充值')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  ref_key: "ThirdPartyRef",
                  ref: ThirdPartyRef,
                  model: ThirdPartyForm,
                  rules: ThirdPartyRules,
                  "label-position": "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createElementVNode("img", {
                          class: "alipayLogo",
                          src: _imports_2
                        }, null, -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('充值金额'),
                      prop: "Amount"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input_number, {
                          modelValue: ThirdPartyForm.Amount,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((ThirdPartyForm.Amount) = $event)),
                          precision: 2,
                          step: RechargeRate.value?.AmountMultiple || 1,
                          min: RechargeRate.value?.MinRechargeAmount || 0,
                          disabled: !RechargeRate.value,
                          "value-on-clear": 0,
                          onChange: changeAmount
                        }, null, 8, ["modelValue", "step", "min", "disabled"]),
                        _createElementVNode("label", null, "  " + _toDisplayString(_ctx.$t('币种')) + ": " + _toDisplayString(_ctx.$SiteSet['CurrencyCode']), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_Transition, { name: "el-fade-in" }, {
                      default: _withCtx(() => [
                        (RechargeRate.value)
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 0,
                              class: "RechargeRate"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('最小充值金额')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(RechargeRate.value.MinRechargeAmount)), 1)
                                ]),
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('最大充值金额')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(RechargeRate.value.MaxRechargeAmount)), 1)
                                ]),
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('手续费')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(RechargeRate.value.Rate), 1)
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "danger",
                          size: "large",
                          onClick: ThirdPartySubmit,
                          disabled: !RechargeRate.value,
                          loading: isLoading.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled", "loading"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        (_unref(country) == 'kh')
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 3,
              name: 4,
              label: _ctx.$t('ABA KHQR')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  ref_key: "ThirdPartyRef",
                  ref: ThirdPartyRef,
                  model: ThirdPartyForm,
                  rules: ThirdPartyRules,
                  "label-position": "top"
                }, {
                  default: _withCtx(() => [
                    _cache[17] || (_cache[17] = _createElementVNode("div", { style: {"display":"flex","align-items":"center","margin-bottom":"18px"} }, [
                      _createElementVNode("img", {
                        class: "alipayLogo",
                        style: {"width":"80px","border-radius":"15px"},
                        src: _imports_3
                      }),
                      _createElementVNode("div", { class: "content" }, [
                        _createElementVNode("div", null, "ABA KHQR"),
                        _createElementVNode("div", null, "Scan to pay with any banking app")
                      ])
                    ], -1)),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('充值金额'),
                      prop: "Amount"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input_number, {
                          modelValue: ThirdPartyForm.Amount,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((ThirdPartyForm.Amount) = $event)),
                          precision: 2,
                          step: RechargeRate.value?.AmountMultiple || 1,
                          min: RechargeRate.value?.MinRechargeAmount || 0,
                          disabled: !RechargeRate.value,
                          "value-on-clear": 0,
                          onChange: changeAmount
                        }, null, 8, ["modelValue", "step", "min", "disabled"]),
                        _createElementVNode("label", null, "  " + _toDisplayString(_ctx.$t('币种')) + ": " + _toDisplayString(_ctx.$SiteSet['CurrencyCode']), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_Transition, { name: "el-fade-in" }, {
                      default: _withCtx(() => [
                        (RechargeRate.value)
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 0,
                              class: "RechargeRate"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('最小充值金额')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(RechargeRate.value.MinRechargeAmount)), 1)
                                ]),
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('最大充值金额')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(RechargeRate.value.MaxRechargeAmount)), 1)
                                ]),
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('手续费')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(RechargeRate.value.Rate), 1)
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "danger",
                          size: "large",
                          onClick: ThirdPartySubmit,
                          disabled: !RechargeRate.value,
                          loading: isLoading.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled", "loading"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        (_unref(country) == 'kh')
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 4,
              name: 6,
              label: _ctx.$t('微信充值')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  ref_key: "ThirdPartyRef",
                  ref: ThirdPartyRef,
                  model: ThirdPartyForm,
                  rules: ThirdPartyRules,
                  "label-position": "top"
                }, {
                  default: _withCtx(() => [
                    _cache[18] || (_cache[18] = _createElementVNode("div", { style: {"display":"flex","align-items":"center","margin-bottom":"18px"} }, [
                      _createElementVNode("img", {
                        class: "alipayLogo",
                        style: {"width":"80px","border-radius":"15px"},
                        src: _imports_4
                      }),
                      _createElementVNode("div", { class: "content" }, [
                        _createElementVNode("div", null, "WeChat"),
                        _createElementVNode("div", null, "Scan to pay with WeChat")
                      ])
                    ], -1)),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('充值金额'),
                      prop: "Amount"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input_number, {
                          modelValue: ThirdPartyForm.Amount,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((ThirdPartyForm.Amount) = $event)),
                          precision: 2,
                          step: RechargeRate.value?.AmountMultiple || 1,
                          min: RechargeRate.value?.MinRechargeAmount || 0,
                          disabled: !RechargeRate.value,
                          "value-on-clear": 0,
                          onChange: changeAmount
                        }, null, 8, ["modelValue", "step", "min", "disabled"]),
                        _createElementVNode("label", null, "  " + _toDisplayString(_ctx.$t('币种')) + ": " + _toDisplayString(_ctx.$SiteSet['CurrencyCode']), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_Transition, { name: "el-fade-in" }, {
                      default: _withCtx(() => [
                        (RechargeRate.value)
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 0,
                              class: "RechargeRate"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('最小充值金额')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(RechargeRate.value.MinRechargeAmount)), 1)
                                ]),
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('最大充值金额')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(RechargeRate.value.MaxRechargeAmount)), 1)
                                ]),
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('手续费')) + ": ", 1),
                                  _createElementVNode("span", null, _toDisplayString(RechargeRate.value.Rate), 1)
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "danger",
                          size: "large",
                          onClick: ThirdPartySubmit,
                          disabled: !RechargeRate.value,
                          loading: isLoading.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled", "loading"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      title: _ctx.$t('支付方式'),
      modelValue: fasDialogVisible.value,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((fasDialogVisible).value = $event)),
      width: "550px"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(RechargeRate.value?.typeList, (item, i) => {
            return (_openBlock(), _createElementBlock("div", null, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, (item2, i2) => {
                return (_openBlock(), _createBlock(_component_el_card, {
                  class: "cardClass",
                  onClick: ($event: any) => (clickCard(item2.Code)),
                  key: i2
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("img", {
                            src: getImgUrl(item2)
                          }, null, 8, _hoisted_6)
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", null, _toDisplayString(item2.Name), 1)
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]))
          }), 256))
        ])
      ]),
      _: 1
    }, 8, ["title", "modelValue"]),
    _createElementVNode("form", {
      method: "GET",
      target: "aba_webservice",
      action: abaFormUrl.value,
      id: "aba_merchant_request"
    }, null, 8, _hoisted_8),
    _createVNode(_component_el_dialog, {
      title: _ctx.$t('支付'),
      modelValue: abaDialogVisible1.value,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((abaDialogVisible1).value = $event)),
      width: "550px"
    }, {
      default: _withCtx(() => [
        _createElementVNode("iframe", {
          frameborder: "no",
          border: "0",
          marginwidth: "0",
          style: {"width":"100%","height":"600px"},
          src: abaDialogUrl.value
        }, null, 8, _hoisted_9)
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}
}

})