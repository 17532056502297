import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

import { ref, reactive, onMounted, watch, getCurrentInstance } from 'vue'
import * as Api from '@/utils/api'
import { useRoute } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'notice',
  setup(__props) {

const { proxy } = getCurrentInstance()
const route = useRoute()
const title = ref(null)
const content = ref(null)
onMounted(() => {
  Api.Get('basic/api/Notice/', {
    Id: route.query.id,
  }).then((res: any) => {
    title.value = res.Notices[0].Title
    content.value = res.Notices[0].Content
    document.title = res.Notices[0].Title + ' - ' + proxy.$SiteSet?.LODA
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", {
      class: "title",
      innerHTML: title.value
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      class: "content",
      innerHTML: content.value
    }, null, 8, _hoisted_3)
  ]))
}
}

})