import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "register" }

import { ref, reactive, watch, toRefs, getCurrentInstance } from 'vue'
import { onMounted } from '@vue/runtime-core'
import { ElMessageBox } from 'element-plus'
import * as Api from '@/utils/api'
import RegionSelection from '@/components/RegionSelection.vue'
import countDown from '@/utils/countDown'
import Article from '@/components/article.vue'
import { textContent } from '@/utils/format'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useUsersStore } from '@/pinia'
import Password from '@/components/password.vue'

import type { FormInstance } from 'element-plus'

export default /*@__PURE__*/_defineComponent({
  __name: 'Register',
  setup(__props) {

const { proxy } = getCurrentInstance()
const store = useUsersStore()
const { EmployessList, Token } = toRefs(store)

const { t } = useI18n()
const Router = useRouter()

const ruleFormRef = ref<FormInstance>()
const CodeLoading = ref(false)

const { state: CodeTime, start: startTimeout, check_count: CCD } = countDown()

const userAreaCode = ref(null)
const LoginNameRegex = ref(null)
const AreaCode = (val) => {
  userAreaCode.value = val
}

const ruleForm = reactive({
  LoginName: null,
  Password: null,
  ConfirmPassword: null,
  PayPwd: null,
  ConfirmPayPwd: null,
  SalesId: null,
  RegisterDevice: null,
  VerificationCode: null,
  VerificationType: proxy.$SiteSet['NoEmail'] ? 'Email' : 'Mobile',
  Agreement: false,
})

const VCode = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validateField('RegisterDevice', (valid) => {
    if (valid) {
      CodeLoading.value = true
      Api.Get('user/api/SendMessage/', {
        SendTo: ruleForm.RegisterDevice,
        SendChannel: 1,
      }).then(
        (res) => {
          startTimeout()
          ElMessageBox.alert(t('已发送验证码,请注意查收'), t('提示'), {
            confirmButtonText: t('确定'),
          })
        },
        (err) => {
          CodeLoading.value = false
        }
      )
    }
  })
}

const imeiKeyDow = (e) => {
  if (e.keyCode == 32) {
    e.returnValue = false
  }
}

const checkPass = (rule: any, value: any, callback: any) => {
  let chackValue =
    rule.fullField == 'ConfirmPassword' ? ruleForm.Password : ruleForm.PayPwd
  if (rule.fullField == 'ConfirmPayPwd' && !ruleForm.PayPwd) {
    callback()
    return
  }
  if (value != chackValue) {
    callback(new Error(t('密码不一致')))
  } else {
    callback()
  }
}

const DevicePass = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error(t('不能为空')))
  } else {
    if (ruleForm.VerificationType == 'Email') {
      const reg = new RegExp(
        '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$'
      )
      !reg.test(value) ? callback(new Error(t('请输入正确的邮箱'))) : callback()
    } else {
      !eval(userAreaCode.value.Regular).test(value)
        ? callback(new Error(t('请输入正确手机号')))
        : callback()
    }
  }
}

const UserNamePass = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error(t('不能为空')))
  } else if (!LoginNameRegex.value.test(value)) {
    callback(
      new Error(
        t(
          '支持英文、数字、"-"、"."、"_"的组合，4-20个字符。不能纯数字，不支持邮箱'
        )
      )
    )
  } else {
    Api.Get('user/api/CustomerRegister/', {
      LoginName: value,
      IsCn: textContent(false),
      LoginChannel: 1,
    })
      .then((res: any) => {
        callback()
      })
      .catch((err) => {
        callback(new Error(err.ErrMsg))
      })
  }
}

const rules = reactive({
  LoginName: [{ required: true, validator: UserNamePass, trigger: 'blur' }],
  Password: [
    { required: true, message: t('请输入'), trigger: 'change' },
    { min: 6, message: t('最少6位'), trigger: 'change' },
  ],
  ConfirmPassword: [{ required: true, validator: checkPass, trigger: 'blur' }],
  RegisterDevice: [{ required: true, validator: DevicePass }],
  VerificationCode: [{ required: true, message: t('请输入'), trigger: 'blur' }],
  PayPwd: [{ message: t('请输入'), trigger: 'blur' }],
  ConfirmPayPwd: [{ validator: checkPass, trigger: 'blur' }],
})

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      let IsCn =
        ruleForm.VerificationType == 'Email' ? false : userAreaCode.value.IsCn
      const body: any = {
        VerificationType: ruleForm.VerificationType,
        SalesId: ruleForm.SalesId,
        PayPwd: ruleForm.PayPwd,
        LoginName: ruleForm.LoginName,
        Password: ruleForm.Password,
        IsCn: textContent(IsCn),
        LoginChannel: 1,
        VerificationCode: ruleForm.VerificationCode,
      }
      if (ruleForm.VerificationType == 'Email') {
        body.Email = ruleForm.RegisterDevice
      } else {
        body.Phone = ruleForm.RegisterDevice
      }
      Api.Post('user/api/CustomerRegister/', body).then((res: any) => {
        if (!res.IsError) {
          ElMessageBox.alert(t('自动跳转登录'), t('注册成功'), {
            confirmButtonText: t('确定'),
          }).then(() => {
            Api.Post('auth/api/Login/', {
              LoginName: ruleForm.LoginName,
              Password: ruleForm.Password,
              LoginChannel: 1,
            }).then((res1: any) => {
              store.setToken(res1.AccessToken)
              Router.push('/')
            })
          })
        }
      })
    }
  })
}

watch(
  () => CodeTime.count,
  (newV, oldV) => {
    CodeLoading.value = newV == 0 ? false : true
  },
  {
    immediate: true, // 立即执行
    deep: true, // 深度监听
  }
)

watch(
  () => Token.value,
  (val) => {
    if (val) {
      Router.push('/')
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  LoginNameRegex.value = new RegExp(
    JSON.parse(localStorage.getItem('GetAppConfig'))?.LoginNameRegex ||
      '^(?=.*[a-zA-Z])(?!.*@)(?!.*\\.$)(?!^[._-])[a-zA-Z0-9._-]{4,20}$'
  )
  store.setState('user/api/CRM/', { NoLanguage: true }, 'EmployessList')
})

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('填写注册信息')), 1),
      _createVNode(_component_el_form, {
        ref_key: "ruleFormRef",
        ref: ruleFormRef,
        model: ruleForm,
        rules: rules,
        "label-width": "200px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('用户名'),
            prop: "LoginName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: _ctx.$t('请输入用户名'),
                modelValue: ruleForm.LoginName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ruleForm.LoginName) = $event)),
                onBlur: _cache[1] || (_cache[1] = ($event: any) => (ruleForm.LoginName = $event.target.value.trim())),
                onKeydown: _cache[2] || (_cache[2] = ($event: any) => (imeiKeyDow($event)))
              }, null, 8, ["placeholder", "modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('登录密码'),
            prop: "Password"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: _ctx.$t('请输入登录密码'),
                modelValue: ruleForm.Password,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((ruleForm.Password) = $event)),
                onBlur: _cache[4] || (_cache[4] = ($event: any) => (ruleForm.Password = $event.target.value.trim())),
                type: "password",
                "show-password": ""
              }, null, 8, ["placeholder", "modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('再次输入登录密码'),
            prop: "ConfirmPassword"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: _ctx.$t('请再次输入登录密码'),
                modelValue: ruleForm.ConfirmPassword,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((ruleForm.ConfirmPassword) = $event)),
                type: "password",
                "show-password": ""
              }, null, 8, ["placeholder", "modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          (_ctx.$SiteSet['NoEmail'])
            ? (_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio_group, {
                    modelValue: ruleForm.VerificationType,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((ruleForm.VerificationType) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio_button, {
                        label: "Email",
                        size: "large"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)('邮箱')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_radio_button, {
                        label: "Mobile",
                        size: "large"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)('电话')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (ruleForm.VerificationType == 'Email')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                label: _ctx.$t('邮箱'),
                prop: "RegisterDevice"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    placeholder: _ctx.$t('请输入邮箱'),
                    modelValue: ruleForm.RegisterDevice,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((ruleForm.RegisterDevice) = $event)),
                    onBlur: _cache[8] || (_cache[8] = ($event: any) => (ruleForm.RegisterDevice = $event.target.value.trim()))
                  }, null, 8, ["placeholder", "modelValue"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          (ruleForm.VerificationType == 'Mobile')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 2,
                label: _ctx.$t('手机号'),
                prop: "RegisterDevice"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: ruleForm.RegisterDevice,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((ruleForm.RegisterDevice) = $event)),
                    onBlur: _cache[10] || (_cache[10] = ($event: any) => (ruleForm.RegisterDevice = $event.target.value.trim())),
                    placeholder: _ctx.$t('请输入手机号'),
                    "prefix-icon": "Iphone"
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(RegionSelection, { onAreaCode: AreaCode })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('验证码'),
            class: "VerificationCode",
            prop: "VerificationCode"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: ruleForm.VerificationCode,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((ruleForm.VerificationCode) = $event)),
                placeholder: _ctx.$t('请输入验证码')
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_component_el_button, {
                loading: CodeLoading.value,
                onClick: _cache[12] || (_cache[12] = ($event: any) => (VCode(ruleFormRef.value)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(!CodeLoading.value ? _ctx.$t('获取验证码') : _unref(CodeTime).count), 1)
                ]),
                _: 1
              }, 8, ["loading"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('交易密码'),
            prop: "PayPwd"
          }, {
            default: _withCtx(() => [
              _createVNode(Password, {
                modelValue: ruleForm.PayPwd,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((ruleForm.PayPwd) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_collapse_transition, null, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_el_form_item, {
                label: _ctx.$t('再次输入交易密码'),
                prop: "ConfirmPayPwd"
              }, {
                default: _withCtx(() => [
                  _createVNode(Password, {
                    modelValue: ruleForm.ConfirmPayPwd,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((ruleForm.ConfirmPayPwd) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]), [
                [_vShow, ruleForm.PayPwd]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('业务员')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: ruleForm.SalesId,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((ruleForm.SalesId) = $event)),
                placeholder: _unref(t)('请选择业务员'),
                clearable: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(EmployessList)?.EmployessList, (Employess) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: Employess.EmployeeId,
                      label: Employess.EmployeeNo,
                      value: Employess.EmployeeId
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_checkbox, {
                modelValue: ruleForm.Agreement,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((ruleForm.Agreement) = $event)),
                label: _ctx.$t('我已阅读并同意'),
                size: "large"
              }, null, 8, ["modelValue", "label"]),
              _createVNode(Article, { id: 1 }, {
                name: _withCtx(() => [
                  _createTextVNode(" 《" + _toDisplayString(_ctx.$t('用户协议')) + "》 ", 1)
                ]),
                _: 1
              }),
              _createVNode(Article, { id: 7 }, {
                name: _withCtx(() => [
                  _createTextVNode(" 《" + _toDisplayString(_ctx.$t(_ctx.$SiteSet['LODA'] + '隐私权政策')) + "》 ", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                size: "large ",
                type: "danger",
                onClick: _cache[17] || (_cache[17] = ($event: any) => (submitForm(ruleFormRef.value))),
                disabled: !ruleForm.Agreement
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('注册')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ])
  ]))
}
}

})