import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "site_query" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "isWarehouses"
}
const _hoisted_4 = ["onClick"]

import { watch, ref, onMounted } from 'vue'
import { GoogleMap, InfoWindow, Marker } from 'vue3-google-map'
import { ElMessageBox } from 'element-plus'
import { useI18n } from 'vue-i18n'
import * as Api from '@/utils/api'
import { SiteSet } from '@/utils/siteConfig'
import { mapKey } from '@/main'

export default /*@__PURE__*/_defineComponent({
  __name: 'site_query',
  setup(__props) {

const { t } = useI18n()

const showSelect = ref(false)
const isDefault = ref(SiteSet().DefaultMapcenter)
const center = ref(isDefault.value)
const zoom = ref(12)
const Cities = ref(null)
const CityName = ref(null)
const Warehouses = ref(null)

const WarehouseDetails = (item) => {
  zoom.value = 18
  center.value = {
    lat: Number(item.LatSize),
    lng: Number(item.LngSize),
  }

  ElMessageBox.alert(
    t('联系人') +
    ': ' +
    item.Consignee +
    '<br>' +
    t('联系电话') +
    ': ' +
    item.Mobile +
    '<br>' +
    t('地址') +
    ': ' +
    item.Address +
    '<br>',
    item.WarehouseName,
    {
      dangerouslyUseHTMLString: true,
    }
  )
}

const GetWarehouses = (City) => {
  showSelect.value = false
  Warehouses.value = null
  CityName.value = City.CityName
  Api.Get('basic/api/GetSignWarehouse', {
    city_id: City.CityId,
  }).then((res: any) => {
    Warehouses.value = res.Warehouses
    center.value = {
      lat: Number(res.Warehouses[0].LatSize),
      lng: Number(res.Warehouses[0].LngSize),
    }
  })
}

onMounted(() => {
  Api.Get('basic/api/GetCitiesByCountryCode/', {}).then((res: any) => {
    Cities.value = res.Cities
  })
})

return (_ctx: any,_cache: any) => {
  const _component_Search = _resolveComponent("Search")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Shop = _resolveComponent("Shop")!
  const _component_ArrowRightBold = _resolveComponent("ArrowRightBold")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "MapSelect",
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (showSelect.value = false))
    }, [
      _createElementVNode("span", {
        onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (showSelect.value = true))
      }, [
        _createTextVNode(_toDisplayString(CityName.value ? CityName.value : _ctx.$t('按城市查询')) + " ", 1),
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_Search)
          ]),
          _: 1
        })
      ], 32),
      _createVNode(_Transition, { name: "el-fade-in" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Cities.value, (Citie) => {
              return (_openBlock(), _createElementBlock("label", {
                key: Citie.CityId,
                onClick: ($event: any) => (GetWarehouses(Citie))
              }, _toDisplayString(Citie.CityName), 9, _hoisted_2))
            }), 128))
          ], 512), [
            [_vShow, showSelect.value]
          ])
        ]),
        _: 1
      })
    ], 32),
    _createVNode(_Transition, { name: "el-fade-in" }, {
      default: _withCtx(() => [
        (Warehouses.value)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Warehouses.value, (Warehouse) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: Warehouse.WarehouseId,
                  onClick: ($event: any) => (WarehouseDetails(Warehouse))
                }, [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Shop)
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(Warehouse.WarehouseName) + " ", 1),
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ArrowRightBold)
                    ]),
                    _: 1
                  })
                ], 8, _hoisted_4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_unref(GoogleMap), {
      ref: "mapRef",
      "api-key": _unref(mapKey),
      style: {"width":"100%","height":"600px"},
      center: center.value,
      zoom: zoom.value,
      minZoom: 11,
      clickableIcons: false,
      "disable-default-ui": true,
      language: "en"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Warehouses.value, (Warehouse) => {
          return (_openBlock(), _createBlock(_unref(Marker), {
            key: Warehouse.WarehouseId,
            options: {
        position: {
          lat: Number(Warehouse.LatSize),
          lng: Number(Warehouse.LngSize),
        },
      }
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(InfoWindow), null, {
                default: _withCtx(() => [
                  _createElementVNode("b", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('联系人')) + ": " + _toDisplayString(Warehouse.Consignee), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('联系电话')) + ": " + _toDisplayString(Warehouse.Mobile), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('地址')) + ": " + _toDisplayString(Warehouse.Address), 1)
                  ])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["options"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["api-key", "center", "zoom"])
  ]))
}
}

})