import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "transaction-password" }

import { ref, reactive, onMounted, watch, toRefs } from 'vue'
import type { FormInstance } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import * as Api from '@/utils/api'
import countDown from '@/utils/countDown'
import { textContent } from '@/utils/format'
import { useI18n } from 'vue-i18n'
import Password from '@/components/password.vue'
import { useUsersStore } from '@/pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'transaction-password',
  setup(__props) {

const store = useUsersStore()
const { CustomerSecureInfo, AreaCodeList } = toRefs(store)

const { t } = useI18n()
const { state: CodeTime, start: startTimeout, check_count: CCD } = countDown()

const activeName = ref('first')
const active = ref(1)
const ruleFormRef = ref<FormInstance>()
const oldFormRef = ref<FormInstance>()

const CodeLoading = ref(false)
const isLoading = ref(false)

const ruleForm = reactive({
  VerificationAccount: null,
  NewPassword: null,
  VerificationCode: null,
  ConfirmPayPwd: null,
  IsOldPasswordVerification: 'False',
})

const oldForm = reactive({
  OldPassword: null,
  NewPassword: null,
  ConfirmPayPwd: null,
  IsOldPasswordVerification: 'True',
})
const { CustomerInfo } = toRefs(store)

const checkPass = (rule: any, value: any, callback: any) => {
  const NewPassword =
    activeName.value == 'first' ? oldForm.NewPassword : ruleForm.NewPassword
  console.log(value)
  console.log(NewPassword)
  console.log(activeName.value)
  console.log(activeName.value)
  if (value != NewPassword) {
    callback(new Error(t('密码不一致')))
  } else {
    callback()
  }
}

const rules = reactive({
  VerificationCode: [
    {
      required: CustomerInfo.value.Customer.HasPayPassword ? true : false,
      message: t('请输入'),
      trigger: 'blur',
    },
  ],
  NewPassword: [{ required: true, message: t('请输入'), trigger: 'blur' }],
  ConfirmPayPwd: [{ required: true, trigger: 'blur', validator: checkPass }],
})

const oldRules = reactive({
  OldPassword: [{ required: true, message: t('请输入'), trigger: 'blur' }],
  NewPassword: [{ required: true, message: t('请输入'), trigger: 'blur' }],
  ConfirmPayPwd: [{ required: true, trigger: 'blur', validator: checkPass }],
})

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      isLoading.value = true
      const body = activeName.value == 'first' ? oldForm : ruleForm
      delete body.ConfirmPayPwd
      Api.Post('user/api/CustomerDrawPassword/', body).then(
        (res) => {
          store.setState(
            'user/api/CustomerSecureInfo/',
            {},
            'CustomerSecureInfo',
            true
          )
          store.NeedUpdatePayPassword = false
          store.setState('user/api/CustomerInfo/', {}, 'CustomerInfo', true)
          isLoading.value = false
          active.value = 2
        },
        (err) => {
          isLoading.value = false
        }
      )
    }
  })
}

const VCode = () => {
  CodeLoading.value = true
  Api.Get('user/api/SendMessage/', {
    SendTo: ruleForm.VerificationAccount,
    SendChannel: 6,
  }).then(
    (res) => {
      startTimeout()
      ElMessageBox.alert(t('已发送验证码,请注意查收'), t('提示'), {
        confirmButtonText: t('确定'),
      })
    },
    (err) => {
      CodeLoading.value = false
    }
  )
}

watch(
  () => CodeTime.count,
  (newV, oldV) => {
    CodeLoading.value = newV == 0 ? false : true
  },
  {
    immediate: true, // 立即执行
    deep: true, // 深度监听
  }
)

const Email = ref(null)
const Mobile = ref(null)

watch(
  () => CustomerSecureInfo.value,
  (val) => {
    if (val) {
      Email.value = val.Email
      Mobile.value = val.Mobile
      ruleForm.VerificationAccount = Email.value || Mobile.value
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  console.log(CustomerInfo.value.Customer.HasPayPassword)
  if (CustomerInfo.value.Customer.HasPayPassword) {
    activeName.value = 'second'
  }
  console.log(store.NeedUpdatePayPassword)
  if (
    !store.NeedUpdatePayPassword ||
    !CustomerInfo.value.Customer.HasPayPassword
  ) {
    activeName.value = 'second'
  }
  CCD()
  store.setState('user/api/SendMessage', {}, 'AreaCodeList')
  store.setState('user/api/CustomerSecureInfo/', {}, 'CustomerSecureInfo')
})

return (_ctx: any,_cache: any) => {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_steps, {
      space: 200,
      active: active.value,
      simple: "",
      class: "steps"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_step, {
          title: _ctx.$t('设置交易密码'),
          icon: "Edit"
        }, null, 8, ["title"]),
        _createVNode(_component_el_step, {
          title: _ctx.$t('完成'),
          icon: "Select"
        }, null, 8, ["title"])
      ]),
      _: 1
    }, 8, ["active"]),
    _withDirectives(_createVNode(_component_el_tabs, {
      type: "border-card",
      modelValue: activeName.value,
      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((activeName).value = $event)),
      class: "password-tabs"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('验证码修改'),
          name: "first"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form, {
              class: "ruleForm",
              ref_key: "ruleFormRef",
              ref: ruleFormRef,
              model: ruleForm,
              rules: rules,
              "label-position": "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('验证方式')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio_group, {
                      modelValue: ruleForm.VerificationAccount,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ruleForm.VerificationAccount) = $event))
                    }, {
                      default: _withCtx(() => [
                        (Email.value)
                          ? (_openBlock(), _createBlock(_component_el_radio, {
                              key: 0,
                              label: Email.value
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (Mobile.value)
                          ? (_openBlock(), _createBlock(_component_el_radio, {
                              key: 1,
                              label: Mobile.value
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('验证码'),
                  class: "VerificationCode",
                  prop: "VerificationCode"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: ruleForm.VerificationCode,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ruleForm.VerificationCode) = $event)),
                      placeholder: _ctx.$t('请输入验证码')
                    }, null, 8, ["modelValue", "placeholder"]),
                    _createVNode(_component_el_button, {
                      loading: CodeLoading.value,
                      onClick: VCode
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(!CodeLoading.value ? _ctx.$t('获取验证码') : _unref(CodeTime).count), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('设置支付密码'),
                  prop: "NewPassword"
                }, {
                  default: _withCtx(() => [
                    _createVNode(Password, {
                      modelValue: ruleForm.NewPassword,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ruleForm.NewPassword) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('确认支付密码'),
                  prop: "ConfirmPayPwd"
                }, {
                  default: _withCtx(() => [
                    _createVNode(Password, {
                      modelValue: ruleForm.ConfirmPayPwd,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((ruleForm.ConfirmPayPwd) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      class: "submitBtn",
                      size: "large ",
                      type: "danger",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (submitForm(ruleFormRef.value))),
                      loading: isLoading.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["label"]),
        (
          _unref(store).NeedUpdatePayPassword && _unref(CustomerInfo).Customer.HasPayPassword
        )
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: _ctx.$t('原密码修改'),
              name: "first"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  class: "ruleForm",
                  ref_key: "oldFormRef",
                  ref: oldFormRef,
                  model: oldForm,
                  rules: oldRules,
                  "label-position": "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('请输入原密码'),
                      prop: "OldPassword"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: oldForm.OldPassword,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((oldForm.OldPassword) = $event)),
                          type: "password",
                          "show-password": "",
                          placeholder: _ctx.$t('原密码')
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('设置支付密码'),
                      prop: "NewPassword"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(Password, {
                          modelValue: oldForm.NewPassword,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((oldForm.NewPassword) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('确认支付密码'),
                      prop: "ConfirmPayPwd"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(Password, {
                          modelValue: oldForm.ConfirmPayPwd,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((oldForm.ConfirmPayPwd) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          class: "submitBtn",
                          size: "large ",
                          type: "danger",
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (submitForm(oldFormRef.value))),
                          loading: isLoading.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
                          ]),
                          _: 1
                        }, 8, ["loading"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_tab_pane, {
          label: 
          _unref(CustomerInfo).Customer.HasPayPassword
            ? _ctx.$t('验证码修改')
            : _ctx.$t('设置交易密码')
        ,
          name: "second"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form, {
              class: "ruleForm",
              ref_key: "ruleFormRef",
              ref: ruleFormRef,
              model: ruleForm,
              rules: rules,
              "label-position": "top"
            }, {
              default: _withCtx(() => [
                (_unref(CustomerInfo).Customer.HasPayPassword)
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 0,
                      label: _ctx.$t('验证方式')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio_group, {
                          modelValue: ruleForm.VerificationAccount,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((ruleForm.VerificationAccount) = $event))
                        }, {
                          default: _withCtx(() => [
                            (Email.value)
                              ? (_openBlock(), _createBlock(_component_el_radio, {
                                  key: 0,
                                  label: Email.value
                                }, null, 8, ["label"]))
                              : _createCommentVNode("", true),
                            (Mobile.value)
                              ? (_openBlock(), _createBlock(_component_el_radio, {
                                  key: 1,
                                  label: Mobile.value
                                }, null, 8, ["label"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"]))
                  : _createCommentVNode("", true),
                (_unref(CustomerInfo).Customer.HasPayPassword)
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 1,
                      label: _ctx.$t('验证码'),
                      class: "VerificationCode",
                      prop: "VerificationCode"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: ruleForm.VerificationCode,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((ruleForm.VerificationCode) = $event)),
                          placeholder: _ctx.$t('请输入验证码')
                        }, null, 8, ["modelValue", "placeholder"]),
                        _createVNode(_component_el_button, {
                          loading: CodeLoading.value,
                          onClick: VCode
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(!CodeLoading.value ? _ctx.$t('获取验证码') : _unref(CodeTime).count), 1)
                          ]),
                          _: 1
                        }, 8, ["loading"])
                      ]),
                      _: 1
                    }, 8, ["label"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('设置支付密码'),
                  prop: "NewPassword"
                }, {
                  default: _withCtx(() => [
                    _createVNode(Password, {
                      modelValue: ruleForm.NewPassword,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((ruleForm.NewPassword) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('确认支付密码'),
                  prop: "ConfirmPayPwd"
                }, {
                  default: _withCtx(() => [
                    _createVNode(Password, {
                      modelValue: ruleForm.ConfirmPayPwd,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((ruleForm.ConfirmPayPwd) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      class: "submitBtn",
                      size: "large ",
                      type: "primary",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (submitForm(ruleFormRef.value))),
                      loading: isLoading.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["modelValue"]), [
      [_vShow, active.value == 1]
    ]),
    (active.value == 2)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true)
  ]))
}
}

})