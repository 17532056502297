import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "MainBusinessClassification" }
const _hoisted_2 = { class: "navigation" }
const _hoisted_3 = ["onClick"]

import { watch, ref, reactive, getCurrentInstance } from 'vue'
import router from '@/router/index'
import Categories from '@/components/Categories.vue'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'MainBusinessClassification',
  setup(__props) {

const { proxy } = getCurrentInstance()
const Router = useRouter()

const routers = reactive(router)

const isShow = ref(false)

const RouteName = ref(null)

const type = ref(null)

const Routes = ref([
  {
    name: proxy?.$SiteSet['LODAPurchase'] || 'LODA Purchase',
    router: 'searchList',
    show: true,
  },
  { name: 'LODA Mall', router: 'searchList', show: proxy?.$SiteSet['LODAMall'] },
  { name: '热销榜', router: 'HotProducts', show: true },
  { name: 'Img Search', router: 'ImgSearch', show: true },
  {
    name: proxy?.$SiteSet['LODAExpress'] || 'LODA Express',
    router: 'express',
    show: true,
  },
  { name: 'LODA Live', router: 'callCharges', show: proxy?.$SiteSet['LODALive'] },
  { name: 'LODA APP', router: 'AppDownload', show: proxy?.$SiteSet['LODAAPP'] },
  { name: 'Loda Food', router: 'LodaFood', show: proxy?.$SiteSet['LodaFood'] },
])

const viewPush = (Rt, index) => {
  if (Rt.router != 'searchList') {
    Router.push({ path: '/' + Rt.router })
  } else {
    proxy?.$Bus.emit('UpSearch', 'mitt')
    Router.push({
      path: '/' + Rt.router,
      query: { type: !index ? 0 : 1 },
    })
  }
}

watch(routers, (newValue, oldValue) => {
  type.value = newValue.currentRoute.query.type
  RouteName.value = newValue.currentRoute.name
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "CategorieTitle",
      onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (isShow.value = true)),
      onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (isShow.value = false))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t('所有类别')) + " ", 1),
      _createVNode(_Transition, { name: "el-fade-in-linear" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(Categories, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isShow.value = false)),
            class: "Categories"
          }, null, 512), [
            [_vShow, isShow.value]
          ])
        ]),
        _: 1
      })
    ], 32),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Routes.value, (Rt, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (Rt.show)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass({
          isSelect: index < 2 ? type.value == index : RouteName.value == Rt.router,
        }),
                onClick: ($event: any) => (viewPush(Rt, index))
              }, _toDisplayString(_ctx.$t(Rt.name)), 11, _hoisted_3))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}
}

})