import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "noActivity"
}
const _hoisted_3 = {
  key: 1,
  class: "isActivity"
}
const _hoisted_4 = { class: "floor" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "isTitle" }
const _hoisted_8 = { style: {"width":"100%"} }
const _hoisted_9 = { style: {"display":"flex","justify-content":"space-evenly","flex-wrap":"wrap"} }
const _hoisted_10 = {
  key: 0,
  class: "MoreBuyings"
}
const _hoisted_11 = {
  key: 2,
  class: "ErrMsg"
}

import { ref, reactive, onMounted, watch, onUnmounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import * as Api from '@/utils/api'
import CommodityCard from '@/components/CommodityCard.vue'
import ProductSkeleton from '@/components/ProductSkeleton.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'activity',
  setup(__props) {

const Router = useRouter()
const route = useRoute()
const AffixIndex = ref(null)

const Activity = ref(null)
const ErrMsg = ref(null)
const msQuiesce = ref(false)

const Point = (i) => {
  location.href = '#Point' + i
  msQuiesce.value = true
  AffixIndex.value = i
  setTimeout(() => {
    msQuiesce.value = false
  }, 500)
}

const affixChange = (bol, i) => {
  if (bol.fixed && !msQuiesce.value) {
    AffixIndex.value = i
  }
}

const state: any = reactive({
  count: 0,
  timer: null,
})

onUnmounted(() => {
  document.querySelector('#app').style.backgroundColor = '#f4f4f4'
})

const getProduct = (item: any) => {
  Api.Post('sellers/api/SellerProdusts/', {
    Page: item.ProductIndex,
    PageSize: 24,
    ModuleId: item.Id,
  }).then((res: any) => {
    item.Products.push(...res.Goods)
    item.MoreProduct = res.Goods?.length < 24 ? false : true
  })
}
onMounted(() => {
  // const Activity1 = JSON.parse(route.query.activity)
  Api.Get('activity/api/MarketingActivities', {
    id: route.query.id,
  }).then((res: any) => {
    Activity.value = res.MarketingActivity
    if (Activity.value) {
      document.title = Activity.value.Title + '- LODA'
      if (Activity.value?.BackgroundColour) {
        document.querySelector('#app').style.backgroundColor =
          Activity.value?.BackgroundColour
      }
      if (Activity.value.Children?.length > 0) {
        Activity.value.Children.forEach((item: any) => {
          item.MoreProduct = false
          item.ProductIndex = 1
          item.Products = []
          getProduct(item)
        })
      }
    } else {
      ErrMsg.value = '暂无活动'
      state.count = 5
      state.timer = setInterval(() => {
        state.count--
        if (state.count <= 0) {
          clearInterval(state.timer)
          Router.push('/')
        }
      }, 1000)
    }
  })
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_el_affix = _resolveComponent("el-affix")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!Activity.value && !ErrMsg.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(36, (i) => {
            return _createVNode(ProductSkeleton, {
              class: "CCard",
              key: i
            })
          }), 64))
        ]))
      : _createCommentVNode("", true),
    (Activity.value && !ErrMsg.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Activity.value.Children, (Module) => {
              return (_openBlock(), _createElementBlock("div", {
                key: Module.Sort,
                onClick: ($event: any) => (Point(Module.Sort)),
                class: _normalizeClass({ isfloor: AffixIndex.value == Module.Sort })
              }, _toDisplayString(Module.Title), 11, _hoisted_5))
            }), 128))
          ]),
          _createVNode(_component_suffix_img, {
            class: "introduce",
            url: Activity.value.ImgUrl
          }, null, 8, ["url"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Activity.value.Children, (Module) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(['isModule' + Module.Sort]),
              key: Module.Sort
            }, [
              _createElementVNode("div", {
                class: "ModuleTitle",
                id: 'Point' + Module.Sort
              }, [
                _createVNode(_component_el_affix, {
                  target: '.isModule' + Module.Sort,
                  offset: 35,
                  onScroll: ($event: any) => (affixChange($event, Module.Sort))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, "• " + _toDisplayString(Module.Title) + " •", 1)
                  ]),
                  _: 2
                }, 1032, ["target", "onScroll"])
              ], 8, _hoisted_6),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Module.Products, (Goods, index) => {
                    return (_openBlock(), _createBlock(CommodityCard, {
                      key: Goods,
                      data: Goods,
                      index: index,
                      class: "CCard"
                    }, null, 8, ["data", "index"]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([].constructor(
                6 - (Module.Products.length % 6)
              ), (placeholder) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "placeholderCard",
                      key: placeholder
                    }))
                  }), 128))
                ]),
                (Module.MoreProduct)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_el_button, {
                        type: "danger",
                        onClick: ($event: any) => (Module.ProductIndex++, getProduct(Module))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('更多')) + " ... ", 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (ErrMsg.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", null, [
            _createVNode(_component_svg_icon, { name: "end" }),
            _createElementVNode("span", null, _toDisplayString(ErrMsg.value), 1),
            _createElementVNode("sub", null, _toDisplayString(_ctx.$t('前往首页')) + _toDisplayString(state.count) + "S ", 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})