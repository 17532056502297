import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "OrderNo" }
const _hoisted_3 = { class: "Status" }
const _hoisted_4 = { class: "StatusName" }
const _hoisted_5 = { class: "StatusType" }
const _hoisted_6 = { class: "Detaileds" }
const _hoisted_7 = { class: "Detailed" }
const _hoisted_8 = { class: "Detailed" }
const _hoisted_9 = { class: "Detailed" }
const _hoisted_10 = { class: "Products" }
const _hoisted_11 = { class: "Product" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 0,
  class: "SupplementaryPayment"
}
const _hoisted_14 = {
  key: 0,
  class: "SupplementaryPayment"
}
const _hoisted_15 = { class: "Remarks" }
const _hoisted_16 = { class: "SkuTitle" }
const _hoisted_17 = { class: "Additional" }
const _hoisted_18 = { class: "OrderMemo" }
const _hoisted_19 = { class: "isTotal" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }

import { ref, onMounted, toRefs, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import * as Api from '@/utils/api'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { formatDate } from '@/utils/format'
import { getSupplementaryAmount } from '@/utils/calculation'
import Pay from '@/components/user-center/pay.vue'
import Loading from '@/components/loading.vue'
import SalesType from '@/components/sales/sales-type.vue'
import EnterPackage from '@/components/sales/enter_package.vue'
import { ElMessageBox, ElLoading } from 'element-plus'
import GoogleTranslate from '@/components/GoogleTranslate.vue'
import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'order-details',
  setup(__props) {

const store = useUsersStore()
const { PaymentStatus, FreightTypes } = toRefs(store)

const { t } = useI18n()
const ShowPay = ref(false)
const ShowSale = ref(false)
const PayDate = ref(null)
const SourceId = ref(null)
const active = ref(1)
const Sku = ref(null)

const ShowEnter = ref(false)
const SkuItem = ref(null)

const arraySpanMethod = ({
  row,
  column,
  rowIndex,
  columnIndex,
}) => {
  if (columnIndex == 3) {
    let count = setSpanArr.value[rowIndex]
    if (count) {
      return [count, 1]
    } else {
      return [0, 0]
    }
  }
}

const SaleClick = (row) => {
  Sku.value = row
  ShowSale.value = true
}

const productDetails = (item) => {
  const href = Router.resolve({
    path: '/productDetails',
    query: { outId: item.OutId, mallId: item.MallId },
  })
  window.open(href.href, '_blank')
}

const CancelSupplementaryPayment = (ItemId, ShopId) => {
  Api.Post('orders/api/SupplyFeight/', {
    OrderNo: item.value.OrderNo,
    ItemId,
    ShopId,
  }).then((res: any) => {
    GetOrders()
  })
}

const Router = useRouter()
const route = useRoute()

watch(
  () => PaymentStatus.value,
  (res) => {
    GetOrders()
  },
  {
    deep: true,
  }
)

const item = ref(null)
const Steps = ref(null)
const SaleOrderList = ref(null)

const isEnterPackage = (item) => {
  SkuItem.value = item
  ShowEnter.value = true
}

const Cancel = (item) => {
  ElMessageBox.confirm(t('是否撤销申请'), t('提示'), {
    confirmButtonText: t('确定'),
    cancelButtonText: t('取消'),
    type: 'warning',
  }).then(() => {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    Api.Post('orders/api/DeleteSaleOrder', {
      SaleOrderNo: item.SaleOrderNo,
    }).then((res: any) => {
      loading.close()
      GetOrders()
    })
  })
}

const setSpanArr = ref([])

const getSpanArr = (data) => {
  let Arr = []
  //计算重复店铺ID数量
  Arr = data.reduce((obj, item) => {
    let find = obj.find((i) => i.ShopId === item.ShopId)
    let _d = {
      ...{ ShopId: item.ShopId },
      num: 1,
    }
    find ? find.num++ : obj.push(_d)
    return obj
  }, [])
  let Numbers = []
  //设置合并行
  Arr.forEach((element) => {
    for (let i = 0; i < element.num; i++) {
      setSpanArr.value.push(i == 0 ? element.num : 0)
    }
  })
}

const SupplementaryAmount = ref(null)

const GetOrders = () => {
  Api.Post('orders/api/SaleOrderList', {
    OrderNo: route.query.OrderNo,
    PageSize: 20,
    PageIndex: 1,
  }).then((res: any) => {
    SaleOrderList.value = res.SaleOrderList
  })

  Api.Get('orders/api/orders/', { order_no: route.query.OrderNo }).then(
    (res: any) => {
      if (!res.IsError) {
        getSpanArr(res.CustomerOrder.ResponseList)
        item.value = res.CustomerOrder

        PayDate.value = item.value
        SourceId.value = item.value.CustomerOrderSourceId

        let StatuList =
          item.value.CustomerOrderSourceId == 10
            ? [
              {
                title: '提交订单',
                id: 2,
                icon: '1-7',
              },
              {
                title: '付款成功',
                id: 6,
                icon: '1-6',
                isType: 1,
              },
              {
                title: '商品出库',
                id: 160,
                icon: '1-2',
                isType: 1,
              },
            ]
            : [
              {
                title: '等待审核',
                id: 2,
                icon: '1-3',
              },
              {
                title: '等待采购',
                id: 3,
                icon: '1-1',
              },
              {
                title: '发往海外',
                id: 9,
                icon: '1-4',
              },
            ]

        StatuList = StatuList.concat([
          {
            title: '等待收货',
            id: 180,
            icon: '1-8',
          },
          {
            title: '完成',
            id: 200,
            icon: '1-5',
          },
        ])

        if (item.value.StatusId == 4) {
          SupplementaryAmount.value = getSupplementaryAmount(res.CustomerOrder)
        }

        let Statu = StatuList.findIndex((index) => {
          return index.id >= item.value.StatusId
        })
        active.value = Statu
        Steps.value = StatuList
      }
    }
  )
}

const FreightType = ref(null)
const ChangeFreightType = () => {
  if (FreightType.value) {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    Api.Post('orders/api/ChangeTranFreightType', {
      OrderId: item.value.CustomerOrderId,
      TransportationType: FreightType.value,
    }).then(() => {
      FreightType.value = null
      loading.close()
      GetOrders()
    })
  }
}

const RemarkLoding = ref(false)
const setRemark = () => {
  let Items = []
  item.value.ResponseList.forEach((e) => {
    Items.push({ ItemID: e.ItemId, ItemRemark: e.ItemMemo })
  })
  RemarkLoding.value = true
  Api.Post('orders/api/CustomerTagging/', {
    OrderID: item.value.CustomerOrderId,
    OrderReamrk: item.value.OrderMemo,
    Items,
  }).then((res: any) => {
    RemarkLoding.value = false
  })
}

const setArrive = (item) => {
  Api.Post('orders/api/SetItemArriveStockOut/', {
    ItemList: [
      {
        ItemId: item.ItemId,
        IsArriveStockOut: item.IsArriveStockOut ? 1 : 0,
      },
    ],
  })
}

onMounted(() => {
  store.setState('basic/api/TransnationalType/', { type_id: 1 }, 'FreightTypes')
  GetOrders()
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_EditPen = _resolveComponent("EditPen")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!item.value)
        ? (_openBlock(), _createBlock(Loading, {
            key: 0,
            type: 13
          }))
        : _createCommentVNode("", true),
      (item.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('订单号')) + ": ", 1),
                _createElementVNode("span", null, _toDisplayString(item.value.OrderNo), 1)
              ]),
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('下单时间')) + ": ", 1),
                _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(item.value.CreatedOn, 'yyyy-MM-dd hh:mm:ss')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("label", null, _toDisplayString(item.value.Status), 1),
                (item.value.StatusId == 3)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      type: "danger",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (ShowPay.value = true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('支付订单')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (item.value.StatusId == 4)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 1,
                      type: "danger",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (ShowPay.value = true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('补款')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_el_steps, {
                  active: active.value,
                  "align-center": ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Steps.value, (Step, index) => {
                      return _withDirectives((_openBlock(), _createBlock(_component_el_step, { key: index }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_svg_icon, {
                            name: Step.icon
                          }, null, 8, ["name"])
                        ]),
                        title: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(Step.title)), 1)
                        ]),
                        _: 2
                      }, 1536)), [
                        [_vShow, !Step.isType || Step.isType == item.value.PaymentTypeId]
                      ])
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["active"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h1", null, _toDisplayString(_ctx.$t('收货人信息')), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('收货人')) + ":", 1),
                    _createElementVNode("span", null, _toDisplayString(item.value.ReceiveInformation.ReceiverName), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('地址')) + ":", 1),
                    _createElementVNode("span", null, _toDisplayString(item.value.ReceiveInformation.Address), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('手机号码')) + ":", 1),
                    _createElementVNode("span", null, _toDisplayString(item.value.ReceiveInformation.Mobile), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h1", null, _toDisplayString(_ctx.$t('配送信息')), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('运输方式')) + ":", 1),
                    _createElementVNode("span", null, [
                      _createTextVNode(_toDisplayString(item.value.TransnationalFreightType) + " ", 1),
                      _createVNode(_component_el_popover, {
                        placement: "bottom",
                        title: _ctx.$t('修改运输方式'),
                        trigger: "hover",
                        width: 300
                      }, {
                        reference: _withCtx(() => [
                          (item.value.StatusId < 7 &&
                      (item.value.CustomerOrderSourceId == 5 ||
                        item.value.PurchasingSiteId == 1)
                      )
                            ? (_openBlock(), _createBlock(_component_el_icon, {
                                key: 0,
                                class: "ChangeFreight",
                                onClick: ChangeFreightType
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_EditPen)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_el_radio_group, {
                            modelValue: FreightType.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((FreightType).value = $event)),
                            onChange: ChangeFreightType
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(FreightTypes)?.Types, (FT) => {
                                return _withDirectives((_openBlock(), _createBlock(_component_el_radio, {
                                  key: FT.ID,
                                  label: FT.ID,
                                  size: "large"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(FT.TypeName + ' ' + FT.Days), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"])), [
                                  [_vShow, FT.ID != item.value.TransnationalFreightTypeId]
                                ])
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["title"])
                    ])
                  ])
                ]),
                (item.value?.Expresses)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('快递公司')) + ":", 1),
                          _createElementVNode("span", null, _toDisplayString(item.value.Expresses.CompanyName), 1)
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('快递单号')) + ":", 1),
                          _createElementVNode("span", null, _toDisplayString(item.value.Expresses.ExpressNo), 1)
                        ])
                      ])
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("h1", null, _toDisplayString(_ctx.$t('付款信息')), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('付款方式')) + ":", 1),
                    _createElementVNode("span", null, _toDisplayString(item.value.PaymentType), 1)
                  ])
                ]),
                (item.value.WarehouseAddress)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('提货仓库')), 1),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", null, _toDisplayString(item.value.WarehouseAddress), 1)
                        ])
                      ])
                    ], 64))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_el_table, {
                data: item.value.ResponseList,
                style: {"width":"100%"},
                "header-row-class-name": "ProductTab",
                "span-method": arraySpanMethod
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    label: _ctx.$t('商品信息'),
                    width: "400",
                    "header-align": "center"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_suffix_img, {
                          url: row.ImagePath,
                          type: 5
                        }, null, 8, ["url"]),
                        _createElementVNode("div", {
                          class: "SkuTitle",
                          onClick: ($event: any) => (productDetails(row))
                        }, [
                          _createElementVNode("p", null, [
                            _createVNode(GoogleTranslate, {
                              text: row.Product,
                              type: null,
                              MallId: row.MallId
                            }, null, 8, ["text", "MallId"])
                          ]),
                          _createVNode(GoogleTranslate, {
                            text: row.SkuTitle,
                            type: null,
                            MallId: row.MallId
                          }, null, 8, ["text", "MallId"])
                        ], 8, _hoisted_12)
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    prop: "GoodsStatus",
                    label: _ctx.$t('状态'),
                    align: "center"
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    prop: "Quantity",
                    label: _ctx.$t('数量'),
                    align: "center"
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    label: _ctx.$t(item.value.ShopId ? '运费' : '中中运费'),
                    align: "center"
                  }, {
                    default: _withCtx(({ row }) => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value.Cn2CnFreightList, (Freight, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.$ccy(Freight.LocalFreightAmount)), 513), [
                            [_vShow, Freight.ShopId == row.ShopId]
                          ]),
                          (Freight.ShopId == row.ShopId && Freight.LocalDifference)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createElementVNode("p", null, _toDisplayString(Freight.ReasonType), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.$ccy(Freight.LocalDifference)), 1),
                                _createVNode(_component_el_popconfirm, {
                                  title: _ctx.$t('是否取消'),
                                  onConfirm: ($event: any) => (CancelSupplementaryPayment(null, row.ShopId))
                                }, {
                                  reference: _withCtx(() => [
                                    _createVNode(_component_el_button, {
                                      size: "small",
                                      type: "danger"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('取消补款')), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["title", "onConfirm"])
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    label: _ctx.$t('单价'),
                    align: "center"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(row.Price)), 1),
                      (row.LocalActualAmount)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t('补货款')), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.$ccy(row.LocalActualAmount)), 1),
                            _createElementVNode("p", null, _toDisplayString(row.ReasonType), 1),
                            _createVNode(_component_el_popconfirm, {
                              title: _ctx.$t('是否取消'),
                              onConfirm: ($event: any) => (CancelSupplementaryPayment(row.ItemId, null))
                            }, {
                              reference: _withCtx(() => [
                                _createVNode(_component_el_button, {
                                  size: "small",
                                  type: "danger"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('取消补款')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["title", "onConfirm"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    label: _ctx.$t('小计'),
                    align: "center"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createTextVNode(_toDisplayString(_ctx.$ccy(row.TotalItemAmount)), 1)
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    align: "center",
                    width: "200"
                  }, {
                    header: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('备注')) + " ", 1),
                      (item.value.CustomerOrderSourceId == 5)
                        ? (_openBlock(), _createBlock(_component_el_button, {
                            key: 0,
                            type: "danger",
                            loading: RemarkLoding.value,
                            onClick: setRemark
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('保存')), 1)
                            ]),
                            _: 1
                          }, 8, ["loading"]))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(({ row }) => [
                      _createVNode(_component_el_tooltip, {
                        class: "box-item",
                        effect: "dark",
                        content: _ctx.$t('仅自己可见'),
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: row.ItemMemo,
                            "onUpdate:modelValue": ($event: any) => ((row.ItemMemo) = $event),
                            rows: 1,
                            type: "textarea",
                            disabled: item.value.CustomerOrderSourceId == 5 ? false : true
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                        ]),
                        _: 2
                      }, 1032, ["content"]),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_el_input, {
                          modelValue: row.Remark,
                          "onUpdate:modelValue": ($event: any) => ((row.Remark) = $event),
                          rows: 2,
                          disabled: "",
                          type: "textarea"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                        _withDirectives(_createVNode(_component_suffix_img, {
                          url: row.RemarkUrl,
                          preview: true,
                          type: 0
                        }, null, 8, ["url"]), [
                          [_vShow, row.RemarkUrl != '']
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    label: _ctx.$t('操作'),
                    align: "center"
                  }, {
                    default: _withCtx(({ row }) => [
                      _withDirectives(_createVNode(_component_el_button, {
                        onClick: ($event: any) => (SaleClick(row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('发起售后')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]), [
                        [_vShow, row.HaveAfterSale == 0 &&
                row.GoodsStatusId == 180 &&
                item.value.PaymentTypeId != 2
                ]
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }, 8, ["data"]),
              _withDirectives(_createVNode(_component_el_table, {
                data: SaleOrderList.value,
                style: {"width":"100%"},
                "header-row-class-name": "ProductTab"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    label: _ctx.$t('商品信息'),
                    width: "300",
                    "header-align": "center"
                  }, {
                    default: _withCtx(({ row }) => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.Items, (Item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "Product",
                          key: Item
                        }, [
                          _createVNode(_component_suffix_img, {
                            url: Item.ProductImgUrl,
                            type: 5
                          }, null, 8, ["url"]),
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("p", null, _toDisplayString(Item.ProductName), 1),
                            _createElementVNode("p", null, _toDisplayString(Item.SkuTitle), 1)
                          ])
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    label: _ctx.$t('发起时间'),
                    align: "center"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createTextVNode(_toDisplayString(_unref(moment)(row.CreationTime).format('YYYY-MM-DD HH:mm:ss')), 1)
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    prop: "SaleOrderNo",
                    label: _ctx.$t('售后订单'),
                    align: "center"
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    prop: "SaleType",
                    label: _ctx.$t('类型'),
                    align: "center"
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    prop: "Statu",
                    label: _ctx.$t('状态'),
                    align: "center"
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    label: _ctx.$t('操作'),
                    align: "center"
                  }, {
                    default: _withCtx(({ row }) => [
                      _withDirectives(_createVNode(_component_el_link, {
                        target: "_blank",
                        style: {"margin":"0 10px 0 0"},
                        onClick: ($event: any) => (isEnterPackage(row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('录入包裹')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]), [
                        [_vShow, row.StatuId == 5]
                      ]),
                      _withDirectives(_createVNode(_component_el_link, {
                        target: "_blank",
                        style: {"margin":"0 10px 0 0"},
                        onClick: ($event: any) => (isEnterPackage(row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('申请平台介入')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]), [
                        [_vShow, row.StatuId == 2]
                      ]),
                      _withDirectives(_createVNode(_component_el_link, {
                        target: "_blank",
                        style: {"margin":"0 10px 0 0"},
                        onClick: ($event: any) => (Cancel(row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('撤销申请')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]), [
                        [_vShow, row.StatuId == 1]
                      ]),
                      _createVNode(_component_router_link, {
                        to: {
                name: 'afterSalesOrder',
                query: { id: row.SaleOrderNo },
              }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_link, { target: "_blank" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('售后详情')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }, 8, ["data"]), [
                [_vShow, SaleOrderList.value?.length]
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _withDirectives(_createElementVNode("div", _hoisted_18, [
                _createVNode(_component_el_tooltip, {
                  class: "box-item",
                  effect: "dark",
                  content: _ctx.$t('仅自己可见'),
                  placement: "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: item.value.OrderMemo,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((item.value.OrderMemo) = $event)),
                      rows: 5,
                      type: "textarea"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["content"]),
                _createElementVNode("p", null, [
                  (item.value.CustomerOrderSourceId == 5)
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 0,
                        type: "danger",
                        loading: RemarkLoding.value,
                        onClick: setRemark
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('保存')), 1)
                        ]),
                        _: 1
                      }, 8, ["loading"]))
                    : _createCommentVNode("", true)
                ])
              ], 512), [
                [_vShow, item.value.CustomerOrderSourceId == 5]
              ]),
              _createElementVNode("div", _hoisted_19, [
                (!SupplementaryAmount.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('总金额')) + ":", 1),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(item.value.ItemsTotalAmount)), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('运费')) + ":", 1),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(item.value.CnFreightAmount)), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('保险费')) + ":", 1),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(item.value.InsuranceFees)), 1)
                      ]),
                      (item.value.CustomerOrderSourceId == 5)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('服务费')) + ":", 1),
                            _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(item.value.PurchaseFees)), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('应付金额')) + ":", 1),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(item.value.TotalAmount)), 1)
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('已付金额')) + ":", 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(item.value.PaidAmount)), 1)
                ]),
                (SupplementaryAmount.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('补款金额')) + ":", 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$ccy(SupplementaryAmount.value)), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (ShowEnter.value)
      ? (_openBlock(), _createBlock(EnterPackage, {
          key: 0,
          SkuItem: SkuItem.value,
          ShowEnter: ShowEnter.value,
          onHideEnter: _cache[4] || (_cache[4] = ($event: any) => (ShowEnter.value = false)),
          onEnterChange: _cache[5] || (_cache[5] = ($event: any) => {; (ShowEnter.value = false), GetOrders()})
        }, null, 8, ["SkuItem", "ShowEnter"]))
      : _createCommentVNode("", true),
    (item.value && Sku.value)
      ? (_openBlock(), _createBlock(SalesType, {
          key: 1,
          ShowSale: ShowSale.value,
          OrderNo: item.value.OrderNo,
          Sku: [Sku.value],
          onHideSale: _cache[6] || (_cache[6] = ($event: any) => (ShowSale.value = false)),
          onSaleChange: _cache[7] || (_cache[7] = ($event: any) => {; (ShowSale.value = false), GetOrders()})
        }, null, 8, ["ShowSale", "OrderNo", "Sku"]))
      : _createCommentVNode("", true),
    _createVNode(Pay, {
      Date: PayDate.value,
      ShowPay: ShowPay.value,
      SourceId: SourceId.value,
      onPayChange: _cache[8] || (_cache[8] = ($event: any) => (ShowPay.value = false))
    }, null, 8, ["Date", "ShowPay", "SourceId"])
  ], 64))
}
}

})