// src/apiHostService.js
import axios from 'axios'

export async function getApiHost() {
  try {
    const response:any = await axios.get('https://apihost.lodaexp.com/ApiHost.json')
    return response.data
  } catch (error) {
    console.error('Error fetching ApiHost:', error)
    return { 9: 'api.loda.com.kh' }
  }
}
