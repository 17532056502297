import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "withdrawal" }

import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import type { FormInstance } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import * as Api from '@/utils/api'
import { useI18n } from 'vue-i18n'
import OSS from '@/components/oss.vue'
import moment from 'moment'
import Password from '@/components/password.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'withdrawal',
  emits: ['SelectType'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()
const emit = __emit

const elRef = ref<FormInstance>()
const { proxy } = getCurrentInstance()
const isLoading = ref(false)

const refForm = reactive({
  account_name: 'user',
  account_no: '',
  amount: null,
  channel: 2,
  remark: '客户申请提现',
  bank_name: '',
  swift_code: '',
  bank_id: 15,
  pay_password: null,
  ReasonIds: null,
  ImgUrlList: [],
})

const rules = reactive({
  account_no: [{ required: true, message: t('不能为空'), trigger: 'change' }],
  pay_password: [{ required: true, message: t('不能为空'), trigger: 'blur' }],
  ReasonIds: [
    {
      required: true,
      type: 'array',
      message: t('不能为空'),
      trigger: 'change',
    },
  ],
  ImgUrlList: [
    {
      required: true,
      type: 'array',
      message: t('不能为空'),
      trigger: 'change',
    },
  ],
})

const DictionaryList = ref([])
const CompanyId = ref(null)

const submit = () => {
  proxy.$refs['elRef']['validate']((valid: boolean) => {
    if (valid) {
      // 检测密码是否锁定
      isLoading.value = true
      Api.Get('user/api/CustomerPwdIsLock/', { TypeId: 1 }).then(
        (res: any) => {
          isLoading.value = false
          if (!res.IsError) {
            if (res.IsLock) {
              ElMessageBox.alert(
                t(`交易密码已锁定`, {
                  name: moment(res.LockTime).format('YYYY-MM-DD HH:mm:ss'),
                }),
                '',
                {
                  confirmButtonText: 'OK',
                  showClose: false,
                }
              )
            } else {
              let body = JSON.parse(JSON.stringify(refForm))
              body.ImgUrlList.map((item) => {
                item.ImgUrl = item.url
                delete item.url
              })
              isLoading.value = true
              Api.Post('bank/api/WithdrawCashByNew/', body).then(
                (res) => {
                  isLoading.value = false
                  emit('SelectType')
                },
                () => {
                  isLoading.value = false
                }
              )
            }
          }
        },
        () => {}
      )
    }
  })
}

onMounted(() => {
  Api.Get('basic/api/BasicDictionary', {
    type_id: 28,
  }).then((res: any) => {
    DictionaryList.value = res.BasicDictionaryList
  })

  Api.Get('user/api/CustomerBankAccount/', {}).then((res: any) => {
    CompanyId.value = res.Accounts[0].Bank.CompanyId
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, { type: "border-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('余额提现')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form, {
              ref_key: "elRef",
              ref: elRef,
              model: refForm,
              rules: rules,
              "label-position": "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('输入提现金额'),
                  prop: "amount"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input_number, {
                      modelValue: refForm.amount,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((refForm.amount) = $event)),
                      precision: 2,
                      step: 1,
                      min: 0.01,
                      "value-on-clear": 0
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("label", null, "  " + _toDisplayString(_ctx.$t('币种')) + ": " + _toDisplayString(_ctx.$SiteSet['CurrencyCode']), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('输入您的交易密码'),
                  prop: "pay_password"
                }, {
                  default: _withCtx(() => [
                    _createVNode(Password, {
                      modelValue: refForm.pay_password,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((refForm.pay_password) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('请选择出您提现的原因'),
                  prop: "ReasonIds"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: refForm.ReasonIds,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((refForm.ReasonIds) = $event)),
                      multiple: "",
                      style: {"width":"100%"},
                      placeholder: _ctx.$t('可多选')
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(DictionaryList.value, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.DicValue,
                            label: item.DicName,
                            value: item.DicValue
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('上传身份信息'),
                  prop: "ImgUrlList"
                }, {
                  default: _withCtx(() => [
                    _createVNode(OSS, {
                      type: 1,
                      imgList: refForm.ImgUrlList,
                      max: 2
                    }, null, 8, ["imgList"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "large",
                      onClick: submit,
                      loading: isLoading.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    })
  ]))
}
}

})