import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString } from "vue"

import { ref, onMounted } from 'vue'
import { translateApi } from '@/utils/GoogleTranslate'


export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleTranslate',
  props: {
  text: String,
  type: String,
  MallId: [String, Number],
  ApiType: { type: Number, default: 2 }, // 1 搜索 2 商品标题 3 商品描述
},
  setup(__props) {

const props = __props
const translateText = ref(null)

onMounted(() => {
  translateApi(props.text, null, props.type, props.MallId, props.ApiType).then((res: any) => {
    translateText.value = res
  })
})

return (_ctx: any,_cache: any) => {
  return _toDisplayString(translateText.value || props.text)
}
}

})