import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["innerHTML"]

import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import * as Api from '@/utils/api'
import Loading from '@/components/loading.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'article',
  props: {
  id: Number,
},
  setup(__props) {

const { proxy } = getCurrentInstance()
const props = __props

const lan = proxy?.$SiteSet['article']

const content = ref(null)
const ShowArticle = ref(false)

const GetArticle = () => {
  ShowArticle.value = true
  if (!content.value) {
    Api.Get('article/api/ArticleTitle/', {
      ArticleId: lan[localStorage.getItem('lang') || 'km'][props.id - 1],
    }).then((res: any) => {
      if (res.ArticlesList.length) {
        content.value = res.ArticlesList[0].Content
      }
    })
  }
}

onMounted(() => { })

return (_ctx: any,_cache: any) => {
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_link, {
      type: "warning",
      onClick: GetArticle
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "name")
      ]),
      _: 3
    }),
    _createVNode(_component_el_dialog, {
      width: "50%",
      modelValue: ShowArticle.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ShowArticle).value = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", { innerHTML: content.value }, null, 8, _hoisted_2),
          _withDirectives(_createVNode(Loading, { type: "12" }, null, 512), [
            [_vShow, !content.value]
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})