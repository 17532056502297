import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from './language'
import './assets/style/font.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import SuffixImg from '@/components/SuffixImg.vue'
import '@/assets/index'
import svgIcon from '@/components/SvgIcon.vue'
import vue3GoogleLogin from 'vue3-google-login'
import mitt from 'mitt'
import pinia from './pinia'
import { SiteSet } from './utils/siteConfig'
import moment from 'moment'
import { getApiHost } from '@/utils/apiHostService'

export const mapKey =
  JSON.parse(localStorage.getItem('GetAppConfig'))?.googleMapKey ||
  'AIzaSyDdT9vHuR0sLb0ZYOZEgo6EWwDaNQBsuxU'
;(async () => {
  const apiHost = await getApiHost()
  localStorage.setItem('apiHost', JSON.stringify(apiHost))
  console.log('apiHost', apiHost)
  const App = (await import('./App.vue')).default
  const router = (await import('./router')).default
  const pinia = (await import('./pinia')).default
  const svgIcon = (await import('@/components/SvgIcon.vue')).default
  const SuffixImg = (await import('@/components/SuffixImg.vue')).default

  const app = createApp(App)

  app.config.globalProperties.$SiteSet = SiteSet()

  const bus = mitt()
  app.config.globalProperties.$Bus = bus

  const currency = require('currency.js')

  const getCurrency = (value) => {
    // let siteCountry = localStorage.getItem("siteCountry")
    let ccy = currency(value, {
      symbol: SiteSet().symbol,
      decimal: '.',
      separator: SiteSet().separator,
      precision: SiteSet().precision,
    }).format()
    return ccy
  }

  app.config.globalProperties.$ccy = getCurrency

  app.use(vue3GoogleLogin, {
    clientId:
      '250157483079-4bajfjtnbuobiakpri5m6kai5mquep15.apps.googleusercontent.com',
  })

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  app.component('suffix-img', SuffixImg).component('svg-icon', svgIcon)
  app.use(router).use(ElementPlus).use(i18n).use(pinia).mount('#app')
})()
