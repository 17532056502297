import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "WarehouseModel" }
const _hoisted_2 = { class: "DefaultWare" }
const _hoisted_3 = { class: "setWare" }

import { ref, reactive, onMounted, watch } from 'vue'
import * as Api from '@/utils/api'
import Repository from '@/components/user-center/repository.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SetDefaultWarehouse',
  setup(__props) {

const Warehouse = ref(null)

const SetWarehouse = (val) => {
  Warehouse.value.Id = val.params.WarehouseId
  Warehouse.value.Name = val.params.WarehouseName
}

onMounted(() => {
  Api.Get('user/api/GetCustomerDefaultWarehouse/', {}).then((res: any) => {
    Warehouse.value = {
      Id: res.DefaultWareId,
      Name: res.DefaultWareName,
    }
  })
})

return (_ctx: any,_cache: any) => {
  const _component_Box = _resolveComponent("Box")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_icon, { size: 20 }, {
        default: _withCtx(() => [
          _createVNode(_component_Box)
        ]),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(Warehouse.value?.Name), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (Warehouse.value)
        ? (_openBlock(), _createBlock(Repository, {
            key: 0,
            noSet: false,
            Warehouse: Warehouse.value,
            onWarehouseChange: SetWarehouse
          }, null, 8, ["Warehouse"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})