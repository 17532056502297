import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "isImg" }
const _hoisted_2 = { class: "image-slot" }

import { ref, onMounted, watch, toRefs } from 'vue'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'SuffixImg',
  props: {
  url: String,
  type: Number,
  fit: String,
  preview: Boolean,
  alt: String,
},
  setup(__props) {

const store = useUsersStore()
const { Suffix } = toRefs(store)

const props = __props
const isPreview = ref([])
const ImgUrl = ref(null)
const fit = ref('cover')

watch(
  [() => props.url],
  () => {
    formatImg()
  },
  {
    deep: true, // 深度监听
  }
)

const formatImg = () => {
  if (
    props.type &&
    Suffix.value &&
    props.url &&
    !props.url.includes(Suffix.value[props.type].Suffix)
  ) {
    ImgUrl.value = props.url + Suffix.value[props.type].Suffix
  } else {
    ImgUrl.value = props.url
  }
  isPreview.value = props.preview ? [ImgUrl.value] : []
}

onMounted(() => {
  formatImg()
})

return (_ctx: any,_cache: any) => {
  const _component_Picture = _resolveComponent("Picture")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_image, {
      alt: props.alt,
      fit: fit.value,
      src: ImgUrl.value,
      "preview-src-list": isPreview.value,
      "initial-index": 1,
      "preview-teleported": true
    }, {
      error: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_Picture)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["alt", "fit", "src", "preview-src-list"])
  ]))
}
}

})