import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "station_letter" }
const _hoisted_2 = { class: "custom-tabs-label" }
const _hoisted_3 = { class: "custom-tabs-label" }
const _hoisted_4 = { class: "custom-tabs-label" }
const _hoisted_5 = { class: "message-card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "ProductPagination" }

import { ref, reactive, onMounted, watch } from 'vue'
import PaginationHook from '@/hooks/pagination'
import * as Api from '@/utils/api'
import moment from 'moment'
import Loading from '@/components/loading.vue'
import EmptyArray from '@/components/EmptyArray.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'station_letter',
  setup(__props) {

const tabType = ref(1)
const Notices = ref(null)
const total = ref(0)

const DeleteMessage = (item) => {
  Api.Post('user/api/CustomerNotification/', {
    Ids: [item.Id],
    IsRead: false,
  }).then(() => {
    TypeCharge()
  })
}

const MessageLan = (i) => {
  let msg
  switch (localStorage.getItem('lang')) {
    case 'en':
      msg = i.EnMessage
      break
    case 'zh-cn':
      msg = i.Message
      break
    default:
      msg = i.LocalMessage
      break
  }
  return msg
}

const TypeCharge = () => {
  Api.Get('user/api/CustomerNotification/', {
    PageIndex: pageNum.value,
    PageSize: pageSize.value,
    typeId: tabType.value,
  }).then((res: any) => {
    Notices.value = res.Notices
    total.value = res.Total
  })
}

const { pageNum, pageSize, handleCurrentChange, handleSizeChange } =
  PaginationHook(TypeCharge)

onMounted(() => {
  pageSize.value = 30
  TypeCharge()
})

return (_ctx: any,_cache: any) => {
  const _component_Message = _resolveComponent("Message")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_DeleteFilled = _resolveComponent("DeleteFilled")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      type: "border-card",
      class: "demo-tabs",
      modelValue: tabType.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((tabType).value = $event)),
      onTabChange: TypeCharge
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, { name: 1 }, {
          label: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Message)
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('订单信息')), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, { name: 2 }, {
          label: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Message)
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('充值信息')), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, { name: 3 }, {
          label: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Message)
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('提货信息')), 1)
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Notices.value, (Notice, index) => {
            return (_openBlock(), _createBlock(_component_el_card, { key: index }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(_unref(moment)(Notice.CreatedOn).format('YYYY-MM-DD HH:mm:ss')), 1),
                  _createVNode(_component_el_popconfirm, {
                    title: _ctx.$t('是否删除'),
                    onConfirm: ($event: any) => (DeleteMessage(Notice))
                  }, {
                    reference: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_DeleteFilled)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["title", "onConfirm"])
                ])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "text-item",
                  innerHTML: MessageLan(Notice)
                }, null, 8, _hoisted_7)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _withDirectives(_createVNode(Loading, { type: "13" }, null, 512), [
          [_vShow, !Notices.value]
        ]),
        _createVNode(EmptyArray, {
          show: Notices.value && !Notices.value.length
        }, null, 8, ["show"]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_el_pagination, {
            currentPage: _unref(pageNum),
            "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => (_isRef(pageNum) ? (pageNum).value = $event : null)),
            "page-size": _unref(pageSize),
            onSizeChange: _unref(handleSizeChange),
            onCurrentChange: _unref(handleCurrentChange),
            total: total.value,
            background: "",
            layout: "prev, pager, next"
          }, null, 8, ["currentPage", "page-size", "onSizeChange", "onCurrentChange", "total"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})