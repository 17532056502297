import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/imgs/0_01.png'
import _imports_1 from '../assets/imgs/0_01en.jpg'
import _imports_2 from '../assets/imgs/0_01id.jpg'
import _imports_3 from '../assets/imgs/0_02.png'
import _imports_4 from '../assets/imgs/0_03.png'
import _imports_5 from '../assets/imgs/0_04.png'
import _imports_6 from '../assets/imgs/0_05.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1
}
const _hoisted_4 = {
  key: 2,
  src: _imports_2
}
const _hoisted_5 = {
  key: 3,
  src: _imports_6
}

import { ref, reactive, onMounted, watch } from 'vue'
import { SiteSet } from '@/utils/siteConfig'

export default /*@__PURE__*/_defineComponent({
  __name: 'AboutUs',
  setup(__props) {

const localLan = localStorage.getItem('lang')
const country = SiteSet().getPlacePredictions
onMounted(() => { })

return (_ctx: any,_cache: any) => {
  const _component_Opportunity = _resolveComponent("Opportunity")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [
          _createVNode(_component_Opportunity)
        ]),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('关于我们')), 1)
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
          'Loda是一个真实、可靠、便捷的在线购物平台。我们的服务让您真正实现网购无国界、无时差、无距离。在这里，您可以随时随地轻松网购各式各样的商品。'
        )), 1),
    (_unref(country) == 'kh')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (_unref(country) != 'kh' && _unref(localLan) != 'id')
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : _createCommentVNode("", true),
    (_unref(country) != 'kh' && _unref(localLan) == 'id')
      ? (_openBlock(), _createElementBlock("img", _hoisted_4))
      : _createCommentVNode("", true),
    _createElementVNode("h1", null, [
      _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [
          _createVNode(_component_Opportunity)
        ]),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('实力')), 1)
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
          'Loda与中国多所知名商务机构合作，提供高品质的商品和服务，行业内最低的服务费收取标准。与多家国际快递合作，运费低廉，多种寄送方式。专业的采购团队和仓储管理，完整的客户服务体系。从购买到打包到运输，环环放心，完善的客户服务体系，及时解决您的需求。'
        )), 1),
    _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_3 }, null, -1)),
    _createElementVNode("h1", null, [
      _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [
          _createVNode(_component_Opportunity)
        ]),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('服务')), 1)
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('完善的售后服务体系：时效保证、商品质量跟踪、退换货保障等')), 1),
    _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_4 }, null, -1)),
    _createElementVNode("h1", null, [
      _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [
          _createVNode(_component_Opportunity)
        ]),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('优势')), 1)
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
          '代购中国购物网站商品（loda帮您买），手机商城（掌上购物，一站式体验），高新科技的商务模式，专业电子商务人员，提供支持多平台多渠道的商品购买，24小时采购服务。'
        )), 1),
    _cache[2] || (_cache[2] = _createElementVNode("img", { src: _imports_5 }, null, -1)),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
          '新生儿的成长将会一路遇到许多挫折和坎坷，却总是在困惑中寻找正确的方向，在不断变化中创新和完善。 也许今天的我们并不是令人满意，但是我们会一直努力做个有态度的网购平台。欢迎大家多多提出对我们的想法和建议，您的需求就是我们成长的动力，我们会不断完善，做个最好的自己。'
        )), 1),
    (_unref(country) == 'kh')
      ? (_openBlock(), _createElementBlock("img", _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}
}

})