import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Transition as _Transition, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "sales-type" }
const _hoisted_2 = { class: "Product" }
const _hoisted_3 = { class: "SkuTitle" }
const _hoisted_4 = {
  key: 0,
  class: "SaleTypes"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "dialog-footer" }

import { reactive, ref, onMounted, watch, toRefs } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import * as Api from '@/utils/api'
import { useI18n } from 'vue-i18n'
import OSS from '@/components/oss.vue'
import AddressCard from '@/components/user-center/address_card.vue'
import { ElMessageBox } from 'element-plus'


export default /*@__PURE__*/_defineComponent({
  __name: 'sales-type',
  props: {
  Sku: Object,
  ShowSale: Boolean,
  OrderNo: String,
},
  emits: ['SaleChange', 'HideSale'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()
const CustomerAddress = ref(null)
const UserWarehousesList = ref(null)

const GetDefaultAddress = () => {
  Api.Get('user/api/GetCustomerDefaultAddress', {}).then((res: any) => {
    CustomerAddress.value = res
  })
}

const GetReceiveAddresses = () => {
  Api.Get('user/api/ReceiveAddresses_New/', {
    addressTypeId: 1,
  }).then((res: any) => {
    UserWarehousesList.value = res.ReceiveAddresses
  })
}

const SaleLoad = ref(false)
const SaleRef = ref<FormInstance>()
const SaleRules = reactive<FormRules>({
  ReasonId: [{ required: true, message: t('请选择'), trigger: 'change' }],
})
const title = ref('')
const emit = __emit

const DoneSale = () => {
  emit('HideSale')
}

const props = __props

const SaleForm = reactive({
  BusinessTypeId: 1,
  SaleTypeId: null,
  ReasonId: null,
  ImgUrlList: [],
  OrderNo: null,
  Remark: null,
  TotalAmount: null,
  GoodsStatu: null,
  SaleOrderItems: [],
})

const QuantityChange = () => {
  let Price = 0
  props.Sku.forEach((ele) => {
    Price += (ele.Qty || 1) * ele.Price
  })
  SaleForm.TotalAmount = Price
}

const SubmitSale = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      let body = {}
      SaleForm.OrderNo = props.OrderNo
      SaleForm.SaleOrderItems = []
      SaleForm.GoodsStatu = props.Sku[0].GoodsStatusId
      props.Sku.forEach((ele) => {
        SaleForm.SaleOrderItems.push({
          ItemUniqueId: ele.UniqueId,
          Quantity: ele.Qty,
        })
      })

      if (SaleForm.SaleTypeId == 2) {
        let obj = {
          Address: CustomerAddress.value.Address,
          Mobile: CustomerAddress.value.Mobile,
          ReceiverName: CustomerAddress.value.ReceiverName,
        }
        SaleForm.TotalAmount = 0
        body = { ...SaleForm, ...obj }
      } else {
        body = { ...SaleForm }
      }
      body.ImgUrlList = body.ImgUrlList.map((item) => {
        return item.url
      })

      SaleLoad.value = true
      Api.Post('orders/api/SaleOrder/', body)
        .then((res: any) => {
          SaleLoad.value = false
          success()
        })
        .catch(() => {
          SaleLoad.value = false
        })
    }
  })
}

const success = () => {
  ElMessageBox.alert(t('提交成功'), '', {
    confirmButtonText: 'OK',
    showClose: false,
    closeOnPressEscape: false,
    callback: () => {
      emit('SaleChange')
    },
  })
}

const Reason = ref(null)

const SaleTypeChange = (val) => {
  if (val == 2) {
    GetDefaultAddress()
  }
  SaleForm.SaleTypeId = val
  let typeIds = [229, 230, 231]
  Reason.value = null
  SaleForm.ReasonId = null
  Api.Get('basic/api/BasicDictionary/', {
    type_id: typeIds[val - 1],
  }).then((res: any) => {
    SaleForm.ReasonId = res.BasicDictionaryList[0].DicValue
    Reason.value = res.BasicDictionaryList
  })
}

const SaleTypes = ref(null)
onMounted(() => {
  Api.Get('basic/api/BasicDictionary/', {
    type_id: 234,
  }).then((res: any) => {
    SaleTypes.value = res.BasicDictionaryList
  })
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: props.ShowSale,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((props.ShowSale) = $event)),
      title: title.value,
      width: "800px",
      "before-close": DoneSale,
      center: true
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_7, [
          _createVNode(_component_el_button, {
            onClick: DoneSale,
            size: "large"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('关闭')), 1)
            ]),
            _: 1
          }),
          _withDirectives(_createVNode(_component_el_button, {
            type: "danger",
            size: "large",
            loading: SaleLoad.value,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (SubmitSale(SaleRef.value)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
            ]),
            _: 1
          }, 8, ["loading"]), [
            [_vShow, SaleForm.SaleTypeId]
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(QuantityChange()) + " ", 1),
        _createVNode(_component_el_table, {
          data: props.Sku,
          "header-row-class-name": "ProductTab"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              label: _ctx.$t('商品信息'),
              width: "200",
              "header-align": "center"
            }, {
              default: _withCtx(({ row }) => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_suffix_img, {
                    url: row.ImagePath,
                    type: 5
                  }, null, 8, ["url"]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("p", null, _toDisplayString(row.Product), 1),
                    _createElementVNode("p", null, _toDisplayString(row.SkuTitle), 1)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              prop: "Quantity",
              label: _ctx.$t('数量'),
              align: "center"
            }, null, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              label: _ctx.$t('退/换货数量'),
              align: "center"
            }, {
              default: _withCtx(({ row }) => [
                _createTextVNode(_toDisplayString(!row.Qty ? (row.Qty = 1) : null) + " ", 1),
                (row.Qty)
                  ? (_openBlock(), _createBlock(_component_el_input_number, {
                      key: 0,
                      modelValue: row.Qty,
                      "onUpdate:modelValue": ($event: any) => ((row.Qty) = $event),
                      min: 1,
                      max: row.Quantity,
                      precision: 0,
                      onChange: QuantityChange
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "max"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              label: _ctx.$t('单价'),
              align: "center"
            }, {
              default: _withCtx(({ row }) => [
                _createTextVNode(_toDisplayString(_ctx.$ccy(row.Price)), 1)
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["data"]),
        (SaleTypes.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(SaleTypes.value, (SaleType) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass([
            'SaleType',
            { SelectSaleType: SaleType.DicValue == SaleForm.SaleTypeId },
          ]),
                  key: SaleType.DicValue,
                  onClick: ($event: any) => (SaleTypeChange(SaleType.DicValue))
                }, [
                  _createElementVNode("img", {
                    src: 
              require('../../assets/imgs/AfterSale' +
                SaleType.DicValue +
                '.png')
            
                  }, null, 8, _hoisted_6),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(SaleType.DicName), 1),
                    _createElementVNode("p", null, _toDisplayString(SaleType.Desc), 1)
                  ])
                ], 10, _hoisted_5))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (SaleForm.SaleTypeId == 2)
          ? (_openBlock(), _createBlock(_component_el_scrollbar, {
              key: 1,
              "max-height": "200px",
              class: "UserWarehousesList"
            }, {
              default: _withCtx(() => [
                (CustomerAddress.value && !UserWarehousesList.value)
                  ? (_openBlock(), _createBlock(AddressCard, {
                      key: 0,
                      item: CustomerAddress.value,
                      isDefault: true,
                      index: 0
                    }, null, 8, ["item"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(UserWarehousesList.value, (Warehouses, index) => {
                  return (_openBlock(), _createBlock(AddressCard, {
                    item: Warehouses,
                    index: index,
                    isDefault: Warehouses.AddressId == CustomerAddress.value.AddressId,
                    key: index,
                    onClick: ($event: any) => (CustomerAddress.value = Warehouses)
                  }, null, 8, ["item", "index", "isDefault", "onClick"]))
                }), 128)),
                (!UserWarehousesList.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "AddressesPlaceholder",
                      onClick: GetReceiveAddresses
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('显示全部')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(!UserWarehousesList.value
            ? 1
            : 3 - (UserWarehousesList.value.length % 3), (placeholder) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: placeholder,
                    class: "AddressesPlaceholder"
                  }))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_Transition, { name: "el-fade-in-linear" }, {
          default: _withCtx(() => [
            (SaleForm.SaleTypeId)
              ? (_openBlock(), _createBlock(_component_el_form, {
                  key: 0,
                  ref_key: "SaleRef",
                  ref: SaleRef,
                  model: SaleForm,
                  rules: SaleRules,
                  "label-position": "top",
                  class: "PackageForm"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('原因'),
                      prop: "ReasonId"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: SaleForm.ReasonId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((SaleForm.ReasonId) = $event)),
                          placeholder: _ctx.$t('请选择'),
                          size: "large",
                          class: "cancelOrder"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Reason.value, (cancel) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: cancel.DicValue,
                                label: cancel.DicName,
                                value: cancel.DicValue
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    (SaleForm.SaleTypeId != 2)
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 0,
                          label: _ctx.$t('退款金额')
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$ccy(SaleForm.TotalAmount)), 1)
                          ]),
                          _: 1
                        }, 8, ["label"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('备注')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: SaleForm.Remark,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((SaleForm.Remark) = $event)),
                          type: "textarea",
                          placeholder: _ctx.$t('请输入')
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('上传凭证')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(OSS, {
                          type: 4,
                          imgList: SaleForm.ImgUrlList,
                          max: 2,
                          onImgsChange: _cache[2] || (_cache[2] = 
                (val) => {
                  SaleForm.ImgUrlList = val
                }
              )
                        }, null, 8, ["imgList"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["model", "rules"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}
}

})