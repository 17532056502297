import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "recover_password" }
const _hoisted_2 = {
  key: 0,
  class: "Success"
}

import { ref, reactive, onMounted, watch, toRefs } from 'vue'
import * as Api from '@/utils/api'
import type { FormInstance } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import countDown from '@/utils/countDown'
import RegionSelection from '@/components/RegionSelection.vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'recover_password',
  setup(__props) {

const store = useUsersStore()
const { Token } = toRefs(store)

const Router = useRouter()

const ruleFormRef = ref<FormInstance>()
const newPwdRef = ref<FormInstance>()

const { t } = useI18n()
const active = ref(1)

const CodeLoading = ref(false)
const { state: CodeTime, start: startTimeout, check_count: CCD } = countDown()

const AccountType = ref(true)

const ruleForm = reactive({
  VerificationCode: null,
  VerificationMode: null,
})

const pwdForm = reactive({
  PayPwd: null,
  ConfirmPayPwd: null,
})

const Equipment = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error(t('不能为空')))
  } else {
    if (!AccountType.value) {
      const reg = new RegExp(
        '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$'
      )
      !reg.test(value) ? callback(new Error(t('请输入正确的邮箱'))) : callback()
    } else {
      !eval(userAreaCode.value.Regular).test(value)
        ? callback(new Error(t('请输入正确手机号')))
        : callback()
    }
  }
}

const rules = reactive({
  VerificationMode: [{ required: true, validator: Equipment }],
  VerificationCode: [{ required: true, message: t('请输入'), trigger: 'blur' }],
})

const checkPass = (rule: any, value: any, callback: any) => {
  if (value != pwdForm.PayPwd) {
    callback(new Error(t('密码不一致')))
  } else {
    callback()
  }
}

const pwdRules = reactive({
  PayPwd: [
    { required: true, message: t('请输入'), trigger: 'blur' },
    { min: 6, message: t('最少6位'), trigger: 'blur' },
  ],
  ConfirmPayPwd: [{ required: true, validator: checkPass, trigger: 'blur' }],
})

const userAreaCode = ref(null)
const AreaCode = (val) => {
  userAreaCode.value = val
}

const VCode = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validateField('VerificationMode', (valid) => {
    if (valid) {
      CodeLoading.value = true
      Api.Get('user/api/SendMessage/', {
        SendTo: ruleForm.VerificationMode,
        SendChannel: 3,
      }).then(
        (res) => {
          startTimeout()
          ElMessageBox.alert(t('已发送验证码,请注意查收'), t('提示'), {
            confirmButtonText: t('确定'),
          })
        },
        (err) => {
          CodeLoading.value = false
        }
      )
    }
  })
}
const AccountId = ref(null)
const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validate((valid, fields) => {
    if (valid) {
      Api.Get('user/api/ForgetPwd/', ruleForm).then((res: any) => {
        active.value = 2
        AccountId.value = res.AccountId
      })
    }
  })
}

const setNewPwd = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      Api.Post('user/api/ForgetPwd/', {
        NewPassWord: pwdForm.PayPwd,
        AccountId: AccountId.value,
      }).then((res: any) => {
        active.value = 3
      })
    }
  })
}

watch(
  () => CodeTime.count,
  (newV, oldV) => {
    CodeLoading.value = newV == 0 ? false : true
  },
  {
    immediate: true, // 立即执行
    deep: true, // 深度监听
  }
)

watch(
  () => Token.value,
  (val) => {
    if (val) {
      Router.push('/')
    }
  },
  {
    immediate: true,
    deep: true,
  }
)

onMounted(() => {
  CCD()
})

return (_ctx: any,_cache: any) => {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_SuccessFilled = _resolveComponent("SuccessFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_steps, {
      space: 200,
      active: active.value,
      simple: "",
      class: "steps"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_step, {
          title: _ctx.$t('验证身份'),
          icon: "User"
        }, null, 8, ["title"]),
        _createVNode(_component_el_step, {
          title: _ctx.$t('设置新密码'),
          icon: "Edit"
        }, null, 8, ["title"]),
        _createVNode(_component_el_step, {
          title: _ctx.$t('完成'),
          icon: "Select"
        }, null, 8, ["title"])
      ]),
      _: 1
    }, 8, ["active"]),
    _withDirectives(_createVNode(_component_el_form, {
      class: "recoverForm",
      ref_key: "ruleFormRef",
      ref: ruleFormRef,
      model: ruleForm,
      rules: rules,
      "label-position": "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio_group, {
              modelValue: AccountType.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((AccountType).value = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio_button, {
                  label: true,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('电话')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_radio_button, {
                  label: false,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('邮箱')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t(!AccountType.value ? '邮箱' : '手机号'),
          prop: "VerificationMode"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: _ctx.$t(!AccountType.value ? '请输入邮箱' : '手机号'),
              modelValue: ruleForm.VerificationMode,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ruleForm.VerificationMode) = $event))
            }, _createSlots({ _: 2 }, [
              (AccountType.value)
                ? {
                    name: "prepend",
                    fn: _withCtx(() => [
                      _createVNode(RegionSelection, { onAreaCode: AreaCode })
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('验证码'),
          class: "VerificationCode",
          prop: "VerificationCode"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: ruleForm.VerificationCode,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ruleForm.VerificationCode) = $event)),
              placeholder: _ctx.$t('请输入验证码')
            }, null, 8, ["modelValue", "placeholder"]),
            _createVNode(_component_el_button, {
              loading: CodeLoading.value,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (VCode(ruleFormRef.value)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(!CodeLoading.value ? _ctx.$t('获取验证码') : _unref(CodeTime).count), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              class: "submitBtn",
              size: "large ",
              type: "danger",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (submitForm(ruleFormRef.value))),
              disabled: !ruleForm.VerificationCode
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('验证')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]), [
      [_vShow, active.value == 1]
    ]),
    _withDirectives(_createVNode(_component_el_form, {
      class: "recoverForm",
      ref_key: "newPwdRef",
      ref: newPwdRef,
      model: pwdForm,
      rules: pwdRules,
      "label-position": "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('新密码'),
          prop: "PayPwd"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: _ctx.$t('请输入新密码'),
              onBlur: _cache[5] || (_cache[5] = ($event: any) => (pwdForm.PayPwd = $event.target.value.trim())),
              modelValue: pwdForm.PayPwd,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((pwdForm.PayPwd) = $event)),
              type: "password",
              "show-password": ""
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('再次输入新密码'),
          prop: "ConfirmPayPwd"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: _ctx.$t('请输再次输入新密码'),
              modelValue: pwdForm.ConfirmPayPwd,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((pwdForm.ConfirmPayPwd) = $event)),
              type: "password",
              "show-password": ""
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              class: "submitBtn",
              size: "large ",
              type: "danger",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (setNewPwd(newPwdRef.value)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]), [
      [_vShow, active.value == 2]
    ]),
    (active.value == 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_icon, {
            size: 100,
            color: "#FA713E"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SuccessFilled)
            ]),
            _: 1
          }),
          _createElementVNode("div", null, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('新密码设置成功')) + "!", 1),
            _createVNode(_component_router_link, { to: "/login" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  size: "large ",
                  type: "danger"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('立即登录')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})