import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/imgs/UserLink.png'
import _imports_1 from '../../assets/imgs/UserOrder.png'
import _imports_2 from '../../assets/imgs/UserBalance.png'
import _imports_3 from '../../assets/imgs/UserCoupon.png'


const _hoisted_1 = { class: "UserCenter" }
const _hoisted_2 = { class: "UserName" }
const _hoisted_3 = { class: "RankName" }
const _hoisted_4 = { class: "UserModular" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }

import { ref, onMounted, toRefs } from 'vue'
import * as Api from '@/utils/api'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'basic',
  setup(__props) {

const store = useUsersStore()
const { CustomerInfo } = toRefs(store)

const User = ref(null)

onMounted(() => {
  store.setState('user/api/CustomerInfo/',{},'CustomerInfo')
  Api.Get('user/api/GetPendingNum', {}).then((res: any) => {
    User.value = res
  })
})

return (_ctx: any,_cache: any) => {
  const _component_avatar = _resolveComponent("avatar")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_icon, null, {
      default: _withCtx(() => [
        _createVNode(_component_avatar)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(CustomerInfo)?.Customer.RankName), 1),
      _createElementVNode("div", null, [
        _createElementVNode("p", null, _toDisplayString(_unref(CustomerInfo)?.Customer.CustomerName), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('业务员')) + ": " + _toDisplayString(_unref(CustomerInfo)?.Customer.SalesName), 1)
      ])
    ]),
    _createElementVNode("ul", _hoisted_4, [
      (_ctx.$SiteSet?.Applink)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: { name: 'invitation' }
          }, {
            default: _withCtx(() => [
              _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('我的专属链接')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, { to: { name: 'orders' } }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
          _createElementVNode("div", null, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('我的订单')), 1),
            (User.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, "(" + _toDisplayString(User.value.OrderNum) + ")", 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: { name: 'wallet' } }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
          _createElementVNode("div", null, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('可用余额')), 1),
            (User.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, "(" + _toDisplayString(_ctx.$ccy(User.value.Balance)) + ")", 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: { name: 'coupons' } }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("img", { src: _imports_3 }, null, -1)),
          _createElementVNode("div", null, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('我的优惠卷')), 1),
            (User.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, "(" + _toDisplayString(User.value.CouponNum) + ")", 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})