import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "freight_estimate" }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { class: "InterFreight" }

import { ref, reactive, onMounted } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import Loading from '@/components/loading.vue'
import * as Api from '@/utils/api'
import Repository from '@/components/user-center/repository.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'freight_estimate',
  setup(__props) {

const Warehouse = ref(null)
const ShowSetWarehouse = ref(false)
const SetWarehouse = (val) => {
  ruleForm.target_ware_id = val.params.WarehouseId
  Warehouse.value.Id = val.params.WarehouseId
  Warehouse.value.Name = val.params.WarehouseName
  ShowSetWarehouse.value = false
}

const ruleFormRef = ref<FormInstance>()
const ruleForm = reactive({
  weight: 1,
  length: 1,
  width: 1,
  height: 1,
  quantity: 1,
  freight_type_id: null,
  target_ware_id: null,
  package_category_id: null,
})

const validatePass = (rule: any, value: any, callback: any) => {
  if (!value && ruleForm.freight_type_id == 4) {
    callback(new Error('Please select'))
  } else {
    callback()
  }
}

const rules = reactive<FormRules>({
  freight_type_id: [
    { required: true, message: 'Please select', trigger: 'change' },
  ],
  target_ware_id: [
    { required: true, message: 'Please select', trigger: 'change' },
  ],
  package_category_id: [{ validator: validatePass, trigger: 'change' }],
})

const InterFreight = ref(0)
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      if (ruleForm.freight_type_id != 4) {
        ruleForm.quantity = 1
        ruleForm.package_category_id = 1
      }
      InterFreight.value = null
      Api.Get('basic/api/EstimateInterFreight/', ruleForm).then((res: any) => {
        InterFreight.value = res.InterFreight
      })
    }
  })
}

const Types = ref(null)
const BasicDictionaryList = ref(null)

onMounted(() => {
  Api.Get('basic/api/TransnationalTypeNew/', {
    type_id: 2,
  }).then((res: any) => {
    Types.value = res.Types
  })

  Api.Get('basic/api/BasicDictionary', {
    type_id: 180,
  }).then((res: any) => {
    BasicDictionaryList.value = res.BasicDictionaryList
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_form, {
        ref_key: "ruleFormRef",
        ref: ruleFormRef,
        model: ruleForm,
        rules: rules,
        size: _ctx.formSize,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('提货仓库'),
              prop: "target_ware_id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (ShowSetWarehouse.value = true)),
                  size: "large",
                  class: "SetWarehouseBtn"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(Warehouse.value?.WarehouseName || _ctx.$t('请选择')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('运输方式'),
              prop: "freight_type_id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: ruleForm.freight_type_id,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ruleForm.freight_type_id) = $event)),
                  placeholder: _ctx.$t('请选择'),
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Types.value, (Type) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: Type.ID,
                        label: Type.TypeName,
                        value: Type.ID
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_collapse_transition, null, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_el_form_item, {
                  label: _ctx.$t('包裹类目'),
                  prop: "package_category_id",
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: ruleForm.package_category_id,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ruleForm.package_category_id) = $event)),
                      placeholder: _ctx.$t('请选择')
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(BasicDictionaryList.value, (Dictionary) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: Dictionary.DicValue,
                            label: Dictionary.DicName,
                            value: Dictionary.DicValue
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]), [
                  [_vShow, ruleForm.freight_type_id == 4]
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('长度') + '(CM)',
              prop: "length"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  modelValue: ruleForm.length,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((ruleForm.length) = $event)),
                  min: 0.1,
                  precision: 1,
                  step: 1,
                  "controls-position": "right",
                  size: "large"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('宽度') + '(CM)',
              prop: "width"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  modelValue: ruleForm.width,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((ruleForm.width) = $event)),
                  min: 0.1,
                  precision: 1,
                  step: 1,
                  "controls-position": "right",
                  size: "large"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('高度') + '(CM)',
              prop: "height"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  modelValue: ruleForm.height,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((ruleForm.height) = $event)),
                  min: 0.1,
                  precision: 1,
                  step: 1,
                  "controls-position": "right",
                  size: "large"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('重量') + '(KG)',
              prop: "weight"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  modelValue: ruleForm.weight,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((ruleForm.weight) = $event)),
                  min: 0.1,
                  precision: 1,
                  step: 1,
                  "controls-position": "right",
                  size: "large"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _createVNode(_component_el_collapse_transition, null, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_el_form_item, {
                label: _ctx.$t('数量'),
                prop: "length"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, {
                    modelValue: ruleForm.length,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((ruleForm.length) = $event)),
                    min: 1,
                    step: 1,
                    "controls-position": "right",
                    size: "large"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]), [
                [_vShow, ruleForm.freight_type_id == 4]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "danger",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (submitForm(ruleFormRef.value))),
                size: "large"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('查询')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules", "size"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createTextVNode(_toDisplayString(_ctx.$t('运费估算')) + ": " + _toDisplayString(_ctx.$ccy(InterFreight.value)) + " ", 1),
      _withDirectives(_createVNode(Loading, { type: "7" }, null, 512), [
        [_vShow, InterFreight.value == null]
      ])
    ]),
    _createVNode(_component_el_dialog, {
      width: "600px",
      modelValue: ShowSetWarehouse.value,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((ShowSetWarehouse).value = $event)),
      title: _ctx.$t('提货仓库')
    }, {
      default: _withCtx(() => [
        _createVNode(Repository, {
          noSet: true,
          Warehouse: Warehouse.value,
          onWarehouseChange: SetWarehouse
        }, null, 8, ["Warehouse"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}
}

})