import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "load-style" }
const _hoisted_2 = {
  key: 0,
  class: "sk-plane"
}
const _hoisted_3 = {
  key: 1,
  class: "sk-chase"
}
const _hoisted_4 = {
  key: 2,
  class: "sk-bounce"
}
const _hoisted_5 = {
  key: 3,
  class: "sk-wave"
}
const _hoisted_6 = {
  key: 4,
  class: "sk-pulse"
}
const _hoisted_7 = {
  key: 5,
  class: "sk-flow"
}
const _hoisted_8 = {
  key: 6,
  class: "sk-swing"
}
const _hoisted_9 = {
  key: 7,
  class: "sk-circle"
}
const _hoisted_10 = {
  key: 8,
  class: "sk-circle-fade"
}
const _hoisted_11 = {
  key: 9,
  class: "sk-grid"
}
const _hoisted_12 = {
  key: 10,
  class: "sk-fold"
}
const _hoisted_13 = {
  key: 11,
  class: "sk-wander"
}
const _hoisted_14 = {
  key: 12,
  class: "sk-win10"
}

import { ref, onMounted } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'loading',
  props: {
  type: [String, Number],
},
  setup(__props) {

const style: any = ref(null)
const props = __props

onMounted(() => {
  style.value = Number(props.type) || 1
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (style.value == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    (style.value == 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
          _createStaticVNode("<div class=\"sk-chase-dot\" data-v-29bd8e71></div><div class=\"sk-chase-dot\" data-v-29bd8e71></div><div class=\"sk-chase-dot\" data-v-29bd8e71></div><div class=\"sk-chase-dot\" data-v-29bd8e71></div><div class=\"sk-chase-dot\" data-v-29bd8e71></div><div class=\"sk-chase-dot\" data-v-29bd8e71></div>", 6)
        ])))
      : _createCommentVNode("", true),
    (style.value == 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "sk-bounce-dot" }, null, -1),
          _createElementVNode("div", { class: "sk-bounce-dot" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (style.value == 4)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [
          _createStaticVNode("<div class=\"sk-wave-rect\" data-v-29bd8e71></div><div class=\"sk-wave-rect\" data-v-29bd8e71></div><div class=\"sk-wave-rect\" data-v-29bd8e71></div><div class=\"sk-wave-rect\" data-v-29bd8e71></div><div class=\"sk-wave-rect\" data-v-29bd8e71></div>", 5)
        ])))
      : _createCommentVNode("", true),
    (style.value == 5)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
      : _createCommentVNode("", true),
    (style.value == 6)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[3] || (_cache[3] = [
          _createElementVNode("div", { class: "sk-flow-dot" }, null, -1),
          _createElementVNode("div", { class: "sk-flow-dot" }, null, -1),
          _createElementVNode("div", { class: "sk-flow-dot" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (style.value == 7)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[4] || (_cache[4] = [
          _createElementVNode("div", { class: "sk-swing-dot" }, null, -1),
          _createElementVNode("div", { class: "sk-swing-dot" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (style.value == 8)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[5] || (_cache[5] = [
          _createStaticVNode("<div class=\"sk-circle-dot\" data-v-29bd8e71></div><div class=\"sk-circle-dot\" data-v-29bd8e71></div><div class=\"sk-circle-dot\" data-v-29bd8e71></div><div class=\"sk-circle-dot\" data-v-29bd8e71></div><div class=\"sk-circle-dot\" data-v-29bd8e71></div><div class=\"sk-circle-dot\" data-v-29bd8e71></div><div class=\"sk-circle-dot\" data-v-29bd8e71></div><div class=\"sk-circle-dot\" data-v-29bd8e71></div><div class=\"sk-circle-dot\" data-v-29bd8e71></div><div class=\"sk-circle-dot\" data-v-29bd8e71></div><div class=\"sk-circle-dot\" data-v-29bd8e71></div><div class=\"sk-circle-dot\" data-v-29bd8e71></div>", 12)
        ])))
      : _createCommentVNode("", true),
    (style.value == 9)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[6] || (_cache[6] = [
          _createStaticVNode("<div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div><div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div><div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div><div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div><div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div><div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div><div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div><div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div><div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div><div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div><div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div><div class=\"sk-circle-fade-dot\" data-v-29bd8e71></div>", 12)
        ])))
      : _createCommentVNode("", true),
    (style.value == 10)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[7] || (_cache[7] = [
          _createStaticVNode("<div class=\"sk-grid-cube\" data-v-29bd8e71></div><div class=\"sk-grid-cube\" data-v-29bd8e71></div><div class=\"sk-grid-cube\" data-v-29bd8e71></div><div class=\"sk-grid-cube\" data-v-29bd8e71></div><div class=\"sk-grid-cube\" data-v-29bd8e71></div><div class=\"sk-grid-cube\" data-v-29bd8e71></div><div class=\"sk-grid-cube\" data-v-29bd8e71></div><div class=\"sk-grid-cube\" data-v-29bd8e71></div><div class=\"sk-grid-cube\" data-v-29bd8e71></div>", 9)
        ])))
      : _createCommentVNode("", true),
    (style.value == 11)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[8] || (_cache[8] = [
          _createElementVNode("div", { class: "sk-fold-cube" }, null, -1),
          _createElementVNode("div", { class: "sk-fold-cube" }, null, -1),
          _createElementVNode("div", { class: "sk-fold-cube" }, null, -1),
          _createElementVNode("div", { class: "sk-fold-cube" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (style.value == 12)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[9] || (_cache[9] = [
          _createElementVNode("div", { class: "sk-wander-cube" }, null, -1),
          _createElementVNode("div", { class: "sk-wander-cube" }, null, -1),
          _createElementVNode("div", { class: "sk-wander-cube" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (style.value == 13)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[10] || (_cache[10] = [
          _createElementVNode("i", null, [
            _createElementVNode("span"),
            _createTextVNode(),
            _createElementVNode("span"),
            _createTextVNode(),
            _createElementVNode("span"),
            _createTextVNode(),
            _createElementVNode("span"),
            _createTextVNode(),
            _createElementVNode("span"),
            _createElementVNode("span")
          ], -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})