import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "invitation" }
const _hoisted_2 = { class: "invitationTitle" }
const _hoisted_3 = { class: "invitationUrl" }

import { ref, reactive, onMounted, toRefs } from 'vue'
import { ElMessageBox } from 'element-plus'
import * as Api from '@/utils/api'
import useClipboard from 'vue-clipboard3'
import { useI18n } from 'vue-i18n'

import { useUsersStore } from '@/pinia'

export default /*@__PURE__*/_defineComponent({
  __name: 'invitation',
  setup(__props) {

const store = useUsersStore()
const { CustomerInfo } = toRefs(store)

const { toClipboard } = useClipboard()
const { t } = useI18n()

const invitationUrl = ref(null)
const loading = ref(false)

const Copy = () => {
  toClipboard(invitationUrl.value)
  ElMessageBox.alert(t('复制成功!快去粘贴发送给您的好友吧'), t('信息'), {
    confirmButtonText: 'OK',
  })
}

onMounted(() => {
  store.setState('user/api/CustomerInfo/', {}, 'CustomerInfo')
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('我的专属链接')), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(invitationUrl.value = 'https://wap.loda.com.kh/loda.html?' + _unref(CustomerInfo)?.Customer.IDCardNo), 1),
    _createVNode(_component_el_button, {
      onClick: Copy,
      type: "danger",
      icon: "Share"
    })
  ])), [
    [_directive_loading, (loading.value = _unref(CustomerInfo)?.Customer ? false : true)]
  ])
}
}

})