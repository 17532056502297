import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../assets/imgs/Guide00.jpg'
import _imports_1 from '../assets/imgs/Guide01.png'
import _imports_2 from '../assets/imgs/Guide02.png'
import _imports_3 from '../assets/imgs/Guide03.png'
import _imports_4 from '../assets/imgs/Guide04.png'
import _imports_5 from '../assets/imgs/Guide05.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "GuideTabs" }
const _hoisted_3 = { class: "GuideContent" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }

import { ref, reactive, onMounted, watch } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'Guide',
  setup(__props) {

const GuideType = ref(1)
onMounted(() => { })

return (_ctx: any,_cache: any) => {
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[55] || (_cache[55] = _createElementVNode("img", {
      class: "GuideM",
      src: _imports_0
    }, null, -1)),
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", {
        class: _normalizeClass({ active: GuideType.value == 1 }),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (GuideType.value = 1))
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('如何注册')), 1)
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass({ active: GuideType.value == 2 }),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (GuideType.value = 2))
      }, [
        _cache[6] || (_cache[6] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('如何下单')), 1)
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass({ active: GuideType.value == 3 }),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (GuideType.value = 3))
      }, [
        _cache[7] || (_cache[7] = _createElementVNode("img", { src: _imports_3 }, null, -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('如何充值')), 1)
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass({ active: GuideType.value == 4 }),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (GuideType.value = 4))
      }, [
        _cache[8] || (_cache[8] = _createElementVNode("img", { src: _imports_4 }, null, -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('如何提现')), 1)
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass({ active: GuideType.value == 5 }),
        onClick: _cache[4] || (_cache[4] = ($event: any) => (GuideType.value = 5))
      }, [
        _cache[9] || (_cache[9] = _createElementVNode("img", { src: _imports_5 }, null, -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('安全设置')), 1)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_collapse_transition, null, {
        default: _withCtx(() => [
          (GuideType.value == 1)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[10] || (_cache[10] = _createElementVNode("span", null, " 1 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('找到“免费注册”')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '新用户进入首页后，点击首页右上角免费注册按钮，即可进入免费注册页面'
                  )), 1),
                    _cache[11] || (_cache[11] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[12] || (_cache[12] = _createElementVNode("span", null, " 2 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('填写注册信息')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('①“用户名”必填,并且唯一。')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('② 设定登录密码,密码长度不能小于6位数。')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('③“邮箱”与“手机号”非必填,若填写,则必须验证。')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('④“支付密码”选填,密码长度不能小于6位数。')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('⑤“业务员”（选填,推荐您的业务员工号,如果没有,可不填）。')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '注意:邮箱和手机号均未验证时,只能登录不能下单;两者只需一个验证通过,则即可登录也能下单;验证邮箱或手机号可进入安全设置模块进行设置。'
                  )), 1),
                    _cache[13] || (_cache[13] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[14] || (_cache[14] = _createElementVNode("span", null, " 3 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('勾选用户注册协议')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('勾选用户注册协议，点击【用户注册协议】，可查看协议内容')), 1),
                    _cache[15] || (_cache[15] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[16] || (_cache[16] = _createElementVNode("span", null, " 4 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('找到“免费注册”')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '注册完后自动登录,用户可点击浏览首页按钮,也可进入用户信息页面绑定相关信息。'
                  )), 1),
                    _cache[17] || (_cache[17] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_transition, null, {
        default: _withCtx(() => [
          (GuideType.value == 2)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[18] || (_cache[18] = _createElementVNode("span", null, " 1 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('查找商品')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '在中国电商网站（淘宝,天猫等）挑选您满意的商品，复制商品详情页链接'
                  )), 1),
                    _cache[19] || (_cache[19] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[20] || (_cache[20] = _createElementVNode("span", null, " 2 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('加入购物车')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '将商品链接粘贴至代购搜索栏中,点击“我要代购”,手动选择颜色、尺寸等,点击“添加到购物车”'
                  )), 1),
                    _cache[21] || (_cache[21] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[22] || (_cache[22] = _createElementVNode("span", null, " 3 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('提交订单')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '①点击购物车,选择网购的商品,点击“提交订单”（建议将想要购买的商品全部添加至购物车后,一起提交）'
                  )), 1),
                    _cache[23] || (_cache[23] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '②创建订单成功后,订单状态为“审核中”,一般在1-3个工作小时内完成审核'
                  )), 1),
                    _cache[24] || (_cache[24] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[25] || (_cache[25] = _createElementVNode("span", null, " 4 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('完成支付')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '①审核通过后,点击“所有订单”中的“待付款”,进入订单页面,点击“去支付”,按照提示完成支付'
                  )), 1),
                    _cache[26] || (_cache[26] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '②输入交易密码即可完成支付。如果未设置交易密码,请您先设置交易密码,提高您的账户的安全'
                  )), 1),
                    _cache[27] || (_cache[27] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '③支付成功后,订单状态显示为“待收货”时,即可在当地自提点收取商品'
                  )), 1),
                    _cache[28] || (_cache[28] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_transition, null, {
        default: _withCtx(() => [
          (GuideType.value == 3)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[29] || (_cache[29] = _createElementVNode("span", null, " 1 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('进入充值页面')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '网站支付电子钱包付款方式,您需要先通过银行转账将订单金额汇入官方指定帐号,并在网站充值界面填写充值信息,我们的工作人员会尽快处理'
                  )), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '①在中国电商网站（淘宝,天猫等）挑选您满意的商品,复制商品详情页链接'
                  )), 1),
                    _cache[30] || (_cache[30] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('②点击“充值提现”,进入账户余额与明细页面')), 1),
                    _cache[31] || (_cache[31] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('③点击账户余额右侧“充值”,进入充值页面')), 1),
                    _cache[32] || (_cache[32] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[33] || (_cache[33] = _createElementVNode("span", null, " 2 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('充值')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '使用银行线下汇款的方式充值成功后,在充值页面填写“汇入银行”（网站官方指定帐号 ）、“充值金额”“充值时间”（时间需与银行汇款单上填写一致）、“充值证明”以及“备注”等信息,并点击确定完成充值'
                  )), 1),
                    _cache[34] || (_cache[34] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[35] || (_cache[35] = _createElementVNode("span", null, " 3 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('充值成功')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '充值完成后,需等待工作人员进行审核,审核完成之后您可以前往充值提现界面查看充值提现记录'
                  )), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_transition, null, {
        default: _withCtx(() => [
          (GuideType.value == 4)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[36] || (_cache[36] = _createElementVNode("span", null, " 1 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('进入提现页面')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('①登录之后,点击位于首页上方用户名,进入订单中心')), 1),
                    _cache[37] || (_cache[37] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('②点击“充值提现”,进入账户余额与明细页面')), 1),
                    _cache[38] || (_cache[38] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('③点击账户余额右侧“提现”,进入提现页面')), 1),
                    _cache[39] || (_cache[39] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[40] || (_cache[40] = _createElementVNode("span", null, " 2 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('提现')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '①如果您还未添加银行卡,请先添加银行卡。添加前请仔细阅读《用户服务协议》,如无异议，请勾选“我已阅读《用户服务协议》”并点击确认添加。'
                  )), 1),
                    _cache[41] || (_cache[41] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '②如果已添加银行卡,请在提现页面填写“提现金额”、“提现密码”（请在“安全设置”中设置您的交易密码以便提现）,并点击确认完成提现。'
                  )), 1),
                    _cache[42] || (_cache[42] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[43] || (_cache[43] = _createElementVNode("span", null, " 3 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('进入提现页面')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '提现完成后,需等待工作人员进行审核,审核完成之后您可以前往充值提现界面查看充值提现记录。'
                  )), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_transition, null, {
        default: _withCtx(() => [
          (GuideType.value == 5)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[44] || (_cache[44] = _createElementVNode("span", null, " 1 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('安全设置页面')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('①在首页顶部点击您已登录的“用户名”')), 1),
                    _cache[45] || (_cache[45] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('②安全设置页面')), 1),
                    _cache[46] || (_cache[46] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[47] || (_cache[47] = _createElementVNode("span", null, " 2 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('修改登录密码')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('①点击登录密码后面的“修改密码”')), 1),
                    _cache[48] || (_cache[48] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('②填写新的登录密码、确认登录密码，点击“修改”')), 1),
                    _cache[49] || (_cache[49] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('③密码修改完成')), 1),
                    _cache[50] || (_cache[50] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("div", null, [
                    _cache[51] || (_cache[51] = _createElementVNode("span", null, " 3 ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('启用交易密码')), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('①点击交易密码后面的“立即启用”')), 1),
                    _cache[52] || (_cache[52] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    '②点击获取验证码、填写验证码,然后填写设置提现密码、确认提现密码,点击“提交”'
                  )), 1),
                    _cache[53] || (_cache[53] = _createElementVNode("img", { src: "" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('③密码设置完成')), 1),
                    _cache[54] || (_cache[54] = _createElementVNode("img", { src: "" }, null, -1))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})