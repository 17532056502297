import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "OrderCard" }
const _hoisted_2 = { class: "Card_Head" }
const _hoisted_3 = { class: "Card_Body" }
const _hoisted_4 = { class: "Card_Body_L" }
const _hoisted_5 = { class: "summary" }
const _hoisted_6 = { class: "Product" }
const _hoisted_7 = { class: "summary" }
const _hoisted_8 = { class: "Product" }
const _hoisted_9 = { class: "Card_Body_R" }
const _hoisted_10 = { class: "Configuration" }
const _hoisted_11 = { class: "Configuration" }
const _hoisted_12 = { class: "Configuration_C" }
const _hoisted_13 = { class: "ModifiedByName" }
const _hoisted_14 = { class: "Configuration" }
const _hoisted_15 = { class: "Configuration_C" }

import { ref, onMounted } from 'vue'
import * as Api from '@/utils/api'
import { formatDate } from '@/utils/format'
import { useRouter } from 'vue-router'
import MallLabel from '@/components/mall_label.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'sale-order-card',
  props: {
  data: Object,
  index: Number,
},
  setup(__props) {

const userouter = useRouter()
const isMore = ref(false)
const show = ref(false)
const FirstArticle = ref(null)
const ShowPay = ref(false)
const PayDate = ref(null)
const SourceId = ref(null)

const props = __props

const orderDetails = () => {
  const href = userouter.resolve({
    path: '/afterSalesOrder',
    query: { id: props.data.SaleOrderNo },
  })
  window.open(href.href, '_blank')
}

onMounted(() => {
  PayDate.value = props.data
  SourceId.value = props.data.CustomerOrderSourceId
  FirstArticle.value = props.data.SaleOrderItemList[0]
  setTimeout(() => {
    show.value = true
  }, 100 * props.index)
})

return (_ctx: any,_cache: any) => {
  const _component_suffix_img = _resolveComponent("suffix-img")!
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition")!
  const _component_el_link = _resolveComponent("el-link")!

  return (_openBlock(), _createBlock(_Transition, { name: "el-fade-in-linear" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(props.data.CreationTime, 'yyyy-MM-dd hh:mm:ss')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('订单号')) + ": ", 1),
          _createElementVNode("span", null, _toDisplayString(props.data.OrderNo), 1),
          _createVNode(MallLabel)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_suffix_img, {
                url: FirstArticle.value.ImgUrl,
                type: 3
              }, null, 8, ["url"]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", null, _toDisplayString(FirstArticle.value.ProductName), 1),
                _createElementVNode("p", null, _toDisplayString(FirstArticle.value.SkuTitle), 1)
              ]),
              _createElementVNode("div", null, "x " + _toDisplayString(FirstArticle.value.Qty), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.data.SaleOrderItemList, (item, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: item.ItemId
              }, [
                (index > 0)
                  ? (_openBlock(), _createBlock(_component_el_collapse_transition, { key: 0 }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_suffix_img, {
                            url: item.ImgUrl,
                            type: 3
                          }, null, 8, ["url"]),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("p", null, _toDisplayString(item.ProductName), 1),
                            _createElementVNode("p", null, _toDisplayString(item.Qty), 1)
                          ]),
                          _createElementVNode("div", null, "x " + _toDisplayString(item.Quantity), 1)
                        ], 512), [
                          [_vShow, isMore.value]
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            (props.data.SaleOrderItemList.length > 1)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "isMore",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (isMore.value = !isMore.value))
                }, _toDisplayString(_ctx.$t(!isMore.value ? '更多' : '收起')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$ccy(props.data.Amount)), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("p", null, _toDisplayString(props.data.TypeName), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("span", null, _toDisplayString(props.data.StatuName), 1)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_el_link, { onClick: orderDetails }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('售后详情')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ])
      ], 512), [
        [_vShow, show.value]
      ])
    ]),
    _: 1
  }))
}
}

})