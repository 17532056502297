import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "enter_package" }
const _hoisted_2 = { class: "dialog-footer" }

import { ref, reactive, onMounted, watch } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import * as Api from '@/utils/api'
import { useI18n } from 'vue-i18n'
import OSS from '@/components/oss.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'enter_package',
  props: {
  SkuItem: Object,
  ShowEnter: Boolean,
},
  emits: ['EnterChange', 'HideEnter'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()
const emit = __emit

const DoneEnter = () => {
  emit('HideEnter')
}

const SaleRef = ref<FormInstance>()
const title = ref(null)

const SaleRules = reactive<FormRules>({
  Remark: [{ required: true, message: t('不能为空'), trigger: 'blur' }],
})

const EnterLoad = ref(false)

const SaleForm = reactive({
  CompanyId: null,
  ImgUrlList: [],
  Remark: null,
})

const SubmitSale = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      let body = {
        ImgUrlList: [],
      }
      body.ImgUrlList = body.ImgUrlList.map((item) => {
        return item.url
      })
      if (props.SkuItem.StatuId != 2) {
        body = {
          AfterSaleId: props.SkuItem.Id,
          ExpressNo: SaleForm.Remark,
          CompanyId: SaleForm.CompanyId,
          ImgUrlList: body.ImgUrlList,
        }
        EnterLoad.value = true

        Api.Post('orders/api/SaleAddExpress', body).then((res: any) => {
          EnterLoad.value = false
          emit('EnterChange')
        })
      } else {
        body = {
          SaleOrderNo: props.SkuItem.SaleOrderNo,
          Remark: SaleForm.Remark,
          ImgUrlList: body.ImgUrlList,
        }
        EnterLoad.value = true
        Api.Post('orders/api/SalePlatformIntervention', body).then(
          (res: any) => {
            EnterLoad.value = false
            emit('EnterChange')
          }
        )
      }
    }
  })
}

const props = __props
const DictionaryList = ref(null)

watch(
  () => props.SkuItem.StatuId,
  (val) => {
    title.value = val == 2 ? t('客服介入') : t('录入包裹')
    if (val != 2) {
      Api.Get('basic/api/BasicDictionary/', {
        type_id: 196,
      }).then((res: any) => {
        DictionaryList.value = res.BasicDictionaryList
        SaleForm.CompanyId = res.BasicDictionaryList[0].DicValue
      })
    }
  },
  {
    deep: true,
    immediate: true,
  }
)

onMounted(() => {})

return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: props.ShowEnter,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((props.ShowEnter) = $event)),
      title: title.value,
      width: "800px",
      "before-close": DoneEnter,
      center: true
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_el_button, {
            onClick: DoneEnter,
            size: "large"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('关闭')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "danger",
            size: "large",
            loading: EnterLoad.value,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (SubmitSale(SaleRef.value)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('提交')), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref_key: "SaleRef",
          ref: SaleRef,
          model: SaleForm,
          rules: SaleRules,
          "label-position": "top",
          class: "PackageForm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('售后单号')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.SkuItem.SaleOrderNo), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('类型')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.SkuItem.SaleType), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('状态')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.SkuItem.Statu), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: props.SkuItem.StatuId == 2 ? _ctx.$t('介入原因') : _ctx.$t('快递单号'),
              prop: "Remark"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: SaleForm.Remark,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((SaleForm.Remark) = $event)),
                  placeholder: _ctx.$t('请输入')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            (props.SkuItem.StatuId != 2)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: _ctx.$t('选择快递')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: SaleForm.CompanyId,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((SaleForm.CompanyId) = $event)),
                      placeholder: _ctx.$t('请选择'),
                      size: "large",
                      class: "cancelOrder"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(DictionaryList.value, (cancel) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: cancel.DicValue,
                            label: cancel.DicName,
                            value: cancel.DicValue
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('上传凭证')
            }, {
              default: _withCtx(() => [
                _createVNode(OSS, {
                  type: 4,
                  imgList: SaleForm.ImgUrlList,
                  max: 2,
                  onImgsChange: _cache[2] || (_cache[2] = 
              (val) => {
                SaleForm.ImgUrlList = val
              }
            )
                }, null, 8, ["imgList"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}
}

})