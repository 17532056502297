import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "InvitationRegister" }
const _hoisted_2 = { class: "I_body" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "newUser" }

import { ref, reactive, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import * as Api from '@/utils/api'
import RegionSelection from '@/components/RegionSelection.vue'
import type { FormInstance, FormRules } from 'element-plus'
import { useI18n } from 'vue-i18n'
import { textContent } from '@/utils/format'
import { ElMessageBox } from 'element-plus'
import countDown from '@/utils/countDown'


export default /*@__PURE__*/_defineComponent({
  __name: 'InvitationRegister',
  setup(__props) {

const { t } = useI18n()
const useroute = useRoute()
const formRef = ref<FormInstance>()
const CodeLoading = ref(false)
const LoginNameRegex = ref(null)

const checkPass = (rule: any, value: any, callback: any) => {
  if (ConfirmPassword.value != modelForm.Password) {
    callback(new Error(t('密码不一致')))
  } else {
    callback()
  }
}

const UserNamePass = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error(t('不能为空')))
  } else if (!LoginNameRegex.value.test(value)) {
    callback(
      new Error(
        t(
          '支持英文、数字、"-"、"."、"_"的组合，4-20个字符。不能纯数字，不支持邮箱'
        )
      )
    )
  } else {
    Api.Get('user/api/CustomerRegister/', {
      LoginName: value,
      IsCn: textContent(false),
      LoginChannel: 1,
    })
      .then((res: any) => {
        callback()
      })
      .catch((err) => {
        callback(new Error(err.ErrMsg))
      })
  }
}

const DevicePass = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error(t('不能为空')))
  } else {
    !eval(userAreaCode.value.Regular).test(value)
      ? callback(new Error(t('请输入正确手机号')))
      : callback()
  }
}

const modelForm = reactive({
  LoginName: null,
  Password: null,
  Phone: null,
  VerificationCode: null,
  PromotionCode: null,
})

const ConfirmPassword = ref(null)

const userAreaCode = ref(null)
const AreaCode = (val) => {
  userAreaCode.value = val
}

const VCode = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validateField('Phone', (valid) => {
    if (valid) {
      Api.Get2('user/api/BindingInformation/', {
        BindingType: 'Mobile',
        BindAccount: modelForm.LoginName,
        IsBinding: textContent(false),
        IsCn: textContent(false),
      }).then((res: any) => {
        if (!res.IsError) {
          Api.Get2('user/api/SendMessage/', {
            SendTo: modelForm.Phone,
          }).then(
            (res) => {
              startTimeout()
              ElMessageBox.alert(t('已发送验证码,请注意查收'), t('提示'), {
                confirmButtonText: t('确定'),
              })
            },
            (err) => {
              CodeLoading.value = false
            }
          )
        }
      })
    }
  })
}

const formRules = reactive({
  Phone: [{ required: true, validator: DevicePass }],
  LoginName: [{ required: true, validator: UserNamePass, trigger: 'blur' }],
  Password: [
    { required: true, message: t('请输入'), trigger: 'change' },
    { min: 6, message: t('最少6位'), trigger: 'change' },
  ],
  ConfirmPassword: [
    { required: true, validator: checkPass, trigger: 'change' },
  ],
  VerificationCode: [{ required: true, message: t('请输入'), trigger: 'blur' }],
})

const { state: CodeTime, start: startTimeout, check_count: CCD } = countDown()

watch(
  () => CodeTime.count,
  (newV, oldV) => {
    CodeLoading.value = newV == 0 ? false : true
  },
  {
    immediate: true, // 立即执行
    deep: true, // 深度监听
  }
)

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      const body = {
        VerificationType: 'Mobile',
        IsCn: textContent(userAreaCode.value.IsCn),
        LoginName: modelForm.LoginName,
        LoginChannel: 1,
      }
      Api.Post('user/api/CustomerRegister/', { ...body, ...modelForm }).then(
        (res: any) => {
          if (!res.IsError) {
          }
        }
      )
    }
  })
}

onMounted(() => {
  sessionStorage.setItem('wid', useroute.query.wid)
  modelForm.PromotionCode = useroute.query.code
  LoginNameRegex.value = new RegExp(
    JSON.parse(localStorage.getItem('GetAppConfig'))?.LoginNameRegex ||
      '^(?=.*[a-zA-Z])(?!.*@)(?!.*\\.$)(?!^[._-])[a-zA-Z0-9._-]{4,20}$'
  )
})

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "bgPng",
        src: require('../assets/imgs/InvitationRegisterBg.png')
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('新用户注册')), 1),
        _createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          model: modelForm,
          rules: formRules
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { prop: "Phone" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: modelForm.Phone,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((modelForm.Phone) = $event)),
                  placeholder: _ctx.$t('手机号'),
                  "prefix-icon": "Iphone"
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(RegionSelection, {
                      wid: _unref(useroute).query.wid,
                      onAreaCode: AreaCode
                    }, null, 8, ["wid"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              class: "VerificationCode",
              prop: "VerificationCode"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: modelForm.VerificationCode,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((modelForm.VerificationCode) = $event)),
                  placeholder: _ctx.$t('验证码')
                }, null, 8, ["modelValue", "placeholder"]),
                _createVNode(_component_el_button, {
                  loading: CodeLoading.value,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (VCode(formRef.value)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(!CodeLoading.value ? _ctx.$t('获取验证码') : _unref(CodeTime).count), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { prop: "LoginName" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: modelForm.LoginName,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((modelForm.LoginName) = $event)),
                  placeholder: _ctx.$t('用户名')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { prop: "Password" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: modelForm.Password,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((modelForm.Password) = $event)),
                  placeholder: _ctx.$t('密码')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { prop: "ConfirmPassword" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: ConfirmPassword.value,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((ConfirmPassword).value = $event)),
                  placeholder: _ctx.$t('确认密码')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: modelForm.PromotionCode,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((modelForm.PromotionCode) = $event)),
                  placeholder: _ctx.$t('邀请码')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  size: "large",
                  type: "danger",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (submitForm(formRef.value))),
                  class: "submit"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('注册')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ])
  ]))
}
}

})