import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, unref as _unref } from "vue"
import _imports_0 from '../assets/imgs/earth.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "step" }
const _hoisted_3 = {
  key: 1,
  class: "Consignee"
}
const _hoisted_4 = { class: "ConsigneeTitle" }
const _hoisted_5 = {
  key: 0,
  class: "ConsigneeList"
}
const _hoisted_6 = {
  key: 2,
  class: "ListTitle MallListTitle"
}
const _hoisted_7 = { class: "MallList MallListSkus" }
const _hoisted_8 = { class: "ShopName" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "image-slot" }
const _hoisted_11 = { class: "SkuName" }
const _hoisted_12 = { class: "SkuQuantity" }
const _hoisted_13 = { class: "SkuPrice" }
const _hoisted_14 = { class: "SkuTotalPrice" }
const _hoisted_15 = { class: "Sku_b" }
const _hoisted_16 = { class: "Remark" }
const _hoisted_17 = { class: "MallFreight" }
const _hoisted_18 = { class: "ListTitle" }
const _hoisted_19 = {
  key: 3,
  class: "SeaAmoyList"
}
const _hoisted_20 = {
  key: 0,
  class: "ShopName"
}
const _hoisted_21 = { class: "image-slot" }
const _hoisted_22 = { class: "SkuName" }
const _hoisted_23 = { class: "SkuQuantity" }
const _hoisted_24 = { class: "SkuPrice" }
const _hoisted_25 = { class: "SkuTotalPrice" }
const _hoisted_26 = { class: "Remark" }
const _hoisted_27 = { class: "RemarkImg" }
const _hoisted_28 = { class: "Sku_b" }
const _hoisted_29 = { class: "MallFreight" }
const _hoisted_30 = { class: "PurchasingConditions" }
const _hoisted_31 = { class: "isFlex" }
const _hoisted_32 = { class: "isTitle" }
const _hoisted_33 = { class: "isFlex" }
const _hoisted_34 = { class: "isTitle" }
const _hoisted_35 = { class: "isFlex" }
const _hoisted_36 = { class: "isTitle" }
const _hoisted_37 = { class: "isFlex" }
const _hoisted_38 = { class: "isTitle" }
const _hoisted_39 = { class: "isFlex tips" }
const _hoisted_40 = {
  key: 5,
  class: "Warehouse"
}
const _hoisted_41 = { class: "Title" }
const _hoisted_42 = { class: "isSubmit" }
const _hoisted_43 = { class: "TotalPrice" }

import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  watch,
  toRefs,
  getCurrentInstance,
} from 'vue'
import { ElLoading } from 'element-plus'
import { useRouter } from 'vue-router'
import * as Api from '@/utils/api'
import * as Sign from '@/utils/sign'
import AddressCard from '@/components/user-center/address_card.vue'
import Article from '@/components/article.vue'
import Loading from '@/components/loading.vue'
import SetAdress from '@/components/user-center/set_adress.vue'
import MallLabel from '@/components/mall_label.vue'
import OSS from '@/components/oss.vue'
import Repository from '@/components/user-center/repository.vue'
import GoogleTranslate from '@/components/GoogleTranslate.vue'
import Pay from '@/components/user-center/pay.vue'
import { useUsersStore } from '@/pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'place-order',
  setup(__props) {

const { proxy } = getCurrentInstance()
const store = useUsersStore()
const { AddressModified, SelectCarts, BuyNowList } = toRefs(store)
const localLan = localStorage.getItem('lang')
const userouter = useRouter()
const ShowWarehouse = ref(false)
const dialogInfo = reactive({
  isShow: false,
})
const SeaAmoyList = ref([])
const MallList = ref([])
const CustomerAddress: any = ref('Loading')
const TransnationalType = ref(null)
const CheckTransnationalType = ref(null)
const Insurance = ref(true)
const SplitOrder = ref(false)
const Print = ref(false)
const CartList = ref([])
const label = ref(null)
const UserWarehousesList = ref(null)
const DefaultWarehouse = ref(null)
const Transport = ref(false)
const ShowPay = ref(false)
const PayDate = ref(null)
const flag = (item) => {
  let id = item.list[0].PurchasingSiteId
  let Site = id == 1 ? 'zh-cn' : 'km'
  if (id == 1) {
    Transport.value = true
  }
  return require('../assets/imgs/' + Site + '.png')
}

const GetReceiveAddresses = () => {
  Api.Get('user/api/ReceiveAddresses_New/', {
    addressTypeId: 1,
  }).then((res: any) => {
    UserWarehousesList.value = res.ReceiveAddresses
  })
}

const Submit = () => {
  let OrderItems = []
  let SellerRemarks = []
  MallList.value.forEach((ele) => {
    SellerRemarks.push({
      ShopName: ele.ShopName,
      ShopRemark: ele.Remark,
      PaymentType: ele.PaymentTypeId,
    })
    ele.list.forEach((Sku) => {
      OrderItems.push({
        CartId: Sku.CartId,
        MallId: Sku.MallId,
        IsArriveStockOut: 0,
      })
    })
  })
  SeaAmoyList.value.forEach((ele) => {
    ele.list.forEach((Shop) => {
      Shop.list.forEach((Sku) => {
        OrderItems.push({
          CartId: Sku.CartId,
          MallId: Sku.MallId,
          IsArriveStockOut: Sku.IsArriveStockOut,
        })
      })
    })
  })
  const data = {
    OrderItems,
    NativeLogisticsCompanyId: 10,
    IsNeedInsurance: Api.FUC(true),
    // IsFreightDelayCharge: Api.FUC(true),
    LabelMessage: Print.value ? label.value : null,
    IsSplitOrder: Api.FUC(SplitOrder.value),
    AddressId: CustomerAddress.value.AddressId,
    Mobile: CustomerAddress.value.Mobile,
    WarehouseId: DefaultWarehouse.value.Id,
    OutId: Sign.Guid(),
    IsOneselfExtract: Api.FUC(true),
    TransportationType: CheckTransnationalType.value,
    SellerRemarks,
    IsFastSumbit: Api.FUC(BuyNowList.value ? true : false),
  }
  let loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  Api.Post('orders/api/createorder/', data).then(
    (res: any) => {
      loading.close()
      if (res.CanPayOrderNos.length > 0) {
        PayDate.value = {
          customer_order_no: res.CanPayOrderNos.join('|'),
          TotalAmount: res.CanPayTotalAmount,
        }
        ShowPay.value = true
      } else {
        userouter.push({
          path: '/userCenter/orders',
        })
      }
    },
    (err) => {
      loading.close()
    }
  )
}

const PayChange = () => {
  ShowPay.value = false
  userouter.push({
    path: '/userCenter/orders',
  })
}

const SkuRemark = (sku) => {
  Api.Put('cart/api/NewShoppingCart/', {
    CartIds: sku.CartId,
    Remark: sku.Remark,
  })
}

const UpImg = (img, sku) => {
  if (img.length) {
    Api.Put('cart/api/NewShoppingCart/', {
      RemarkImg: img[0].url,
      CartIds: sku.CartId,
    }).then((res: any) => {})
  } else {
    Api.Put('cart/api/NewShoppingCart/', {
      RemarkImg: 'del',
      CartIds: sku.CartId,
    }).then(() => {})
  }
}

const PaymentTypeId = (Shop) => {
  return (Shop.PaymentTypeId = Shop.Payments[0].PaymentTypeId)
}

const ShopTotalPrice = (Shop) => {
  let TotalPrice = 0
  Shop.list.forEach((ele) => {
    TotalPrice += ele.Price * ele.Quantity
  })
  TotalPrice += Shop.Freight || 0
  return proxy.$ccy(TotalPrice)
}

const CartTotalPrice = (Freightlist) => {
  let TotalPrice = CartList.value.reduce(
    (sum, e) => sum + e.Price * e.Quantity,
    0
  )
  let Freight = Freightlist.reduce((sum, e) => sum + Number(e.Freight || 0), 0)
  return proxy.$ccy(TotalPrice + Freight)
}

const FreightLoading = ref(false)

const ProductFreights = () => {
  let CartId = ''
  MallList.value.forEach((Shop) => {
    PaymentTypeId(Shop)
    Shop.list.forEach((Sku) => {
      CartId += Sku.CartId + '|'
    })
  })

  const data = {
    CartId,
    AddressId: CustomerAddress.value.AddressId,
    isFastSubmit: BuyNowList.value ? true : false,
  }
  FreightLoading.value = true
  Api.Get('cart/api/ProductFreights/', data).then((res: any) => {
    FreightLoading.value = false
    if (res.SellerFreights.length) {
      MallList.value = MergeArray(
        MallList.value,
        res.SellerFreights,
        'ShopName'
      )
    }
  })
}

const MergeArray = (a, b, name) => {
  let newJson = [] //盛放去重后数据的新数组
  a.forEach((item) =>
    b.forEach(
      (value) =>
        item[name] === value[name] && newJson.push({ ...item, ...value })
    )
  )
  return newJson
}

const GetCustomerDefaultAddress = () => {
  Api.Get('user/api/GetCustomerDefaultAddress', {}).then((res: any) => {
    CustomerAddress.value = res
    if (MallList.value.length > 0) {
      ProductFreights()
    }
  })
}

const SetAddress = (Warehouses) => {
  CustomerAddress.value = Warehouses
  if (MallList.value.length > 0) {
    ProductFreights()
  }
}

const SetWarehouse = (val) => {
  DefaultWarehouse.value.Id = val.params.WarehouseId
  DefaultWarehouse.value.Name = val.params.WarehouseName
  ShowWarehouse.value = false
}

const GetCustomerDefaultWarehouse = () => {
  Api.Get('user/api/GetCustomerDefaultWarehouse/', {}).then((res: any) => {
    DefaultWarehouse.value = {
      Id: res.DefaultWareId,
      Name: res.DefaultWareName,
    }
  })
}

const GetTransnationalTypeNew = () => {
  Api.Get('basic/api/TransnationalTypeNew/', { type_id: 1 }).then(
    (res: any) => {
      TransnationalType.value = res.Types
      CheckTransnationalType.value = res.Types[0].ID
    }
  )
}

const GetSubmitOrders = () => {
  const CartIds = []
  SelectCarts.value &&
    SelectCarts.value.forEach((element) => {
      CartIds.push(element.Id)
    })
  Api.Post('cart/api/GetSubmitOrders/', {
    CartIds: BuyNowList.value ? null : CartIds,
    SubmitItem: BuyNowList.value,
  }).then((res: any) => {
    CartList.value = res.ShoppingCartList

    let GroupingList = ArrayGrouping(res.ShoppingCartList, 'MallId')
    for (const i in GroupingList) {
      GroupingList[i].list = ArrayGrouping(
        GroupingList[i].list,
        'ShopName',
        'ShopId'
      )
    }
    const isMall = GroupingList.findIndex((item) => item.MallId == '111036')
    if (isMall != -1) {
      MallList.value = GroupingList[isMall].list
      MallList.value = MergeArray(MallList.value, res.PaymentTypes, 'ShopId')
      GroupingList.splice(isMall, 1)
    }
    SeaAmoyList.value = GroupingList
    GetCustomerDefaultAddress()
  })
}

const ArrayGrouping = (list, name, id = undefined) => {
  const arr = []
  list.forEach((ele) => {
    const num = arr.findIndex((item) => item[name] == ele[name])
    if (num == -1) {
      const data: any = {}
      data[name] = ele[name]
      data[id] = ele[id]
      data.list = [ele]
      arr.push(data)
    } else {
      arr[num].list.push(ele)
    }
  })
  return arr
}

watch(
  () => AddressModified.value,
  (val) => {
    if (val) {
      if (UserWarehousesList.value) {
        GetReceiveAddresses()
      } else {
        GetCustomerDefaultAddress()
      }
    }
  },
  {
    deep: true,
  }
)

onUnmounted(() => {
  useUsersStore().BuyNowList = null
  useUsersStore().SelectCarts = null
})

onMounted(() => {
  if (!BuyNowList.value && !SelectCarts.value) {
    userouter.push({
      path: '/',
    })
    return false
  }
  GetTransnationalTypeNew()
  GetCustomerDefaultWarehouse()
  GetSubmitOrders()
})

return (_ctx: any,_cache: any) => {
  const _component_Picture = _resolveComponent("Picture")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", null, [
          _createElementVNode("span", null, [
            _cache[9] || (_cache[9] = _createElementVNode("b", null, "1", -1)),
            _createTextVNode(_toDisplayString(_ctx.$t('购物车')), 1)
          ])
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("span", null, [
            _cache[10] || (_cache[10] = _createElementVNode("b", null, "2", -1)),
            _createTextVNode(_toDisplayString(_ctx.$t('提交订单')), 1)
          ])
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("span", null, [
            _cache[11] || (_cache[11] = _createElementVNode("b", null, "3", -1)),
            _createTextVNode(_toDisplayString(_ctx.$t('正在审核')), 1)
          ])
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("span", null, [
            _cache[12] || (_cache[12] = _createElementVNode("b", null, "4", -1)),
            _createTextVNode(_toDisplayString(_ctx.$t('确认支付')), 1)
          ])
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("span", null, [
            _cache[13] || (_cache[13] = _createElementVNode("b", null, "5", -1)),
            _createTextVNode(_toDisplayString(_ctx.$t('确认收货')), 1)
          ])
        ])
      ]),
      (CustomerAddress.value == 'Loading')
        ? (_openBlock(), _createBlock(Loading, {
            key: 0,
            type: 8
          }))
        : _createCommentVNode("", true),
      (CustomerAddress.value != 'Loading')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('收货人信息')), 1),
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (dialogInfo.isShow = true))
              }, _toDisplayString(_ctx.$t('新增收货地址')), 1)
            ]),
            (CustomerAddress.value != 'Loading' && CustomerAddress.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (!UserWarehousesList.value)
                    ? (_openBlock(), _createBlock(AddressCard, {
                        key: 0,
                        item: CustomerAddress.value,
                        isDefault: true,
                        index: 0
                      }, null, 8, ["item"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(UserWarehousesList.value, (Warehouses, index) => {
                    return _withDirectives((_openBlock(), _createBlock(AddressCard, {
                      item: Warehouses,
                      index: index,
                      isDefault: Warehouses.AddressId == CustomerAddress.value.AddressId,
                      key: index,
                      onClick: ($event: any) => (SetAddress(Warehouses))
                    }, null, 8, ["item", "index", "isDefault", "onClick"])), [
                      [_directive_loading, FreightLoading.value]
                    ])
                  }), 128)),
                  (!UserWarehousesList.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "AddressesPlaceholder",
                        onClick: GetReceiveAddresses
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('显示全部')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(!UserWarehousesList.value
            ? 4
            : 6 - (UserWarehousesList.value.length % 6), (placeholder) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: placeholder,
                      class: "AddressesPlaceholder"
                    }))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (MallList.value.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('商品信息')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('数量')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('单价')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('合计')), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(MallList.value, (Shop, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "Shop"
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(MallLabel),
              _createTextVNode(" " + _toDisplayString(Shop.ShopName) + " ", 1),
              _createElementVNode("img", {
                class: "flag",
                src: flag(Shop)
              }, null, 8, _hoisted_9)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Shop.list, (Sku, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "Sku",
                key: index
              }, [
                _createVNode(_component_el_image, {
                  class: "SkuImg",
                  fit: "scale-down",
                  src: Sku.GoodsImageUrl
                }, {
                  error: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Picture)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 2
                }, 1032, ["src"]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", null, _toDisplayString(Sku.GoodsName), 1),
                  _createElementVNode("span", null, _toDisplayString(Sku.SkuTitle), 1)
                ]),
                _createElementVNode("div", _hoisted_12, _toDisplayString(Sku.Quantity), 1),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$ccy(Sku.Price)), 1),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$ccy(Sku.Quantity * Sku.Price)), 1)
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('留言')) + ":", 1),
                _createVNode(_component_el_input, {
                  modelValue: Shop.Remark,
                  "onUpdate:modelValue": ($event: any) => ((Shop.Remark) = $event),
                  rows: 2,
                  type: "textarea",
                  placeholder: "留言"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, _toDisplayString(_ctx.$t('支付方式')) + " :", 1),
                  _createElementVNode("li", null, _toDisplayString(_ctx.$t('运费')) + " :", 1),
                  _createElementVNode("li", null, _toDisplayString(_ctx.$t('店铺合计(含运费)')) + " :", 1)
                ]),
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, [
                    _createVNode(_component_el_radio_group, {
                      modelValue: Shop.PaymentTypeId,
                      "onUpdate:modelValue": ($event: any) => ((Shop.PaymentTypeId) = $event)
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Shop.Payments, (Payments) => {
                          return (_openBlock(), _createBlock(_component_el_radio, {
                            key: Payments.PaymentTypeId,
                            label: Payments.PaymentTypeId
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(Payments.PaymentValue), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _createElementVNode("li", null, _toDisplayString(_ctx.$ccy(Shop.Freight || 0)), 1),
                  _createElementVNode("li", null, _toDisplayString(ShopTotalPrice(Shop)), 1)
                ])
              ])
            ])
          ]))
        }), 128))
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_18, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('商品信息')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('数量')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('单价')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('合计')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('备注')), 1)
      ], 512), [
        [_vShow, SeaAmoyList.value.length]
      ]),
      (SeaAmoyList.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(SeaAmoyList.value, (SeaAmoy, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "MallList",
                key: index
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(SeaAmoy.list, (Shop, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "Shop"
                  }, [
                    (_unref(localLan) == 'zh-cn')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _cache[14] || (_cache[14] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
                          _createTextVNode(" " + _toDisplayString(Shop.ShopName), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Shop.list, (Sku, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "Sku",
                        key: index
                      }, [
                        _createVNode(_component_el_image, {
                          class: "SkuImg",
                          fit: "scale-down",
                          src: Sku.GoodsImageUrl
                        }, {
                          error: _withCtx(() => [
                            _createElementVNode("div", _hoisted_21, [
                              _createVNode(_component_el_icon, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Picture)
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 2
                        }, 1032, ["src"]),
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("span", null, [
                            _createVNode(GoogleTranslate, {
                              text: Sku.GoodsName,
                              type: null,
                              MallId: Sku.MallId
                            }, null, 8, ["text", "MallId"])
                          ]),
                          _createElementVNode("span", null, [
                            _createVNode(GoogleTranslate, {
                              text: Sku.SkuTitle,
                              type: null,
                              MallId: Sku.MallId
                            }, null, 8, ["text", "MallId"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_23, _toDisplayString(Sku.Quantity), 1),
                        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$ccy(Sku.Price)), 1),
                        _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$ccy(Sku.Quantity * Sku.Price)), 1),
                        _createElementVNode("div", _hoisted_26, [
                          _createVNode(_component_el_input, {
                            modelValue: Sku.Remark,
                            "onUpdate:modelValue": ($event: any) => ((Sku.Remark) = $event),
                            rows: 2,
                            type: "textarea",
                            placeholder: _ctx.$t('留言'),
                            onBlur: ($event: any) => (SkuRemark(Sku))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "onBlur"]),
                          _createElementVNode("span", _hoisted_27, [
                            _createVNode(OSS, {
                              type: 9,
                              imgList: Sku.RemarkImg,
                              onImgsChange: (message) => UpImg(message, Sku)
                            }, null, 8, ["imgList", "onImgsChange"])
                          ])
                        ])
                      ]))
                    }), 128)),
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("ul", null, [
                          _createElementVNode("li", null, _toDisplayString(_ctx.$t('店铺合计')) + " :", 1)
                        ]),
                        _createElementVNode("ul", null, [
                          _createElementVNode("li", null, _toDisplayString(ShopTotalPrice(Shop)), 1)
                        ])
                      ])
                    ])
                  ]))
                }), 128))
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (!CartList.value.length)
        ? (_openBlock(), _createBlock(Loading, {
            key: 4,
            type: 13
          }))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_30, [
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('运输方式')), 1),
            _cache[15] || (_cache[15] = _createTextVNode("  ")),
            _createVNode(Article, { id: 5 }, {
              name: _withCtx(() => [
                _createTextVNode(" (" + _toDisplayString(_ctx.$t('查看禁运品')) + ") ", 1)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_el_radio_group, {
            modelValue: CheckTransnationalType.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((CheckTransnationalType).value = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(TransnationalType.value, (Type) => {
                return (_openBlock(), _createBlock(_component_el_radio, {
                  key: Type,
                  label: Type.ID
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(Type.TypeName + Type.Days), 1)
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createVNode(_component_el_checkbox, {
              modelValue: Insurance.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((Insurance).value = $event)),
              label: _ctx.$t('商品保险费(非人为破损100%退款)'),
              disabled: ""
            }, null, 8, ["modelValue", "label"]),
            _createVNode(Article, { id: 4 }, {
              name: _withCtx(() => [
                _createTextVNode(" 《" + _toDisplayString(_ctx.$t('商品质量保障协议')) + "》 ", 1)
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _createVNode(_component_el_checkbox, {
              modelValue: SplitOrder.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((SplitOrder).value = $event)),
              label: _ctx.$t('是否拆单')
            }, null, 8, ["modelValue", "label"])
          ])
        ]),
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("div", _hoisted_38, [
            _createVNode(_component_el_checkbox, {
              modelValue: Print.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((Print).value = $event)),
              label: _ctx.$t('打印标签')
            }, null, 8, ["modelValue", "label"])
          ]),
          (Print.value)
            ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                modelValue: label.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((label).value = $event)),
                rows: 2,
                type: "textarea",
                placeholder: "Please input"
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$t(
            '备注 : 以发往国际物流仓的时间开始计算,不包含中国采购时间,如果有节假日,就顺延几天节假日的时间'
          )), 1)
      ], 512), [
        [_vShow, SeaAmoyList.value.length || Transport.value]
      ]),
      ((DefaultWarehouse.value && SeaAmoyList.value.length) || Transport.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
            _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.$t('提货仓库')), 1),
            _createVNode(_component_el_radio_group, {
              modelValue: DefaultWarehouse.value.Id,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((DefaultWarehouse.value.Id) = $event)),
              size: "large"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio, {
                  label: DefaultWarehouse.value.Id
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(DefaultWarehouse.value?.Name), 1)
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _withDirectives(_createVNode(_component_el_button, {
              type: "danger",
              icon: "HomeFilled",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (ShowWarehouse.value = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('选择仓库')), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, DefaultWarehouse.value]
            ])
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
            (Transport.value)
              ? (_openBlock(), _createBlock(Loading, {
                  key: 0,
                  type: 4
                }))
              : _createCommentVNode("", true)
          ], 64)),
      _createElementVNode("div", _hoisted_42, [
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_43, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('应付总额')) + ":", 1),
            _createElementVNode("span", null, _toDisplayString(CartTotalPrice(MallList.value)), 1)
          ]),
          _createVNode(_component_el_button, {
            type: "danger",
            class: "Submit",
            size: "large",
            onClick: Submit,
            disabled: CustomerAddress.value == 'Loading' || !CustomerAddress.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('提交订单')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    _createVNode(SetAdress, { dialogInfo: dialogInfo }, null, 8, ["dialogInfo"]),
    _createVNode(_component_el_dialog, {
      width: "600px",
      modelValue: ShowWarehouse.value,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((ShowWarehouse).value = $event)),
      title: _ctx.$t('提货仓库')
    }, {
      default: _withCtx(() => [
        _createVNode(Repository, {
          noSet: true,
          Warehouse: DefaultWarehouse.value,
          onWarehouseChange: SetWarehouse
        }, null, 8, ["Warehouse"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(Pay, {
      Date: PayDate.value,
      ShowPay: ShowPay.value,
      onPayChange: PayChange,
      PayType: "OrderNos"
    }, null, 8, ["Date", "ShowPay"])
  ], 64))
}
}

})