import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "google_map" }
const _hoisted_2 = { style: {"position":"relative"} }
const _hoisted_3 = { class: "isAutoComplete" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { style: {"margin":"20px 0"} }
const _hoisted_6 = { style: {"display":"flex"} }

import { ref, watch, onMounted } from 'vue'
import { GoogleMap, Marker } from 'vue3-google-map'
import { SiteSet } from '@/utils/siteConfig'
import { mapKey } from '@/main'
// var google

export default /*@__PURE__*/_defineComponent({
  __name: 'google_map',
  props: {
  isMap: Object,
},
  setup(__props) {

const center = ref(SiteSet().DefaultMapcenter)
const mapRef = ref(null)
const props = __props

const isLoding = ref(false)
const _isMap = props.isMap
const gmap = ref(null)
const sessionToken = ref(null)
const service = ref(null)
const placesService = ref(null)

const WatchMap = (isMap) => {
  isLoding.value = true
  sessionToken.value = new google.maps.places.AutocompleteSessionToken()
  service.value = new google.maps.places.AutocompleteService()
  placesService.value = new google.maps.places.PlacesService(isMap.map)
  gmap.value = isMap
  isMap.map.addListener('click', (mapsMouseEvent) => {
    center.value = {
      lat: mapsMouseEvent.latLng.lat(),
      lng: mapsMouseEvent.latLng.lng(),
    }
  })
}

const AutocompleteList = ref([])

let timeout: ReturnType<typeof setTimeout>;
const placeName = ref('')
const AutoComplete = (val) => {
  AutocompleteList.value = []
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    if (val) {
      const displaySuggestions = (predictions, status) => {
        if (status == 'OK') {
          AutocompleteList.value = predictions
        }
      }
      service.value.getPlacePredictions(
        {
          componentRestrictions: { country: [SiteSet().getPlacePredictions] },
          input: val,
          sessionToken: sessionToken.value,
        },
        displaySuggestions
      )
    }
  }, 2000)
}

const handleClose = (done: () => void) => {
  done()
}
const Address = ref(null)
const convert = (placeId) => {
  AutocompleteList.value = []
  placesService.value.getDetails({ placeId }, (details) => {
    let location = details.geometry.location
    Address.value = {
      lat: location.lat(),
      lng: location.lng(),
      address: details.name,
    }
    gmap.value.map.setZoom(18)
    center.value = { lat: location.lat(), lng: location.lng() }
    gmap.value.map.setCenter(center.value)
  })
}

const setAddress = () => {
  new google.maps.Geocoder().geocode({ location: center.value }, (results) => {
    if (results) {
      let DistrictName = null
      let CityName = null
      results.find((result) => {
        result.address_components.find((address) => {
          address.types.find((types) => {
            DistrictName =
              types == 'administrative_area_level_2'
                ? address.long_name
                : DistrictName
            CityName =
              types == 'administrative_area_level_1'
                ? address.long_name
                : CityName
          })
        })
      })
      Address.value = {
        lat: center.value.lat,
        lng: center.value.lng,
        address: results.length > 0 ? results[0].formatted_address : null,
        CityName,
        DistrictName,
      }
      _isMap.address = Address.value
      _isMap.isShow = false
    }
  })
}

watch(
  () => mapRef.value?.ready,
  (ready) => {
    if (!ready) return
    WatchMap(mapRef.value)
  }
)

watch(
  () => _isMap.address,
  (val) => {
    if (val && val.lat) {
      timeSetCenter(val)
    }
  }
)

let timeout2: ReturnType<typeof setTimeout>;
const timeSetCenter = (val) => {
  clearTimeout(timeout2)
  if (!gmap.value) {
    timeout2 = setTimeout(() => {
      timeSetCenter(val)
    }, 1000)
  } else {
    center.value = { lat: Number(val.lat), lng: Number(val.lng) }
    gmap.value.map.setCenter({ lat: Number(val.lat), lng: Number(val.lng) })
  }
}

onMounted(() => { })

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _unref(_isMap).isShow,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(_isMap).isShow) = $event)),
    modal: true,
    title: _ctx.$t('收货地址'),
    width: "50%",
    "before-close": handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createVNode(_component_el_input, {
            modelValue: placeName.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((placeName).value = $event)),
            onInput: AutoComplete,
            placeholder: _ctx.$t('请输入地址')
          }, null, 8, ["modelValue", "placeholder"]), [
            [_vShow, isLoding.value]
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(AutocompleteList.value, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.place_id,
                onClick: ($event: any) => (convert(item.place_id))
              }, _toDisplayString(item.description), 9, _hoisted_4))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(GoogleMap), {
            ref_key: "mapRef",
            ref: mapRef,
            "api-key": _unref(mapKey),
            style: {"width":"100%","height":"500px"},
            center: center.value,
            zoom: 15,
            "disable-default-ui": true,
            language: "en"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(Marker), {
                options: { position: center.value }
              }, null, 8, ["options"])
            ]),
            _: 1
          }, 8, ["api-key", "center"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: setAddress
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('确定')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})