import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "IsMobile",
  href: "https://wap.loda.com.kh"
}

import Head from '@/components/Head.vue'
import Bottom from '@/components/Bottom.vue'
import { useRouter, useRoute } from 'vue-router'
import BackTop from '@/components/back_top.vue'
import { useUsersStore } from '@/pinia'
import * as Api from '@/utils/api'
import {
  toRefs,
  onMounted,
  ref,
  nextTick,
  getCurrentInstance,
  watch,
} from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { proxy } = getCurrentInstance()
const store = useUsersStore()
const { Token } = toRefs(store)
const isRouterAlive = ref(true)
const Router = useRouter()
const route = useRoute()
const ShowBottom = ref(false)
const IsMobile = ref(false)

const reload = () => {
  isRouterAlive.value = false
  nextTick(() => {
    isRouterAlive.value = true
  })
}

proxy?.$Bus.on('reload', reload)

const notAllowRoutes = [
  '/login',
  '/userCenter',
  '/placeOrder',
  '/express/international',
  '/recoverPassword',
  '/register',
  '/InvitationRegister',
]

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    let userStore = JSON.parse(localStorage.getItem('userStore'))
    let route = window.history.state
    let newToken = localStorage.getItem('token')
    if (Token.value != newToken) {
      if (newToken) {
        if (route.current == '/login') {
          store.setToken(newToken)
          ChckToken()
        } else {
          store.setToken(newToken)
          if (
            notAllowRoutes.find((i) => {
              return route.current.includes(i)
            })
          ) {
            Router.push('/')
          }
        }
        // route.current == route.forward?:
      } else {
        store.setToken(null)
        // store.logout()
      }
    }
  }
})

const fIsMobile = () => {
  return /Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

const ChckToken = () => {
  let route = window.history.state
  let userStore = JSON.parse(localStorage.getItem('userStore'))
  if (Token.value && route.current == '/login') {
    if (window.history.state.back) {
      if (
        notAllowRoutes.find((i) => {
          return route.back.includes(i)
        })
      ) {
        Router.push('/')
      } else {
        Router.back()
      }
    } else {
      Router.push('/')
    }
  }
}

const isInvitationRegister = ref()

watch(
  route,
  (newValue, oldValue) => {
    isInvitationRegister.value = newValue.path == '/InvitationRegister'
  },
  {}
)

onMounted(() => {
  let newToken = localStorage.getItem('token')
  if (newToken && !Token.value) {
    store.setToken(newToken)
  }

  IsMobile.value = fIsMobile()

  ChckToken()
  setTimeout(() => {
    ShowBottom.value = true
  }, 300)
  const script = document.createElement('script')
  const script2 = document.createElement('script')
  const head = document.getElementsByTagName('head')[0]
  const script1 = document.getElementById('goo')
  if (script1 === null) {
    script.async = true
    script.id = 'goo'
    script.src = proxy?.$SiteSet['LODAGoogle']
    head.appendChild(script)
    script2.innerHTML = proxy?.$SiteSet['LODAGoogleCode']
    head.appendChild(script2)
  }
  Api.Get('basic/api/GetAppConfig', { ApiName: 'api/GetAppConfig', channel: 1 }).then((res: any) => {
    const obj = {
      googleMapKey: res?.googleMapKey,
      iframeUrl: res?.iframeUrl,
      loginConfig: res?.loginConfig,
      needEmailRegister: res?.needEmailRegister,
      shareLink: res?.shareLink,
      wechatLink: res?.wechatLink,
      LoginNameRegex: res?.LoginNameRegex,
    };
    localStorage.setItem('GetAppConfig', JSON.stringify(obj));
  })

})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Iphone = _resolveComponent("Iphone")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (!isInvitationRegister.value)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(Head),
        (isRouterAlive.value)
          ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
          : _createCommentVNode("", true),
        (ShowBottom.value)
          ? (_openBlock(), _createBlock(Bottom, { key: 1 }))
          : _createCommentVNode("", true),
        _createVNode(BackTop),
        (IsMobile.value)
          ? (_openBlock(), _createElementBlock("a", _hoisted_1, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Iphone)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ], 64))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}
}

})